import React from 'react';
import styled from 'styled-components';

const SVGWrapper = styled.svg`
  width: 24px;
  height: 24px;
  flex: none;
`;

const StarPath = styled.path`
  fill: ${props => {
    switch (props.color) {
      case 'bronze':
        return '#CD7F32';
      case 'silver':
        return '#C0C0C0';
      case 'gold':
        return '#FFD700';
      case 'platinum':
        return '#E5E4E2';
      case 'diamond':
        return '#B9F2FF';
      default:
        return '#CCCCCC';
    }
  }};
`;

const ClubIconMember = ({ color }) => {
  return (
    <SVGWrapper viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <StarPath
        color={color}
        d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
      />
      <StarPath
        color={color}
        d="M12 4.36L9.74 8.86L4.56 9.59L8.28 13.2L7.41 18.35L12 15.93L16.59 18.35L15.72 13.2L19.44 9.59L14.26 8.86L12 4.36Z"
        fillOpacity="0.3"
      />
    </SVGWrapper>
  );
};

export default ClubIconMember;