import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.size || '24px'};
  height: ${props => props.size || '24px'};
  flex: none;
  transition: transform 0.2s ease;
  
  & path, & circle {
    fill: ${props => props.fill || '#B3BEC1'};
  }
`;

const NotificationsSkyla = ({ size, fill, className }) => {
  return (
    <StyledSVG 
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      fill={fill}
      className={className}
    >
      <circle 
        cx="17.5869" 
        cy="24.0712" 
        r="2.90558" 
        transform="rotate(-15 17.5869 24.0712)" 
      />
      <path 
        d="M24.1189 16.055L25.7539 17.9422C26.0732 18.152 26.3332 18.4567 26.5015 18.8182C26.6699 19.1797 26.7389 19.5821 26.7002 19.975C26.6614 20.3678 26.5165 20.7338 26.2835 21.0271C26.0506 21.3203 25.74 21.5279 25.3905 21.6239L21.7425 22.6014L19.571 23.1832L15.2281 24.3469L13.0566 24.9288L9.40857 25.9063C9.05793 25.9979 8.68512 25.9734 8.33676 25.8359C7.98839 25.6984 7.67992 25.4539 7.44991 25.1331C7.2199 24.8122 7.07855 24.4292 7.04353 24.032C7.00852 23.6347 7.08139 23.2408 7.25304 22.8995L7.47901 20.5136C7.29893 17.5003 6.5022 14.0972 6.8904 11.1031C7.04095 9.94195 7.41895 8.84145 7.99918 7.87502C8.57941 6.90859 9.34853 6.09846 10.2552 5.49867C11.1619 4.89889 12.1853 4.52324 13.2571 4.3968C15.1153 4.22128 17.0024 4.78814 18.57 5.99271C21.5499 8.2825 22.6057 12.7441 24.1189 16.055Z"
      />
    </StyledSVG>
  );
};

export default NotificationsSkyla;