import React from 'react';
import styled from 'styled-components';

const PolicyContainer = styled.div`
  background-color: #323738;
  color: #b9bbbe;
  padding: 24px;
  border-radius: 20px;
`;

const PolicyTitle = styled.div`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
`;

const PolicySection = styled.div`
  margin-bottom: 30px;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  margin-bottom: 10px;
  line-height: 1.6;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const  PrivacyPolicyHelp = () => (
  <PolicyContainer>
    <PolicyTitle>Privacy Policy</PolicyTitle>
    
    <PolicySection>
      <SectionContent>
        You hereby acknowledge and accept that if we deem necessary, we are able to collect and otherwise use your personal
        data in order to allow you access and use of the Websites and in order to allow you to participate in the Games.
      </SectionContent>
      <SectionContent>
        We hereby acknowledge that in collecting your personal details as stated in the previous provision, we are bound by the
        Data Protection Act. We will protect your personal information and respect your privacy in accordance with best business
        practices and applicable laws.
      </SectionContent>
      <SectionContent>
        We will use your personal data to allow you to participate in the Games and to carry out operations relevant to your
        participation in the Games. We may also use your personal data to inform you of changes, new services and promotions
        that we think you may find interesting. If you do not wish to receive such direct marketing correspondences, you may opt
        out of the service.
      </SectionContent>
      <SectionContent>
        Your personal data will not be disclosed to third parties, unless it is required by law. As Games business partners
        or suppliers or service providers may be responsible for certain parts of the overall functioning or operation of the Website,
        personal data may be disclosed to them. The employees of Games have access to your personal data for the
        purpose of executing their duties and providing you with the best possible assistance and service. You hereby consent to
        such disclosures.
      </SectionContent>
      <SectionContent>
        We shall keep all information provided as personal data. You have the right to access personal data held by us about you.
        No data shall be destroyed unless required by law, or unless the information held is no longer required to be kept for the
        purpose of the relationship.
      </SectionContent>
      <SectionContent>
        In order to make your visit to the Websites more user-friendly, to keep track of visits to the Websites and to improve the
        service, we collect a small piece of information sent from your browser, called a cookie. You can, if you wish, turn off the
        collection of cookies. You must note, however, that turning off cookies may severely restrict or completely hinder your use
        of the Websites.
      </SectionContent>
    </PolicySection>

    <PolicySection>
      <SectionTitle>Cookies Policy</SectionTitle>
      <SectionContent>
        <strong>1. What are cookies?</strong>
        <br />
        A cookie is a piece of information in the form of a very small text file that is placed on an internet user's computer. It is
        generated by a web page server (which is basically the computer that operates the website) and can be used by that
        server whenever the user visits the site. A cookie can be thought of as an internet user's identification card, which tells a
        website when the user has returned. Cookies can't harm your computer and we don't store any personally identifiable
        information about you on any of our cookies.
      </SectionContent>
      <SectionContent>
        <strong>2. Why do we use cookies on Games?</strong>
        <br />
        Games uses two types of cookies: cookies set by us and cookies set by third parties (i.e. other websites or
        services). Games cookies enable us to keep you signed in to your account throughout your visit and to tailor the
        information displayed on the site to your preferences.
      </SectionContent>
      <SectionContent>
        <strong>3. What cookies do we use on Games?</strong>
        <br />
        Below is a list of the main cookies set by Games, and what each is used for:
      </SectionContent>
      <List>
        <ListItem>_fp - stores browser's fingerprint. Lifetime: forever.</ListItem>
        <ListItem>_t - stores timestamp when user firstly visited site in current browsing session. Needed for unique visits statistic. Lifetime: browsing session.</ListItem>
        <ListItem>_r - stores http referrer for current browsing session. Needed in order to external track traffic sources. Lifetime: browsing session.</ListItem>
        <ListItem>_c - stores identifier of affiliate campaign. Needed for affiliate statistic. Lifetime: forever.</ListItem>
      </List>
      <SectionContent>
        Cookies set by third parties for wildcard domain: *.Games
      </SectionContent>
      <List>
        <ListItem>Google analytics: _ga, _gat, _gid</ListItem>
        <ListItem>Zendesk: __ zlcmid</ListItem>
        <ListItem>Cloudflare: __ cfuid</ListItem>
      </List>
      <SectionContent>
        Please keep in mind that some browsers (i.e. chrome on mac) keep background processes running even if no tabs opened
        due to this session cookies may left set between sessions.
      </SectionContent>
      <SectionContent>
        There are also cookies set by third party scripts to their domains.
      </SectionContent>
      <SectionContent>
        <strong>4. How can I manage my cookies on Games?</strong>
        <br />
        If you wish to stop accepting cookies, you can do so through the Privacy Settings option in your browser.
      </SectionContent>
    </PolicySection>

    <PolicySection>
      <SectionTitle>5. Personal Data Protection Policy</SectionTitle>
      <SectionContent>
        Games's main goal is to keep your Data safe and for this reason we protect your data in various ways. We provide:
      </SectionContent>
    </PolicySection>

    <PolicySection>
      <SectionTitle>6. Server Protection Policy</SectionTitle>
      <List>
        <ListItem>All servers have full encryption;</ListItem>
        <ListItem>All backups have encryption;</ListItem>
        <ListItem>Firewalls, VPN Access;</ListItem>
        <ListItem>Access to servers allowed only over VPN;</ListItem>
        <ListItem>All http/s services work over Cloudflare;</ListItem>
        <ListItem>Connections to nodes over VPN;</ListItem>
        <ListItem>SSH port forwarding tunnels;</ListItem>
        <ListItem>Services allowed only over VPN;</ListItem>
        <ListItem>Server have firewall and allowed only SSH port;</ListItem>
        <ListItem>Alerts on critical services.</ListItem>
      </List>
    </PolicySection>

    <PolicySection>
      <SectionTitle>Data Breach Notification</SectionTitle>
      <SectionContent>
        When Games will be made aware of personal data breaches we will notify relevant users in accordance with
        GDPR timeframes.
      </SectionContent>
    </PolicySection>

    <PolicySection>
      <SectionTitle>7. Data International Transfer</SectionTitle>
      <SectionContent>
        We only disclose personal data to third parties where it is necessary to provide the high-quality service or in order to
        respond lawful requests from authorities.
      </SectionContent>
      <SectionContent>
        We share the following data to third party systems:
      </SectionContent>
      <SectionContent>
        Zendesk Inc. – username and e-mail information is transferred if user sends a message to live-chat or sends an e-mail to
        support mailbox.
      </SectionContent>
    </PolicySection>

    <PolicySection>
      <SectionTitle>Technical issues</SectionTitle>
      <SectionContent>
        Although we try to do our best, problems could occur now and then. Our team will do everything we could to solve your
        problems as soon as possible. To assist you quicker, You can join us by clicking the button above to join the telegram
        group.
      </SectionContent>
      <SectionContent>
        If an error occurs, please provide the following information:
      </SectionContent>
      <List>
        <ListItem>Username</ListItem>
        <ListItem>Date and time of the problem</ListItem>
      </List>
    </PolicySection>

    <PolicySection>
      <SectionTitle>Information from Third-Party Social Media Services</SectionTitle>
      <SectionContent>
        Games allows You to create an account and log in to use the Service through the following Third-party Social
        Media Services:
      </SectionContent>
      <List>
        <ListItem>Google</ListItem>
        <ListItem>Facebook</ListItem>
        <ListItem>Telegram</ListItem>
        <ListItem>Metamask</ListItem>
        <ListItem>Web3.0</ListItem>
      </List>
      <SectionContent>
        If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect
        Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your
        email address, Your activities or Your contact list associated with that account.
      </SectionContent>
      <SectionContent>
        You may also have the option of sharing additional information with the Games through Your Third-Party Social
        Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise,
        You are giving Games permission to use, share, and store it in a manner consistent with this Privacy Policy.
      </SectionContent>
    </PolicySection>

    <PolicySection>
      <SectionTitle>Delete Personal Data</SectionTitle>
      <SectionContent>
        You can request to have your personal data deleted if Games no longer have a legal reason to continue to
        process or store it. Please note that this right is not guaranteed - in the sense that Games do not have the ability
        to comply with your request if it is subject to a legal obligation to store your data. You can request the deletion of your
        personal data by sending an email to support@nanogames.io
      </SectionContent>
    </PolicySection>
  </PolicyContainer>
);

export default  PrivacyPolicyHelp;