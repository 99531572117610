import React, { useState } from 'react';
import { Search, Users, FolderPlus } from 'lucide-react';
import styled from 'styled-components';
import MemberDetails from './MembersInfo';


const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const TabContainer = styled.div`
  background-color: #24272c;
  border-radius: 6px;
  display: flex;
  margin-bottom: 15px;
  overflow: hidden;
`;

const Tab = styled.button`
  background: ${props => props.active ? '#5CEB7F' : 'transparent'};
  border: none;
  color: ${props => props.active ? 'white' : '#6b7280'};
  cursor: pointer;
  flex: 1;
  padding: 10px 8px;
  transition: all 0.3s ease;
  font-weight: ${props => props.active ? '600' : '400'};
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  
  &:hover {
    color: ${props => props.active ? 'white' : '#a1a1aa'};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#5CEB7F' : 'transparent'};
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

const SearchInput = styled.input`
  padding: 8px 8px 8px 35px;
  border-radius: 6px;
  font-size: 14px;
  border: 1px solid rgba(82, 236, 130, 0.2);
  width: 100%;
  background-color: #3a3d42;
  color: white;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #5CEB7F;
    box-shadow: 0 0 8px rgba(92, 235, 127, 0.2);
  }
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #6b7280;
`;

const MemberList = styled.div`
  border: 1px solid rgba(82, 236, 130, 0.2);
  border-radius: 6px;
  overflow: hidden;
`;

const MemberItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #3a3d42;
  border-bottom: 1px solid rgba(82, 236, 130, 0.1);
  transition: background-color 0.3s ease;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #464b52;
  }
`;

const NoMembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  text-align: center;
  color: #8b8b8b;
`;

const NoMembersTitle = styled.h3`
  margin-top: 10px;
  font-size: 16px;
  color: white;
`;

const NoMembersDescription = styled.p`
  margin-top: 8px;
  font-size: 12px;
`;

const MemberAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid #5CEB7F;
`;

const MemberInfo = styled.div`
  flex: 1;
`;

const MemberName = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: white;
`;

const MemberRole = styled.div`
  font-size: 12px;
  color: #8b8b8b;
`;

const MemberStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
`;

const Members = (props) => {
  const { role} = props;
  console.log(role);
  const [activeTab, setActiveTab] = useState('Members');
  const [searchTerm, setSearchTerm] = useState('');
  const [openDetails, setOpenDeatils] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const members = [
    { 
        id: 747182, 
        name: 'kk747182', 
        nickname: 'kk747182', 
        avatar: 'https://img.freepik.com/free-vector/cute-cat-gaming-cartoon_138676-2969.jpg',
        totalMembers: 50,
        hands: 1234,
        buyin: '$5,000',
        winnings: '$7,500',
        rakeback: '$350',
        systemRakeback: 20,
        fee: '$100'
    },
  ];

  const filteredMembers = members.filter(member => 
    member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    member.nickname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleMemberClick = (member) => {
    setSelectedMember(member);
  };

  const renderNoMembers = () => (
    <NoMembersContainer>
      <Users size={40} color="#5CEB7F" />
      <NoMembersTitle>No Members Found</NoMembersTitle>
      <NoMembersDescription>
        {searchTerm 
          ? `No members match your search for "${searchTerm}"`
          : "There are no members in this list yet. Add a new member to get started!"}
      </NoMembersDescription>
    </NoMembersContainer>
  );

  return (
    <Container>
      {selectedMember ? (
        <MemberDetails 
          role={role}
          member={selectedMember} 
          onBack={() => setSelectedMember(null)} 
        />
      ) : (
        <>
          <TabContainer>
            <Tab 
              active={activeTab === 'Members'} 
              onClick={() => setActiveTab('Members')}
            >
              <Users size={16} />
              Members
            </Tab>
            <Tab 
              active={activeTab === 'NewMembers'} 
              onClick={() => setActiveTab('NewMembers')}
            >
              <FolderPlus size={16} />
              New Members
            </Tab>
          </TabContainer>
     
          <SearchWrapper>
            <SearchIconWrapper>
              <Search size={16} />
            </SearchIconWrapper>
            <SearchInput 
              placeholder="Search Member" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchWrapper>

          <MemberStats>
            <div>Members: {members.length}</div>
            <div style={{color:"#5CEB7F"}}>Club Role</div>
          </MemberStats>

          <MemberList>
            {filteredMembers.length > 0 
              ? filteredMembers.map(member => (
                  <MemberItem 
                    key={member.id} 
                    onClick={() => handleMemberClick(member)}
                  >
                    <MemberAvatar src={member.avatar} alt={member.name} />
                    <MemberInfo>
                      <MemberName>{member.name}</MemberName>
                      <MemberRole>ID: {member.id}</MemberRole>
                      <MemberRole>Nickname: {member.nickname}</MemberRole>
                    </MemberInfo>
                  </MemberItem>
                ))
              : renderNoMembers()
            }
          </MemberList>
        </>
      )}
    </Container>
  );
};

export default Members;