import styled from "styled-components";

const ContentSection = styled.div`
   background-color: #323738;
  color: #b9bbbe;
  padding: 24px;
  border-radius: 20px;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
`;

export default function HGSwap(){
    return(
        <ContentSection>
        <SectionTitle>Swap Policy</SectionTitle>
        <SectionContent>
       Skyla  has a strict anti-money laundering policy. This is in accordance with our AML procedures outlined in our terms of service.
If deposits & withdrawals are suspected of being attempts to Swap coins we reserve the right to hold withdrawals until completion of one of the following procedures

<SectionContent>
1. Withdrawal is sent after full completion of account verification & KYC
    </SectionContent>

<SectionContent>
2. Withdrawal refunded & 1x gameplay is reached.
    </SectionContent>

Swap will always try to ensure every matter is resolved & the above can be modified to suit specific situations.
        </SectionContent>
      </ContentSection>
    )
}