import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: 24px;
  height: 24px;
`;

const SecondaryPath = styled.path`
  fill: #efefef;
`;

const BrandPath = styled.path`
  fill: #22C55E;
`;

const TradingIcon = () => {
  return (
    <StyledSVG viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="botton_menu/trading_default">
        <g id="Group 1556234779">
          <SecondaryPath
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.76023 6.01479C6.71663 6.10036 6.71663 6.21237 6.71663 6.43639V10.3195H5.59982C5.03976 10.3195 4.75974 10.3195 4.54583 10.4285C4.35766 10.5244 4.20468 10.6774 4.10881 10.8655C3.99982 11.0795 3.99982 11.3595 3.99982 11.9195V21.7208C3.99982 22.2808 3.99982 22.5608 4.10881 22.7748C4.20468 22.9629 4.35766 23.1159 4.54583 23.2118C4.75974 23.3208 5.03976 23.3208 5.59982 23.3208H6.71663V27.2058C6.71663 27.4298 6.71663 27.5419 6.76023 27.6274C6.79858 27.7027 6.85977 27.7639 6.93504 27.8022C7.0206 27.8458 7.13261 27.8458 7.35663 27.8458H7.93449C8.15851 27.8458 8.27052 27.8458 8.35609 27.8022C8.43135 27.7639 8.49255 27.7027 8.5309 27.6274C8.57449 27.5419 8.57449 27.4298 8.57449 27.2058V23.3208H9.69079C10.2508 23.3208 10.5309 23.3208 10.7448 23.2118C10.9329 23.1159 11.0859 22.9629 11.1818 22.7748C11.2908 22.5608 11.2908 22.2808 11.2908 21.7208V11.9195C11.2908 11.3595 11.2908 11.0795 11.1818 10.8655C11.0859 10.6774 10.9329 10.5244 10.7448 10.4285C10.5309 10.3195 10.2508 10.3195 9.69079 10.3195H8.57449V6.43639C8.57449 6.21237 8.57449 6.10036 8.5309 6.01479C8.49255 5.93953 8.43135 5.87833 8.35609 5.83998C8.27052 5.79639 8.15851 5.79639 7.93449 5.79639H7.35663C7.13261 5.79639 7.0206 5.79639 6.93504 5.83998C6.85977 5.87833 6.79858 5.93953 6.76023 6.01479Z"
          />
          <BrandPath
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1142 1.84169C15.0706 1.92726 15.0706 2.03927 15.0706 2.26329V6.14644H13.9533C13.3933 6.14644 13.1133 6.14644 12.8993 6.25543C12.7112 6.35131 12.5582 6.50429 12.4623 6.69245C12.3533 6.90636 12.3533 7.18639 12.3533 7.74644V17.5477C12.3533 18.1077 12.3533 18.3878 12.4623 18.6017C12.5582 18.7898 12.7112 18.9428 12.8993 19.0387C13.1133 19.1477 13.3933 19.1477 13.9533 19.1477H15.0706V23.0327C15.0706 23.2567 15.0706 23.3688 15.1142 23.4543C15.1526 23.5296 15.2137 23.5908 15.289 23.6291C15.3746 23.6727 15.4866 23.6727 15.7106 23.6727H16.2885C16.5125 23.6727 16.6245 23.6727 16.7101 23.6291C16.7853 23.5908 16.8465 23.5296 16.8849 23.4543C16.9285 23.3688 16.9285 23.2567 16.9285 23.0327V19.1477H18.0443C18.6044 19.1477 18.8844 19.1477 19.0983 19.0387C19.2865 18.9428 19.4394 18.7898 19.5353 18.6017C19.6443 18.3878 19.6443 18.1077 19.6443 17.5477V7.74644C19.6443 7.18639 19.6443 6.90636 19.5353 6.69245C19.4394 6.50429 19.2865 6.35131 19.0983 6.25543C18.8844 6.14644 18.6044 6.14644 18.0443 6.14644H16.9285V2.26329C16.9285 2.03927 16.9285 1.92726 16.8849 1.84169C16.8465 1.76643 16.7853 1.70524 16.7101 1.66689C16.6245 1.62329 16.5125 1.62329 16.2885 1.62329H15.7106C15.4866 1.62329 15.3746 1.62329 15.289 1.66689C15.2137 1.70524 15.1526 1.76643 15.1142 1.84169Z"
          />
          <SecondaryPath
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.4692 8.54555C23.4256 8.63112 23.4256 8.74313 23.4256 8.96715V12.851H22.3092C21.7492 12.851 21.4691 12.851 21.2552 12.96C21.0671 13.0559 20.9141 13.2089 20.8182 13.397C20.7092 13.6109 20.7092 13.891 20.7092 14.451V24.2523C20.7092 24.8123 20.7092 25.0923 20.8182 25.3062C20.9141 25.4944 21.0671 25.6474 21.2552 25.7433C21.4691 25.8523 21.7492 25.8523 22.3092 25.8523H23.4256V29.7366C23.4256 29.9606 23.4256 30.0726 23.4692 30.1582C23.5075 30.2334 23.5687 30.2946 23.644 30.333C23.7296 30.3766 23.8416 30.3766 24.0656 30.3766H24.6434C24.8675 30.3766 24.9795 30.3766 25.065 30.333C25.1403 30.2946 25.2015 30.2334 25.2399 30.1582C25.2834 30.0726 25.2834 29.9606 25.2834 29.7366V25.8523H26.4002C26.9603 25.8523 27.2403 25.8523 27.4542 25.7433C27.6424 25.6474 27.7953 25.4944 27.8912 25.3062C28.0002 25.0923 28.0002 24.8123 28.0002 24.2523V14.451C28.0002 13.891 28.0002 13.6109 27.8912 13.397C27.7953 13.2089 27.6424 13.0559 27.4542 12.96C27.2403 12.851 26.9603 12.851 26.4002 12.851H25.2834V8.96715C25.2834 8.74313 25.2834 8.63112 25.2399 8.54555C25.2015 8.47029 25.1403 8.4091 25.065 8.37075C24.9795 8.32715 24.8675 8.32715 24.6435 8.32715H24.0656C23.8416 8.32715 23.7296 8.32715 23.644 8.37075C23.5687 8.4091 23.5075 8.47029 23.4692 8.54555Z"
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default TradingIcon;