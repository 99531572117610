import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.size || '28px'};
  height: ${props => props.size || '28px'};
`;

const MainPath = styled.path`
  fill: white;
`;

const AccentPath = styled.path`
  fill: #22C55E; /* Bright green color - you can adjust this hex code */
`;

const ClaudeIcon = ({ size }) => {
  return (
    <StyledSVG 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 32 32"
      size={size}
    >
      <MainPath 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M16.7061 6.29942C16.2054 6.80411 15.9524 7.59373 15.4462 9.17298L12.0917 19.6395C11.5856 21.2188 11.3325 22.0084 11.4465 22.7101C11.5468 23.3273 11.8399 23.897 12.2839 24.3374C12.7885 24.838 13.5782 25.0911 15.1574 25.5972L18.52 26.6749C20.0992 27.1811 20.8888 27.4342 21.5905 27.3201C22.2078 27.2198 22.7774 26.9267 23.2178 26.4828C23.7184 25.9781 23.9715 25.1885 24.4777 23.6092L27.8322 13.1427C28.3383 11.5634 28.5914 10.7738 28.4774 10.0721C28.3771 9.45486 28.084 8.88523 27.64 8.44483C27.1353 7.94417 26.3457 7.69109 24.7665 7.18495L21.4039 6.10726C19.8247 5.60112 19.035 5.34804 18.3333 5.46206C17.7161 5.56236 17.1465 5.85548 16.7061 6.29942ZM18.8802 19.4939L22.3691 17.7772C22.3729 17.7754 22.3766 17.7736 22.3804 17.7717C22.3825 17.7707 22.3845 17.7697 22.3866 17.7687C23.3548 17.2923 23.7535 16.1212 23.2771 15.153C22.8007 14.1848 21.6297 13.7862 20.6615 14.2625L20.6612 14.2625C20.1849 13.2943 19.0137 12.8956 18.0455 13.372C17.0774 13.8483 16.6787 15.0194 17.155 15.9876L18.8802 19.4939Z"
      />
      <AccentPath 
        d="M13.7709 8.16535C12.5097 12.1006 11.2091 16.0257 9.97062 19.9688C9.74175 20.6975 9.62732 21.0618 9.42994 21.1861C9.25865 21.294 9.04621 21.3136 8.85803 21.239C8.6412 21.1531 8.46289 20.8179 8.10628 20.1476C6.95387 17.9813 5.81899 15.8053 4.67102 13.6369C3.89508 12.1713 3.50711 11.4385 3.49597 10.7276C3.48617 10.1024 3.67457 9.4901 4.03415 8.97851C4.44295 8.3969 5.17577 8.00893 6.64142 7.23299L9.09094 5.93616C10.5566 5.16021 11.2894 4.77224 12.0002 4.76111C12.6255 4.75131 13.2378 4.9397 13.7494 5.29929C13.8043 5.33792 13.8576 5.37944 13.9095 5.42421C14.0414 5.53772 14.1073 5.59448 14.199 5.75987C14.2633 5.87582 14.3264 6.10382 14.331 6.23631C14.3374 6.4253 14.2867 6.57864 14.1852 6.88531C14.0582 7.26903 13.9221 7.69372 13.7709 8.16535Z"
      />
    </StyledSVG>
  );
};

export default ClaudeIcon;