import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import { decode, encode, wait, sendNotfication } from "../../../../Helper";
import C from "../../../../Constant";
import { Icon } from "@mui/material";
import styled from "styled-components";
import ActiveSessions from "./Sessions";
import LockIcon from "../../../../Icons/Lock";
import EmailIcon from "../../../../Icons/EmailIcon";

const SecuritySetupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;
`;

const SecuritySetupTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff15;
  width: 100%;
`;

const SecuritySetupContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const SecurityItem = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  // border: 1px solid #ffffff15;
  padding: 15px;
  border-radius: 10px;
  background-color: #3A4142;
  min-height: 240px;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ItemHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;

const IconImg = styled.img`
  width: 40px;
  height: 40px;
`;

const Status = styled.div`
  font-size: 12px;
  color: ${(props) => (props.isEnabled ? "green" : "#ffffff50")};
`;

const ItemDetails = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ItemDetailsTitle = styled.div`
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  width: 100%;
`;

const ItemDetailsDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #ffffff80;
  width: 100%;
`;

const EditButton = styled.button`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  width: 200px;
  margin-top: 15px;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    color: #fff !important;
    border: 1px solid #da22ff;
  }
`;

const ActiveSessionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  background-color: #323738;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
`;

const ActiveSessionsTitle = styled.div`
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #ffffff15;
`;

const UpdateModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const UpdateModalPop = styled.div`
  width: 500px;
  padding: 15px;
  border-radius: 10px;
  background-color: #17181b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const UpdateModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UpdateModalTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
`;

const UpdateModalClose = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
`;

const UpdateModalBody = styled.div`
  max-height: max-content;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const UpdateModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const UpdateModalInput = styled.input`
  padding: 15px 10px;
  border: 1px solid #ffffff15;
  border-radius: 10px;
  font-size: 14px;
`;

const UpdateModalBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
`;

class Security extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabled: false,
      password: "",
      _password: "",
      twoFa: null,
      disabled2: false,
      disabled3: false,
      activated: false,
      checkbox: false,
      checkbox2: false,
      changePasswordModal: false,
      g2faModal: false,
    };
    this.saveUserSetting = this.saveUserSetting.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      socket.on(C.EDIT_PASSWORD, (data) => this.updateProfile(decode(data)));
      socket.on(C.TWO_FA, (data) => this.setupTwoFa(decode(data)));
      socket.on(C.TWO_FA_CONFIRM, (data) => this.confirmTwoFa(decode(data)));
      socket.on(C.TWO_FA_DISABLE, (data) =>
        this.disableTwoFactorStatus(decode(data))
      );
      socket.emit(C.TWO_FA);
    }
  }

  componentWillMount() {
    wait(500).then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setupTwoFa(qr) {
    if (this._isMounted) {
      if (qr === "activated") {
        this.setState({ activated: true });
      } else this.setState({ twoFa: qr });
    }
  }

  confirmTwoFa(data) {
    if (this._isMounted) {
      this.setState({ disabled2: false });

      if (data.status !== true) {
        return sendNotfication(data.status.toString());
      }

      sendNotfication("2FA Successfully activated.");
    }
  }

  saveUserSetting(e) {
    if (this._isMounted) {
      wait(750).then(() => {
        socket.emit(
          C.EDIT_PASSWORD,
          encode({
            oldpassword: this.state.passwordold,
            password: this.state.password,
          })
        );
      });
    }
  }

  confirm = (e) => {
    if (this._isMounted) {
      e.preventDefault();
      if (!this.state.verify_code) return;
      if (!this.state.loginpass) return;
      this.setState({ disabled2: true });
      socket.emit(
        C.TWO_FA_CONFIRM,
        encode({
          code: this.state.verify_code,
          password: this.state.loginpass,
        })
      );
    }
  };

  updateProfile(data) {
    if (this._isMounted) {
      this.setState({ disabled: false });
      if (data.status) {
        return sendNotfication(
          "Your Account Password was Updated ",
          "success",
          "top-center"
        );
      } else {
        return sendNotfication(data.error, "error", "top-center");
      }
    }
  }

  disableTwoFactorStatus = (data) => {
    if (this._isMounted) {
      this.setState({ disabled3: false });

      if (data.status !== true) {
        return sendNotfication(data.status.toString());
      }
      sendNotfication("2FA Successfully disabled.");
    }
  };

  disableTwoFa = (e) => {
    if (this._isMounted) {
      e.preventDefault();
      if (!this.state.verify_code) return;
      if (!this.state.loginpass) return;
      this.setState({ disabled3: true });
      socket.emit(
        C.TWO_FA_DISABLE,
        encode({
          code: this.state.verify_code,
          password: this.state.loginpass,
        })
      );
    }
  };

  handleCheckBox = (e) => {
    this.setState({ checkbox: true, loadingPrivacy: true });
    wait(1200).then(() => {
      this.setState({ loadingPrivacy: false, checkbox: false });
      return sendNotfication(
        "This feature can't be active in your account !",
        "info",
        "top-center"
      );
    });
  };

  toggleModal = (type, isOpen) => {
    this.setState({
      [`${type}Modal`]: isOpen,
    });
  };

  handleCheckBox2 = (e) => {
    this.setState({ checkbox2: true, loading2: true });
    wait(1200).then(() => {
      this.setState({ checkbox2: false, loading2: false });
      return sendNotfication(
        "This feature can't be active in your account !",
        "info",
        "top-center"
      );
    });
  };

  render() {
    const {
      loading,
      disabled,
      password,
      _password,
      twoFa,
      disabled2,
      disabled3,
      activated,
      checkbox,
      checkbox2,
      changePasswordModal,
      g2faModal,
    } = this.state;

    const inputStyle = {
      backgroundColor: "#1a1a1a",
      border: "1px solid #ffffff15",
      borderRadius: "10px",
      color: "#fff",
      padding: "10px 12px",
      fontSize: "14px",
      width: "100%",
    };

    const buttonStyle = {
      backgroundColor: "#5DFF00",
      border: "none",
      borderRadius: "24px",
      color: "#000",
      padding: "12px 24px",
      fontSize: "14px",
      fontWeight: "bold",
      cursor: "pointer",
      width: "50%",
      marginTop: "8px",
    };

    const disabledButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#5DFF0080",
      cursor: "not-allowed",
    };

    const isPasswordEnabled = this.state.passwordold !== null;

    return (
      <>
        <SecuritySetupWrapper>
          <SecuritySetupTitle>Security Setup</SecuritySetupTitle>
          <SecuritySetupContainer>
            <SecurityItem>
              <ItemHeader>
               <LockIcon/>
                <Status isPasswordEnabled={isPasswordEnabled}>
                  {isPasswordEnabled ? "Enabled" : "Disabled"}
                </Status>
              </ItemHeader>

              <ItemDetails>
                <ItemDetailsTitle>Change Password</ItemDetailsTitle>
                <ItemDetailsDescription>
                  Change to a strong password to protect your account from
                  unauthorized access.
                </ItemDetailsDescription>
                <EditButton
                  type="button"
                  onClick={() => this.toggleModal("changePassword", true)}
                >
                  Change Password
                </EditButton>
              </ItemDetails>
            </SecurityItem>

            {/* <SecurityItem>
              <ItemHeader>
              <EmailIcon/>
                <Status>Enabled</Status>
              </ItemHeader>

              <ItemDetails>
                <ItemDetailsTitle>Two-factor authentication</ItemDetailsTitle>
                <ItemDetailsDescription>
                  Enable Two-factor authentication to protect your account from unauthorized
                  access.
                </ItemDetailsDescription>
                <EditButton
                  type="button"
                  onClick={() => this.toggleModal("g2fa", true)}
                >
                  Enable 2FA
                </EditButton>
              </ItemDetails>
            </SecurityItem> */}
          </SecuritySetupContainer>
        </SecuritySetupWrapper>

        <ActiveSessionsWrapper>
          <ActiveSessionsTitle>Active Sessions</ActiveSessionsTitle>
          <ActiveSessions />
        </ActiveSessionsWrapper>

        {g2faModal && (
          <UpdateModalWrapper>
            <UpdateModalPop>
              <UpdateModalHeader>
                <UpdateModalTitle>Enable G2FA</UpdateModalTitle>
                <UpdateModalClose
                  type="button"
                  onClick={() => this.toggleModal("g2fa", false)}
                >
                  <i className="fas fa-times" style={{ fontSize: "18px" }}></i>
                </UpdateModalClose>
              </UpdateModalHeader>
              <UpdateModalBody>
                {!this.state.activated ? (
                  <Form onSubmit={this.confirm}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <ul style={{ margin: "0px", padding: "0px" }}>
                          <li
                            style={{
                              margin: "10px 0px",
                              fontSize: "14px",
                              color: "#ffffff80",
                            }}
                          >
                            Steps:
                          </li>
                          <li
                            style={{
                              margin: "10px 0px",
                              fontSize: "12px",
                              color: "#ffffff60",
                            }}
                          >
                            1. Download and install Google Authenticator.
                          </li>
                          <li
                            style={{
                              margin: "10px 0px",
                              fontSize: "12px",
                              color: "#ffffff60",
                            }}
                          >
                            2. Enable Two-factor Authentication to protect your
                            account from unauthorized access.
                          </li>
                          <li
                            style={{
                              margin: "10px 0px",
                              fontSize: "12px",
                              color: "#ffffff60",
                            }}
                          >
                            3. Scan the QR code with your Google Authenticator
                            App.
                          </li>
                        </ul>
                        <div
                          className="text-center"
                          style={{ margin: "20px 0px" }}
                        >
                          <img
                            className="p-1 border rounded img-fluid"
                            alt="2FA"
                            src={this.state.twoFa}
                            style={{ width: "150px" }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <div>
                          <label>Verification Code</label>
                          <input
                            type="text"
                            style={inputStyle}
                            value={this.state.verify_code}
                            onChange={(e) =>
                              this.setState({ verify_code: e.target.value })
                            }
                          />
                        </div>
                        <div className="mt-2">
                          <label>Login Password</label>
                          <input
                            type="text"
                            style={inputStyle}
                            value={this.state.loginpass}
                            onChange={(e) =>
                              this.setState({ loginpass: e.target.value })
                            }
                          />
                        </div>
                        <div className="text-center">
                          <EditButton
                            type="submit"
                            disabled={this.state.disabled2}
                          >
                            Enable
                            {this.state.disabled2 && (
                              <div className="ml-2 spinner-border spinner-border-sm" />
                            )}
                          </EditButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                ) : (
                  <>
                    <Form onSubmit={this.disableTwoFa}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          gap: "10px",
                          flexDirection: "column",
                        }}
                      >
                        <p className="text-white font-14">
                          To disable Two-Factor authentication, enter your
                          one-time Password (OTP)
                        </p>
                        <div>
                          <label>Login Password</label>
                          <input
                            type="text"
                            style={inputStyle}
                            value={this.state.loginpass}
                            onChange={(e) =>
                              this.setState({ loginpass: e.target.value })
                            }
                          />
                        </div>
                        <div>
                          <label>Verification Code</label>
                          <input
                            type="text"
                            style={inputStyle}
                            value={this.state.verify_code}
                            onChange={(e) =>
                              this.setState({ verify_code: e.target.value })
                            }
                          />
                        </div>
                        <EditButton
                          type="submit"
                          disabled={this.state.disabled3}
                        >
                          Disable
                          {this.state.disabled3 && (
                            <div className="ml-2 spinner-border spinner-border-sm" />
                          )}
                        </EditButton>
                      </div>
                    </Form>
                  </>
                )}
              </UpdateModalBody>
            </UpdateModalPop>
          </UpdateModalWrapper>
        )}

        {changePasswordModal && (
          <UpdateModalWrapper>
            <UpdateModalPop>
              <UpdateModalHeader style={{ marginBottom: "15px" }}>
                <UpdateModalTitle>Change Password</UpdateModalTitle>
                <UpdateModalClose
                  type="button"
                  onClick={() => this.toggleModal("changePassword", false)}
                >
                  <i className="fas fa-times" style={{ fontSize: "18px" }}></i>
                </UpdateModalClose>
              </UpdateModalHeader>
              <UpdateModalBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!this.state.password) {
                      document
                        .getElementById("password")
                        .classList.add("border-danger");
                      return;
                    }
                    if (!this.state._password) {
                      document
                        .getElementById("_password")
                        .classList.add("border-danger");
                      return;
                    }
                    if (this.state.password !== this.state._password) {
                      sendNotfication(
                        "Passwords is different!",
                        "error",
                        "top-center"
                      );
                    }
                    if (this.state.password === this.state._password) {
                      this.setState({ disabled: true });
                      this.saveUserSetting(e);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <label className="mt-1" htmlFor="passwordold">
                        Old Password
                      </label>
                      <input
                        type="password"
                        id="passwordold"
                        autoComplete="off"
                        style={inputStyle}
                        value={this.state.passwordold}
                        required={true}
                        onChange={(e) =>
                          this.setState({ passwordold: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <label className="mt-1" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        autoComplete="off"
                        style={inputStyle}
                        value={this.state.password}
                        required={true}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <label className="mt-1" htmlFor="_password">
                        Confirmation Password
                      </label>
                      <input
                        type="password"
                        id="_password"
                        autoComplete="off"
                        style={inputStyle}
                        value={this.state._password}
                        required={true}
                        onChange={(e) =>
                          this.setState({ _password: e.target.value })
                        }
                      />
                    </div>
                    <div className="mt-2 text-center">
                      <EditButton
                        type="submit"
                        style={
                          this.state.disabled
                            ? disabledButtonStyle
                            : buttonStyle
                        }
                        disabled={this.state.disabled}
                      >
                        Save Changes
                        {this.state.disabled && (
                          <div className="ml-2 spinner-border spinner-border-sm" />
                        )}
                      </EditButton>
                    </div>
                  </div>
                </Form>
              </UpdateModalBody>
            </UpdateModalPop>
          </UpdateModalWrapper>
        )}
      </>
    );
  }
}

export default Security;
