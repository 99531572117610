const { default: styled } = require("styled-components");

const ContentSection = styled.div`
  background-color: #323738;
  color: #b9bbbe;
  padding: 24px;
  border-radius: 20px;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
`;

const RegistrationAndLogin = () => (
    <ContentSection>
      <SectionTitle>Registration and Login</SectionTitle>
      <SectionContent>
      You must be at least 18 years old to register. If you want to add your email address, please make sure the email address you entered is correct so that later it can be used in KYC account verification.

You can login at any time. For added security, we recommend you to add 2FA. To know more about Google authenticator.

If you need to change your registered email, we are so sorry, but we are not able to update this information. If you insist on changing your username and/or registered email, we suggest you close the current account and register a new one.


      </SectionContent>
    </ContentSection>
  );

  export default RegistrationAndLogin
  