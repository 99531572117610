import React from 'react';
import { Card, Row, Col } from "react-bootstrap";
import Canvas from "./Canvas";
import Queue from "./Queue";
import History from "./History";
import Bet from "./Bet";
import { Helmet } from "react-helmet";
import BankRoll from "../../Components/Game/BankRoll";
import { wait, Event } from "../../../Helper";
import Help from "./Help";
import Engine from "./Engine";
import Loading from "../Loading";
import BetsPopKeno from './Graph';
import styled from 'styled-components';
import { Heart, HelpCircle, Share2, Star, Volume2 } from 'lucide-react';





const OpenGraphButton = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: #222328;
  border-radius: 50%;
`;

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  /* Mobile layout (default) */
  flex-direction: column;
  
  /* Reverse order on mobile by default */
  > *:nth-child(1) { order: 1; }
  > *:nth-child(2) { order: 0; }
  
  /* Desktop layout (768px and above) */
  @media (min-width: 768px) {
    flex-direction: row;
    
    /* Reset order for desktop */
    > *:nth-child(1) { order: 0; }
    > *:nth-child(2) { order: 0; }
  }
`;

const MainWrapperInner = styled.div`
flex:1;


  @media (max-width: 768px) {

  }

`;

const NavContainer = styled.nav`
display: flex;
align-items: center;
justify-content: space-between;
background-color: rgb(50,55,56);
opacity: 0.9;
padding: 0.75rem 1.5rem;
color: #ffffff;
border-top: 1px solid #2a2a2a;
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

@media (max-width: 768px) {
  padding: 0.5rem 0.75rem;
}
`;

const LeftSection = styled.div`
display: flex;
align-items: center;
gap: 1.5rem;

@media (max-width: 768px) {
  gap: 0.75rem;
}
`;

const RightSection = styled.div`
display: flex;
align-items: center;
gap: 1.5rem;

@media (max-width: 768px) {
  gap: 0.75rem;
}
`;

const Tooltip = styled.div`
position: absolute;
top: -35px;
left: 50%;
transform: translateX(-50%);
background-color: #292D2F;
color: #BAC6C9;
padding: 6px 12px;
border-radius: 4px;
font-size: 12px;
white-space: nowrap;
opacity: 0;
visibility: hidden;
transition: all 0.2s;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

&::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #292D2F transparent transparent transparent;
}
`;

const NavItem = styled.div`
display: flex;
align-items: center;
gap: 8px;
cursor: pointer;
color: #BAC6C9;
padding: 8px;
border-radius: 8px;
transition: all 0.2s;
position: relative;

@media (max-width: 768px) {
  padding: 6px;
  gap: 4px;
}

&:hover {
  background-color: rgba(255, 255, 255, 0.1);
  
  ${Tooltip} {
    opacity: 1;
    visibility: visible;
    top: -45px;

    @media (max-width: 768px) {
      display: none; // Hide tooltips on mobile to save space
    }
  }
}
`;

const Count = styled.span`
font-size: 0.9rem;
color: #ffffff;

@media (max-width: 768px) {
  font-size: 0.8rem;
}
`;

const IconImage = styled.img`
width: 20px;
height: 20px;
object-fit: contain;
`;

const SwapStatsIcon = () => (
<svg 
  viewBox="0 0 32 32" 
  xmlns="http://www.w3.org/2000/svg" 
  width="20" 
  height="20" 
  fill="none"
>
  <path 
    fill="currentColor" 
    d="M25.461 5.249a3.44 3.44 0 0 1 1.485 6.545l-.002-.057q.027.93.022 1.79l-.011.679c-.151 6.343-1.785 9.75-5.373 9.75-1.894 0-3.369-.972-4.516-2.684q-.1-.15-.195-.301l-.186-.306-.178-.317-.174-.331q-.128-.255-.258-.538l-.174-.392-.179-.427-.186-.465-.196-.509-.21-.558-.517-1.404-.194-.512-.189-.478-.18-.443-.176-.41-.086-.193-.168-.362q-.041-.087-.082-.17l-.162-.316c-.696-1.306-1.304-1.785-2.077-1.785-1.273 0-2.272 1.39-2.813 4.397l-.081.488a22 22 0 0 0-.075.515l-.066.542-.03.282-.053.583-.024.302-.042.625q-.018.32-.033.653l-.024.681-.003.102a3.44 3.44 0 1 1-3.013-.012q.037-1.395.144-2.636l.063-.653c.616-5.782 2.522-8.878 6.048-8.878 1.8 0 3.196.946 4.284 2.605q.093.144.183.289l.174.293.168.303.164.317.162.338.164.362.083.193.171.411.18.45.19.494.31.835.305.832.202.541.197.506.19.47.183.439.09.207.178.39.087.183.172.344.17.315c.727 1.298 1.399 1.784 2.275 1.784.883 0 1.59-.93 1.995-2.914l.076-.397q.034-.205.067-.424l.059-.45q.029-.232.051-.478l.043-.504.034-.532.026-.56.01-.29.012-.601.003-.629q0-.322-.006-.658l-.016-.685-.003-.052a3.44 3.44 0 0 1 1.529-6.524z"
  />
</svg>
);



class Game extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            mobile: false,
            padding: "p-1",
            showPopup: false,
        };
        this.handleResize = this.handleResize.bind(this);
        this.handleShowPopUp = this.handleShowPopUp.bind(this);
    }

    componentDidMount() {
        let { engine } = this.state;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this._Mounted = true;
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);

        if (this.isMobile()) {
            this.setState({ height: "unset", mobile: true });
        }
        else {
            this.setState({ height: this.getHeight(), mobile: false });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        let { engine } = this.state;

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize() {
        if (this.getWidth() < 1540) {
            this.setState({ col: 'col-xl-12' });
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9' });
            Event.emit('show_min_games');
        }

        if (this.isMobile()) {
            this.setState({ mobile: true, ovh: 'ovh', padding: 'p-0' });
        }
        else {
            this.setState({ mobile: false });
        }
    }

    handleShowPopUp() {
        this.setState((prevState) => ({
            showPopup: !prevState.showPopup
        }));
    }

    getHeight() {
        return document.documentElement.clientHeight || document.body.clientHeight;
    }

    getWidth() {
        return document.documentElement.clientWidth || document.body.clientWidth;
    }

    isMobile() {
        return this.getWidth() < 992
    }

    render() {
        let { engine, col, ovh, padding } = this.state;
        return (
            <>
                <Helmet>
                    <title>Keno - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Keno Game, Online Keno Game, Bitcoin Keno Game, Blockchain Keno Game, Best Crypto Keno Game, PlayCrash.com Keno" />
                    <meta name="description" content="Play Online Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/keno" />
                </Helmet>
                <div>
                    {this._Mounted ?


                        <div className={`${col} ${padding}`} style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {this.state.showPopup && (
                                <BetsPopKeno
                                    onClose={this.handleShowPopUp}
                                    engine={engine}
                                />
                            )}
                            <Row className={"animated fadeIn " + ovh}>
                                <Col sm={12} className={'m-auto ' + col + ' ' + padding}>
                                    <Card className="mb-0">
                                        <Card.Body className="px-2 py-3">
                                            <Row>
                                                <Col md={6} className={'col-6 text-left'}>
                                                    <BankRoll game={'keno'} />
                                                </Col>
                                                <Col md={6} className={'col-6 text-right'}>
                                                    <div style={{ display: 'flex', gap:'10px' }}>
                                                        <div onClick={this.handleShowPopUp} style={{ marginLeft: '12px', alignItems: 'center', height: '37px', width: '37px', borderRadius: '5px', backgroundColor: "#1B2336", display: 'flex', justifyContent: 'center' }}>
                                                            <img style={{ height: '17px', width: '17px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />

                                                        </div>                 <History engine={engine} />
                                                        <Help />

                                                    </div>

                                                </Col>
                                            
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <Canvas engine={engine} />

                                    <Card className="mb-0">
                                        <Card.Body className="p-1">
                                            <Row>
                                                <Col sm={12} md={12} className={'text-left m-auto'}>
                                                    <Bet engine={engine} mobile={this.state.mobile} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <NavContainer>
                                    <LeftSection>
                                        <NavItem>
                                            <Tooltip>Favorites</Tooltip>
                                            <Star size={20} color="#BAC6C9" />
                                            <Count>20711</Count>
                                        </NavItem>
                                        <NavItem>
                                            <Tooltip>Likes</Tooltip>
                                            <Heart size={20} color="#BAC6C9" />
                                            <Count>20747</Count>
                                        </NavItem>
                                        <NavItem>
                                            <Tooltip>Share</Tooltip>
                                            <Share2 size={20} color="#BAC6C9" />
                                        </NavItem>
                                    </LeftSection>

                                    <RightSection>
                                        <NavItem>
                                            <Tooltip>Sound Effects</Tooltip>
                                            <Volume2 size={20} color="#BAC6C9" />
                                        </NavItem>
                                        <NavItem onClick={this.handleShowPopUp}>
                                            <Tooltip>Game Stats</Tooltip>
                                            <SwapStatsIcon />
                                        </NavItem>
                                        <NavItem>
                                            <Tooltip>Help</Tooltip>
                                            <HelpCircle size={20} color="#BAC6C9" />
                                        </NavItem>
                                    </RightSection>
                                </NavContainer>

                                    <Card className="mb-0">
                                        <Card.Body className="py-0 px-2">
                                            <Queue game={'keno'} engine={engine} t={this.props.t} />
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Row>
                        </div>
                        :
                        <Loading />
                    }
                </div>
            </>
        );
    }
}

export default Game;
