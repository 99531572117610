import styled from "styled-components";

const ContentSection = styled.div`
  background-color: #323738;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;

  h4 {
    color: #ffffff;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  p, li {
    margin-bottom: 10px;
  }

  ol, ul {
    padding-left: 20px;
  }
`;
export default function ProvidersHelp() {
    return (
        <ContentSection>
            <SectionTitle>Provider Availability Policy        </SectionTitle>
            <SectionContent>
                1. Absolute Restriction
                NetEnt will not permit NetEnt Casino Games to be supplied to any entity that operates in any of the below jurisdictions (irrespective of whether or not NetEnt Casino Games are being supplied by the entity in that jurisdiction) without the appropriate licenses.
                Belgium, Bulgaria, Colombia, Croatia, Czech Republic, Denmark, Estonia, France, Italy, Latvia, Lithuania, Mexico, Portugal, Romania, Spain, Sweden, Switzerland, United Kingdom, United States of America.
            </SectionContent>
            <SectionContent>
                2.Absolute Restriction
                NetEnt will not permit NetEnt Casino Games to be supplied to any entity that operates in any of the below jurisdictions (irrespective of whether or not NetEnt Casino Games are being supplied by the entity in that jurisdiction) without the appropriate licenses.
                Belgium, Bulgaria, Colombia, Croatia, Czech Republic, Denmark, Estonia, France, Italy, Latvia, Lithuania, Mexico, Portugal, Romania, Spain, Sweden, Switzerland, United Kingdom, United States of America.

            </SectionContent>
            <SectionContent>
                3.Blacklisted Branded Games Territories
                The followed NetEnt Braded Games have some further restrictions in addition to the Blacklisted Territories set out above:
                In addition to the jurisdictions set out in paragraph 2, Planet of the Apes Video Slot must not be offered in the following territories:
                Azerbaijan, China, India, Malaysia, Qatar, Russia, Thailand, Turkey, Ukraine.
                In addition to the jurisdictions set out in paragraph 2, Vikings Video Slot must not be offered in the following jurisdictions:
                Azerbaijan, Cambodia, Canada, China, France, India, Indonesia, Laos, Malaysia, Myanmar, Papua New Guinea, Qatar, Russia, South Korea, Thailand, Turkey, Ukraine, United States of America.
                In addition to the jurisdictions set out in paragraph 2, Narcos Video Slot must not be offered in the following territories:
                Indonesia, South Korea.
                In addition to the jurisdictions set out in paragraph 2, Street Fighter Video Slot must not be offered in the following territories:
                Anguilla, Antigua & Barbuda, Argentina, Aruba, Barbados, Bahamas, Belize, Bermuda, Bolivia, Bonaire, Brazil, British Virgin Islands, Canada, Cayman Islands, China, Chile, Clipperton Island, Columbia, Costa Rica, Cuba, Curacao, Dominica, Dominican Republic, El Salvador, Greenland, Grenada, Guadeloupe, Guatemala, Guyana, Haiti, Honduras, Jamaica, Japan, Martinique, Mexico, Montserrat, Navassa Island, Paraguay, Peru, Puerto Rico, Saba, Saint Barthelemy, Saint Eustatius, Saint Kitts and Nevis, Saint Lucia, Saint Maarten, Saint Martin, Saint Pierre and Miquelon, Saint Vincent and the Grenadines, South Korea, Suriname, Turks and Caicos Islands, United States of America, Uruguay, US Virgin Islands, Venezuela.
                In addition to the jurisdictions set out in paragraph 2, Fashion TV Video Slot must not be offered in the following territories:
                Cuba, Jordan, Turkey, Saudi Arabia.

            </SectionContent>
            <SectionContent>
                4.Universal Monsters (Dracula, Creature from the Black Lagoon, Phantoms Curse and The Invisible Man) may only be played in the following territories:
                Andorra, Austria, Armenia, Azerbaijan, Belarus, Bosnia and Herzegovina, Cyprus, Finland, Georgia, Germany, Greece, Hungary, Iceland, Ireland, Liechtenstein, Luxembourg, Malta, Moldova, Monaco, Montenegro, Netherlands, North Macedonia, Norway, Poland, Russia, San Marino, Serbia, Slovakia, Slovenia, Turkey and Ukraine.


            </SectionContent>
            <SectionContent>

            </SectionContent>
            <SectionContent>

            </SectionContent>
            <SectionContent>

            </SectionContent>


        </ContentSection>
    )
}