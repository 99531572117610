import React from 'react';
import styled from 'styled-components';

// Container for the entire bar
const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:  34px;
  border-radius:12px;
  background: #1F2223;
  backdrop-filter:blur(20px)
  width: 100%;
`;

// Left section with bonus text
const BonusText = styled.div`
  color: #4ADE80;
  font-size: 24px;
  font-weight: 600;
`;

// Middle section for payment methods
const PaymentMethods = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

// Payment method icon container
const PaymentIcon = styled.div`
  opacity: 0.8;
  transition: opacity 0.2s;
  
  &:hover {
    opacity: 1;
  }
  
  img {
    height: 24px;
    width: auto;
  }
`;


const CryptoIcons = styled.div`
  display: flex;
  align-items: center;


`;

// Individual crypto icon
const CryptoIcon = styled.div`
  width: 24px; // Increase the size to make them more visible
  height: 24px;
  opacity: 0.7;
  transition: opacity 0.2s;
  z-index: ${({ index }) => index}; // Use the index to control the z-index

  &:hover {
    opacity: 1;
    z-index: 10; // Bring the hovered icon to the front
  }
      img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;


const PaymentBar = () => {
  const paymentMethods = [
    { name: 'Apple Pay', icon: 'https://bc.co/assets/apple_pay-DhGEreIw.png' },
    { name: 'Mastercard', icon: 'https://bc.co/assets/mastercard-CVVg_XRh.png' },
    { name: 'Visa', icon: 'https://bc.co/assets/visa-CHvdFeKw.png' },
    { name: 'Google Pay', icon: 'https://bc.co/assets/google_pay-FVJ2d1pF.png' },
    { name: 'PicPay', icon: 'https://bc.co/assets/pic_pay-fE-XPIEr.png' }
  ];

  const cryptoCurrencies = [
    { name: 'Bitcoin', icon: 'https://bc.co/coin/BTC.black.png' },
    { name: 'Ethereum', icon: 'https://bc.co/coin/ETH.black.png' },
    { name: 'Binance', icon: 'https://bc.co/coin/BNB.black.png' },
    { name: 'XRP', icon: 'https://bc.co/coin/XRP.black.png' },
    { name: 'Tether', icon: 'https://bc.co/coin/USDT.black.png' },
    { name: 'USD Coin', icon: 'https://bc.co/coin/USDC.black.png' },
    { name: 'Cardano', icon: 'https://bc.co/coin/SOL.black.png' },
    { name: 'Dogecoin', icon: 'https://bc.co/coin/DOGE.black.png' },
    { name: 'Polygon', icon: 'https://bc.co/coin/POL.black.png' },
    { name: 'Tron', icon: 'https://bc.co/coin/TRX.black.png' }
  ];

  return (
    <NavBar>
      <BonusText>300% <span style={{fontFamily:"normal", color:"white"}}>Deposit Bonus</span></BonusText>

      <PaymentMethods>
        {paymentMethods.map((method) => (
          <PaymentIcon key={method.name}>
            <img src={method.icon} alt={method.name} />
          </PaymentIcon>
        ))}
      </PaymentMethods>

      <CryptoIcons>
        {cryptoCurrencies.map((crypto) => (
          <CryptoIcon key={crypto.name}>
            <img src={crypto.icon} alt={crypto.name} />
          </CryptoIcon>
        ))}
      </CryptoIcons>
    </NavBar>
  );
};

export default PaymentBar;