import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.size || '28px'};  // h-7 equals 28px
  height: ${props => props.size || '28px'};  // w-7 equals 28px
`;

const PrimaryPath = styled.path`
  fill: ${props => props.brandColor || '#00FF00'}; // Changed to green color
`;

const SecondaryPath = styled.path`
  fill: ${props => props.secondaryColor || '#B3BEC1'}; // Secondary color remains same
`;

const MessageIcon = ({ size, brandColor, secondaryColor }) => {
  return (
    <StyledSVG 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      size={size}
      fill="none"
    >
      <PrimaryPath 
        brandColor={brandColor}
        d="M25.784 22.91v-7.694l-4.824 5-4.738 4.037 5.396 1.707c1.41.446 2.114.669 2.674.522a2 2 0 0 0 1.183-.867c.308-.49.308-1.228.308-2.706Z"
      />
      <SecondaryPath
        secondaryColor={secondaryColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.862 7.998c-.654 1.284-.654 2.964-.654 6.324v.383c0 3.36 0 5.04.654 6.324a6 6 0 0 0 2.622 2.622c1.284.654 2.964.654 6.324.654h.384c3.36 0 5.04 0 6.324-.654a6 6 0 0 0 2.622-2.622c.654-1.283.654-2.963.654-6.324v-.383c0-3.36 0-5.04-.654-6.324a6 6 0 0 0-2.622-2.622c-1.284-.654-2.964-.654-6.324-.654h-.384c-3.36 0-5.04 0-6.324.654a6 6 0 0 0-2.622 2.622Zm6.276 7.819h5.8c.333 0 .499 0 .648.097.111.072.228.239.258.369.04.173-.006.297-.097.543-.42 1.144-1.462 2.462-3.623 2.462-2.129 0-3.263-1.278-3.776-2.41-.116-.255-.173-.382-.142-.565a.692.692 0 0 1 .253-.392c.153-.104.328-.104.679-.104Z"
      />
    </StyledSVG>
  );
};

export default MessageIcon;