import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import MenuPlayIcon from '../../../Icons/Menu';
import SearchStarIcon from '../../../Icons/Search';
import ClaudeIcon from '../../../Icons/Casino';
import GameIcon from '../../../Icons/Game';
import MessageIcon from '../../../Icons/Chat';
import { addNewStyle, CRISTP_ID, wait } from '../../../Helper';
import LotteryIcon from '../../../Icons/Lottery';

const BottomNavWrapper = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #323738;
  border-top: 1px solid #2C2F36;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: none;
  z-index: 1000;
  @media (max-width: 768px) {
    display: block;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 8px;
  transition: all 0.2s ease;
  color: ${props => props.active === 'true' ? '#10B981' : '#9CA3AF'};
  &:hover {
    color: #10B981;
  }
`;

const NavIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;

  /* SVG icon color logic */
  & svg {
    color: ${props => props.active ? '#24EE89' : '#9CA3AF'};
    stroke: ${props => props.active ? '#24EE89' : '#9CA3AF'};
  }
`;
const NavLabel = styled.span`
  font-size: 10px;
  font-weight: 500;
`;

const BottomNavigation = () => {
  const location = useLocation();

  const navItems = [
    { 
      path: '/menu', 
      icon: <MenuPlayIcon /> ,
      label: 'Menu' 
    },
    { 
      path: '/', 
      icon: <LotteryIcon/>,
      label: 'Lottery' 
    },
    { 
      path: '/casino', 
      icon: <ClaudeIcon/>, 
      label: 'Casino' 
    },
    { 
      path: '/sports/markets/in-play', 
      icon: <GameIcon/>, 
      label: 'Sports' 
    },
    { 
      path: '/', 
      icon: <MessageIcon/>, 
      label: 'Chat' 
    }
  ];

  const [loaded, setLoaded] = useState(false)

  const support = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISTP_ID;

    if (!loaded) {
        console.log("support");
        setLoaded(true);
        const script = document.createElement('script');
        script.src = "https://client.crisp.chat/l.js";
        script.id = "chtt";
        script.async = true;
        document.head.appendChild(script);
    }

    addNewStyle("#crisp-chatbox {display:block !important;}");
    addNewStyle(
        ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
    );

    wait(2000).then(() => {
        const close = document.querySelector(".cc-wdhl");
        if (close === null) return;
        close.addEventListener("click", function (event) {
            addNewStyle("#crisp-chatbox {display:none !important;}");
            document.getElementById("chtt").src = "";
        });
    });
};

  return (
    <BottomNavWrapper>
      <NavContainer>
        {navItems.map((item) => {
          const isActive = location.pathname === item.path;
          return (
            <NavItem
            onClick={()=>item.label==='Chat' && support()}
              key={item.path}
              to={item.path}
              active={isActive.toString()}
            >
              <NavIconWrapper active={isActive}>
                {item.icon}
              </NavIconWrapper>
              <NavLabel>{item.label}</NavLabel>
            </NavItem>
          );
        })}
      </NavContainer>
    </BottomNavWrapper>
  );
};

export default BottomNavigation;