import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.size || '28px'};
  height: ${props => props.size || '28px'};
`;

const BrandPath = styled.path`
  fill: #22C55E; /* Green color */
`;

const SecondaryPath = styled.path`
  fill: white;
`;

const GameIcon = ({ size }) => {
  return (
    <StyledSVG 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 32 32"
      size={size}
    >
      <BrandPath 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M19.1738 9.19259C18.6181 9.19259 16.8925 8.84162 14.4065 7.49628C13.8947 7.21843 12.564 6.79435 11.0285 6.79435C10.6483 6.79435 10.2827 6.8236 9.93179 6.86747C11.672 5.71223 13.7485 5.03955 16.0005 5.03955H16.0151C16.4831 5.21503 18.3256 6.04856 19.4077 8.94399C19.4223 8.97324 19.437 9.00248 19.437 9.03173C19.4662 9.09022 19.4662 9.13409 19.4662 9.14872C19.437 9.16334 19.3492 9.19259 19.1738 9.19259ZM20.3003 15.8317C20.4319 15.8317 20.5489 15.861 20.5927 16.1242L20.5781 16.1096C20.6659 16.636 20.5781 19.5022 19.8031 21.9736C18.8672 24.5473 17.6096 26.1851 16.001 26.9601C13.5881 26.9601 11.3508 26.1851 9.53748 24.8544C10.371 24.3864 11.6579 23.3628 13.6613 21.2424L13.745 21.1507L13.745 21.1506C13.8819 21.0009 14.0566 20.81 14.2608 20.5697L14.2778 20.5506L14.2778 20.5506L14.2778 20.5505C15.4224 19.2592 17.5302 16.8811 19.2181 16.1681C19.2664 16.1584 19.321 16.136 19.3862 16.1093C19.4198 16.0956 19.4562 16.0806 19.496 16.0657L19.559 16.0403C19.7762 15.9524 20.0744 15.8317 20.3003 15.8317ZM24.0587 17.2062C23.4591 16.1533 22.9327 15.8316 22.2308 15.6269C22.1577 15.5976 22.0845 15.5976 22.0114 15.5976C21.6751 15.5976 21.6458 15.9778 21.6458 15.9925C21.4729 20.6194 21.1294 21.8506 20.8815 22.7392L20.8708 22.7777L20.8269 22.9386C20.4614 24.1962 19.8764 25.4538 19.1599 26.5067C21.2656 25.8778 23.1082 24.6202 24.4682 22.9678C25.1701 20.2479 24.5559 18.0836 24.0587 17.2062ZM20.8564 9.14886H20.7979V9.13424C20.637 9.11961 20.4908 9.10499 20.3153 8.69554C20.1398 8.22759 19.4379 6.41429 18.3558 5.27367C20.0228 5.63925 21.5583 6.38505 22.8451 7.4233V7.49642C22.8451 7.49706 22.8452 7.49863 22.8454 7.50107C22.8482 7.55448 22.8736 8.02745 22.538 8.44694C22.2017 8.85639 21.6314 9.09037 20.8564 9.14886Z"
      />
      <SecondaryPath 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M11.8028 12.6003H11.759V12.6149C10.4429 12.9366 9.11214 13.3754 8.03002 13.8579C6.68467 14.4575 5.69028 15.1155 5.03223 15.8467C5.0761 13.1706 6.05586 10.7431 7.67906 8.87135C8.27861 8.62276 9.58009 8.16943 11.1594 8.16943C11.9198 8.16943 12.651 8.28642 13.3529 8.49115C13.4992 8.53502 13.9671 8.72512 14.4643 8.92985C15.5026 9.36855 16.804 9.90961 17.4475 10.012C17.6961 10.0705 18.5881 10.2606 19.3193 10.2606C19.524 10.2606 19.6848 10.246 19.8311 10.2167C19.9188 10.5238 20.065 11.0795 20.2113 11.7229C18.9975 11.7375 14.3619 11.9569 11.8028 12.6003ZM23.2969 9.35394C23.5601 9.0761 23.7356 8.72514 23.8087 8.31568C25.0078 9.52942 25.9144 11.0064 26.4409 12.6442C25.6366 12.2347 24.1158 11.7668 21.2788 11.7229C21.118 11.0795 20.9864 10.6116 20.8548 10.1875C21.6298 10.1729 22.6534 10.0266 23.2969 9.35394ZM11.4369 13.9893C7.67871 14.9983 5.95315 16.8555 5.20736 17.9961C5.61682 20.2043 6.6697 22.1784 8.19053 23.7139C9.84297 23.0266 13.0162 20.292 13.5134 19.7802C14.0691 19.166 16.2626 16.8701 16.6721 16.4607C17.7542 15.32 19.1142 14.6328 20.5765 14.4719C20.5374 13.6116 20.4518 13.0417 20.413 12.7831C20.4082 12.7516 20.4042 12.7247 20.401 12.7025C15.4145 12.7902 11.4808 13.9747 11.4369 13.9893ZM26.8361 14.2964C26.9239 14.8521 26.9677 15.4224 26.9677 16.0073V15.9927C26.9677 17.9084 26.4705 19.7071 25.6078 21.2718C25.754 20.0288 25.8271 18.04 25.1398 16.7678L25.1106 16.7239C24.8181 16.1682 23.9114 14.4719 21.5863 14.428V14.2525L21.5847 14.2203C21.556 13.6602 21.5267 13.0898 21.4693 12.7025H21.7326C22.7708 12.7025 25.3007 12.8633 26.8361 14.2964Z"
      />
    </StyledSVG>
  );
};

export default GameIcon;