import React from "react";
import { Switch, Route, useLocation, useParams } from "react-router-dom";

import Index from "./App/Pages";
import Home from "./App/Pages/Parts/Home";
import NotFound from "./App/Pages/404";
import Rakeback from "./App/Pages/Rakeback";
import BonusAff from "./App/Pages/Affiliate/Bonus";
import Bonus from "./App/Pages/Bonus";
import AboutUs from "./App/Pages/AboutUs";
import Rules from "./App/Pages/Rules";
import Faq from "./App/Pages/Faq";
// import Vip from "./App/Pages/Vip";


import Login from "./App/Pages/Auth/Login";
import Register from "./App/Pages/Auth/Register";
import UserStat from "./App/Components/User/Stat/Modal";
import GameStat from "./App/Components/Game/Stat";
import UserSetting from "./App/Components/User/Setting";
import GamesHome from "./App/Games";
import Wingo from './App/Games/Wingo/Wingo'
import SlotsHome from "./App/Slots/index";
import Slots from "./App/Slots/Game";
// import SportsHome from "./App/Sports";

import Crash from "./App/Games/Crash";
import Keno from "./App/Games/Keno";
import Wheel from "./App/Games/Wheel";
import Limbo from "./App/Games/Limbo";
import Mine from "./App/Games/Mine";
import Hilo from "./App/Games/Hilo";
import Hashdice from "./App/Games/HashDice";
import ClassicDice from "./App/Games/ClassicDice";
import SingleKeno from "./App/Games/SingleKeno";
import Plinko from "./App/Games/Plinko";
import VideoPoker from "./App/Games/VideoPoker";
import Blackjack from "./App/Games/BlackJack";
import Goal from "./App/Games/Goal";
import Roulette from "./App/Games/Roulette";
import SnackAndLadders from "./App/Games/SnakeAndLadders";
import ThreeCardMonte from "./App/Games/ThreeCardMonte";
import MagicWheel from "./App/Games/MagicWheel";
import Tower from "./App/Games/Tower";
import Diamond from "./App/Games/Diamond";
import HighLow from "./App/Games/HighLow";
import Lottery from "./App/Games/Lottery";
import VipClub from "./App/Pages/VipClub";
import Promotions from "./App/Components/Promotions";

import ProvablyFair from "./App/Pages/Parts/ProvableFair";
import GlobalSettings from "./App/Pages/Parts/GlobalSettings";
import ClubMembership from "./App/Pages/ClubMembership";
import BonusNew from "./App/Pages/BonusNew";
import CreateOwnClub from "./App/Pages/CreateOwnClub";
import SkylaWallet from "./App/Pages/Wallet";
import SkylaMobileMenu from "./App/Pages/SkylaMobileMenu";
import SkylaMobileUserProfile from "./App/Pages/Parts/SkylaHeaderComponents/SkylaMobileProfile";
import { isMobile } from "./Helper";
import SkylaWithdraw from "./App/Pages/Wallet/Withdraw";
import SkylaMobileWithdraw from "./App/Pages/Wallet/SkylaMobileWithdraw";
import Affiliate from "./App/Pages/Affiliate";
import AffiliateDashboard from "./App/Components/AffiliateDashboard";
import SportsPage from "./App/Pages/Sports";
import Casino from "./App/Pages/Casino";
import GlobalSearch from "./App/Pages/GlobalSearch";
import ClubMembershipPage from "./App/Pages/ClubMembership/MobileJoinClub";
import JoinClubPageMobile from "./App/Pages/ClubMembership/JoinClubModal";
import Sports from "./App/Pages/Sports";

import MobileCricketBettingUI from "./App/Pages/Sports/components/MobileUis/MobileCricketUi";
import CricketBetUiComponent from "./App/Pages/Sports/components/CricketUi/CricketBetUi";
import ComingSoon from "./App/Components/ComingSoon";

// import Pick2 from "./App/Games/Lottery/Pick2";

// import Jocker from "./App/Games/Jocker";
const ReferralRoute = (props) => {
  const { referralCode } = useParams(); // Use the hook inside a valid functional component
  console.log("code", referralCode);
  return (
    <Index
      t={props.t}
      content={
        <Home
          t={props.t}
          content={
            <Register referral={referralCode} history={props.history} show={true} />
          }
        />
      }
    />
  );
};

function Router(props) {
  let location = useLocation();
  let background = location.state && location.state.background;
  let loc = location.pathname;
  const { t } = props;

  const checkPath = () => {
    if (isMobile()) {
      return '/mobile-user'
    }
    else {
      return '/'
    }
  }
  return (
    <>
      <Switch history={props.history} location={background || location}>
        <Route
          exact
          path="/"
          children={<Index t={t} location={loc} content={<Home t={t} />} />}
        />
        <Route
          path="/register"
          children={
            <Index
              t={t}
              content={
                <Home
                  t={t}
                  content={
                    <Register t={t} history={props.history} show={true} />
                  }
                />
              }
            />
          }
        />
        <Route path="/referal/:referralCode" component={ReferralRoute} />
        <Route
          path="/login"
          children={
            <Index
              t={t}
              content={
                <Home
                  t={t}
                  content={<Login t={t} history={props.history} show={true} />}
                />
              }
            />
          }
        />
        <Route
          path="/wingo"
          children={
            <Index
              t={t}
              content={
                <Wingo
                  t={t}
                  content={<Login t={t} history={props.history} show={true} />}
                />
              }
            />
          }
        />
        <Route
          path="/setting"
          children={
            <Index
              t={t}
              content={<UserSetting t={t} history={props.history} />}
            />
          }
        />
        <Route
          path="/wallet"
          children={
            <Index
              t={t}
              content={<SkylaWallet t={t} history={props.history} />}
            />
          }
        />
        <Route
          path="/withdraw"
          children={
            <Index
              t={t}
              content={<SkylaWallet t={t} history={props.history} />}
            />
          }
        />
        <Route
          path="/swap"
          children={
            <Index
              t={t}
              content={<SkylaWallet t={t} history={props.history} />}
            />
          }
        />
        <Route
          path="/vault-pro"
          children={
            <Index
              t={t}
              content={<SkylaWallet t={t} history={props.history} />}
            />
          }
        />
        <Route
          path="/menu"
          children={
            <Index
              t={t}
              content={<SkylaMobileMenu t={t} history={props.history} />}
            />
          }
        />

        <Route
          path="/withdraw-mobile"
          children={
            <Index
              t={t}
              content={<SkylaMobileWithdraw t={t} history={props.history} />}
            />
          }
        />

        <Route
          path="/affiliate-dashbaord"
          children={
            <Index
              t={t}
              content={<AffiliateDashboard />}
            />
          }
        />

        <Route
          path={'/mobile-user'}
          children={
            <Index
              t={t}
              content={<SkylaMobileUserProfile t={t} history={props.history} />}
            />
          }
        />

        <Route
          path="/transactions"
          children={
            <Index
              t={t}
              content={<SkylaWallet t={t} history={props.history} />}
            />
          }
        />


        <Route
          path="/affiliate"
          children={
            <Index
              t={t}
              content={<Affiliate t={t} history={props.history} />}
            />
          }
        />
        <Route
          path="/affiliate-dashbaord"
          children={
            <Index
              t={t}
              content={<AffiliateDashboard />}
            />
          }
        />
        <Route
          path="/sports/markets/:tab"
          children={
            <Index
              t={t}
              content={<Sports />}
            />
          }
        />
        {/* <Route
          path="/sports/markets/:tab"  
          children={
            <Index
              t={t}
              content={<CricketBetUiComponent/>}
            />
          }
        /> */}
        <Route
          path="/match/fullmarket/event/:id"  // Note the "/" before :id
          children={
            <Index
              t={t}
              content={<MobileCricketBettingUI />}
            />
          }
        />

        <Route
          path="/casino"
          children={
            <Index
              t={t}
              content={<Casino />}
            />
          }
        />
        <Route
          path="/join-mobile"
          children={
            <Index
              t={t}
              content={<JoinClubPageMobile />}
            />
          }
        />
        <Route
          path="/global-search"
          children={
            <Index
              t={t}
              content={<GlobalSearch />}
            />
          }
        />
        <Route
          path="/provably-fair"
          children={
            <Index
              t={t}
              content={<ProvablyFair />}
            />
          }
        />
        <Route
          path="/global-settings"
          children={
            <Index
              t={t}
              content={<GlobalSettings />}
            />
          }
        />
        <Route
          path="/affiliate"
          children={
            <Index
              t={t}
              content={<Affiliate t={t} history={props.history} />}
            />
          }
        />

        <Route
          path="/lottery"
          children={
            <Index
              t={t}
              content={<ComingSoon/>}
            />
          }
        />
        <Route
          path="/racing"
          children={
            <Index
              t={t}
              content={<ComingSoon/>}
            />
          }
        />
         <Route
          path="/up-down"
          children={
            <Index
              t={t}
              content={<ComingSoon/>}
            />
          }
        />
          <Route
          path="/bingo"
          children={
            <Index
              t={t}
              content={<ComingSoon/>}
            />
          }
        />




        <Route
          path="/promotions"
          children={
            <Index
              t={t}
              content={<Promotions t={t} history={props.history} />}
            />
          }
        />
        <Route
          path="/rakeback"
          children={
            <Index t={t} content={<Rakeback t={t} history={props.history} />} />
          }
        />

        <Route
          path="/lottery"
          children={
            <Index t={t} content={<Lottery t={t} history={props.history} />} />
          }
        />
        {/* <Route
          path="/pick2"
          children={
            <Index t={t} content={<Pick2 t={t} history={props.history} />} />
          }
        /> */}
        <Route
          path="/aff"
          children={
            <Index
              t={t}
              content={
                <BonusAff params={location} history={props.history} t={t} />
              }
            />
          }
        />
        <Route
          path="/bonus-page"
          children={
            <Index
              t={t}
              content={
                <BonusNew params={location} history={props.history} t={t} />
              }
            />
          }
        />
        <Route
          path="/club-membership"
          children={
            <Index
              t={t}
              content={
                <ClubMembership params={location} history={props.history} t={t} />
              }
            />
          }
        />
        <Route
          path="/create-own-club"
          children={
            <Index
              t={t}
              content={
                <CreateOwnClub params={location} history={props.history} t={t} />
              }
            />
          }
        />
        <Route
          path="/rules"
          children={
            <Index
              t={t}
              content={
                <Rules params={location} history={props.history} t={t} />
              }
            />
          }
        />
        <Route
          path="/faq"
          children={
            <Index
              t={t}
              content={<Faq params={location} history={props.history} t={t} />}
            />
          }
        />
        <Route
          path="/user/:id"
          children={
            <Index
              t={t}
              content={
                <Home
                  t={t}
                  content={
                    <UserStat
                      t={t}
                      history={props.history}
                      params={location}
                      byLink={true}
                    />
                  }
                />
              }
            />
          }
        />
        <Route
          path="/game/:id"
          children={
            <Index t={t} content={<GameStat t={t} params={location} />} />
          }
        />
        <Route
          path="/crash"
          children={<Index t={t} content={<Crash t={t} />} />}
        />
        <Route
          path="/keno"
          children={<Index t={t} content={<Keno t={t} />} />}
        />
        <Route
          path="/wheel"
          children={<Index t={t} content={<Wheel t={t} />} />}
        />
        <Route
          path="/limbo"
          children={<Index t={t} content={<Limbo t={t} />} />}
        />
        <Route
          path="/hash-dice"
          children={<Index t={t} content={<Hashdice t={t} />} />}
        />
        <Route
          path="/classic-dice"
          children={
            <Index
              t={t}
              content={<ClassicDice t={t} history={props.history} />}
            />
          }
        />
        <Route
          path="/mine"
          children={
            <Index t={t} content={<Mine t={t} history={props.history} />} />
          }
        />
        <Route
          path="/singlekeno"
          children={
            <Index
              t={t}
              content={<SingleKeno t={t} history={props.history} />}
            />
          }
        />
        <Route
          path="/hilo"
          children={
            <Index t={t} content={<Hilo t={t} history={props.history} />} />
          }
        />
        <Route
          path="/roulette"
          children={
            <Index t={t} content={<Roulette t={t} history={props.history} />} />
          }
        />
        <Route
          path="/plinko"
          children={<Index t={t} content={<Plinko t={t} />} />}
        />
        <Route
          path="/videopoker"
          children={<Index t={t} content={<VideoPoker t={t} />} />}
        />
        <Route
          path="/blackjack"
          children={
            <Index t={t} content={<Blackjack history={props.history} />} />
          }
        />
        <Route
          path="/goal"
          children={<Index t={t} content={<Goal history={props.history} />} />}
        />
        <Route
          path="/snake-and-ladders"
          children={
            <Index
              t={t}
              content={<SnackAndLadders history={props.history} />}
            />
          }
        />
        <Route
          path="/3-card-monte"
          children={
            <Index t={t} content={<ThreeCardMonte history={props.history} />} />
          }
        />
        {/* <Route path="/jocker" children={<Index t={t} content={<Jocker history={props.history} />} />} />*/}
        {/*<Route path="/baccarat" children={<Index t={t} content={<Baccarat history={props.history} />} />} />*/}
        <Route
          path="/magic-wheel"
          children={
            <Index t={t} content={<MagicWheel history={props.history} />} />
          }
        />
        <Route
          path="/tower"
          children={<Index t={t} content={<Tower history={props.history} />} />}
        />
        <Route
          path="/diamond"
          children={
            <Index t={t} content={<Diamond history={props.history} />} />
          }
        />
        <Route
          path="/high-or-low"
          children={
            <Index t={t} content={<HighLow history={props.history} />} />
          }
        />
        <Route
          path="/slots/:id"
          children={
            <Index t={t} content={<Slots t={t} history={props.history} />} />
          }
        />
        <Route
          path="/slots"
          children={<Index t={t} content={<SlotsHome t={t} />} />}
        />
        <Route
          path="/games"
          children={<Index t={t} content={<GamesHome t={t} />} />}
        />
        <Route
          path="/bonus"
          children={<Index t={t} content={<Bonus t={t} />} />}
        />
        <Route
          path="/about-us"
          children={<Index t={t} content={<AboutUs t={t} />} />}
        />
        {/* <Route path="/sports" children={<Index t={t} content={<SportsHome t={t} />} />} />*/}
        <Route path="/vip-club" children={<Index t={t} content={<VipClub t={t} />} />} />
        <Route path="*">
          <NotFound history={props.history} />
        </Route>
      </Switch>
    </>
  );
}

export default Router;
