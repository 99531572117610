import React, { useState, useEffect } from 'react';
import SportsWeb from "./SportsWeb";
import SportsMobileView from './SportsMobile';
import CricketBetUiComponent from './components/CricketUi/CricketBetUi';

const Sports = () => {
  const [activeTab, setActiveTab] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check initial screen size
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Set initial state
    checkScreenSize();

    // Add event listener for window resize
    window.addEventListener('resize', checkScreenSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {isMobile ? (
        <SportsMobileView />
      ) : (
        <CricketBetUiComponent
        />
      )}
    </>
  );
};

export default Sports;