import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  width: 18.14px;
  height: 18.14px;
  fill: currentColor;
`;

const LockIcon = ({ color = 'currentColor' }) => {
  return (
    <StyledSvg viewBox="0 0 32 32">
      <g id="global_settings/locked">
        <path
          d="M23.7656 13.5598H22.5286V8.15924C22.5243 5.12776 20.0683 2.67178 17.0377 2.66748H15.1021C12.0707 2.67178 9.61469 5.12776 9.61039 8.15838V13.5589H8.21336C6.56687 13.571 5.23694 14.9078 5.23694 16.556V26.3369C5.23694 27.992 6.57891 29.334 8.23401 29.334H23.7647C25.4198 29.334 26.7618 27.992 26.7618 26.3369V16.556C26.7618 14.9009 25.4198 13.5589 23.7647 13.5589L23.7656 13.5598ZM17.2192 21.8869C17.171 22.4839 16.6747 22.9502 16.0699 22.9502C15.4652 22.9502 14.9688 22.4839 14.9206 21.8912V19.9101C14.9688 19.3131 15.4652 18.8468 16.0699 18.8468C16.6747 18.8468 17.171 19.3131 17.2192 19.9058V19.9101V21.8869ZM20.0838 13.5598H12.056V8.15924C12.0603 6.47833 13.4212 5.11744 15.1021 5.11314H17.0377C18.7186 5.11744 20.0795 6.47833 20.0838 8.15924V13.5598Z"
          fill={color}
        />
      </g>
    </StyledSvg>
  );
};

export default LockIcon;