import React from "react";
import styled from "styled-components";
import CommissionHistory from "./History/CommissionHistory";
import ReferralHistory from "./History/ReferralHistory";
import { Link } from "react-router-dom";

const AffiliateBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;

  @media (max-width: 911px) {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #323738;
`;

const SectionNavWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-bottom: 1px solid #ffffff20;
`;

const NavItem = styled.div`
  padding: 0px 10px;
  padding-bottom: 8px;
  text-align: center;
  min-width: 150px;
  border-bottom: 2px solid ${(props) => (props.active ? "#24EE89" : "#323738")};
  cursor: pointer;
  color: ${(props) => (props.active ? "#ffffff" : "#24EE89")};
  transition: all 0.3s ease;
`;

const SectionDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  min-height: 50vh;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const ValueText = styled.span`
  color: #24EE89;
  font-size: 18px;
  font-weight: bold;
`;

const Label = styled.span`
  color: ${(props) => (props.dimmed ? "#24EE89" : "#ffffff")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const FlexRow = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-left: 1.5px solid #ffffff20;
  justify-content: center;
  min-height: 8vh;
  width: 100%;

  @media (max-width: 911px) {
    border-left: none;
    justify-content: start;
    align-items: start;
  }
`;

const StyledButton = styled.button`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  min-width: 100px;
  max-width: max-content;
  transition: all 0.3s ease;

  &:hover {
    background-position: right center;
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    border: 1px solid #24EE89;
  }
`;

class AffiliateRewards extends React.Component {
  state = {
    isHistoryTab: "Commission",
  };

  handleTabClick = (tab) => {
    this.setState({ isHistoryTab: tab });
  };

  render() {
    const { isHistoryTab } = this.state;

    return (
      <AffiliateBody>
        <SectionHeader>
          <Column>
            <div>
              <Label>Available Commission Rewards</Label>
            </div>
            <div>
              <ValueText>$0.00</ValueText>
            </div>
            <div>
              <FlexRow>
                <Label>Total Received:</Label>
                <Label bold>$0.00</Label>
              </FlexRow>
            </div>
          </Column>

          <Column>
            <div>
              <Label>Available Referral Rewards</Label>
            </div>
            <div>
              <ValueText>$0.00</ValueText>
            </div>
            <FlexRow>
              <FlexRow>
                <Label dimmed>Total Received:</Label>
                <Label bold>$0.00</Label>
              </FlexRow>
              <FlexRow>
                <Label dimmed>Locked Rewards:</Label>
                <Label bold>$0.00</Label>
              </FlexRow>
            </FlexRow>
          </Column>

          <DividerContainer>
            <StyledButton type="button">Withdraw to Wallet</StyledButton>
          </DividerContainer>
        </SectionHeader>

        <SectionBody>
          <SectionNavWrapper>
            <div style={{ display: "flex", gap: "10px" }}>
              <NavItem
                active={isHistoryTab === "Commission"}
                onClick={() => this.handleTabClick("Commission")}
              >
                Commission
              </NavItem>
              <NavItem
                active={isHistoryTab === "Referral"}
                onClick={() => this.handleTabClick("Referral")}
              >
                Referral
              </NavItem>
            </div>

            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                display: "flex",
                gap: "10px",
                padding: "8px 15px",
                borderRadius: "5px",
                backgroundColor: "#2b2f33",
              }}
            >
              <Link
                to="/affiliate/rules"
                style={{ color: "#ffffff", lineHeight: "normal" }}
              >
                Rules
              </Link>
            </div>
          </SectionNavWrapper>

          <SectionDataWrapper>
            {isHistoryTab === "Commission" && <CommissionHistory />}
            {isHistoryTab === "Referral" && <ReferralHistory />}
          </SectionDataWrapper>
        </SectionBody>
      </AffiliateBody>
    );
  }
}

export default AffiliateRewards;
