import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  height: 1.5rem;
  width: 1.5rem;
  flex-shrink: 0;
  fill: none;
`;

const SecondaryPath = styled.path`
  fill: #fff;
`;

const BrandPath = styled.path`
  fill: #22C55E; /* Green color */
`;

const PromotionIcon = () => {
  return (
    <SVG viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g id="botton_menu/promotion_default">
        <g id="Group 1000006359">
          <SecondaryPath
            id="Vector"
            d="M24.6075 6.86262L28.4985 10.3944C29.0205 10.8678 29.3189 11.5404 29.3189 12.2449L29.333 25.7647C29.3339 26.6862 28.5873 27.4347 27.6658 27.4356H19.3374C18.4168 27.4356 17.6693 26.69 17.6684 25.7684L17.6544 12.2486C17.6534 11.5423 17.9519 10.8678 18.4748 10.3925L22.364 6.86262C23.0002 6.28538 23.9713 6.28538 24.6075 6.86262ZM23.5128 10.341C22.7064 10.341 22.0524 11.0455 22.0524 11.9156C22.0524 12.7857 22.7064 13.4901 23.5128 13.4901C24.3193 13.4901 24.9733 12.7857 24.9733 11.9156C24.9733 11.0455 24.3193 10.341 23.5128 10.341Z"
          />
          <BrandPath
            id="Vector_2"
            d="M19.238 7.93644L17.7393 9.29674C16.8907 10.0667 16.4014 11.2025 16.4023 12.3981L16.4164 25.6401C16.4164 25.7916 16.4126 25.8852 16.4463 26.0863C16.48 26.2875 16.4033 26.3071 16.2573 26.265C15.609 26.0798 14.636 25.7982 13.3384 25.4212C12.4487 25.1639 11.921 24.1787 12.1577 23.2198L15.3236 10.425C15.5145 9.65506 16.0113 9.0142 16.6802 8.67647L18.9574 7.52666C19.0809 7.46398 19.2315 7.51356 19.2932 7.63706C19.3438 7.73716 19.3213 7.86066 19.238 7.93644Z"
          />
          <SecondaryPath
            id="Vector_3"
            d="M17.9109 6.76015L16.1118 7.68636C15.093 8.21027 14.3267 9.18045 14.018 10.3359L10.6041 23.1306C10.5779 23.2298 10.5508 23.3954 10.548 23.5329C10.5452 23.6704 10.5312 23.8426 10.1485 23.6087C9.60215 23.2756 8.78259 22.7798 7.68705 22.123C6.89463 21.6449 6.63922 20.5559 7.11636 19.6906L13.4857 8.15133C13.8692 7.45621 14.5148 6.96691 15.2492 6.81348L17.7462 6.29237C17.8819 6.26431 18.0138 6.35131 18.0428 6.48603C18.0662 6.59643 18.0128 6.70776 17.9127 6.76015H17.9109Z"
          />
          <BrandPath
            id="Vector_4"
            d="M16.9785 5.20541L15.0007 5.6339C13.8808 5.87621 12.8891 6.6153 12.2922 7.6519L5.68344 19.1266C5.62076 19.2351 5.56463 19.3464 5.51504 19.4587C5.47107 19.5588 5.46358 19.7908 5.20163 19.5569C4.71233 19.12 4.02095 18.4006 3.12562 17.3986C2.48382 16.7315 2.51937 15.6135 3.2042 14.9016L12.3428 5.40375C12.8929 4.83212 13.6432 4.52619 14.3926 4.56829L16.9392 4.71143C17.0776 4.71892 17.1824 4.83773 17.1749 4.97526C17.1684 5.08753 17.0879 5.18202 16.9785 5.20541Z"
          />
        </g>
      </g>
    </SVG>
  );
};

export default PromotionIcon;