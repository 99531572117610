import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: inline-block;
  width: ${props => props.size || '32px'};
  height: ${props => props.size || '32px'};
`;

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${props => props.color || '#666666'};
  transition: fill 0.3s ease;

  &:hover {
    fill: ${props => props.hoverColor || '#444444'};
  }
`;

const EmailIcon = ({ size, color, hoverColor, className }) => {
  return (
    <IconWrapper size={size} className={className}>
      <StyledSVG
        viewBox="0 0 32 32"
        color={color}
        hoverColor={hoverColor}
      >
        <path d="M22.667 4.667h-13.333c-4 0-6.667 2-6.667 6.667v9.333c0 4.667 2.667 6.667 6.667 6.667h13.333c4 0 6.667-2 6.667-6.667v-9.333c0-4.667-2.667-6.667-6.667-6.667zM23.293 12.787l-4.173 3.333c-0.88 0.707-2 1.053-3.12 1.053s-2.253-0.347-3.12-1.053l-4.173-3.333c-0.427-0.347-0.493-0.987-0.16-1.413 0.347-0.427 0.973-0.507 1.4-0.16l4.173 3.333c1.013 0.813 2.733 0.813 3.747 0l4.173-3.333c0.427-0.347 1.067-0.28 1.4 0.16 0.347 0.427 0.28 1.067-0.147 1.413z" />
      </StyledSVG>
    </IconWrapper>
  );
};

export default EmailIcon;