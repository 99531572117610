import React from "react";
import { ChevronLeft, ChevronRight, Play } from 'lucide-react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  __,
  isMobile,
  wait,
  Event,
  decode,
  sendNotfication,
} from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const HeaderContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const IconBg = styled.div`
  padding: 6px 8px;
  background-color: #394142;
  border-radius:10px;
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top:0px;
`;

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
   overflow-y: hidden;
  gap: 8px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const GameTitle = styled.div`
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
`;

const GameCard = styled.div`
  min-width: 142px;
  height: 195px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(0);
    border-radius: 8px;
    opacity: 0;
    transition: backdrop-filter 0.2s ease, opacity 0.2s ease;
  }

  &:hover {
    transform: translateY(-5px);

    &:before {
      backdrop-filter: blur(10px);
      opacity: 1;
    }

    ${GameTitle} {
      opacity: 1;
    }
  }
     @media (max-width: 768px) {
       min-width: 118px;
 
  }
`;

const GameImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;



const PlayIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease;

  ${GameCard}:hover & {
    opacity: 1;
  }
`;

const PlayIcon = styled(Play)`
  color: #fff;
  font-size: 24px;
`;

const TopTitle = styled.h3`
  color: white;
  font-size: 16px;
  font-weight:bold;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => props.right ? 'right: 10px' : 'left: 10px'};
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  z-index: 10;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;


class Games extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      col: "col-12",
      mdCol: 3,
      isMin: false,
      loading: true,
      games: [],
    };
    this.scrollContainerRef = React.createRef();
  }


  componentDidMount() {
    socket.emit(C.GAMES);

    if (isMobile()) {
      this.setState({ col: "col-4" });
    }

    window.addEventListener("resize", this.handleResize);

    wait(50).then(() => {
      this.handleResize();
    });

    Event.on("hide_games", () => {
      wait(50).then(() => {
        this.handleResize();
      });
    });

    socket.on(C.GAMES, (data) => {
      this.setState({ games: decode(data), loading: false });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const pageContent = document.querySelector("#page-content");

    if (pageContent !== null) {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (width < 700) {
        this.setState({ mdCol: 4, isMin: true });
      } else {
        this.setState({ mdCol: 3, isMin: false });
      }
    }
  };

  handleScrollLeft = () => {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollBy({
        left: -400,
        behavior: 'smooth'
      });
    }
  }

  handleScrollRight = () => {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollBy({
        left: 400,
        behavior: 'smooth'
      });
    }
  }


  render() {
    const { games, loading } = this.state;
    const { currentPage } = this.props;

    if (loading) {
      return (
        <div className="ycenter text-center">
          <div className="spinner-grow text-white my-5" role="status" />
        </div>
      );
    }

    return (

      <>
    {
      currentPage === "home" &&(
        <HeaderContainer>
        <TopTitle>
          Originals
        </TopTitle>
        <HeaderContent>
          <Link to="/games">
          <IconBg>
          <span style={{fontWeight:"bold", fontFamily:"normal"}}> All</span>
       <span>
       <ChevronRight size={14}/>
       </span>
       </IconBg>
          </Link>
        <IconBg onClick={this.handleScrollLeft}>
        <ChevronLeft size={16}/>
       </IconBg>
       <IconBg>
        <ChevronRight onClick={this.handleScrollRight} size={16}/>
       </IconBg>
        </HeaderContent>
      </HeaderContainer>
      )
    }
      
      <CarouselContainer>
   
        <ScrollContainer ref={this.scrollContainerRef}>
          {games.slice(0, 18).map((game, index) => (
          <GameCard key={__.toString(index)}>
          <Link
            style={{ borderRadius: '30px' }}
            to={game.prefix !== null ? '/' + game.prefix : '#'}
            onClick={game.disabled ? this.maintance : undefined}
          >
            <GameImage src={game.image} alt={game.name} />
            <GameTitle>{game.name}</GameTitle>
            <PlayIconContainer>
              <PlayIcon />
            </PlayIconContainer>
          </Link>
        </GameCard>
          ))}
        </ScrollContainer>

     
      </CarouselContainer>
    </>
     
    );
  }
}

export default Games;