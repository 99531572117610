// import React from 'react'
// import { Modal, Row, Col, Card } from "react-bootstrap";
// import { Helmet } from "react-helmet";

// export default class Bonus extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {};
//     }

//     componentWillMount() {
//         document.body.scrollTop = 0;
//         document.documentElement.scrollTop = 0;
//     }

//     render() {
//         return (
//             <>
//                 <Helmet>
//                     <title>Bonus - Crypto Casino Games</title>
//                     <meta name="keywords" content="Crypto Slot Games, Online Slot Games, Bitcoin Slot Games, Blockchain Slot Games, Best Crypto Slot Games, PlayCrash.com Slot Games" />
//                     <meta name="description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
//                     <meta name="og:title" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
//                     <meta name="og:description" content="Play Online Slot Games - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
//                     <link rel="canonical" href="/bonus" />
//                 </Helmet>
//                 <div>
//                     <Card className="levbg bg-dark text-white">
//                         <Card.Body>
//                             <h1 className="text-center">Level Up Rewards</h1>
//                             <Card.Text className="font-17 font-weight-bold text-center">
//                                 Collect all medals and get rewarded !
//                             </Card.Text>
//                             <div className={"medals-view mt-5"}>
//                                 <Row>
//                                     <Col sm="12" md="6">
//                                         <div className="media mb-2">
//                                             <img src="/assets/images/badges/badge-2.svg" height="60" className="mr-3" />
//                                             <div className="media-body align-self-center">
//                                                 <div className="mb-2">
//                                                     <span className="badge badge-purple px-3">1</span>
//                                                 </div>
//                                                 Required Minimum 100 USD Deposit
//                                             </div>
//                                         </div>
//                                     </Col>
//                                     <Col sm="12" md="6">
//                                         <div className="media mb-2">
//                                             <img src="/assets/images/badges/badge-3.svg" height="60" className="mr-3" />
//                                             <div className="media-body align-self-center">
//                                                 <div className="mb-2">
//                                                     <span className="badge badge-purple px-3">2</span>
//                                                 </div>
//                                                 Required Minimum 500 USD Deposit
//                                             </div>
//                                         </div>
//                                     </Col>
//                                     <Col sm="12" md="6">
//                                         <div className="media mb-2">
//                                             <img src="/assets/images/badges/badge-7.svg" height="45" className="mr-3" />
//                                             <div className="media-body align-self-center">
//                                                 <div className="mb-2">
//                                                     <span className="badge badge-purple px-3">3</span>
//                                                 </div>
//                                                 Required Minimum 2000 USD Deposit
//                                             </div>
//                                         </div>
//                                     </Col>
//                                     <Col sm="12" md="6">
//                                         <div className="media mb-2">
//                                             <img src="/assets/images/badges/badge-6.svg" height="60" className="mr-3" />
//                                             <div className="media-body align-self-center">
//                                                 <div className="mb-2">
//                                                     <span className="badge badge-purple px-3">4</span>
//                                                 </div>
//                                                 Required 10 Times Rain
//                                             </div>
//                                         </div>
//                                     </Col>
//                                     <Col sm="12" md="12">
//                                         <div className="media my-2">
//                                             <img src="/assets/images/badges/badge-8.svg" height="60" className="mr-3" />
//                                             <div className="media-body align-self-center">
//                                                 <div className="mb-2">
//                                                     <span className="badge badge-purple px-3">5</span>
//                                                 </div>
//                                                 Required 2500 USD Profit
//                                             </div>
//                                         </div>
//                                     </Col>
//                                 </Row>
//                             </div>
//                         </Card.Body>
//                     </Card>
//                 </div>
//             </>
//         );
//     }
// }



import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getUID, isMobile } from '../../../Helper';
import { X } from 'lucide-react';
import BonusDetails from './BonusDetailsTable';
import BonusMobile from './BonusMobile';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Header = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 20px;
`;

const Title = styled.h1`
  color: white;
  font-size: 20px;
  margin: 0;
  font-weight:bold
`;
const Title1 = styled.h1`
  color: white;
  font-size: 18px;
  font-weight:bold;
  margin-bottom: 10px;
`;
const RedeemWrapper = styled.div`
  position: relative;
  width: 400px;
  background-color: #292D2E;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
`;

const RedeemInput = styled.input`
  background: rgba(42, 42, 42, 0.9);
  border: none;
  border-radius: 4px;
  color: white;
  padding: 8px 120px 8px 12px;
  width: 100%;
  height: 36px;
  font-size: 14px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    background: rgba(42, 42, 42, 1);
  }
`;

const RedeemButton = styled.button`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #4F5253;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 6px 8px;
  cursor: pointer;
  font-size: 13px;
  height: 28px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: background 0.2s ease;

  &:hover {
    background: #4F5253;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    background-size: contain;
    transform: rotate(180deg);
  }
`;


const BonusCard = styled.div`
background: linear-gradient(274deg, rgba(246, 105, 44, 0) 14.01%, rgba(246, 105, 44, .6) 146.96%);
  border-radius: 12px;
  padding: 24px;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
`;

const BonusContent = styled.div`
  position: relative;
  z-index: 1;
  width: 60%;
`;

const BonusCardImage = styled.img`
  position: absolute;
  right: -5%;
  top: 50%;
  transform: translateY(-50%);
  height: 120%;
  z-index: 0;
  opacity: 0.8;
  object-fit: contain;

  @media (max-width: 768px) {
    opacity: 0.4;
    right: -20%;
  }
`;

const TotalAmount = styled.div`
  font-size: 32px;
  color: white;
  font-weight: bold;
  margin-bottom: 30px;
`;

const BonusTypes = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
`;

const BonusType = styled.div`
  .label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .amount {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
`;
const BonusType1 = styled.div`
  .label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
   
  }
  
  .amount {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
`;


const DetailsButton = styled.button`
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: white;
  }
`;

const DepositBonusCard = styled.div`
  background: linear-gradient(86deg, rgba(255, 164, 49, 0) 65.46%, rgba(255, 136, 3, .3) 130.45%), linear-gradient(288deg, rgba(255, 164, 49, 0) 21.4%, rgba(255, 136, 3, .3) 116.92%);
  border-radius: 12px;
  padding: 24px;
  height: 100%;
`;

const DepositHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BonusAmount1 = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: bold;
`;
const BonusAmount = styled.div`
  color: #8B8B85;
  font-size: 14px;
  font-weight: bold;
`;

const BonusTimer = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
`;

const DepositButton = styled.div`
  background: linear-gradient(to bottom, #4AE54A, #94E874);
   padding: 7px 14px;
  border-radius: 6px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: black !important;
  cursor: pointer;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.9;
  }
`;

const ProgressContainer = styled.div`
  position: relative;
  padding-top: 10px;
`;

const ProgressBar = styled.div`
  position: relative;
  height: 4px;
  background: #333;
 

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => props.progress}%;
    background: linear-gradient(90deg, #FFD700, #FFA500);
  }
`;

const ProgressPoints = styled.div`
  display: flex;
  justify-content: space-between;
  // margin-top: 20px;
  padding: 0 0px;
`;

const ProgressPoint = styled.div`
  color: ${props => props.active ? '#FFD700' : 'white'};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  font-size: 16px;
  text-align: center;
  
  img {
    width: 70px;
    height: 70px;
    margin-bottom: 4px;
  }
`;



const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;


const CardBG = styled.div`
  background-color: rgb(50, 55, 56);
    border-radius: 12px;
`;


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 1050;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ModalContent = styled.div`
  background-color: #1E2024;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  max-height: 80vh;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalHeader = styled.div`
  height: 80px;
  background-color: #2A2D35;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  @media (max-width: 768px) {
    border-radius: 0;
    height: 60px;
  }
`;

const ModalTitle = styled.h3`
  color: white;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #6F767E;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &:hover {
    color: #fff;
  }
`;

const ModalBody = styled.div`
  padding: 16px;
  color: white;
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 3px;
  }

  @media (max-width: 768px) {
    padding: 12px 8px;
  }
`;

const DepositInfo = styled.div`
  background-color: #2A2D35;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
`;

const QRContainer = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  width: fit-content;
  margin: 20px auto;
`;

const AddressContainer = styled.div`
  background-color: #2A2D35;
  border-radius: 8px;
  padding: 12px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Address = styled.input`
  background: none;
  border: none;
  color: white;
  width: 100%;
  margin-right: 12px;
  outline: none;
  font-family: monospace;
`;

const CopyButton = styled.button`
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  color: black;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  
  &:hover {
    opacity: 0.9;
  }
`;






const Card = styled.div`
 background: ${props => props.background || '#2b2b3d'};
border-radius: 12px;
  padding: 10px 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px); /* For Safari support */

`;

const ImagePlaceholder = styled.img`
  width: 120px;
  height: 120px;
  object-fit:contain;
`;

const CardTitle = styled.h3`
  color: white;
  font-size: 18px;
  margin: 4px 0;
  font-weight: 600;
`;

const StatsContainer = styled.div`
  width: 100%;
  margin: 10px 0;
`;
const ContainerBottom = styled.div`
  background-color: #272B2C;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  margin-top: 20px;
`;


const StatRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: #9ca3af;
  margin: 5px 0;
  font-size: 14px;
`;

const ClaimButton = styled.button`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  cursor: pointer;
  margin-top: auto;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;
const ClaimButton1 = styled.button`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 24px;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const Timer = styled.span`
  color: #9ca3af;
  font-size: 14px;
`;

const Bonus = () => {
  const [progress] = useState(25); // Example progress

  const [openModal, setOpneModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [bonus,setBonus] = useState(null)

  const handleBonusRequest = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch('https://api.skyla.game/bonus/userbonus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: getUID,
          role: 'user'
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      console.log('Success:', data);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };


  const fetchGameDeatils = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch('https://api.skyla.game/bonus/bonusgame', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: getUID,
          role: 'user'
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      setBonus(data)
      console.log('Success:', data);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    fetchGameDeatils()

    handleBonusRequest()
  },[])
  const formatAmount = (amount) => `$${amount.toFixed(2)}`;
  const history= useHistory();
  return (
    <>
    {
      isMobile() ? (<BonusMobile/>):(
        <>
        <Container>
           <Header>
             <Title>Bonus</Title>
             <RedeemWrapper>
               <RedeemInput placeholder="Redeem your bonus here." />
               <RedeemButton>Redeem Code</RedeemButton>
             </RedeemWrapper>
           </Header>
   
           <BonusCard>
             <BonusContent>
               <BonusType1>
                 <div className="label">Total Bonus Claimed(USD)</div>
               </BonusType1>
               <TotalAmount>$0.00</TotalAmount>
               <BonusTypes>
                 <BonusType>
                   <div className="label">Total VIP Bonus</div>
                   <div className="amount">$0.00</div>
                 </BonusType>
                 <BonusType>
                   <div className="label">Total Special Bonus</div>
                   <div className="amount">$0.00</div>
                 </BonusType>
                 <BonusType>
                   <div className="label">Total General Bonus</div>
                   <div className="amount">$0.00</div>
                 </BonusType>
               </BonusTypes>
               <DetailsButton onClick={()=>setOpneModal(true)}>
                 Details →
               </DetailsButton>
             </BonusContent>
             <BonusCardImage
               src="https://bc.game/modules/bonus2/assets/banner_pc-c1d1fd48.png"
               alt="Banner"
             />
           </BonusCard>
   
           <DepositBonusCard>
             <DepositHeader>
               <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
   
                 <BonusAmount1>Deposit Bonus </BonusAmount1>
                 <BonusAmount>Get Upto:<span style={{ color: "#26E284", fontSize: "16px" }}> 20000 SBC</span></BonusAmount>
   
               </div>
               {/* <BonusAmount>Get up to: 20000 SBC</BonusAmount> */}
               {/* <BonusTimer>Bonus ends: 18d 15h 54m</BonusTimer> */}
             </DepositHeader>
   
             <ProgressContainer>
               <ProgressPoints>
                 <ProgressPoint active={progress >= 25}>
                   <img src="https://bc.game/modules/bonus2/assets/coin-ef1c21e7.png" alt="Icon 1" />
   
                 </ProgressPoint>
                 <ProgressPoint active={progress >= 50}>
                   <img src="https://bc.game/modules/bonus2/assets/coin2_closed-4758251f.png" alt="Icon 2" />
   
                 </ProgressPoint>
                 <ProgressPoint active={progress >= 75}>
                   <img src="https://bc.game/modules/bonus2/assets/coin3_closed-7cd09531.png" alt="Icon 3" />
   
                 </ProgressPoint>
                 <ProgressPoint active={progress >= 100}>
                   <img src="https://bc.game/modules/bonus2/assets/coin4_closed-e0ce66d3.png" alt="Icon 4" />
   
                 </ProgressPoint>
               </ProgressPoints>
               <ProgressBar progress={progress} />
               <ProgressPoints>
                 <ProgressPoint active={progress >= 25}>
                   {/* <img src="https://bc.game/modules/bonus2/assets/coin-ef1c21e7.png" alt="Icon 1" /> */}
                   +180%
                 </ProgressPoint>
                 <ProgressPoint active={progress >= 50}>
                   {/* <img src="https://bc.game/modules/bonus2/assets/coin2_closed-4758251f.png" alt="Icon 2" /> */}
                   +240%
                 </ProgressPoint>
                 <ProgressPoint active={progress >= 75}>
                   {/* <img src="https://bc.game/modules/bonus2/assets/coin3_closed-7cd09531.png" alt="Icon 3" /> */}
                   +300%
                 </ProgressPoint>
                 <ProgressPoint active={progress >= 100}>
                   {/* <img src="https://bc.game/modules/bonus2/assets/coin4_closed-e0ce66d3.png" alt="Icon 4" /> */}
                   +360%
                 </ProgressPoint>
               </ProgressPoints>
             </ProgressContainer>
             <div style={{ width: "100%", display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
               {/* <BonusTimer>Bonus ends: 18d 15h 54m</BonusTimer> */}
               <DepositButton onClick={()=>history.push('wallet')}>Deposit Now</DepositButton>
             </div>
           </DepositBonusCard>
   
   
         </Container>
         <div style={{ padding: "10px" }}>
           <Title1>General Bonus</Title1>
           <GridContainer>
             {/* Quests Card */}
             <CardBG>
               <Card background="radial-gradient(71.39% 51.03% at 46.49% 7.5%, rgba(111, 60, 255, .6) 0%, rgba(111, 60, 255, 0) 98%)">
                 <ImagePlaceholder src='https://bc.game/modules/bonus2/assets/bonus-quests-1356d0d8.png' />
                 <CardTitle>Daily Bonus</CardTitle>
                 <ContainerBottom>
                 <StatsContainer>
                   <StatRow>
                     <span>Daily Bonus:</span>
                     <span>0/1</span>
                   </StatRow>
                   {/* <StatRow>
                     <span>Weekly Quests:</span>
                     <span>0/1</span>
                   </StatRow> */}
                 </StatsContainer>
                 <ClaimButton1>Claim</ClaimButton1>
                 </ContainerBottom>
               </Card>
             </CardBG>
   
             {/* Lucky Spin Card */}
             <CardBG>
               <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(206, 124, 0, .6) 0%, rgba(206, 124, 0, 0) 60%)">
                 <ImagePlaceholder src='https://bc.game/modules/bonus2/assets/bonus-lucky-spin-dad264d8.png' />
                 <CardTitle>Lucky Spin</CardTitle>
                <ContainerBottom>
                <StatsContainer>
                   <StatRow>
                     <span>VIP Spin:</span>
                     <span>Reach VIP 1</span>
                   </StatRow>
                   <StatRow>
                     <span>Daily Spin:</span>
                     <span>$0.00/$200.00</span>
                   </StatRow>
                 </StatsContainer>
                 <ClaimButton>Claim</ClaimButton>
                </ContainerBottom>
               </Card>
   
             </CardBG>
             {/* Roll Competition Card */}
             <CardBG>
               <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(165, 207, 0, .6) 0%, rgba(165, 207, 0, 0) 60%)">
                 <ImagePlaceholder src='https://bc.game/modules/bonus2/assets/bonus-roll-0e5fcc4f.png' />
                 <CardTitle>Roll Competition</CardTitle>
                 <ContainerBottom>
                 <StatsContainer>
                   <StatRow>
                     <span>Total claimed:</span>
                     <span>$0.00</span>
                   </StatRow>
                   <Timer>Ready in 15h 21m 28s</Timer>
                 </StatsContainer>
                 <ClaimButton>Claim</ClaimButton>
                 </ContainerBottom>
               </Card>
             </CardBG>
   
             {/* Rakeback Card */}
             <CardBG>
               <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(214, 129, 0, .6) 0%, rgba(214, 129, 0, 0) 60%)">
                 <ImagePlaceholder src="https://bc.game/modules/bonus2/assets/bonus-rakeback-67a8b0b9.png" />
   
                 <CardTitle>Rakeback</CardTitle>
   
                 <ContainerBottom>
                   <StatsContainer>
                     <StatRow>
                       <span>Ready to claim:</span>
                       <span>0 SBC</span>
                     </StatRow>
                   </StatsContainer>
                   <ClaimButton style={{marginTop:"25px"}}>Claim</ClaimButton>
                 </ContainerBottom>
   
   
               </Card>
             </CardBG>
           </GridContainer>
         </div>
         <div style={{ padding: "10px" }}>
           <Title1>VIP Bonus</Title1>
           <GridContainer>
             {/* Quests Card */}
             <CardBG>
               <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(188, 1, 191, .6) 0%, rgba(188, 1, 191, 0) 60%)">
                 <ImagePlaceholder src='https://bc.game/modules/bonus2/assets/bonus-levelup-f821389b.png' />
                 <CardTitle>Welcome Bonus</CardTitle>
                 <ContainerBottom>
                 <StatsContainer>
                   <StatRow>
                     <span>XP to VIP:</span>
                     <span>0/1</span>
                   </StatRow>
                   {/* <StatRow>
                     <span>Weekly Quests:</span>
                     <span>0/1</span>
                   </StatRow> */}
                 </StatsContainer>
                 <ClaimButton style={{marginTop:"25px"}}>Claim</ClaimButton>
                 </ContainerBottom>
               </Card>
             </CardBG>
   
             {/* Lucky Spin Card */}
             <CardBG>
               <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(13, 148, 111, .6) 0%, rgba(13, 148, 111, 0) 60%)">
                 <ImagePlaceholder src='https://bc.game/modules/bonus2/assets/bonus-recharge-9dc7ac82.png' />
                 <CardTitle>Special Bonus</CardTitle>
                <ContainerBottom>
                <StatsContainer>
                   <StatRow>
                     <span>VIP Spin:</span>
                     <span>Reach VIP 1</span>
                   </StatRow>
                   <StatRow>
                     <span>Daily Spin:</span>
                     <span>$0.00/$200.00</span>
                   </StatRow>
                 </StatsContainer>
                 <ClaimButton>Claim</ClaimButton>
                </ContainerBottom>
               </Card>
   
             </CardBG>
             {/* Roll Competition Card */}
             <CardBG>
               <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(6, 115, 217, .6) 0%, rgba(6, 115, 217, 0) 60%)">
                 <ImagePlaceholder src='https://bc.game/modules/bonus2/assets/bonus-weekly-88b3b7eb.png' />
                 <CardTitle>Weekly Cashback</CardTitle>
                 <ContainerBottom>
                 <StatsContainer>
                   <StatRow>
                     <span>Total claimed:</span>
                     <span>$0.00</span>
                   </StatRow>
                   <Timer>Ready in 15h 21m 28s</Timer>
                 </StatsContainer>
                 <ClaimButton>Claim</ClaimButton>
                 </ContainerBottom>
               </Card>
             </CardBG>
   
             {/* Rakeback Card */}
             <CardBG>
               <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(179, 9, 61, .6) 0%, rgba(179, 9, 61, 0) 60%)">
                 <ImagePlaceholder src="https://bc.game/modules/bonus2/assets/bonus-monthly-74b225b7.png" />
   
                 <CardTitle>Monthly Cashback</CardTitle>
   
                 <ContainerBottom>
                   <StatsContainer>
                     <StatRow>
                       <span>Ready to claim:</span>
                       <span>0 SBC</span>
                     </StatRow>
                   </StatsContainer>
                   <ClaimButton style={{marginTop:"25px"}}>Claim</ClaimButton>
                 </ContainerBottom>
   
   
               </Card>
             </CardBG>
             {/* <CardBG>
               <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(65, 84, 254, .6) 0%, rgba(65, 84, 254, 0) 60%)">
                 <ImagePlaceholder src="https://bc.game/modules/bonus2/assets/bonus-sports-baf971bd.png" />
   
                 <CardTitle>Sports Weekly Bonus</CardTitle>
   
                 <ContainerBottom>
                   <StatsContainer>
                     <StatRow>
                       <span>Ready to claim:</span>
                       <span>0 SBC</span>
                     </StatRow>
                   </StatsContainer>
                   <ClaimButton style={{marginTop:"25px"}}>Claim</ClaimButton>
                 </ContainerBottom>
   
   
               </Card>
             </CardBG> */}
   
             <ModalOverlay show={openModal}>
               <ModalContent>
                 <ModalHeader>
                   <ModalTitle>Bonus Details</ModalTitle>
                   <CloseButton onClick={() => setOpneModal(false)}>
                     <X size={20} />
                   </CloseButton>
                 </ModalHeader>
                 <ModalBody>
                   <BonusDetails data={bonus}/>
                 </ModalBody>
               </ModalContent>
             </ModalOverlay>
           </GridContainer>
         </div>
        </>
      )
    }
    </>
  );
};

export default Bonus;
