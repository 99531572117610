import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";
import { Event, __, forceSatoshiFormat, isValidNumber, wait, sendNotfication } from "../../../Helper";


import styled from "styled-components";
import { Info } from "lucide-react";


const Container = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  max-width: 380px;
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const Container2 = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  width: 100%;  /* Full width on mobile */
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  height:59vh;

  /* Desktop size (768px and above) */
  @media (min-width: 768px) {
    max-width: 380px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #2a2a2a;
`;

const Tab = styled.button`
  padding: 8px 16px;
  color: ${props => props.active ? '#34d399' : '#808080'};
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#34d399' : 'transparent'};
  }
`;

const NewTag = styled.span`
  color: #fbbf24;
  font-size: 12px;
  margin-left: 4px;
`;

const AmountSection = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  color: #808080;
  margin-bottom: 4px;
  
  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
`;

const BetDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #292D2E;
  padding: 4px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  position:relative;
`;
const SwitcherContainer = styled.div`
display:flex;
  background-color: #292D2E;
  border-radius: 8px;
  padding: 2px;
  gap: 2px;
  margin-top:-14px;
  
`;


const SwitchButton = styled.div`
  border: none;
  padding: 13px 16px;
  border-radius: 8px;
  width:100px;
  font-size: 12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-warap:no-wrap;
  cursor: pointer;
  transition: all 0.2s ease;
  
  ${props => props.active ? `
    background-color: #3A4142;
    color: white;
  ` : `
    background: none;
    color: #6B7280;
  `}

  &:hover {
    color: ${props => props.active ? 'white' : '#9CA3AF'};
  }
`;
const BetInput = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
const BetInput1 = styled.input`
  border: none;
  outline: none;
  padding: 12px;
  color: white;
  background: none;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const BetInput2 = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  padding: 12px;
  width: 50%;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const CurrencyDot = styled.div`
  width: 20px;
  height: 20px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const PercentageText = styled.span`
  color: #808080;
  font-size: 14px;
`;


const MultiplierGroup = styled.div`
  margin-left: auto;
  display: flex;
  gap: 4px;

`;

const MultiplierButton = styled.button`
  background-color: #3A4142;
  padding: 4px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
`;

const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 24px;
`;

const ValueButton = styled.button`
  background-color: #2a2a2a;
  padding: 4px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  
  &:hover {
    background-color: #404040;
  }
`;

const WinAmountInput = styled.div`
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const RollButton = styled.div`
  width: 100%;
  padding: 12px;
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  color:black !important;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-top:20px;
`;
const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 16px;
    margin-top: 8px;
`;

const DemoText = styled.div`
  color: #808080;
  font-size: 14px;
  text-align: center;
`;



const Container1 = styled.div`
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  max-width: 380px;
`;

const Section = styled.div`
  margin-bottom: 10px;
`;

const Label1 = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #808080;
  margin-bottom: 10px;
  font-size: 14px;
`;

const AmountDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const CurrencyIcon = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
`;

const ActionButton = styled.button`
  background-color: #404040;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #505050;
  }
`;

const ChevronGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #404040;
  border-radius: 6px;
  overflow: hidden;

  button {
    border: none;
    background: none;
    color: white;
    padding: 2px;
    cursor: pointer;
    
    &:hover {
      background-color: #505050;
    }
  }
`;

const ValueGrid1 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 16px;
`;

const ValueButton1 = styled.button`
  background-color: #2a2a2a;
  padding: 8px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const BetControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const ResetButton = styled.button`
  background-color: #2a2a2a;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 6px;
  padding: 6px 12px;
  gap: 8px;
  flex: 1;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  background: none;
  color: white;
  width: 60px;
  padding: 4px;
  font-size: 14px;
`;

class Bet extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            isLogged: storage.getKey("logged") !== null ? true : false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            started: false,
            fields: 'Medium',
            profit: 0,
            cashout: false,
            inputDisabled: false,
            buttonText: "BET",
            btnType: "btn-bet",
            activeTab: 'Manual',
            betAmount: 0,
            multiplier: '1/2',
            amount: 0,
            betsNumber: '∞',
            onWinIncrease: 0,
            onLossIncrease: 0,
            stopOnWin: 0,
            activeButton: 'reset', // or 'increase'
            onWinMode: 'reset', // Add new state for win mode
            onLossMode: 'reset', // Add new state for loss mode
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
    }

    componentDidMount() {
        let { engine } = this.state;
        this._isMounted = true;
        this.props.gameCoin();
        engine.on(C.PLAY_TOWER, () => this.play());
        engine.on(C.CLICKED_TOWER, (data) => this.clicked(data));
        engine.on(C.CASHOUT_TOWER, () => this.end());
        engine.on(C.BUSTED_TOWER, () => this.busted());
        engine.on(C.ERORR_TOWER, (data) => this.error(data));
        engine.on("stop_playing", () => this.stopManual());
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    stopManual() {
        this.setState({ started: false, buttonText: 'BET', inputDisabled: false });
    }

    busted = () => {
        if (this._isMounted) {
            this.setState({
                inputDisabled: true,
                inputDisabled2: true
            });

            return this.end();
        }
    }

    end() {
        if (this._isMounted) {
            wait(2200).then(() => {
                this.setState({
                    profit: 0,
                    cashout: false,
                    buttonText: "BET",
                    btnType: "btn-bet",
                    inputDisabled: false,
                    inputDisabled2: false,
                    started: false
                });
            });
        }
    }

    play() {
        if (this._isMounted) {
            this.setState({
                started: true,
                inputDisabled: true,
                inputDisabled2: true,
                buttonText: "Game is Started!",
            });
        }
    }

    clicked(data) {
        if (this._isMounted) {
            let { profit, coin } = data;
            let calc = __.toNumber(profit) + __.toNumber(this.state.profit);
            let win = forceSatoshiFormat(calc);

            this.setState({
                inputDisabled: true,
                inputDisabled2: true,
                btnType: "btn-bet-success",
                buttonText: win + " " + coin + " CashOut",
                cashout: true,
                profit: win,
            });

            wait(2000).then(() => {
                this.setState({
                    inputDisabled: false,
                    inputDisabled2: false,
                })
            })
        }
    }

    error(data) {
        if (this._isMounted) {
            sendNotfication(data.message, 'info', 'top-center');

            if (data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false });
        }
    }

    handleBet() {
    
        let { isLogged, amount } = this.state;

        // Check User
        if (!isLogged) {
            return Event.emit('showAuthModal', true);
        }

        if (parseFloat(amount) <= 0) {
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        this.setState({ inputDisabled: true });
        this.placeBet();
    }

    placeBet() {
        if (this._isMounted) {
            let { amount, engine, cashout, profit } = this.state;
            engine.init = true;

            if (cashout) {
                engine.profit = profit;
                engine.cashout();
                return;
            }

            wait(100).then(() => {
                engine.amount = amount;
                engine.coin = this.props.coin;
                engine.start();
            });
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;

        if (target.name === "amount") {
            this.setState({ amount: value })
            if (!isValidNumber(value)) return;
        }

        if (target.name === 'amount') {
            storage.setKey('lam', value);
        }

        this.setState({ [target.name]: value });
    }

    handleInputFields = (value) => {
        if (!this.state.started) {
            this.setState({ fields: value });
            Event.emit('tower_risk', value)
        }
    };

    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am);
    }

    render() {
        let { inputDisabled, inputDisabled2, fields } = this.state;
        const { activeTab, betAmount } = this.state;
        const tabs = ['Manual', ''];
        return (
            <>
                {/* <form
                    className="w-100"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if(this.props.coin==='INR'){
                          if(this.state.amount < 20){
                            sendNotfication('Enter valid amount')
                           
                          }else{
                            this.handleBet(e);
                          }
                        }
                        else if (this.props.coin ==='USDT'){
                           if(this.state.amount < 0.5){
                          sendNotfication('Enter valid amount')
        
                        }else{
                          this.handleBet(e);
        
                        }
                      }else{
                        this.handleBet(e);
        
                      }
                      }}
                >
                    <Row>
                        <Col xl={6} md={7} sm={12}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img
                                                src={"/assets/images/" + this.props.coin + ".png"}
                                                className={"mini-coin-7"}
                                                alt=""
                                            />
                                            BET
                                        </span>
                                    </div>
                                    <input
                                        disabled={inputDisabled2}
                                        type="text"
                                        className="form-control text-left"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter Bet Amount"
                                        value={this.state.amount}
                                        autoComplete={"off"}
                                        onKeyUp={this.handleInputChange}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="input-group-append">
                                        <RangeCredit set={this.setAmount} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={5} sm={12}>
                            <div className={"form-group mb-1"}>
                                <div className="input-group fw">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="mdi mdi-bomb font-25 text-danger mr-1" />
                                            RISK
                                        </span>
                                    </div>
                                    <Dropdown drop={''} className={"drop-select btn-block"}>
                                        <Dropdown.Toggle split variant="" className={"py-0 mt-0"}>
                                            <span className="text-muted text-center">
                                                <i className={"mdi mdi-chevron-down mr-1"} /> {fields}
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            disabled={inputDisabled2}
                                            className={"dopdown-menu-bottom " + inputDisabled2}
                                        >
                                            <Dropdown.Item
                                                onClick={(e) => this.handleInputFields('Medium')}
                                                className={"animated fadeIn"}
                                            >
                                                Medium
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={(e) => this.handleInputFields('High')}
                                                className={"animated fadeIn"}
                                            >
                                                High
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div>
              <span style={{fontSize:11,marginLeft:5}}>
                {this.props.coin ==='INR' ?
              "(Minimum Bet - 20 INR)"
              :
              "(Minimum Bet - 0.0001  USDT)"
              }
              </span>
              </div>
                    <Row>
                        <Col md={5} sm={12} className=" m-auto">
                            <div className={"form-group rev mt-2 mb-0"}>
                                <Button
                                    variant={"btn btn-md btn-block mt-2 " + this.state.btnType}
                                    disabled={inputDisabled}
                                    type="submit"
                                >
                                    {this.state.buttonText}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    
                </form> */}

                <Container2>


                    <div className="w-100 mt-1"
                    >
                        <>
                            <TabContainer>
                                {tabs.map(tab => (
                                    <Tab
                                        key={tab}
                                        active={activeTab === tab}
                                        onClick={() => this.handleTabChange(tab)}
                                    >
                                        {tab}
                                        {tab === 'Advanced' && <NewTag>New!</NewTag>}
                                    </Tab>
                                ))}
                            </TabContainer>
                            {
                                activeTab == "Manual" &&
                                <>

                                    <AmountSection>
                                        <Label>
                                            Amount
                                            <Info />
                                        </Label>

                                        <BetDisplay>
                                            <img
                                                src={"/assets/images/" + this.props.coin + ".png"}
                                                className={"mini-coin-7"}
                                                alt=""
                                            />



                                            <BetInput type="text"
                                                 disabled={inputDisabled2}
                                               
                                               
                                                 id="amount"
                                                 name="amount"
                                                 placeholder="Enter Bet Amount"
                                                 value={this.state.amount}
                                                 autoComplete={"off"}
                                                 onKeyUp={this.handleInputChange}
                                                 onChange={this.handleInputChange}/>
                                            <MultiplierGroup>
                                                <MultiplierButton>1/2</MultiplierButton>
                                                <MultiplierButton>2x</MultiplierButton>
                                                <div className="input-group-append" >
                                                    <RangeCredit set={this.setAmount} />
                                                </div>
                                            </MultiplierGroup>
                                        </BetDisplay>
                                    </AmountSection>

                                    {/* <Label>Payout</Label> */}
                                    {/* <WinAmountInput>
    <img
      src={"/assets/images/" + this.props.coin + ".png"}
      className={"mini-coin-7"}
      alt=""
    />
    <span style={{ marginLeft: "15px" }}>{forceSatoshiFormat(
      this.props.classic_dice_payout
        ? this.state.amount * this.props.classic_dice_payout
        : 0.00000004
    )}</span>
  </WinAmountInput> */}
                                    {/* 
                <BetDisplay>
                    <BetInput1
                        ref={this.payoutRef}
                        disabled={inputDisabled}
                        type="text"

                        id="payout"
                        name="payout"
                        placeholder=".."
                        value={this.state.payout}
                        autoComplete={"off"}
                        onChange={this.handleInputChange} />
                </BetDisplay> */}
                                    <div className={"form-group mb-1"}>
                                        <div className="input-group fw">
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="mdi mdi-bomb font-25 text-danger mr-1" />
                                                    RISK
                                                </span>
                                            </div>
                                            <Dropdown drop={''} className={"drop-select btn-block"}>
                                                <Dropdown.Toggle split variant="" className={"py-0 mt-0"}>
                                                    <span className="text-muted text-center">
                                                        <i className={"mdi mdi-chevron-down mr-1"} /> {fields}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    disabled={inputDisabled2}
                                                    className={"dopdown-menu-bottom " + inputDisabled2}
                                                >
                                                    <Dropdown.Item
                                                        onClick={(e) => this.handleInputFields('Medium')}
                                                        className={"animated fadeIn"}
                                                    >
                                                        Medium
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={(e) => this.handleInputFields('High')}
                                                        className={"animated fadeIn"}
                                                    >
                                                        High
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <RollButton onClick={() => {

                                        if (this.props.coin === 'INR') {
                                            if (this.state.amount < 50) {
                                                sendNotfication('Enter valid amount')

                                            } else {
                                                this.handleBet();
                                            }
                                        }
                                        else if (this.props.coin === 'USDT') {
                                            if (this.state.amount < 1) {
                                                sendNotfication('Enter valid amount')

                                            } else {
                                                this.handleBet();

                                            }
                                        } else {
                                            this.handleBet();

                                        }
                                    }}
                                    >Bet</RollButton>
                                    <WarningContainer>
                                        <DemoText>Betting with $0 will enter demo mode.</DemoText>
                                    </WarningContainer>
                                </>
                            }



                        </>

                        {/* <>
<div

>
<Row>
  <Col sm={12} className={"d-md-none d-sm-block"}>
    {bet === "manual" && (
      <Button
        variant={
          "btn btn-xs btn-block btn-bet py-2 mb-2 " +
          this.state.buttonType
        }
        disabled={inputDisabled}
        type="submit"
      >
        {this.state.buttonText}
      </Button>
    )}
  </Col>
  <Col xl={6} md={7}>
    <div className={"form-group mb-1 bet-input"}>
      <div className="input-group">
        <div className="input-group-append">
          <span className="input-group-text">
            <img
              src={"/assets/images/" + this.props.coin + ".png"}
              className={"mini-coin-7"}
              alt=""
            />
            BET
          </span>
        </div>
        <input
          type="text"
          disabled={inputDisabled}
          className="form-control"
          id="amount"
          name="amount"
          value={this.state.amount}
          autoComplete={"off"}
          onKeyUp={this.handleInputChange}
          onChange={this.handleInputChange}
        />
        <div className="input-group-append">
          <RangeCredit set={this.setAmount} />
        </div>
      </div>
    </div>
  </Col>
  <Col xl={6} md={5}>
    <div className={"form-group mb-1 bet-input"}>
      <div className="input-group">
        <div className="input-group-append">
          <span className="input-group-text">
            <img
              src={"/assets/images/" + this.props.coin + ".png"}
              className={"mini-coin-7"}
              alt=""
            />
            WIN AMOUNT
          </span>
        </div>
        <input
          type="text"
          disabled={true}
          className="form-control"
          value={forceSatoshiFormat(
            this.props.classic_dice_payout
              ? this.state.amount * this.props.classic_dice_payout
              : 0.00000004
          )}
        />
      </div>
    </div>
  </Col>
</Row>
<div>
  <span style={{ fontSize: 11, marginLeft: 5 }}>
    {this.props.coin === 'INR' ?
      "(Minimum Bet - 50 INR)"
      :
      "(Minimum Bet - 0.0001  USDT)"
    }
  </span>
</div>

{bet === "manual" && (
  <Row>
    <Col md={5} sm={12} className="m-auto hidden-sm">
      <div className={"form-group rev mb-1"}>
        <Button
          variant={
            "btn btn-md btn-block mt-2 btn-bet " +
            this.state.buttonType
          }
          disabled={inputDisabled}
          type="submit"
        >
          {this.state.buttonText}

        </Button>
      </div>
    </Col>
  </Row>
)}

{bet === "auto" && (
  <>
    <Row>
      <Col xl={6} md={6} sm={6}>
        <div className={"form-group mb-1 bet-input"}>
          <div className="input-group">
            <div className="input-group-append">
              <span className="input-group-text">Bets Num</span>
            </div>
            <input
              type="number"
              disabled={inputDisabled}
              className="form-control"
              required={true}
              value={this.state.betNumber}
              onChange={(e) => this.setNumber(e, e.target.value)}
            />
            <div className="input-group-append">
              <button
                onClick={(e) => this.setNumber(e, 10)}
                className={"btn btn-s-4 btn-xs"}
              >
                10
              </button>
              <button
                onClick={(e) => this.setNumber(e, 50)}
                className={"btn btn-s-4 btn-xs"}
              >
                50
              </button>
              <button
                onClick={(e) => this.setNumber(e, 100)}
                className={"btn btn-s-4 btn-xs"}
              >
                100
              </button>
            </div>
          </div>
        </div>
      </Col>
      <Col xl={6} md={6} sm={12}>
        <div className={"form-group mb-1 bet-input"}>
          <div className="input-group">
            <div className="input-group-append">
              <span className="input-group-text">
                Increase Amount On Win %
              </span>
            </div>
            <input
              disabled={inputDisabled}
              type="number"
              className="form-control"
              name="onWin"
              min="0"
              autoComplete={"off"}
              value={this.state.onWin}
              onChange={(e) =>
                this.setState({ onWin: e.target.value })
              }
            />
            <div className="input-group-append">
              <button
                className="btn bg-cs2 after-text no-hover npt nh"
                type="button"
              >
                X
              </button>
            </div>
          </div>
        </div>
      </Col>
      <Col xl={6} md={6} sm={12}>
        <div className={"form-group mb-1 bet-input"}>
          <div className="input-group">
            <div className="input-group-append">
              <span className="input-group-text">
                Increase Amount On Lost %
              </span>
            </div>
            <input
              disabled={inputDisabled}
              type="number"
              className="form-control"
              name="onLost"
              min="0"
              autoComplete={"off"}
              value={this.state.onLost}
              onChange={(e) =>
                this.setState({ onLost: e.target.value })
              }
            />
            <div className="input-group-append">
              <button
                className="btn bg-cs2 after-text no-hover npt nh"
                type="button"
              >
                X
              </button>
            </div>
          </div>
        </div>
      </Col>
      <Col xl={6} md={6} sm={12}>
        <div className={"form-group mb-1 bet-input"}>
          <div className="input-group">
            <div className="input-group-append">
              <span className="input-group-text">
                Stop On Win
              </span>
            </div>
            <input
              disabled={inputDisabled}
              type="text"
              className="form-control"
              name="stopOnWin"
              placeholder=".."
              value={this.state.stopOnWin}
              autoComplete={"off"}
              onChange={this.handleInputChange}
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <img
                  src={
                    "/assets/images/" + this.props.coin + ".png"
                  }
                  className={"mini-coin-2 mr-1"}
                  alt=""
                />
                {this.props.coin}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col md={5} className={"m-auto"}>
        <Button
          variant={"btn btn-md btn-block mt-2 btn-bet"}
          type="submit"
        >
          {this.state.buttonText}
          {started && (
            <>
              <div
                className={
                  "ml-2 spinner-border spinner-border-sm"
                }
              />
            </>
          )}
        </Button>
      </Col>
    </Row>
  </>
)}
</div>
<div
className="btn-group btn-group-toggle my-2 ml-1"
data-toggle="buttons"
>
<label
  className={
    "" + bet === "manual"
      ? "btn bg-cs2 btn-xs active"
      : "btn bg-cs2 btn-xs "
  }
>
  <input
    disabled={started}
    onClick={(e) => this.betMode("manual")}
    type="radio"
  />{" "}
  Manual
</label>
<label
  className={
    "" + bet === "auto"
      ? "btn bg-cs2 btn-xs active"
      : "btn bg-cs2 btn-xs "
  }
>
  <input
    disabled={started}
    onClick={(e) => this.betMode("auto")}
    type="radio"
  />{" "}
  Auto
</label>
</div>
</> */}
                    </div>




                </Container2>
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = (state) => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, {
    gameCoin,
    setWallet
})(Bet);
