// TransferVault.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import storage from "../../../../Storage";
import C from "../../../../Constant";
import coins from "../../../coins";
import socket from "../../../../Socket";
import { ChevronDown, Search } from 'lucide-react';
import { decode, encode, forceSatoshiFormat,   __, wait,Event, } from '../../../../Helper';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const TabSwitcher = styled.div`
  display: flex;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color:#1E2024;
`;

const Tab = styled.button`
  flex: 1;
  padding: 12px;
  background-color: ${props => props.active ? '#3A4142' : 'transparent'};
  color: ${props => props.active ? '#ffffff' : '#808080'};
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }
`;

const Card = styled.div`
//   background-color: #1a1d1f;
  border-radius: 12px;
//   padding: 24px;
`;

const DropdownContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  flex:1;
  max-width:150px;

`;


const DividerContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0; /* Adjust the margin to your liking */
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  background-color:#394142; /* Background for better visibility */
  padding: 12px; /* Padding for the content inside */
  color: black; /* Text color */
  font-size: 14px; /* Adjust font size */
  border: 1px solid #e4eaf01; /* Optional border */
  border-radius: 8px; /* Optional rounded corners */
  display:flex;
  justify-content:center;
  align-items:center;
`;



const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding:9px 10px;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
   border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  margin-top:-4px;
`;


const Input = styled.input`
  width: 100%;
  background-color: #292D2E;
  border: 1px solid gray;
  color: white;
  font-size: 24px;
  margin-bottom: 20px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  
  /* Hide spin buttons for number input */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* For Firefox */
  -moz-appearance: textfield;
  padding-left:6px;
`;

const TokenSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  color: white;
  cursor: pointer;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  color: #808080;
`;

const Value = styled.span`
  color: ${props => props.green ? '#00ff9d' : 'white'};
`;

const Button = styled.div`
  width: 100%;
  padding: 16px;
 background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  color: black;
  border: none;
  border-radius: 8px;
  margin: 20px 0;
  display:flex;
  align-items:center;
  font-weight:bold !important;
  justify-content:center;
  cursor: pointer;
  font-weight: 500;
  
  &:hover {
    opacity: 0.9;
  }
`;

const SecurityLink = styled.a`
  color: #00ff9d;
  text-decoration: none;
  display: flex;
  justify-content: center;
  gap: 8px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: 100%;
  left: -200px;
  right: 0;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  margin-top: 4px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  padding:10px 12px;
`;

const SearchContainer = styled.div`
  position: relative;
  padding:0px;
    border-radius: 8px;
  border: 1px solid rgb(58 65 66);
  margin-top:12px;
`;
const SearchIcon = styled.div`
  position: absolute;
  left: 14px;
  top: 49%;
  transform: translateY(-50%);
  color: #fff;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 6px 12px 6px 40px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  &::placeholder {
    color: #fff;
    opacity:0.6;
  }
`;

const Input1 = styled.input`
width:100%;
  padding:6px 10px;
  background: transparent;
  margin-bottom:12px;
  color: #ffffff;
  font-size: 20px;
  border:none;
  outline:none;
  &::placeholder {
    color: #fff;
  }
`;

const CoinOption = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  cursor: pointer;
  border-radius:12px;
  &:hover {
    background: #323738;
  }
  ${props => props.isSelected && `
    background: #323738;
  `}
`;

const CoinImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
`;

const CoinName = styled.div`
  font-size: 16px;
  font-weight: bold;
 
`;

const CoinBalance = styled.span`
  margin-left: auto;
  color: #8E9293;
   display:none;
`;



// const TransferIn = () => (
//   <Card>
//     <Input type="number" placeholder="0" />

//     <DropdownContainer className="dropdown-container">
//                         <DropdownButton onClick={(e) => {
//                             e.stopPropagation();
//                             setIsFromDropdownOpen(!isFromDropdownOpen);
//                             setIsToDropdownOpen(false);
//                         }}>
//                             <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
//                                 <CoinImage
//                                     src={`/assets/images/${fromCoinData?.image}`}
//                                     alt={fromSelectedCoin}
//                                 />
//                                 <CoinName>{fromSelectedCoin}</CoinName>
//                             </div>
//                             <ChevronDown size={20} />
//                         </DropdownButton>

//                         {isFromDropdownOpen && renderDropdown(isFromDropdownOpen, fromCoinData, handleFromCoinSelect, 'from')}
//                     </DropdownContainer>
//     {/* <TokenSelector>
//       <img src="/eth-icon.png" alt="ETH" width="20" />
//       ETH
//     </TokenSelector> */}
//     <InfoRow>
//       <span>Available:</span>
//       <Value>0</Value>
//     </InfoRow>
//     <InfoRow>
//       <span>Locked funds:</span>
//       <Value>0</Value>
//     </InfoRow>
//     <InfoRow>
//       <span>Annual Rate:</span>
//       <Value green>5%</Value>
//     </InfoRow>
//     <Button>Transfer to Vault Pro</Button>
//     <SecurityLink href="#">
//       Security Rules
//       <span>›</span>
//     </SecurityLink>
//   </Card>
// );

// const TransferOut = () => (
//   <Card>
//     <Input type="number" placeholder="0" />
//     <DropdownContainer className="dropdown-container">
//                         <DropdownButton onClick={(e) => {
//                             e.stopPropagation();
//                             setIsFromDropdownOpen(!isFromDropdownOpen);
//                             setIsToDropdownOpen(false);
//                         }}>
//                             <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
//                                 <CoinImage
//                                     src={`/assets/images/${fromCoinData?.image}`}
//                                     alt={fromSelectedCoin}
//                                 />
//                                 <CoinName>{fromSelectedCoin}</CoinName>
//                             </div>
//                             <ChevronDown size={20} />
//                         </DropdownButton>

//                         {isFromDropdownOpen && renderDropdown(isFromDropdownOpen, fromCoinData, handleFromCoinSelect, 'from')}
//                     </DropdownContainer>
//     <InfoRow>
//       <span>Vault Balance:</span>
//       <Value>0ETH</Value>
//     </InfoRow>
//     <InfoRow>
//       <span>Annual Rate:</span>
//       <Value green>5%</Value>
//     </InfoRow>
//     <Button>Withdraw to Wallet</Button>
//     <SecurityLink href="#">
//       Security Rules
//       <span>›</span>
//     </SecurityLink>
//   </Card>
// );

const VaultPro = () => {
    const [isFromDropdownOpen, setIsFromDropdownOpen] = useState(false);
    const [isToDropdownOpen, setIsToDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeTab, setActiveTab] = useState('in');

    // Separate states for from and to coins
    const [fromSelectedCoin, setFromSelectedCoin] = useState(storage.getKey('fromCoin') || "BTC");
    const [toSelectedCoin, setToSelectedCoin] = useState(storage.getKey('toCoin') || "ETH");

    const [credits, setCredits] = useState({});
    const [activeCoins, setActiveCoins] = useState(
        storage.getKey('active_coins')?.split(',').map(String) || ['1']
    );

    const [state, setState] = useState({
        amount: "0",
        toAmount: "0",
        networks: [],
        isLoading: false,
    });


    // Handle swap functionality
    const handleSwap = () => {
        // Swap the selected coins
        const tempFromCoin = fromSelectedCoin;
        const tempToCoin = toSelectedCoin;

        setFromSelectedCoin(tempToCoin);
        setToSelectedCoin(tempFromCoin);

        // Update storage
        storage.setKey('fromCoin', tempToCoin);
        storage.setKey('toCoin', tempFromCoin);

        // Swap the amounts
        setState(prev => ({
            ...prev,
            amount: prev.toAmount,
            toAmount: prev.amount
        }));

        // Emit events for both coin changes
        Event.emit('from_coin_changed', tempToCoin);
        Event.emit('to_coin_changed', tempFromCoin);
    };


    // Socket Connection Effect
    useEffect(() => {
        const token = storage.getKey('token');

        if (token) {
            socket.emit(C.CREDIT, encode({ token, coin: fromSelectedCoin }));
        }

        const handleCreditData = (data) => {
            const decodedData = decode(data);
            const { credit } = decodedData;
            setCredits(credit);
        };

        const handleCreditUpdate = (data) => {
            const decodedData = decode(data);
            const { value, coin: updatedCoin } = decodedData;

            setCredits(prev => ({
                ...prev,
                [__.lowerCase(updatedCoin)]: value
            }));
        };

        socket.on(C.CREDIT, handleCreditData);
        socket.on(C.UPDATE_CREDIT, handleCreditUpdate);

        return () => {
            socket.off(C.CREDIT, handleCreditData);
            socket.off(C.UPDATE_CREDIT, handleCreditUpdate);
        };
    }, [fromSelectedCoin]);

    // Click Outside Handler Effect
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.dropdown-container')) {
                setIsFromDropdownOpen(false);
                setIsToDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Coin Selection Handlers
    const handleFromCoinSelect = async (coinName) => {
        if (coinName === toSelectedCoin) {
            // Optionally swap the coins
            setToSelectedCoin(fromSelectedCoin);
        }

        storage.setKey('fromCoin', coinName);
        setFromSelectedCoin(coinName);
        setIsFromDropdownOpen(false);

        try {
            await wait(200);
            Event.emit('from_coin_changed', coinName);
        } catch (error) {
            console.error('Error during from coin selection:', error);
        }
    };

    const handleToCoinSelect = async (coinName) => {
        if (coinName === fromSelectedCoin) {
            // Optionally swap the coins
            setFromSelectedCoin(toSelectedCoin);
        }

        storage.setKey('toCoin', coinName);
        setToSelectedCoin(coinName);
        setIsToDropdownOpen(false);

        try {
            await wait(200);
            Event.emit('to_coin_changed', coinName);
        } catch (error) {
            console.error('Error during to coin selection:', error);
        }
    };

    // Filtered Coins Logic
    const filteredCoins = coins
        .filter(coin => {
            if (!activeCoins.includes(coin.id.toString())) return false;
            return coin.preffix.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .sort((a, b) => {
            if (a.preffix === 'BTC') return -1;
            if (b.preffix === 'BTC') return 1;
            return 0;
        });

    const fromCoinData = coins.find(coin => coin.preffix === fromSelectedCoin);
    const toCoinData = coins.find(coin => coin.preffix === toSelectedCoin);

    const renderDropdown = (isOpen, coinData, onSelect, type) => (
        <DropdownPanel onClick={e => e.stopPropagation()}>
            <SearchContainer>
                <SearchIcon>
                    <Search size={20} />
                </SearchIcon>
                <SearchInput
                    placeholder="Search coins..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoFocus
                />
            </SearchContainer>

            {filteredCoins.map((coin) => (
                <CoinOption
                    key={coin.id}
                    onClick={() => onSelect(coin.preffix)}
                    isSelected={coinData.preffix === coin.preffix}
                >
                    <CoinImage src={`/assets/images/${coin.image}`} alt={coin.preffix} />
                    <CoinName>{coin.preffix}</CoinName>
                    <CoinBalance>
                        {forceSatoshiFormat(credits[__.lowerCase(coin.preffix)] || 0)}
                    </CoinBalance>
                </CoinOption>
            ))}
        </DropdownPanel>
    );


    return (
        <Container>
            <TabSwitcher>
                <Tab
                    active={activeTab === 'in'}
                    onClick={() => setActiveTab('in')}
                >
                    Transfer In
                </Tab>
                <Tab
                    active={activeTab === 'out'}
                    onClick={() => setActiveTab('out')}
                >
                    Transfer Out
                </Tab>
            </TabSwitcher>
            {activeTab === 'in' ? <Card>

                <div style={{display:"flex", alignItems:'center'}} >

                <Input style={{flex:'1'}} type="number" placeholder="0" />

                <DropdownContainer className="dropdown-container">
                    <DropdownButton onClick={(e) => {
                        e.stopPropagation();
                        setIsFromDropdownOpen(!isFromDropdownOpen);
                        setIsToDropdownOpen(false);
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <CoinImage
                                src={`/assets/images/${fromCoinData?.image}`}
                                alt={fromSelectedCoin}
                            />
                            <CoinName>{fromSelectedCoin}</CoinName>
                        </div>
                        <ChevronDown size={20} />
                    </DropdownButton>

                    {isFromDropdownOpen && renderDropdown(isFromDropdownOpen, fromCoinData, handleFromCoinSelect, 'from')}
                </DropdownContainer>
                </div>
                {/* <TokenSelector>
      <img src="/eth-icon.png" alt="ETH" width="20" />
      ETH
    </TokenSelector> */}
                <InfoRow>
                    <span>Available:</span>
                    <Value>0</Value>
                </InfoRow>
                <InfoRow>
                    <span>Locked funds:</span>
                    <Value>0</Value>
                </InfoRow>
                <InfoRow>
                    <span>Annual Rate:</span>
                    <Value green>5%</Value>
                </InfoRow>
                <Button>Transfer to Vault Pro</Button>
                <SecurityLink href="#">
                    Security Rules
                    <span>›</span>
                </SecurityLink>
            </Card> : <Card>
                <div style={{display:"flex", alignItems:'center'}}>
                <Input style={{flex:'1'}} type="number" placeholder="0" />
                <DropdownContainer className="dropdown-container">
                    <DropdownButton onClick={(e) => {
                        e.stopPropagation();
                        setIsFromDropdownOpen(!isFromDropdownOpen);
                        setIsToDropdownOpen(false);
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <CoinImage
                                src={`/assets/images/${fromCoinData?.image}`}
                                alt={fromSelectedCoin}
                            />
                            <CoinName>{fromSelectedCoin}</CoinName>
                        </div>
                        <ChevronDown size={20} />
                    </DropdownButton>

                    {isFromDropdownOpen && renderDropdown(isFromDropdownOpen, fromCoinData, handleFromCoinSelect, 'from')}
                </DropdownContainer>
                </div>
                <InfoRow>
                    <span>Vault Balance:</span>

                    <div style={{display:'flex', alignItems:"center"}}>
                    <Value>0</Value>
                    <Value>{fromSelectedCoin}</Value>
                  
                    </div>
                

                </InfoRow>
                <InfoRow>
                    <span>Annual Rate:</span>
                    <Value green>5%</Value>
                </InfoRow>
                <Button>Withdraw to Wallet</Button>
                <SecurityLink href="#">
                    Security Rules
                    <span>›</span>
                </SecurityLink>
            </Card>}
        </Container>
    );
};

export default VaultPro;