import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ChevronRight, ChevronDown, Search, Info, FileQuestion } from "lucide-react";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import coins from "../../../coins";
import socket from "../../../../Socket";
import { Col, Dropdown } from "react-bootstrap";
import {
    __, wait, decode, encode, forceSatoshiFormat, Event,
    isMobile,
    sendNotfication,
    getUID,
} from '../../../../Helper';
import axios from 'axios';


const WithdrawContainer = styled.div`
  margin: 0 auto;
  padding: 24px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Row1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

const Label1 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  ${props => props.isGreen && `
    color: #20EE89;
    font-size: 16px;
    font-weight: 500;
  `}
  
  ${props => props.isGray && `
    color: #9ba1a6;
    font-size: 14px;
  `}
`;

const Amount = styled.div`
${props => props.isGreen && `
    color: #20EE89;

   
  `}
  font-size: ${props => props.large ? '16px' : '14px'};
  font-weight: ${props => props.bold ? '600' : '400'};
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  &:hover {
    opacity: 1;
  }
`;



const Container = styled.div`
//   padding: 8px;
  color: #ffffff;
`;
const DropdownContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  flex:1;
  max-width:150px;
`;


const DividerContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0; /* Adjust the margin to your liking */
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  background-color:#394142; /* Background for better visibility */
  padding: 12px; /* Padding for the content inside */
  color: black; /* Text color */
  font-size: 14px; /* Adjust font size */
  border: 1px solid #e4eaf01; /* Optional border */
  border-radius: 8px; /* Optional rounded corners */
  display:flex;
  justify-content:center;
  align-items:center;
`;



const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding:6px 10px;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: 100%;
  left: -200px;
  right: 0;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  margin-top: 4px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  padding:10px 12px;
`;

const SearchContainer = styled.div`
  position: relative;
  padding:0px;
    border-radius: 8px;
  border: 1px solid rgb(58 65 66);
  margin-top:12px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 6px 12px 6px 40px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  &::placeholder {
    color: #fff;
    opacity:0.6;
  }
`;

const Input = styled.input`
width:100%;
  padding:6px 10px;
  background: transparent;
  margin-bottom:12px;
  color: #ffffff;
  font-size: 20px;
  border:none;
  outline:none;
  &::placeholder {
    color: #fff;
  }
`;
const DepositButton = styled.div`
  
  margin-top: auto;
flex:1;
padding:7px 9px;
  display:flex;
  justify-content:center;
  align-items:center;
  background: linear-gradient(to right, #9FE871, #24EE89);
  color: black !important;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  
  &:hover {
    opacity: 0.9;
  }
`;
const SearchIcon = styled.div`
  position: absolute;
  left: 14px;
  top: 49%;
  transform: translateY(-50%);
  color: #fff;
`;

const CoinOption = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  cursor: pointer;
  border-radius:12px;
  &:hover {
    background: #323738;
  }
  ${props => props.isSelected && `
    background: #323738;
  `}
`;

const CoinImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
`;

const CoinName = styled.div`
  font-size: 16px;
  font-weight: bold;
 
`;

const CoinBalance = styled.span`
  margin-left: auto;
  color: #8E9293;
   display:none;
`;


// const CoinName = styled.span`
//   font-size: 16px;
//   font-weight: 500;
// `;

const TokenListContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

const TokenButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #394142;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  color: #ffffff;
  transition: background 0.2s ease;
  &:hover {
    background: #363A3B;
  }
`;

const TokenIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const TokenName = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const MoreButton = styled(TokenButton)`
  padding: 8px 12px;
`;

const FooterText = styled.div`
  color: #ffffff;
  margin: 18px 0;
`;

const AddLink = styled.span`
  color: #20EE89;
  cursor: pointer;
`;

const QRCodeContainer = styled.div`
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.div`
  color: #9ba1a6;
  font-size: 14px;
`;

const AddressContainer = styled.div`
  background-color: #2c3235;
  padding: 12px 16px;
  border-radius: 6px;
  color: #fff;
  font-family: monospace;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const CopyButton = styled.button`
  background-color: #3c4246;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: background-color 0.2s;
  white-space: nowrap;
  
  &:hover {
    background-color: #4c5256;
  }
`;

const CopyIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: currentColor;
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z' /%3E%3C/svg%3E");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
`;



const CodeContainer = styled.div`
  border-radius: 8px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 12px auto;
`;
const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 16px auto;
  margin-bottom: 16px;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const WarningText = styled.span`
  color: #fff;
  font-size: 13px;
  font-weight: 500;
`;

const BonusContainer = styled.div`
  background-color: rgba(180, 155, 100, 0.2);
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 18px auto;
  margin-bottom: 16px;
`;

const IconWrapper1 = styled.div`
  width: 28px;
  height: 28px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

const BaseText = styled.span`
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
`;

const HighlightedText = styled(BaseText)`
  color: #FFA500;
`;


const Container2 = styled.div`
  background-color: #4A5354;
  padding: 12px 20px;
  border-radius: 8px;
  display: flex;
  margin-top:24px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  backdrop-filter: blur(5px);
`;

const MoneyItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Label2 = styled.span`
  color: #B0B0B0;
  font-size: 16px;
  font-weight: 500;
`;

const Value = styled.span`
  color: white;
  font-size: 16px;
  font-weight: 600;
`;

const cryptoOptions = [
    { id: 'dot', name: 'DOT', iconClass: 'dot' },
    { id: 'link', name: 'LINK', iconClass: 'link' },
    { id: 'dai', name: 'DAI', iconClass: 'dai' },
    { id: 'usdc', name: 'USDC', iconClass: 'usdc' },
    { id: 'xmr', name: 'XMR', iconClass: 'xmr' },
];

const tokens = [
    { id: 'eth', name: 'ETH', icon: '/assets/images/ETH.png' },
    { id: 'btc', name: 'BTC', icon: '/assets/images/BTC.png' },
    { id: 'usdt', name: 'USDT', icon: '/assets/images/USDT.png' },
    { id: 'usdc', name: 'USDC', icon: '/assets/images/USDC.png' },
    { id: 'doge', name: 'DOGE', icon: '/assets/images/DOGE.png' },
];


const SkylaSwap = () => {
    const [isFromDropdownOpen, setIsFromDropdownOpen] = useState(false);
    const [isToDropdownOpen, setIsToDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    
    // Separate states for from and to coins
    const [fromSelectedCoin, setFromSelectedCoin] = useState(storage.getKey('fromCoin') || "BTC");
    const [toSelectedCoin, setToSelectedCoin] = useState(storage.getKey('toCoin') || "ETH");
    
    const [credits, setCredits] = useState({});
    const [activeCoins, setActiveCoins] = useState(
        storage.getKey('active_coins')?.split(',').map(String) || ['1']
    );

    const [state, setState] = useState({
        amount: "0",
        toAmount: "0",
        networks: [],
        isLoading: false,
    });


     // Handle swap functionality
     const handleSwap = () => {
        // Swap the selected coins
        const tempFromCoin = fromSelectedCoin;
        const tempToCoin = toSelectedCoin;
        
        setFromSelectedCoin(tempToCoin);
        setToSelectedCoin(tempFromCoin);
        
        // Update storage
        storage.setKey('fromCoin', tempToCoin);
        storage.setKey('toCoin', tempFromCoin);
        
        // Swap the amounts
        setState(prev => ({
            ...prev,
            amount: prev.toAmount,
            toAmount: prev.amount
        }));

        // Emit events for both coin changes
        Event.emit('from_coin_changed', tempToCoin);
        Event.emit('to_coin_changed', tempFromCoin);
    };


    // Socket Connection Effect
    useEffect(() => {
        const token = storage.getKey('token');

        if (token) {
            socket.emit(C.CREDIT, encode({ token, coin: fromSelectedCoin }));
        }

        const handleCreditData = (data) => {
            const decodedData = decode(data);
            const { credit } = decodedData;
            setCredits(credit);
        };

        const handleCreditUpdate = (data) => {
            const decodedData = decode(data);
            const { value, coin: updatedCoin } = decodedData;

            setCredits(prev => ({
                ...prev,
                [__.lowerCase(updatedCoin)]: value
            }));
        };

        socket.on(C.CREDIT, handleCreditData);
        socket.on(C.UPDATE_CREDIT, handleCreditUpdate);

        return () => {
            socket.off(C.CREDIT, handleCreditData);
            socket.off(C.UPDATE_CREDIT, handleCreditUpdate);
        };
    }, [fromSelectedCoin]);

    // Click Outside Handler Effect
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.dropdown-container')) {
                setIsFromDropdownOpen(false);
                setIsToDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Coin Selection Handlers
    const handleFromCoinSelect = async (coinName) => {
        if (coinName === toSelectedCoin) {
            // Optionally swap the coins
            setToSelectedCoin(fromSelectedCoin);
        }
        
        storage.setKey('fromCoin', coinName);
        setFromSelectedCoin(coinName);
        setIsFromDropdownOpen(false);

        try {
            await wait(200);
            Event.emit('from_coin_changed', coinName);
        } catch (error) {
            console.error('Error during from coin selection:', error);
        }
    };

    const handleToCoinSelect = async (coinName) => {
        if (coinName === fromSelectedCoin) {
            // Optionally swap the coins
            setFromSelectedCoin(toSelectedCoin);
        }
        
        storage.setKey('toCoin', coinName);
        setToSelectedCoin(coinName);
        setIsToDropdownOpen(false);

        try {
            await wait(200);
            Event.emit('to_coin_changed', coinName);
        } catch (error) {
            console.error('Error during to coin selection:', error);
        }
    };

    // Filtered Coins Logic
    const filteredCoins = coins
        .filter(coin => {
            if (!activeCoins.includes(coin.id.toString())) return false;
            return coin.preffix.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .sort((a, b) => {
            if (a.preffix === 'BTC') return -1;
            if (b.preffix === 'BTC') return 1;
            return 0;
        });

    const fromCoinData = coins.find(coin => coin.preffix === fromSelectedCoin);
    const toCoinData = coins.find(coin => coin.preffix === toSelectedCoin);

    const renderDropdown = (isOpen, coinData, onSelect, type) => (
        <DropdownPanel onClick={e => e.stopPropagation()}>
            <SearchContainer>
                <SearchIcon>
                    <Search size={20} />
                </SearchIcon>
                <SearchInput
                    placeholder="Search coins..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoFocus
                />
            </SearchContainer>

            {filteredCoins.map((coin) => (
                <CoinOption
                    key={coin.id}
                    onClick={() => onSelect(coin.preffix)}
                    isSelected={coinData.preffix === coin.preffix}
                >
                    <CoinImage src={`/assets/images/${coin.image}`} alt={coin.preffix} />
                    <CoinName>{coin.preffix}</CoinName>
                    <CoinBalance>
                        {forceSatoshiFormat(credits[__.lowerCase(coin.preffix)] || 0)}
                    </CoinBalance>
                </CoinOption>
            ))}
        </DropdownPanel>
    );

    return (
        <Container>
            <div style={{ color: "#B3BEC1", margin: "4px 0" }}>
                From
            </div>
            <div style={{ display: "flex", gap: "5px", width: "100%", alignItems: "center" }}>
                <div style={{ width: "100%" }}>
                    <Input
                        type='text'
                        value={state.amount}
                        onChange={(e) => setState((prev) => ({ ...prev, amount: e.target.value }))}
                    />
                </div>

                <div style={{ flex: '1' }}>
                    <DropdownContainer className="dropdown-container">
                        <DropdownButton onClick={(e) => {
                            e.stopPropagation();
                            setIsFromDropdownOpen(!isFromDropdownOpen);
                            setIsToDropdownOpen(false);
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <CoinImage
                                    src={`/assets/images/${fromCoinData?.image}`}
                                    alt={fromSelectedCoin}
                                />
                                <CoinName>{fromSelectedCoin}</CoinName>
                            </div>
                            <ChevronDown size={20} />
                        </DropdownButton>

                        {isFromDropdownOpen && renderDropdown(isFromDropdownOpen, fromCoinData, handleFromCoinSelect, 'from')}
                    </DropdownContainer>
                </div>
            </div>

            <DividerContainer>
                <Divider />
                <Overlay onClick={handleSwap} style={{ cursor: 'pointer' }}>
                    <img 
                        style={{height:"20px", width:"20px", objectFit:"contain"}} 
                        src="/assets/images/nanoicons/nanoswap.png" 
                        alt="Swap" 
                    />
                </Overlay>
            </DividerContainer>

            <div style={{ color: "#B3BEC1", margin: "4px 0" }}>
                To
            </div>
            <div style={{ display: "flex", gap: "5px", width: "100%", alignItems: "center" }}>
                <div style={{ width: "100%" }}>
                    <Input
                        type='text'
                        value={state.toAmount}
                        onChange={(e) => setState((prev) => ({ ...prev, toAmount: e.target.value }))}
                    />
                </div>

                <div style={{ flex: '1' }}>
                    <DropdownContainer className="dropdown-container">
                        <DropdownButton onClick={(e) => {
                            e.stopPropagation();
                            setIsToDropdownOpen(!isToDropdownOpen);
                            setIsFromDropdownOpen(false);
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <CoinImage
                                    src={`/assets/images/${toCoinData?.image}`}
                                    alt={toSelectedCoin}
                                />
                                <CoinName>{toSelectedCoin}</CoinName>
                            </div>
                            <ChevronDown size={20} />
                        </DropdownButton>

                        {isToDropdownOpen && renderDropdown(isToDropdownOpen, toCoinData, handleToCoinSelect, 'to')}
                    </DropdownContainer>
                </div>
            </div>

            <Container2>
      <MoneyItem>
        <Label1>Real Money:</Label1>
        <Value>{1.000}</Value>
      </MoneyItem>
      <MoneyItem>
        <Label>Bonus Money:</Label>
        <Value>{0}</Value>
      </MoneyItem>
    </Container2>

    
    <WithdrawContainer>
                <Row1>
                    <Label1 >Rate</Label1>
                    <Amount large bold>1 {fromSelectedCoin} ≈ 1 {toSelectedCoin}</Amount>
                </Row1>

                <Row1>
                    <Label1 isGray>
                      
                    Estimated Time
                    </Label1>
                    <Amount>30 Seconds</Amount>
                </Row1>

                <Row1>
                    <Label1 isGray>Swap fee</Label1>
                    <Amount>0 USDT</Amount>
                </Row1>
            </WithdrawContainer>
            <DepositButton>Swap Now</DepositButton>
        </Container>
    );
};

export default SkylaSwap;