import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import storage from '../../../Storage';
import socket from '../../../Socket';
import { Event, wait, decode, encode, sendNotfication } from '../../../Helper';
import C from '../../../Constant';
import GoogleBtn from "./Google";
import { CrossIcon } from 'lucide-react';

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #232626;
    color: white;
    border-radius: 12px;
    overflow: hidden;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 999;
    
    @media (min-width: 768px) {
      flex-direction: row;
    }
    
    @media (max-width: 768px) {
      padding: 16px;
      height: 100vh;
      border-radius: 0;
    }
  }
  
  .modal-dialog {
    max-width: 900px;
    margin: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    
    @media (min-width: 768px) {
      margin: 1.75rem auto;
      height: auto;
      width: auto;
    }
  }
  
  &.modal {
    z-index: 1060;
  }
  
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    
    &.modal-dialog-centered {
      min-height: 100vh;
      align-items: flex-start;
    }
  }
`;

const LeftSection = styled(Modal.Body)`
  padding: 32px;
  flex: 1;
  position: relative;
  width: 100%;
  
  @media (max-width: 768px) {
    padding: 16px;
    order: 2;
  margin-top:-398px;
  }
`;

const RightSection = styled.div`
  flex: 1;
  background-image: url('/assets/images/bcgames/login/r2.webp');
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  object-fit: cover;
  
  @media (min-width: 768px) {
    min-height: 450px;
    background-position: center top;
    overflow: hidden;
    background-size: 100% auto;
  }
  
  @media (max-width: 768px) {
    order: 1;
    padding: 12px;
    height: 90px;
    min-height: auto;
    background-image: url('/assets/images/bcgames/login/r2.webp');
    background-position: top;
    background-size: contain;
    justify-content: flex-end;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      background: linear-gradient(to bottom, rgba(35, 38, 38, 0), rgba(35, 38, 38, 1));
      pointer-events: none;
    }
  }
`;

const RightContent = styled.div`
  position: relative;
  z-index: 1;
  color: white;
`;

const StayUntamed = styled.div`
  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 16px;
    color: white;
    text-align: center;
    
    @media (max-width: 768px) {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  right: 16px;
  top: 16px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  font-size: 18px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  
  @media (min-width: 768px) {
    position: absolute;
  }
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const Logo = styled.div`
  margin-bottom: 32px;
  
  img {
    height: 36px;
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
    
    img {
      height: 28px;
    }
  }
`;

const Title = styled.h2`
  font-size: 24px;
  color: white;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledInput = styled.input`
  background-color: #111315;
  border: 1px solid #272B30;
  border-radius: 12px;
  color: white;
  padding: 12px 16px;
  width: 100%;
  font-size: 15px;

  @media (max-width: 768px) {
    padding: 10px 12px;
    font-size: 13px;
    border-radius: 8px;
  }

  &::placeholder {
    color: #6F767E;
  }

  &:focus {
    outline: none;
    border-color: #27ED87;
  }
`;

const PasswordWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const PasswordToggle = styled.button`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #6F767E;
  cursor: pointer;
`;

const ForgotPassword = styled.a`
  color: #6F767E;
  text-decoration: none;
  font-size: 14px;
  text-align: right;
  display: block;
  margin-top: -12px;
  
  @media (max-width: 768px) {
    font-size: 12px;
    margin-top: -8px;
  }
  
  &:hover {
    color: #27ED87;
  }
`;

const SignInButton = styled.button`
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 12px;
  color: black !important;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  cursor: pointer;
  margin-top: 12px;

  @media (max-width: 768px) {
    padding: 10px 12px;
    font-size: 14px;
    margin-top: 8px;
    border-radius: 8px;
  }

  &:hover {
    background-color: #00A06D;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  color: #6F767E;
  font-size: 14px;
  
  &::before, &::after {
    content: "";
    flex: 1;
    height: 1px;
    background: #272B30;
    margin: 0 16px;
  }

  @media (max-width: 768px) {
    margin: 16px 0;
    font-size: 12px;
  }
`;

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const SocialButton = styled.button`
  background: #111315;
  border: 1px solid #272B30;
  border-radius: 12px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    
    svg {
      width: 18px;
      height: 18px;
    }
  }
  
  &:hover {
    background: #191B1D;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #6F767E;
  }
`;

const SignUpPrompt = styled.div`
  text-align: center;
  margin-top: 24px;
  color: #6F767E;
  font-size: 14px;

  @media (max-width: 768px) {
    margin-top: 16px;
    font-size: 12px;
  }

  a {
    color: #27ED87;
    text-decoration: none;
    font-weight: 600;
    margin-left: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ModalClose = styled.div`
 display:none;
  @media (max-width: 768px) {
  display:flex;
   height:30px;
   width:30px;
   justify-content:center;
   align-items:center;
   border-radius:20%;
   background-color:#2D6A4D;
   position:absolute;
   padding:12px;
   top:-550px;;
   left:299px;
   z-index:9999;

  }
`;

const StyledSignInButton = styled.div`
  font-size: 14px;
  color: #fff !important;
  background-color: transparent;
  border: 1px solid #B3BEC1;
  border-radius: 10px;
  padding: 7px 14px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 13px;
    padding: 6px 12px;
    border-radius: 8px;
  }
`;
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: storage.getKey('logged'),
            show: this.props.show ? this.props.show : false,
            username: '',
            password: '',
            status: false,
            submitted: false,
            disabled: false,
            effect: 'zoomIn',
            forgetPage: false,
            showPassword: false
        };
    }

    togglePasswordVisibility = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }

    componentDidMount() {
        socket.on(C.LOGIN_USER, data => this.setLogin(decode(data)));
        Event.on('login_form', () => {
            this.handleShow();
        });
    }

    setLogin = (data) => {
        if (data.status === true) {
            sendNotfication('Successfully Login, Please Wait...', 'success', 'bottom-left');
            Cookies.set("session", data.token, { expires: 14 });
            storage.setKey('logged', true);
            storage.setKey('token', data.token);
            storage.setKey('name', data.name);
            storage.setKey('avatar', data.avatar);
            storage.setKey('email', data.email);
            storage.setKey('credit', data.credit);
            storage.setKey('room', data.room);
            storage.setKey('friends', data.friends);

            wait(500).then(() => {
                window.location.reload();
            });
        } else {
            this.setState({ submitted: false, disabled: false });
            sendNotfication(data.status, 'success', 'bottom-left');
        }
    };

    handleShow = () => {
        this.setState({ show: true, effect: 'zoomIn' });
    }

    handleClose = () => {
        this.setState({ 
            show: false, 
            effect: 'zoomOut', 
            disabled: false, 
            status: false, 
            submitted: false,
            forgetPage: false 
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        this.setState({ submitted: true, disabled: true, status: 'Please wait' });

        const { username, password } = this.state;

        if (!(username && password)) {
            this.setState({ disabled: false, status: false });
            return;
        }

        wait(200).then(() => {
            socket.emit(C.LOGIN_USER, encode({
                username: username,
                password: password,
                recaptcha: 'google'
            }));
        });
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    signup = (e) => {
        e.preventDefault();
        this.handleClose();
        Event.emit('register_form');
    }

    forgetPass = (e) => {
        e.preventDefault();
        this.setState({ forgetPage: !this.state.forgetPage });
    };

    render() {
        const { show, username, password, disabled, forgetPage } = this.state;

        return (
          <>
          <StyledSignInButton onClick={this.handleShow}>
              Sign in
          </StyledSignInButton>
          <StyledModal
              size="md"
              centered
              show={show}
              onHide={this.handleClose}
              aria-labelledby="login-modal"
              className={`animated ${this.state.effect}`}
          >
              <CloseButton onClick={this.handleClose}>×</CloseButton>
              <RightSection>
                  <RightContent>
                    <ModalClose  onClick={this.handleClose}>
                      x
                    </ModalClose>
                  </RightContent>
                  <StayUntamed>
                      {/* <h2>Stay Untamed</h2> */}
                      {/* <p>Sign Up & Get Welcome Bonus</p> */}
                  </StayUntamed>
                
              </RightSection>
              <LeftSection>
                  <Title>Sign In</Title>
                  {!forgetPage ? (
                      <>
                          <StyledForm onSubmit={this.handleSubmit}>
                              <InputWrapper>
                                  <StyledInput
                                      type="text"
                                      name="username"
                                      value={username}
                                      onChange={this.handleInputChange}
                                      placeholder="Email / Phone Number"
                                  />
                              </InputWrapper>
                              <InputWrapper>
                                  <PasswordWrapper>
                                      <StyledInput
                                          type={this.state.showPassword ? "text" : "password"}
                                          name="password"
                                          value={password}
                                          onChange={this.handleInputChange}
                                          placeholder="Password"
                                      />
                                      <PasswordToggle type="button" onClick={this.togglePasswordVisibility}>
                                          {this.state.showPassword ? (
                                              <svg viewBox="0 0 24 24" width="20" height="20">
                                                  <path fill="currentColor" d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"/>
                                              </svg>
                                          ) : (
                                              <svg viewBox="0 0 24 24" width="20" height="20">
                                                  <path fill="currentColor" d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                                              </svg>
                                          )}
                                      </PasswordToggle>
                                  </PasswordWrapper>
                              </InputWrapper>
                              <ForgotPassword href="#" onClick={this.forgetPass}>
                                  Forgot your password?
                              </ForgotPassword>
                              <SignInButton type="submit" disabled={disabled}>
                                  {disabled ? 'Signing in...' : 'Sign In'}
                              </SignInButton>
                          </StyledForm>
                          <Divider>or continue with</Divider>
                          <SocialLoginContainer>
                              <GoogleBtn />
                              {/* <SocialButton>
                                  <svg viewBox="0 0 24 24">
                                      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                                  </svg>
                              </SocialButton>
                              <SocialButton>
                                  <svg viewBox="0 0 24 24">
                                      <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 01-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"/>
                                  </svg>
                              </SocialButton> */}
                          </SocialLoginContainer>
                          <SignUpPrompt>
                              Don't have an account?
                              <a href="#" onClick={this.signup}>Sign up</a>
                          </SignUpPrompt>
                      </>
                  ) : (
                      <StyledForm onSubmit={this.handleSubmit}>
                          <Title>Reset Password</Title>
                          <InputWrapper>
                              <StyledInput
                                  type="email"
                                  name="email"
                                  placeholder="Enter your email"
                                  onChange={this.handleInputChange}
                              />
                          </InputWrapper>
                          <SignInButton type="submit" disabled={disabled}>
                              {disabled ? 'Sending...' : 'Reset Password'}
                          </SignInButton>
                          <SignUpPrompt>
                              Remember your password?
                              <a href="#" onClick={this.forgetPass}>Sign in</a>
                          </SignUpPrompt>
                      </StyledForm>
                  )}
              </LeftSection>
          </StyledModal>
      </>

        );
    }
}

export default Login;