import React from 'react';
import styled from 'styled-components';
import ClaudeIcon from '../../../Icons/Casino';
import GameIcon from '../../../Icons/Game';
import LotteryIcon from '../../../Icons/Lottery';
import RacingIcon from '../../../Icons/RacingIcon';
import { isMobile } from '../../../Helper';
import { useHistory } from 'react-router-dom';

const CardGrid = styled.div`
  display: grid;
  grid-template-areas:
    "casino sports sports lottery racing"
    "casino sports sports updown bingo";
  grid-template-columns: 1.4fr 1.4fr 0fr 1fr 1fr;
  gap: 16px;
  padding: 1rem 0.1rem;
  
  @media (max-width: 768px) {
    grid-template-areas:
      "casino casino"
      "sports sports"
      "lottery racing"
      "updown bingo";
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Card = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  padding: ${props => props.isLarge ? '28px' : '18px'};
  transition: all 0.2s ease;
  background: ${props => props.background};
  height: ${props => props.isLarge ? '250px' : '117px'};
`;

const Card1 = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  padding: 10px;
  transition: all 0.2s ease;
  background: ${props => props.background};
  height: 116px;
  width:177px;
`;

const CasinoCard = styled(Card)`
  grid-area: casino;
  padding: 1rem 0.8rem;
  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(44, 80, 64), transparent 75%);
`;

const SportsCard = styled(Card)`
  grid-area: sports;
  padding: 1rem 0.8rem;
  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(88, 46, 89), transparent 75%);
`;

const CasinoCard1 = styled(Card1)`
flex:1;

  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(44, 80, 64), transparent 75%);
`;

const SportsCard1 = styled(Card1)`

  flex:1;
  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(88, 46, 89), transparent 75%);
`;

const LotteryCard = styled(Card)`
  grid-area: lottery;
  padding: 0.25rem 0.3rem;
  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(66, 83, 48), transparent 75%);
`;

const LotteryCard1 = styled(Card)`
 display:flex;
 justify-content:center;
 align-items:center;
 height:90px;
 width:90px;
 
  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(66, 83, 48), transparent 75%);
`;


const RacingCard = styled(Card)`
  grid-area: racing;
  padding: 0.25rem 0.3rem;
  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(80, 65, 48), transparent 75%);
`;
const RacingCard1 = styled(Card)`
 display:flex;
 justify-content:center;
 align-items:center;
  height:90px;
 width:90px;
  background-color: rgb(50, 55, 56);
 background-image: linear-gradient(to left, rgb(80, 65, 48), transparent 75%);
`;

const UpdownCard = styled(Card)`
  grid-area: updown;
  padding: 0.15rem 0.2rem;
  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(45, 79, 49), transparent 75%);
`;


const UpdownCard1 = styled(Card)`
 display:flex;
 justify-content:center;
 align-items:center;
  height:90px;
 width:90px;
  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(45, 79, 49), transparent 75%);
`;



const BingoCard = styled(Card)`
  grid-area: bingo;
  padding: 0.25rem 0.3rem;
  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(71, 56, 111), transparent 75%);
`;


const BingoCard1= styled(Card)`
 display:flex;
 justify-content:center;
 align-items:center;
  height:90px;
 width:90px;
  background-color: rgb(50, 55, 56);
  background-image: linear-gradient(to left, rgb(71, 56, 111), transparent 75%);
`;
const CardContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TitleContainer1 = styled.h3`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TitleContainer = styled.h3`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  color: #ffffff;
  font-size: 24px;
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
    @media (max-width: 768px) {
     font-size: 14px;
  }
`;

const TitleS = styled.div`
  color: #ffffff;
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @media (max-width: 768px) {
    display: none;
  }
`;
const TitleR = styled.div`
 display: none;

  @media (max-width: 768px) {
   display:flex;
      color: #ffffff;
  font-size: 13px;
  margin: -6px  auto 0px 9px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align:center;
  }
`;


const Description = styled.p`
  color: #858E90;
  font-size: ${props => props.isLarge ? '14px' : '14px'};
  margin-top: 130px;
  max-width: ${props => props.isLarge ? '75%' : '75%'};
  line-height: 1.4;
`;

const CardImage = styled.img`
  position: absolute;
  right: ${props => props.isLarge ? '5px' : '-10px'};
  bottom: ${props => props.isLarge ? '-10px' : '-10px'};
  width: ${props => props.isLarge ? '245px' : '140px'};
  height: ${props => props.isLarge ? '245px' : '140px'};
  object-fit: contain;
  transition: transform 0.3s ease;
  
  ${Card}:hover & {
    transform: scale(1.05);
  }
`;

const CardImage1 = styled.img`
  position: absolute;
  // right: ${props => props.isLarge ? '5px' : '-10px'};
  // bottom: ${props => props.isLarge ? '-10px' : '-10px'};
  // width: ${props => props.isLarge ? '245px' : '140px'};
  // height: ${props => props.isLarge ? '245px' : '140px'};
   right: -10px;
  top:8px;
  width: 120px;
  height:120px;



  
  object-fit: contain;
  transition: transform 0.3s ease;
  
  ${Card}:hover & {
    transform: scale(1.05);
  }
`;

const CardImageS = styled.img`
  position: absolute;
  right: ${props => props.isLarge ? '5px' : '0px'};
  bottom: ${props => props.isLarge ? '-10px' : '0px'};
  width: 110px;
  height: 110px;
  object-fit: contain;
  transition: transform 0.3s ease;

  ${Card}:hover & {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    position: static;
    margin: 0 auto;
    display: block;
    width: 80px;
    height: 70px;
  }
`;


const CardImageSM = styled.img`

  width: 60px;
  height: 60px;
  object-fit: contain;
  transition: transform 0.3s ease;
   position: static;
 margin: -10px  auto 0px auto;
  

  ${Card}:hover & {
    transform: scale(1.05);
  }


`;
const GamingCards = () => {

  const history = useHistory();
  return (
    <>

      {
        !isMobile() ? (<CardGrid>
          <CasinoCard isLarge onClick={() => history.push('/casino')}>
            <CardContent>
              <TitleContainer>
                <ClaudeIcon />
                <Title isLarge>CASINO</Title>
              </TitleContainer>
              <Description isLarge>
                Dive into our in-house games, live casino and slots
              </Description>
              <div style={{ padding: "40px" }}>
                <CardImage
                  src="/assets/images/bcgames/gamescards/s1.webp"
                  alt="Casino"
                  isLarge
                />
              </div>
            </CardContent>
          </CasinoCard>

          <SportsCard isLarge onClick={() => history.push('/sports/markets/in-play')}>
            <CardContent>
              <TitleContainer>
                <GameIcon />
                <Title isLarge>Sports</Title>
              </TitleContainer>
              <Description isLarge>
                Bet on Football, Cricket, NFL, eSports & over 80 sports!
              </Description>
              <div style={{ padding: '40px' }}>
                <CardImage
                  src="/assets/images/bcgames/gamescards/s2.webp"
                  alt="Sports"
                  isLarge
                />
              </div>
            </CardContent>
          </SportsCard>

          <LotteryCard onClick={() => history.push('/lottery')}>
            <CardContent>
              <TitleContainer1>
                <LotteryIcon />
                <TitleS>Lottery</TitleS>
              </TitleContainer1>
              <CardImageS
                src="/assets/images/bcgames/gamescards/s3.webp"
                alt="Lottery"
              />
              <TitleR>RACING</TitleR>
            </CardContent>
          </LotteryCard>

          <RacingCard onClick={() => history.push('/racing')}>
            <CardContent>
              <TitleContainer1>
                <LotteryIcon />
                <TitleS>RACING</TitleS>
              </TitleContainer1>
              <CardImageS
               src="/assets/images/bcgames/gamescards/s4.webp"
                alt="Racing"
              />
              <TitleR>RACING</TitleR>

            </CardContent>
          </RacingCard>

          <UpdownCard onClick={() => history.push('/up-down')}>
            <CardContent>
              <TitleContainer1>
                <ClaudeIcon />
                <TitleS>UPDOWN</TitleS>
              </TitleContainer1>
              <CardImageS
                src="/assets/images/bcgames/gamescards/s5.webp"
                alt="Updown"
              />
              <TitleR>UPDOWN</TitleR>
            </CardContent>
          </UpdownCard>

          <BingoCard onClick={() => history.push('/bingo')}>
            <CardContent>
              <TitleContainer1>
                <ClaudeIcon />
                <TitleS>BINGO</TitleS>
              </TitleContainer1>
              <CardImageS
                src="/assets/images/bcgames/gamescards/s6.webp"
                alt="Bingo"
              />
              <TitleR>BINGO</TitleR>
            </CardContent>
          </BingoCard>
        </CardGrid>) : (<>


         <div style={{display:"flex", gap:'7px', width:'100%'}}>

         <CasinoCard1 isLarge onClick={() => history.push('/casino')}>
            <CardContent>
              <TitleContainer>
                <ClaudeIcon />
                <Title isLarge>CASINO</Title>
              </TitleContainer>
              {/* <Description isLarge>
                Dive into our in-house games, live casino and slots
              </Description> */}
              <div style={{ padding: "40px" }}>
                <CardImage1
                  src="/assets/images/bcgames/gamescards/s1.webp"
                  alt="Casino"
                  isLarge
                />
              </div>
            </CardContent>
          </CasinoCard1>

          <SportsCard1 isLarge onClick={() => history.push('/sports/markets/in-play')}>
            <CardContent>
              <TitleContainer>
                <GameIcon />
                <Title isLarge>Sports</Title>
              </TitleContainer>
              {/* <Description isLarge>
                Bet on Football, Cricket, NFL, eSports & over 80 sports!
              </Description> */}
              <div style={{ padding: '40px' }}>
                <CardImage1
                   src="/assets/images/bcgames/gamescards/s2.webp"
                  alt="Sports"
                  isLarge
                />
              </div>
            </CardContent>
          </SportsCard1>

         </div>

          
       
      <div style={{ display: "flex", gap: '8.5px',marginTop:'6px'}}>
      <LotteryCard1 onClick={() => history.push('/lottery')}>
        <CardContent>
          {/* <TitleContainer1>
            <LotteryIcon />
            <TitleS>Lottery</TitleS>
          </TitleContainer1> */}
       <div>
       <CardImageSM
            src="/assets/images/bcgames/gamescards/s3.webp"
            alt="Lottery"
          />
          <TitleR>LOTTERY</TitleR>
       </div>
        </CardContent>
      </LotteryCard1>

      <RacingCard1 onClick={() => history.push('/racing')}>
        <CardContent>
          {/* <TitleContainer1>
            <LotteryIcon />
            <TitleS>RACING</TitleS>
          </TitleContainer1> */}
        <div>
        <CardImageSM
            src="/assets/images/bcgames/gamescards/s4.webp"
            alt="Racing"
          />
          <TitleR>RACING</TitleR>
        </div>

        </CardContent>
      </RacingCard1>

      <UpdownCard1 onClick={() => history.push('/up-down')}>
        <CardContent>
          {/* <TitleContainer1>
            <ClaudeIcon />
            <TitleS>UPDOWN</TitleS>
          </TitleContainer1> */}
      <div>
      <CardImageSM
          src="/assets/images/bcgames/gamescards/s5.webp"
            alt="Updown"
          />
          <TitleR>UPDOWN</TitleR>
      </div>
        </CardContent>
      </UpdownCard1>

      <BingoCard1 onClick={() => history.push('/bingo')}>
        <CardContent>
          {/* <TitleContainer1>
            <ClaudeIcon />
            <TitleS>BINGO</TitleS>
          </TitleContainer1> */}
         <div>
         <CardImageSM
           src="/assets/images/bcgames/gamescards/s6.webp"
            alt="Bingo"
          />
          <TitleR>BINGO</TitleR>
         </div>
        </CardContent>
      </BingoCard1>
    </div>
    </>  
      )
      }
    </>
  );
};

export default GamingCards;