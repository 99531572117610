import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import { ChevronRight, Headphones } from 'lucide-react';
import { addNewStyle, CRISTP_ID, wait } from '../../../Helper';
import Social from '../../Pages/Parts/Sidebar/Social';

const SupportButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #27272a;
  border: none;
  width: 200px;
  border-radius: 20px;
  padding-right: 20px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2a2a2a;
  }
`;

const IconWrapperSupport = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #57D219;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;

const Text = styled.span`
  color: white;
  font-size: 14px;
  font-weight: 500;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  border-radius: 8px;
`;

const Title = styled.div`
  color: #9ca3af;
  font-size: 14px;
  margin-left: 10px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
`;

const LanguageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #27272a;
  color: white;
  padding: 8px 12px;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3f3f46;
  }
`;

const ButtonText = styled.span`
  font-size: 14px;
`;

const StyledChevronRight = styled(ChevronRight)`
  color: #9ca3af;
`;

const SidebarContainer = styled.nav`
  color: rgba(153, 164, 176, 0.8);
  background-color: rgb(30, 32, 36);
  transition: 0.2s linear;
  width: ${props => props.isCollapsed ? '80px' : '239px'};
  height: 100vh;
  padding: 12px 0px;
  color: #ffffff;
  position: fixed;
  top: -12px;
  left: 0;
  transition: width 0.3s ease;
  overflow-y: auto;
`;

const SidebarTop = styled.div`
  background-color: rgb(30, 32, 36);
  width: 100%;
  padding: 18px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  position: sticky;
  z-index: 9999;
`;

const CircularBarContainer = styled.button`
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.9375rem;
  background-color: rgb(26, 28, 32);
  box-shadow: transparent 0px 2px 4px 0px;
  z-index: 9999;
  position: absolute;
  top: 20px;
  right: -5px;
  transition: right 0.2s linear;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
`;

const MenuItemLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : '#ffffff')};
  padding: 4px 8px;
  cursor: pointer;
  position: relative;
  margin-top: 8px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  text-decoration: none;
  font-weight: ${(props) => (props.isVIP || props.isPromotion ? 'bold' : 'normal')};
  font-style: ${(props) => (props.isVIP ? 'italic' : 'normal')};

  ${(props) =>
    !props.isCollapsed &&
    props.active &&
    `
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 80%;
        background-color: rgba(76,175,80,0.2);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    `}

  ${(props) =>
    props.isCollapsed &&
    `
      justify-content: center;
      padding: 8px 0;
    `}
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${props => props.isCollapsed ? '0' : '3px'};
  position: relative;
  z-index: 1;

  ${(props) =>
    props.isCollapsed &&
    props.active &&
    `
      background-color: #2E3238;
      border-radius: 50%;
      width: 50px;
      height: 50px;
    `}
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
`;
const IconNew = styled.img`
  width: 20px;
  height: 20px;
`;


const Label = styled.span`
   font-size: ${(props) => (props.isVIP || props.isPromotion ? '16px' : '14px')};
  font-weight: ${(props) => (props.isVIP || props.isPromotion ? 'bold' : 'normal')};
  font-style: ${(props) => (props.isVIP || props.isPromotion ? 'italic' : 'normal')};
  position: relative;
  z-index: 1;
  color: ${(props) => {
    if (props.isVIP) return '#EB5D0E';
    if (props.isPromotion) return '#5DDB1B';
    return '#808A94';
  }} !important;
  display: ${props => props.isCollapsed ? 'none' : 'inline'};
`;

const Divider = styled.div`
  height: 1px;
  background-color: #353535;
  margin: 8px ${props => props.isCollapsed ? '4px' : '16px'};
`;

const SwitcherContainer = styled.div`
  display: flex;
  border-radius: 9999px;
  padding: 4px;
`;

const Tab = styled.div`
  padding: 8px 28px;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
  
  ${props => props.active ? `
    background-color: #31343c;
    color: white;
  ` : `
    color: #9CA3AF;
    &:hover {
      color: white;
    }
  `}
`;

const SidebarHello = ({ isCollapsed, setIsCollapsed }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('Casino');
  const tabs = ['Casino', 'Sports'];

  const [loaded, setLoaded] = useState(false);

  const support = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISTP_ID;

    if (!loaded) {
      console.log("support");
      setLoaded(true);
      const script = document.createElement('script');
      script.src = "https://client.crisp.chat/l.js";
      script.id = "chtt";
      script.async = true;
      document.head.appendChild(script);
    }

    addNewStyle("#crisp-chatbox {display:block !important;}");
    addNewStyle(
      ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
    );

    wait(2000).then(() => {
      const close = document.querySelector(".cc-wdhl");
      if (close === null) return;
      close.addEventListener("click", function (event) {
        addNewStyle("#crisp-chatbox {display:none !important;}");
        document.getElementById("chtt").src = "";
      });
    });
  };

  useEffect(() => {
    const toggleValue = localStorage.getItem('isCollapsed');
    if (toggleValue !== null) {
      setIsCollapsed(toggleValue === 'true');
    }
  }, []);

  const toggleSidebar = () => {
    const newIsCollapsed = !isCollapsed;
    setIsCollapsed(newIsCollapsed);
    localStorage.setItem('isCollapsed', newIsCollapsed.toString());
  };

  const menuItems = [
    { name: 'Home', icon: 'https://static.nanogames.io/assets/home.e1cf89b4.png', path: '/' },
    { name: 'Originals', icon: 'https://static.nanogames.io/assets/originalcasino.bb7966a7.png', path: '/games' },
    { name: 'Club Membership', icon: 'assets/images/members.png', path: '/club-membership' },
    { name: 'Bonus', icon: 'assets/images/bonus-img.png', path: '/bonus-page' },

    // { name: 'Originals', icon: 'https://static.nanogames.io/assets/originalcasino.bb7966a7.png', path: '/games' },
    // { name: 'Lottery', icon: 'https://static.nanogames.io/assets/lottery.bc95b607.png', path: '/wingo' },
    { name: 'Slots', icon: 'https://static.nanogames.io/assets/slots.42749df8.png', path: '/slots' },
    // { name: 'Live Casino', icon: 'https://static.nanogames.io/assets/livecasino.80cdfcc2.png', path: '/live-casino' },
    { name: 'Promotions', icon: 'https://static.nanogames.io/assets/promotion.316446ec.png', color: 'rgb(93, 219, 28)', path: '/promotions' },
    { divider: true },
    // { name: 'Most Populars', icon: 'https://static.nanogames.io/assets/myfavorite.466f0741.png', path: '/populars' },
    { name: 'Rakeback', icon: 'https://static.nanogames.io/assets/lottery.bc95b607.png', path: '/rakeback' },
    { name: 'Affiliate', icon: 'https://static.nanogames.io/assets/affiliate.6f434c33.png', path: '/affiliate' },
    { name: 'VIP Club', icon: 'https://static.nanogames.io/assets/vipclub.3b37e72c.png', color: 'rgb(235, 93, 15)', path: '/vip-club' },
    { name: 'Provably Fair', icon: 'https://static.nanogames.io/assets/fairness.12d49bfb.png', color: 'rgb(235, 93, 15)', path: '/provably-fair' },

    { divider: true },
    { name: 'About Us', icon: 'https://static.nanogames.io/assets/myfavorite.466f0741.png', color: 'rgb(93, 219, 28)', path: '/about-us' },
    { name: 'FAQ', icon: 'https://static.nanogames.io/assets/livecasino.80cdfcc2.png', color: 'rgb(93, 219, 28)', path: '/faq' },
  ];

  return (
    // <SidebarContainer isCollapsed={isCollapsed}>
    //   <SidebarTop>
    //     {isCollapsed ? (
    //       <MenuItemLink
    //         key={"casino"}
    //         to={'/casino'}
    //         active={location.pathname === 'casino'}
    //         color={""}
    //         isCollapsed={isCollapsed}
    //       >
    //         <IconWrapper active={location.pathname === 'casino'} isCollapsed={isCollapsed}>
    //           <Icon src="/assets/images/casinoBet.webp" />
    //         </IconWrapper>
    //       </MenuItemLink>
    //     ) : (
    //       <SwitcherContainer>
    //         {tabs.map(tab => (
    //           <Tab
    //             key={tab}
    //             active={activeTab === tab}
    //             onClick={() => setActiveTab(tab)}
    //           >
    //             {tab}
    //           </Tab>
    //         ))}
    //       </SwitcherContainer>
    //     )}

    //     <CircularBarContainer onClick={toggleSidebar}>
    //       <svg width="20" height="20" stroke-width="0" class="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" stroke="#fff">
    //         <path fill-rule="evenodd" clip-rule="evenodd" d="M3 5C3 4.44772 3.44772 4 4 4H16C16.5523 4 17 4.44772 17 5C17 5.55228 16.5523 6 16 6H4C3.44772 6 3 5.55228 3 5Z" fill="#4A5568"></path>
    //         <path fill-rule="evenodd" clip-rule="evenodd" d="M3 10C3 9.44772 3.44772 9 4 9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H4C3.44772 11 3 10.5523 3 10Z" fill="#4A5568"></path>
    //         <path fill-rule="evenodd" clip-rule="evenodd" d="M3 15C3 14.4477 3.44772 14 4 14H16C16.5523 14 17 14.4477 17 15C17 15.5523 16.5523 16 16 16H4C3.44772 16 3 15.5523 3 15Z" fill="#4A5568"></path>
    //       </svg>
    //     </CircularBarContainer>
    //   </SidebarTop>

    //   {menuItems.map((item, index) =>
    //     item.divider ? (
    //       <Divider key={`divider-${index}`} isCollapsed={isCollapsed} />
    //     ) : (
    //       <MenuItemLink
    //         key={item.name}
    //         to={item.path}
    //         active={location.pathname === item.path}
    //         color={item.color}
    //         isCollapsed={isCollapsed}
    //         isVIP={item.name === 'VIP Club'}
    //         isPromotion={item.name === 'Promotions'}
    //       >
    //       {
    //         item.name === "Club Membership" || item.name==="Bonus" ? (  <IconWrapper active={location.pathname === item.path} isCollapsed={isCollapsed}>
    //           <IconNew src={item.icon} alt={item.name} />
    //         </IconWrapper>):  <IconWrapper active={location.pathname === item.path} isCollapsed={isCollapsed}>
    //         <Icon src={item.icon} alt={item.name} />
    //       </IconWrapper>
    //       }
    //         <Label 
    //           active={location.pathname === item.path} 
    //           isCollapsed={isCollapsed}
    //           isVIP={item.name === 'VIP Club'}
    //           isPromotion={item.name === 'Promotions'}
    //         >
    //           {item.name}
    //         </Label>
    //       </MenuItemLink>
    //     )
    //   )}

    //   <Divider />

    //   <Flex style={{marginTop:'20px', marginBottom:'20px'}}>
    //     <Container>
    //       <Title>Language Options</Title>
    //       <LanguageButton>
    //         <ButtonText>English</ButtonText>
    //         <StyledChevronRight size={24} />
    //       </LanguageButton>
    //     </Container>
    //   </Flex>

    //   <Divider />

    //   <Flex onClick={support} style={{marginTop:'20px', marginBottom:'20px'}}>
    //     <SupportButton>
    //       <IconWrapperSupport>
    //         <Headphones size={18} color="white" />
    //       </IconWrapperSupport>
    //       <Text>Live Support</Text>
    //     </SupportButton>
    //   </Flex>
    // </SidebarContainer>
    <></>
  );
};

export default SidebarHello;