import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  background: #1e4d5c;
  color: white;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TabContainer = styled.div`
  display: flex;
  background: #1e4d5c;
  padding: 0 16px;
`;

const Tab = styled.div`
  padding: 8px 16px;
  color: white;
  cursor: pointer;
  background: ${props => props.active ? '#ffffff20' : 'transparent'};
  border-radius: 4px 4px 0 0;
`;

const TableContainer = styled.div`
  background: white;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 6fr 2fr 2fr 2fr;
  border-bottom: 1px solid #eee;
  position: relative;
  background: ${props => props.selected ? '#f8f9fa' : 'transparent'};
`;

const MarketName = styled.div`
  padding: 12px;
  font-size: 13px;
  color: #333;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  display: flex;
  align-items: center; // Vertical center alignment
  min-height: 60px; // Ensure consistent height
`;

const OddsCell = styled.div`
  padding: 4px 5px;
  text-align: center;
  cursor: pointer;
  background: ${props => props.type === 'no' ? '#FAA9BA' : '#bbdefb'};
  display: flex;
  flex-direction: column;
  justify-content: center; // Vertical center alignment
  align-items: center;
  position: relative;
  opacity: ${props => props.suspended || props.ballRunning ? 0.9 : 1};
  border-right: 1px solid #eee;
  min-height: 40px; // Ensure consistent height
  
  ${props => props.selected && `
    background: ${props.type === 'no' ? '#FAA9BA' : '#90caf9'};
  `}
`;

const MainOdds = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: black;
`;

const SubOdds = styled.div`
  font-size: 12px;
  color: #666;
`;

const MinMax = styled.div`
  padding: 4px 8px;
  font-size: 11px;
  color: #666;
  display: flex;
  align-items: center; // Vertical center alignment
  border-right: 1px solid #eee;
  min-height: 60px; // Ensure consistent height
`;

const BettingPanel = styled.div`
  background: ${props => props.type === 'no' ? '#FFEBEE' : '#E3F2FD'};
  padding: 12px;
  border-bottom: 1px solid #eee;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Button = styled.div`
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: ${props => props.primary ? '#1e4d5c' : 'white'};
  color: ${props => props.primary ? 'white' : 'black'};
  cursor: pointer;
`;

const StakeInput = styled.input`
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 120px;
`;

const QuickStakeContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const StatusOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
`;

const FancyBet = () => {
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [stake, setStake] = useState('');
  const [acceptAnyOdds, setAcceptAnyOdds] = useState(false);

  const markets = [
    {
      name: "20 Over BANU19",
      no: { odds: 89, amount: 100 },
      yes: { odds: 90, amount: 100 },
      minMax: "63.00 / 62,500.00",
      status: "active"
    },
    {
      name: "1st 3 WKT BANU19",
      no: { odds: 107, amount: 110 },
      yes: { odds: 107, amount: 90 },
      minMax: "63.00 / 31,250.00",
      status: "suspended"
    },
    {
      name: "3rd WKT BANU19 Run Bhav",
      no: { odds: 88, amount: 8 },
      yes: { odds: 88, amount: 5 },
      minMax: "63.00 / 6,250.00",
      status: "ball_running"
    }
  ];

  const quickStakes = [500, 1000, 2000, 5000, 50000, 100000];

  const handleSelect = (marketIndex, type) => {
    setSelectedMarket(marketIndex);
    setSelectedType(type);
  };

  return (
    <Container>
      <Header>
        <span>⭐</span>
        <span>Fancy Bet</span>
      </Header>
      
      <TabContainer>
        <Tab active>All</Tab>
        <Tab>Fancy</Tab>
        <Tab>Ball by Ball</Tab>
        <Tab>Khadda</Tab>
        <Tab>Lottery</Tab>
        <Tab>Odd/Even</Tab>
      </TabContainer>

      <TableContainer>
        {markets.map((market, index) => (
          <React.Fragment key={index}>
            <TableRow selected={selectedMarket === index}>
              <MarketName>{market.name}</MarketName>
              <OddsCell 
                type="no"
                selected={selectedMarket === index && selectedType === 'no'}
                suspended={market.status === 'suspended'}
                ballRunning={market.status === 'ball_running'}
                onClick={() => handleSelect(index, 'no')}
              >
                <MainOdds>{market.no.odds}</MainOdds>
                <SubOdds>{market.no.amount}</SubOdds>
                {market.status !== 'active' && (
                  <StatusOverlay>
                    {market.status === 'suspended' ? 'Suspend' : 'Ball Running'}
                  </StatusOverlay>
                )}
              </OddsCell>
              <OddsCell 
                type="yes"
                selected={selectedMarket === index && selectedType === 'yes'}
                suspended={market.status === 'suspended'}
                ballRunning={market.status === 'ball_running'}
                onClick={() => handleSelect(index, 'yes')}
              >
                <MainOdds>{market.yes.odds}</MainOdds>
                <SubOdds>{market.yes.amount}</SubOdds>
                {market.status !== 'active' && (
                  <StatusOverlay>
                    {market.status === 'suspended' ? 'Suspend' : 'Ball Running'}
                  </StatusOverlay>
                )}
              </OddsCell>
              <MinMax>{market.minMax}</MinMax>
            </TableRow>
            
            {selectedMarket === index && (
             <BettingPanel type={selectedType}>
             <CheckboxContainer>
               <input 
                 type="checkbox" 
                 checked={acceptAnyOdds}
                 onChange={(e) => setAcceptAnyOdds(e.target.checked)}
               />
               <span style={{color: 'black'}}>Accept Any Odds</span>
             </CheckboxContainer>
             
             <ActionContainer>
               <Button onClick={() => setSelectedMarket(null)}>Cancel</Button>
               <StakeInput 
                 type="text"
                 value={stake}
                 onChange={(e) => setStake(e.target.value)}
                 placeholder="Enter stake"
               />
               <Button primary>Place Bets</Button>
             </ActionContainer>
         
             <QuickStakeContainer>
               {quickStakes.map(value => (
                 <Button key={value} onClick={() => setStake(value)}>
                   {value}
                 </Button>
               ))}
             </QuickStakeContainer>
           </BettingPanel>
            )}
          </React.Fragment>
        ))}
      </TableContainer>
    </Container>
  );
};

export default FancyBet;