import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  background-color: #E4F1F9;
`;
const StatusBar = styled.div`
  background: linear-gradient(to right, #1a2d44, #2c4668);
  color: white;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(to right, #4CAF50, #45a049);
  }
`;
const StatusTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  
  svg {
    width: 18px;
    height: 18px;
    fill: #4CAF50;
  }
`;
const StatusInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 13px;
  color: #b0bec5;
`;
const TabContainer = styled.div`
  display: flex;
  background: #1a2d44;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
`;

const Tab = styled.div`
  padding: 10px 15px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  background: ${props => props.active ? '#2c4668' : 'transparent'};
  border-bottom: 2px solid ${props => props.active ? '#4CAF50' : 'transparent'};
`;

const MarketContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background: #f5f7fa;
`;

const MarketRow = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.selected === 'no' ? '#fff2f2' : props.selected === 'yes' ? '#f2f8ff' : 'white'};
  border-radius: 4px;
  overflow: hidden;
  transition: background-color 0.3s ease;
  position: relative;
`;

const MarketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: #f0f4f9;
  border-bottom: 1px solid #e0e6ed;
`;

const MarketTitle = styled.div`
  font-size: 14px;
  color: #2c3e50;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InfoIcon = styled.span`
  color: #95a5a6;
  font-size: 16px;
`;

const OddsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 1px;
  background: #e0e6ed;
  position: relative;
`;

const OddsContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 1px;
  position: relative;
  padding:4px 0px
`;

const OddsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  background: ${props => {
        if (props.selected) {
            return props.type === 'no' ? '#ffcdd2' : '#bbdefb';
        }
        return props.type === 'no' ? '#ffe5e5' : '#e3f2fd';
    }};
  cursor: pointer;
  transition: background-color 0.3s ease;

  .odds {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }

  .volume {
    font-size: 12px;
    color: #666;
    margin-top: 2px;
  }
`;

const BetSlip = styled.div`
  background: ${props => props.type === 'no' ? '#fff2f2' : '#f2f8ff'};
  padding: 12px;
`;

const BetInputContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
`;

const OddsDisplay = styled.div`
  background: #fff;
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .odds {
    font-weight: 600;
    font-size: 16px;
  }

  .volume {
    font-size: 12px;
    color: #666;
    margin-top: 2px;
  }
`;

const AmountContainer = styled.div`
  flex: 1;
  display: flex;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background: white;
`;

const AdjustButton = styled.div`
  width: 40px;
  border: none;
  background: #f0f0f0;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AmountInput = styled.div`
  flex: 1;
  padding: 8px;
  text-align: center;
  font-size: 16px;
  color: #333;
  min-width: 80px;
`;

const QuickAmounts = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 4px;
  margin-bottom: 12px;
`;

const QuickAmount = styled.div`
  padding: 6px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 2px;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  color: #333 !important;
`;

const Numpad = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  margin-bottom: 12px;
  color: #333 !important;
  text-align: center;
`;

const NumpadButton = styled.div`
  padding: 12px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 2px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  text-align: center;

`;

const Button = styled.div`
  padding: 12px;
  border-radius: 4px;
    text-align: center;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid ${props => props.variant === 'primary' ? '#f8b853' : '#ddd'};
  background: ${props => props.variant === 'primary' ? '#f8b853' : 'white'};
  color: #333;
`;

const AcceptOddsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  font-size: 13px;
  color: #666;
`;

const StatusOverlay = styled.div`
 position: absolute;
    top: 41%;
    left: 49.33%;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(200, 200, 200, 0.85), rgba(180, 180, 180, 0.9));
    box-shadow: inset 0 0 20px rgba(160, 160, 160, 0.4), inset 0 0 40px rgba(140, 140, 140, 0.2);
    display: flex
;
    align-items: center;
    justify-content: center;
    z-index: 1;
    height: 53px;
    width: 183px;
    backdrop-filter: blur(1px);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(80, 80, 80, 0.15);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      rgba(120, 120, 120, 0.1),
      rgba(100, 100, 100, 0.2)
    );
    z-index: -2;
  }
`;

const StatusText = styled.span`
  color: white !important;
  font-weight: 600;
  font-size: 14px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`;

const handleAmountChange = (currentAmount, change) => {
    const newAmount = Number(currentAmount) + change;
    return newAmount < 0 ? '0' : String(newAmount);
};

const FancyBetMobile = () => {
    const [activeTab, setActiveTab] = useState('All');
    const [selectedBets, setSelectedBets] = useState({});
    const [betAmounts, setBetAmounts] = useState({});
    const [acceptAnyOdds, setAcceptAnyOdds] = useState({});

    const tabs = ['All', 'Fancy', 'Ball by Ball', 'Khadda', 'Lottery', 'Odd/Even'];

    const markets = [
        { title: '20 Over SA', no: { odds: 59, volume: 140 }, yes: { odds: 59, volume: 50 }, status: 'active' },
        { title: '4th Wkt SA', no: { odds: 85, volume: 110 }, yes: { odds: 85, volume: 90 }, status: 'suspended' },
        { title: '4th Wkt Pship Boundaries SA', no: { odds: 5, volume: 100 }, yes: { odds: 6, volume: 100 }, status: 'ball_running' },
        { title: 'R Ricketon Runs', no: { odds: 45, volume: 110 }, yes: { odds: 45, volume: 90 }, status: 'active' },
        { title: 'Total Match Boundaries', no: { odds: 32, volume: 200 }, yes: { odds: 33, volume: 180 }, status: 'ball_running' },
        { title: '1st 6 Overs Runs SA', no: { odds: 92, volume: 150 }, yes: { odds: 93, volume: 130 }, status: 'active' },
        { title: 'D Miller Runs', no: { odds: 28, volume: 120 }, yes: { odds: 29, volume: 100 }, status: 'suspended' },
        { title: 'H Klaasen Runs', no: { odds: 41, volume: 160 }, yes: { odds: 42, volume: 140 }, status: 'active' },
        { title: 'Total Match Sixes', no: { odds: 15, volume: 180 }, yes: { odds: 16, volume: 160 }, status: 'ball_running' },
        { title: 'Total Match Wickets', no: { odds: 12, volume: 130 }, yes: { odds: 13, volume: 120 }, status: 'suspended' },
        { title: '1st Innings Score', no: { odds: 165, volume: 250 }, yes: { odds: 166, volume: 230 }, status: 'active' },
        { title: 'Highest Over Runs', no: { odds: 19, volume: 140 }, yes: { odds: 20, volume: 120 }, status: 'ball_running' }
    ];

    const quickAmounts = [100, 1000, 2000, 5000, 50000, 100000];

    const handleOddsClick = (market, type) => {
        const marketKey = `${market.title}-${type}`;
        const oppositeType = type === 'no' ? 'yes' : 'no';
        const oppositeKey = `${market.title}-${oppositeType}`;

        setSelectedBets(prev => {
            const newBets = { ...prev };
            delete newBets[oppositeKey];

            if (newBets[marketKey]) {
                delete newBets[marketKey];
            } else {
                newBets[marketKey] = { market, type };
            }

            return newBets;
        });

        if (!betAmounts[marketKey]) {
            setBetAmounts(prev => ({
                ...prev,
                [marketKey]: '100'
            }));
        }
    };

    return (
        <Container>
            <StatusBar>
                <StatusTitle>
                    <svg viewBox="0 0 24 24">
                        <path d="M3.5,18.5L9.5,12.5L13.5,16.5L22,6.92L20.59,5.5L13.5,13.5L9.5,9.5L2,17L3.5,18.5Z"/>
                    </svg>
                    Fancy Bet
                </StatusTitle>
                <StatusInfo>
                    <span>Active Markets: 8</span>
                    <span>•</span>
                    <span>Total Volume: ₹2.4M</span>
                </StatusInfo>
            </StatusBar>
            <TabContainer>
                {tabs.map(tab => (
                    <Tab
                        key={tab}
                        active={activeTab === tab}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </Tab>
                ))}
            </TabContainer>

            <OddsContainerHeader>

                <div></div>
                <div style={{ width: '100%', textAlign: 'center', color: '#333' }}>No</div>
                <div style={{ width: '100%', textAlign: 'center', color: '#333' }}>Yes</div>
            </OddsContainerHeader>

            <MarketContainer>
                {markets.map((market, index) => {
                    const noKey = `${market.title}-no`;
                    const yesKey = `${market.title}-yes`;
                    const selectedType = selectedBets[noKey] ? 'no' : selectedBets[yesKey] ? 'yes' : null;

                    return (
                        <React.Fragment key={index}>

                            <MarketRow selected={selectedType}>
                                <MarketHeader>
                                    <MarketTitle>
                                        {market.title}
                                        <InfoIcon>ⓘ</InfoIcon>
                                    </MarketTitle>
                                </MarketHeader>
                                <OddsContainer>
                                    <div style={{ width: '100%', backgroundColor: 'white' }}></div>

                                    <OddsBox
                                        type="no"
                                        selected={selectedBets[noKey]}
                                        onClick={() => market.status === 'active' && handleOddsClick(market, 'no')}
                                    >
                                        <span className="odds">{market.no.odds}</span>
                                        <span className="volume">{market.no.volume}</span>
                                    </OddsBox>
                                    <OddsBox
                                        type="yes"
                                        selected={selectedBets[yesKey]}
                                        onClick={() => market.status === 'active' && handleOddsClick(market, 'yes')}
                                    >
                                        <span className="odds">{market.yes.odds}</span>
                                        <span className="volume">{market.yes.volume}</span>
                                    </OddsBox>
                                </OddsContainer>
                                {market.status !== 'active' && (
                                    <StatusOverlay>
                                        <StatusText>
                                            {market.status === 'suspended' ? 'SUSPENDED' : 'BALL RUNNING'}
                                        </StatusText>
                                    </StatusOverlay>
                                )}
                            </MarketRow>

                            {selectedBets[noKey] && (
                                <BetSlip type="no">
                                    <BetInputContainer>
                                        <OddsDisplay>
                                            {/* <span className="odds">{market.no.odds}</span>
                                            <span className="volume">{market.no.volume}</span> */}

                                            {`${market.no.odds}/${market.no.volume}`}
                                        </OddsDisplay>
                                        <AmountContainer>
                                            <AdjustButton onClick={() => {
                                                setBetAmounts(prev => ({
                                                    ...prev,
                                                    [noKey]: handleAmountChange(prev[noKey] || 0, -100)
                                                }));
                                            }}>-</AdjustButton>
                                            <AmountInput>{betAmounts[noKey]}</AmountInput>
                                            <AdjustButton onClick={() => {
                                                setBetAmounts(prev => ({
                                                    ...prev,
                                                    [noKey]: handleAmountChange(prev[noKey] || 0, 100)
                                                }));
                                            }}>+</AdjustButton>
                                        </AmountContainer>
                                    </BetInputContainer>

                                    <QuickAmounts>
                                        {quickAmounts.map(amount => (
                                            <QuickAmount
                                                key={amount}
                                                onClick={() => setBetAmounts(prev => ({
                                                    ...prev,
                                                    [noKey]: String(amount)
                                                }))}
                                            >
                                                {amount}
                                            </QuickAmount>
                                        ))}
                                    </QuickAmounts>

                                    <Numpad>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '00', '×'].map((num, i) => (
                                            <NumpadButton
                                                key={i}
                                                onClick={() => {
                                                    if (num === '×') {
                                                        setBetAmounts(prev => ({ ...prev, [noKey]: '0' }));
                                                    } else {
                                                        setBetAmounts(prev => ({
                                                            ...prev,
                                                            [noKey]: prev[noKey] === '0' ?
                                                                String(num) :
                                                                String(Number(prev[noKey] + num) || 0) // Ensures valid number or defaults to 0
                                                        }));
                                                    }
                                                }}
                                            >
                                                {num}
                                            </NumpadButton>
                                        ))}
                                    </Numpad>

                                    <ActionButtons>
                                        <Button onClick={() => {
                                            setSelectedBets(prev => {
                                                const newBets = { ...prev };
                                                delete newBets[noKey];
                                                return newBets;
                                            });
                                        }}>Cancel</Button>
                                        <Button variant="primary">Place Bet</Button>
                                    </ActionButtons>

                                    <AcceptOddsContainer>
                                        <input
                                            type="checkbox"
                                            checked={acceptAnyOdds[noKey] || false}
                                            onChange={() => setAcceptAnyOdds(prev => ({
                                                ...prev,
                                                [noKey]: !prev[noKey]
                                            }))}
                                        />
                                        <span>Accept Any Odds</span>
                                    </AcceptOddsContainer>
                                </BetSlip>
                            )}

                            {selectedBets[yesKey] && (
                                <BetSlip type="yes">
                                    <BetInputContainer>
                                        <OddsDisplay>
                                        
                                            {`${market.yes.odds}/${market.yes.volume}`}
                                        </OddsDisplay>
                                        <AmountContainer>
                                            <AdjustButton onClick={() => {
                                                setBetAmounts(prev => ({
                                                    ...prev,
                                                    [yesKey]: handleAmountChange(prev[yesKey] || 0, -100)
                                                }));
                                            }}>-</AdjustButton>
                                            <AmountInput>{betAmounts[yesKey]}</AmountInput>
                                            <AdjustButton onClick={() => {
                                                setBetAmounts(prev => ({
                                                    ...prev,
                                                    [yesKey]: handleAmountChange(prev[yesKey] || 0, 100)
                                                }));
                                            }}>+</AdjustButton>
                                        </AmountContainer>
                                    </BetInputContainer>

                                    <QuickAmounts>
                                        {quickAmounts.map(amount => (
                                            <QuickAmount
                                                key={amount}
                                                onClick={() => setBetAmounts(prev => ({
                                                    ...prev,
                                                    [yesKey]: String(amount)
                                                }))}
                                            >
                                                {amount}
                                            </QuickAmount>
                                        ))}
                                    </QuickAmounts>

                                    <Numpad>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '00', '×'].map((num, i) => (
                                            <NumpadButton
                                                key={i}
                                                onClick={() => {
                                                    if (num === '×') {
                                                        setBetAmounts(prev => ({ ...prev, [yesKey]: '0' }));
                                                    } else {
                                                        setBetAmounts(prev => ({
                                                            ...prev,
                                                            [yesKey]: prev[yesKey] === '0' ?
                                                                String(num) :
                                                                String(Number(prev[yesKey] + num) || 0) // Ensures valid number or defaults to 0
                                                        }));
                                                    }
                                                }}
                                            >
                                                {num}
                                            </NumpadButton>
                                        ))}
                                    </Numpad>

                                    <ActionButtons>
                                        <Button onClick={() => {
                                            setSelectedBets(prev => {
                                                const newBets = { ...prev };
                                                delete newBets[yesKey];
                                                return newBets;
                                            });
                                        }}>Cancel</Button>
                                        <Button variant="primary">Place Bet</Button>
                                    </ActionButtons>

                                    <AcceptOddsContainer>
                                        <input
                                            type="checkbox"
                                            checked={acceptAnyOdds[yesKey] || false}
                                            onChange={() => setAcceptAnyOdds(prev => ({
                                                ...prev,
                                                [yesKey]: !prev[yesKey]
                                            }))}
                                        />
                                        <span>Accept Any Odds</span>
                                    </AcceptOddsContainer>
                                </BetSlip>
                            )}
                        </React.Fragment>
                    );
                })}
            </MarketContainer>
        </Container>
    );
};

export default FancyBetMobile;