import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
// import socket from '../../../../Socket';
import socket from '../../../../../Socket';
// import C from "../../../../Constant";
import C from '../../../../../Constant'
// import { __, addNewStyle, CRISTP_ID, decode, defaultAvatar, encode, getUID, toUSD, wait } from "../../../../Helper";
import { __, addNewStyle, CRISTP_ID, decode, defaultAvatar, encode, forceSatoshiFormat, getUID, toUSD, wait } from '../../../../../Helper';
// import storage from '../../../../Storage';
import storage from '../../../../../Storage';
import { ChevronRight, Heart, LucideDivideSquare, PencilIcon, Wallet2, X } from 'lucide-react';
import NotificationsSkyla from '../../../../../Icons/Notifications';
import AffilateIcon from '../../../../../Icons/AffliateIcon';
import CurrencyIcon, { WhiteCurrencyIcon } from '../../../../../Icons/Currency';
import { Modal } from 'react-bootstrap';
import SkylaDepositModal from '../../../Wallet/Deposit/ModalContent';
import Cookies from 'js-cookie';

// Main container with background
const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  color: white;
  background-color: #232626;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow-y: auto;

`;

const InnerContainer = styled.div`
 padding:10px;
`;




// Back button
const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10000;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(45deg);
    margin-left: 4px;
  }
`;

// Header section with background image and gradient
const Header = styled.div`
  background: url('https://bc.game/modules/account2/assets/top_bg-31e8e65d.png');
  background-size: cover;
  background-position: center;
  padding: 20px;
  padding-top: 60px;
  border-radius: 12px;
  margin-bottom: 20px;
  position: relative;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

const ProfileImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const ProfileInfo = styled.div`
  h2 {
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    color: white;
  }

  p {
    font-size: 14px;
    margin: 4px 0 0 0;
    opacity: 0.8;
    color: rgba(255, 255, 255, 0.8);
  }
`;

const VipSection = styled.div`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 8px 12px;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const VipStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background: #4CAF50;
    border-radius: 50%;
  }
`;

const VipText = styled.span`
  color: #4CAF50;
  font-size: 14px;
`;

const VipProgress = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
`;

const BalanceSection = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BalanceIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const BalanceText = styled.span`
  font-size: 14px;
//   color: #FFD700;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 8px;
  transition: all 0.2s ease;
  color: ${props => props.active === 'true' ? '#10B981' : '#9CA3AF'};
  &:hover {
    color: #10B981;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
`;

const NavIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;

  /* SVG icon color logic */
  & svg {
    color: ${props => props.active ? '#24EE89' : '#9CA3AF'};
    stroke: ${props => props.active ? '#24EE89' : '#9CA3AF'};
  }
`;
const NavLabel = styled.span`
  font-size: 10px;
  font-weight: 500;
`;

// Card container with common styles
const Card = styled.div`
  background-color: #323738;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
`;

// Action buttons with icons
const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 16px;
`;

const ActionButton = styled.div`
  background: ${props => props.primary ? ' linear-gradient(90deg, #24ee89, #9fe871)' : 'transparent'};
  border: ${props => props.primary ? 'none' : '1px solid rgba(255, 255, 255, 0.2)'};
  color:${props => props.primary ? 'black' : 'white'};
  padding: 8px;
  border-radius: 8px;
 box-shadow:  ${props => props.primary ? '0 0 12px #23ee884d, 0 -2px #1dca6a inset' : 'none'} ;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const ButtonIcon = styled.img`
  width: 20px;
  height: 20px;
`;

// Quick action buttons grid
const QuickActions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  margin-top: 16px;
`;

const QuickActionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ActionIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const ActionText = styled.span`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
`;

// Menu item styles
const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
//   border-bottom: ${props => props.noBorder ? 'none' : '1px solid rgba(255, 255, 255, 0.1)'};
`;

const MenuLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const MenuIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const MenuText = styled.div`
  font-size: 16px;
  color: white;
`;

const MenuRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MenuLabel = styled.span`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
`;

const ChevronIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const NotificationBadge = styled.div`
  background-color: #4CAF50;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 8px;
`;

// Referral section
const ReferralSection = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoutButton = styled.div`
  background-color:none;
  color: white;
  border: none;
  padding: 12px 32px;
  border-radius: 8px;
  font-size: 16px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const IconBg = styled.div`
  padding: 2px 4px;
  background-color: #394142;
  border-radius:10px;
 
`;

const ReferralText = styled.span`
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CopyButton = styled.button`
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #17181B;
    border-radius: 15px;
    border: none;
    color: white;
    height:100Vh;
    width:100%;
    margin-top:24px;
  }
  .modal-dialog {
    @media (min-width: 576px) {
      height:100Vh;
    width:100%;
    }
    @media (min-width: 768px) {
    height:100Vh;
    width:100%;
    }
    @media (min-width: 992px) {
       height:100Vh;
    width:100%;
    }
  }
`;

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .close-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 0 20px 20px;
  background-color: #17181B;
`;



const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 99999;


`;

const ModalContent = styled.div`
  background-color: #232626;
  border-radius: 12px;
  width: 100%;
  max-width: 470px;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  min-height: 400px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    border-radius: 0; /* Optional: Remove border radius on mobile */
  }

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalHeader1 = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr 24px;  /* Create three columns: space for balance, title, close button */
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #2A2D35;
  flex-shrink: 0;
  background-color: #323738 !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const ModalTitle = styled.h4`
  color: white;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  grid-column: 2;  /* Place in middle column */
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #6F767E;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 3;  /* Place in last column */
  margin-left: auto;  /* Push to the end */
  
  &:hover {
    color: white;
  }
`;
const ModalBody1 = styled.div`
  padding: 24px;
  color: white;
  overflow-y: auto;
  flex-grow: 1;
  
  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #2A2D35;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #454951;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;


const StatBox = styled.div`
  background-color: #1B1C1F;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
  
  .stat-label {
    color: #676D76;
    font-size: 12px;
    margin-bottom: 5px;
  }
  
  .stat-value {
    font-size: 14px;
    font-weight: bold;
  }
`;
const MobileCloseButton = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255,255,255,0.1);
    border: none;
    border-radius: 10%;
    padding: 8px;
    z-index: 101;
  }
`;



const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledButtonNew = styled.button`
background: linear-gradient(to bottom, #4AE54A, #94E874);
  color: white;
  font-weight: bold;
  padding: 0.55rem 1rem;
  border-radius: 9999px;
  width: 100%;
  max-width: 20rem;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #56CF16;
    transform: scale(1.05);
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #a0a0a0;
  font-family:normal;
`;
const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #25282C;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
`;


const Pencil = styled.div`
display:flex;
justify-content:center
background-color:#1C1D21;
padding: 0.75rem;
border-radius:50%;
`;


const UserContainer = styled.div`
display:flex;
justify-content:space-between;
gap:5px;
padding:5px;
`;

const LogoutModal = styled(Modal)`
  .modal-content {
    background-color: #232626;
    border-radius: 15px;
    border: none;
    color: white;
    width: 100%;
    max-width: 400px;
    height: 235px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  @media (max-width: 576px) {
    .modal-content {
      max-width: 90%;
      margin: 0 auto;
    }
  }
`;

const ModalHeaderLogout = styled(Modal.Header)`
  border-bottom: none;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  
  .close-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .header-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
`;

const LogoutModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  flex: 1;
  padding: 1rem;
`;

const LogoutButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
`;

const LogoutButton1 = styled.button`
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.2s ease;

  &.cancel {
    background-color: #373E3F;
    color: white;
    &:hover {
      background-color: #3A3D45;
    }
  }

  &.signout {
    font-weight: 800;
    background-image: linear-gradient(90deg, #24ee89, #9fe871);
    box-shadow: 0 0 12px #23ee884d, 0 -2px #1dca6a inset;
    color: black;
  }
`;

const SkylaMobileUserProfile = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [showEditUi, setShowEditUi] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const [openDeposit, setOpenDeposit] =useState(false)

    const history = useHistory();
    const progressAnimationRef = useRef(null);
    const currentName = storage.getKey('name') !== undefined ? storage.getKey('name') : 'Guest';

    // Convert all class state to useState hooks
    const [level, setLevel] = useState(1);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [displayUid] = useState(getUID);
    const [name, setName] = useState(currentName);
    const [avatar] = useState(defaultAvatar);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupUserProfile, setShowPopupUserProfile] = useState(false);
    const [showPopupUserProfileStatistics, setShowPopupUserProfileStatistics] = useState(false);
    const [showWalletModal, setShowWalletModal] = useState(false);
    const [showCurrencyModal, setShowCurrencyModal] = useState(false);
    const [activeSection, setActiveSection] = useState('deposit');
    const [haveData, setHaveData] = useState('');
    const [data, setData] = useState([]);
    const [currentLevel, setCurrentLevel] = useState('vip 0');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [nextLevel, setNextLevel] = useState('vip 1');
    const [progress, setProgress] = useState(0);
    const [xpNeeded, setXpNeeded] = useState(1);
    const [currentXP, setCurrentXP] = useState(0);
    const [vipDetails, setVipDetails] = useState(null);
    const [wageredAmount, setWageredAmount] = useState(null);
    const [isUpdatingUsername, setIsUpdatingUsername] = useState(false);
    const [newUsername, setNewUsername] = useState(currentName);
    const [updateError, setUpdateError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleUserInfoClick = () => {
        // Fetch user info when opening modal
        socket.emit(C.USER_INFO, encode({
            id: getUID,
            coin: storage.getKey('coin') ? storage.getKey('coin') : '',
            rate: null,
            game: 'all',
            first: true
        }));
        // setShowUserModal(true);
        // setIsOpen(false);
    };



    const ask = () => {
        Swal.fire({
            title: 'Are you sure to logout?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.value) {
                socket.emit(C.LOGOUT_USER);
                localStorage.clear();
                Cookies.remove('session');
                Cookies.remove('auth');
                Cookies.remove('uid');
                window.location.replace('./');
            }
        });
    };
    const handleToggle = () => {
        setIsOpenMenu(prev => !prev);
    };

    const handleUsernameChange = (event) => {
        setNewUsername(event.target.value);
    };

    const updateUsername = async () => {
        if (!newUsername.trim()) {
            setUpdateError('Username cannot be empty');
            return;
        }

        setIsUpdatingUsername(true);
        setUpdateError(null);

        try {
            const response = await fetch('https://api.skyla.game/editProfile', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: displayUid,
                    username: newUsername.trim()
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update username');
            }

            const data = await response.json();
            console.log("data is", data);

            // Update local storage and state with new username
            storage.setKey('name', newUsername);
            setName(newUsername);
            setShowEditUi(false);

            // Refresh user info
            socket.emit(C.USER_INFO, encode({
                id: getUID,
                coin: storage.getKey('coin') ? storage.getKey('coin') : '',
                rate: null,
                game: 'all',
                first: true
            }));

        } catch (error) {
            setUpdateError('Failed to update username. Please try again.');
        } finally {
            setIsUpdatingUsername(false);
        }
    };

    const getUserInfo = (data) => {
        if (data.status) {
            console.log("data received", data);
            setHaveData('ok');
            setData(data);

            let amount = parseFloat(data?.profit).toFixed(4);
            if (amount) {
                setWageredAmount(amount);
                const vipInfo = getVipLevelDetails(amount);
                setVipDetails(vipInfo);
                animateProgress(parseFloat(vipInfo.completionPercentage));
            }
        } else {
            setHaveData('notFound');
        }
    };

    const animateProgress = (targetProgress) => {
        let currentProgress = 0;
        const duration = 1000; // 1 second animation
        const startTime = performance.now();

        const animate = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);

            currentProgress = progress * targetProgress;
            setProgress(currentProgress);

            if (progress < 1) {
                progressAnimationRef.current = requestAnimationFrame(animate);
            }
        };

        progressAnimationRef.current = requestAnimationFrame(animate);
    };

    const handleTogglePopup = () => {
        console.log("get ui", getUID);
        socket.emit(C.USER_INFO, encode({
            id: getUID,
            coin: storage.getKey('coin') ? storage.getKey('coin') : '',
            rate: null,
            game: 'all',
            first: true
        }));
        setShowPopup(prev => !prev);
        setShowEditUi(false);
    };

    const handleTogglePopupProfile = () => {
        console.log("profile");
        setShowPopupUserProfile(prev => !prev);
    };

    const handleTogglePopupProfileStatistics = () => {
        setShowPopupUserProfileStatistics(prev => !prev);
    };

    const toggleModal = () => {
        setIsModalOpen(prev => !prev);
    };

    const support = () => {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = CRISTP_ID;

        if (!loaded) {
            console.log("support");
            setLoaded(true);
            const script = document.createElement('script');
            script.src = "https://client.crisp.chat/l.js";
            script.id = "chtt";
            script.async = true;
            document.head.appendChild(script);
        }

        addNewStyle("#crisp-chatbox {display:block !important;}");
        addNewStyle(
            ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
        );

        wait(2000).then(() => {
            const close = document.querySelector(".cc-wdhl");
            if (close === null) return;
            close.addEventListener("click", function (event) {
                addNewStyle("#crisp-chatbox {display:none !important;}");
                document.getElementById("chtt").src = "";
            });
        });
    };

    // ComponentDidMount equivalent
    useLayoutEffect(() => {
        socket.on(C.USER_INFO, data => getUserInfo(decode(data)));

        // ComponentWillUnmount equivalent
        return () => {
            if (progressAnimationRef.current) {
                cancelAnimationFrame(progressAnimationRef.current);
            }
            socket.off(C.USER_INFO);
        };
    }, []);

    const handleStatisticsClick = () => {
        setIsOpen(false);
    };

    const handleTransactionsClick = () => {
        history.push("/transactions");
        setIsOpen(false);
    };

    const handleSupportClick = () => {
        support();
        setIsOpen(false);
    };

    const handleAffiliateClick = () => {
        history.push("/affiliate");
        setIsOpen(false);
    };

    const handleWalletClick = () => {
        history.push("/wallet");
        setIsOpen(false);
    };

    const handleSwapClick = () => {
        history.push("/swap");
        setIsOpen(false);
    };

    const handleVaultProClick = () => {
        history.push("/vault-pro");
        setIsOpen(false);
    };

    const handleRakebackClick = () => {
        history.push("/rakeback");
        setIsOpen(false);
    };

    const handleVipClubClick = () => {
        history.push("/vip-club");
        setIsOpen(false);
    };

    const handleGlobalSettingsClick = () => {
        history.push("/global-settings");
        setIsOpen(false);
    };

    const handleLogoutClick = () => {
        setShowLogoutModal(true);
    };


    const handleBack = () => {
        history.goBack();
    };

    // const formattedCredit = forceSatoshiFormat(storage.getKey('credit'), storage.getKey('coin'), storage.getKey('usd') === "true" ? true : false);

    const handleLogout = () => {
        socket.emit(C.LOGOUT_USER);
        localStorage.clear();
        Cookies.remove('session');
        Cookies.remove('auth');
        Cookies.remove('uid');
        window.location.replace('./');
    };

    return (
        <Container>
            <BackButton onClick={handleBack} />
            <Header>
                <div style={{width:"100%", display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <ProfileSection>
                    <ProfileImage src="//img2.distributedresourcestorage.com/avatar/57268122/s?t=0" alt="Profile" />
                    <ProfileInfo>
                        <h2>{name}</h2>
                        <p>ID:{getUID}</p>
                    </ProfileInfo>
                </ProfileSection>
                <div onClick={()=>setShowUserModal(!showUserModal)}>
                    <ChevronRight/>
                </div>
                </div>

                {/* <VipSection>
          <VipStatus>
            <VipText>VIP 0</VipText>
          </VipStatus>
          <VipProgress>1 XP to VIP 1</VipProgress>
        </VipSection> */}



            </Header>

            <InnerContainer>

                {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:"4px" }}>
                    <BalanceSection>
                        <BalanceIcon src="https://bc.game/modules/account2/assets/balance-e766e5d4.png" alt="Balance" />
                        <BalanceText>Total Balance </BalanceText>
                    </BalanceSection>

                    <div>${0.00}</div>

                </div> */}
                <Card>
                    <ButtonGroup>
                        <ActionButton onClick={()=>setOpenDeposit(!openDeposit)} primary>

                            Deposit
                        </ActionButton>
                        <ActionButton onClick={()=>history.push('/withdraw-mobile')}>

                            Withdraw
                        </ActionButton>
                    </ButtonGroup>

                    <QuickActions>
                        <QuickActionItem onClick={handleTransactionsClick}>
                            <ActionIcon src="/assets/images/nanoicons/transactions.png" alt="Transactions" />
                            <ActionText>Transactions</ActionText>
                        </QuickActionItem>
                        <QuickActionItem onClick={handleSwapClick}>
                            <ActionIcon src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />
                            <ActionText>Swap</ActionText>
                        </QuickActionItem>
                        <QuickActionItem onClick={handleVaultProClick}>
                            <ActionIcon src="/assets/images/nanoicons/vault.png" alt="Vault" />
                            <ActionText>Vault Pro</ActionText>
                        </QuickActionItem>
                        <QuickActionItem onClick={handleWalletClick} >
                            <Wallet2 color="#fff" size={18} />
                            <ActionText>Wallet</ActionText>
                        </QuickActionItem>
                    </QuickActions>

                    <QuickActions>
                        {/* <QuickActionItem onClick={handleWalletClick} >
                            <Wallet2 color="#fff" size={18} />
                            <ActionText>Wallet</ActionText>
                        </QuickActionItem> */}
                        {/* <QuickActionItem>
                            <ActionIcon src="https://bc.game/modules/account2/assets/rollover.png" alt="Rollover" />
                            <ActionText>Rollover</ActionText>
                        </QuickActionItem>
                        <QuickActionItem>
                            <ActionIcon src="https://bc.game/modules/account2/assets/history.png" alt="Bet History" />
                            <ActionText>Bet History</ActionText>
                        </QuickActionItem> */}
                    </QuickActions>
                </Card>

                <Card>
                    <MenuItem>
                        <MenuLeft>
                            <NotificationsSkyla />
                            <MenuText>Notification</MenuText>
                        </MenuLeft>
                        <MenuRight>
                            <NotificationBadge>1</NotificationBadge>
                            <IconBg>
                                <ChevronRight />
                            </IconBg>
                        </MenuRight>
                    </MenuItem>

                    <MenuItem onClick={handleAffiliateClick} noBorder>
                        <MenuLeft>
                            <AffilateIcon />
                            <MenuText>Affiliate</MenuText>
                        </MenuLeft>
                        <IconBg>
                            <ChevronRight />
                        </IconBg>
                    </MenuItem>
                </Card>

                <Card>
                    <MenuItem onClick={handleGlobalSettingsClick}>
                        <MenuLeft>
                            <MenuIcon src="/assets/images/normal-seetings.png" alt="Settings" />
                            <MenuText>Global Setting</MenuText>
                        </MenuLeft>
                        <MenuRight>
                            <NotificationBadge>1</NotificationBadge>
                            <IconBg>
                                <ChevronRight />
                            </IconBg>
                        </MenuRight>
                    </MenuItem>
                    <MenuItem>
                        <MenuLeft>
                            <WhiteCurrencyIcon />
                            <MenuText>Currency</MenuText>
                        </MenuLeft>
                        <MenuRight>
                            <MenuLabel>INR</MenuLabel>
                            <IconBg>
                                <ChevronRight />
                            </IconBg>
                        </MenuRight>
                    </MenuItem>

                </Card>
                <Card>

                    <MenuItem onClick={support}>
                        <MenuLeft>
                            <MenuIcon src="/assets/images/nanoicons/liveSupport.png" alt="Currency" />
                            <MenuText>Live Support</MenuText>
                        </MenuLeft>
                        <MenuRight>

                            <IconBg>
                                <ChevronRight />
                            </IconBg>
                        </MenuRight>
                    </MenuItem>

                </Card>



                {/* <ReferralSection>
                    <ReferralText>
                        <img src="https://bc.game/modules/account2/assets/gift.png" alt="Gift" />
                        Referral & get $1,000.00 + 15% commission
                    </ReferralText>
                    <CopyButton>Copy</CopyButton>
                </ReferralSection> */}
                <LogoutButton onClick={handleLogoutClick}>
                    <ButtonIcon src="/assets/images/nanoicons/logout.png" alt="Logout" />
                    <span>Logout</span>
                </LogoutButton>

            
            </InnerContainer>

            <StyledModal show={showUserModal} onHide={() => setShowUserModal(false)} centered>
                <ModalHeader>
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>User Profile</div>
                    <button className="close-button" onClick={() => {
                        setShowUserModal(false)
                        setShowEditUi(false)
                    }}>
                        <X size={24} />
                    </button>
                </ModalHeader>
                <ModalBody>

                    {
                        showEditUi ? (
                            <div style={{ width: "100%", height: '50vh' }}>
                                <div>
                                    <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img style={{ marginTop: '10px', width: '80px', height: '80px', borderRadius: '50%' }} src="https://img2.distributedresourcestorage.com/avatar/57268122/s?t=0" />


                                    </div>


                                </div>
                                <InputContainer>
                                    <Label>Username</Label>
                                    <Input
                                        type="text"
                                        value={newUsername}
                                        onChange={handleUsernameChange}
                                        placeholder="Enter new username"
                                        disabled={isUpdatingUsername}
                                    />
                                </InputContainer>
                                <Label>Do not use special punctuation, otherwise your account may not be supported.</Label>
                                {/* {updateError && <Label style={{ color: 'red' }}>{updateError}</Label>} */}

                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <StyledButtonNew
                                        type="button"
                                        onClick={updateUsername}
                                        disabled={isUpdatingUsername}
                                    >
                                        {isUpdatingUsername ? 'Updating...' : 'Modify'}
                                    </StyledButtonNew>
                                </div>
                            </div>) : (
                            <div style={{ padding: '20px 0' }}>
                                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                                    <img
                                        src="//img2.distributedresourcestorage.com/avatar/57268122/s?t=0"
                                        style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '10px' }}
                                        alt="Profile"
                                    />
                                    <h3>{name}</h3>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItems: "center" }}>
                                        <Heart size={16} color="#ff6b6b" />
                                        <span>Level {data.level}</span>
                                        <Pencil onClick={() => setShowEditUi(true)}>
                                            <PencilIcon size={10} />
                                        </Pencil>
                                    </div>

                                </div>
                                {/* <div style={{ backgroundColor: '#1E2024', padding: '20px', borderRadius: '15px' }}>
                                    <h4 style={{ marginBottom: '15px' }}>Medals {data.medal}</h4>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
                                        <img src="https://static.nanogames.io/assets/achieve_1.c87ad7ad.png" style={{ width: '44px', height: '63px' }} alt="Medal" />
                                        <img src="https://static.nanogames.io/assets/achieve_3.bf42dfa3.png" style={{ width: '44px', height: '63px' }} alt="Medal" />
                                        <img src="https://static.nanogames.io/assets/achieve_4.c845bc7e.png" style={{ width: '44px', height: '63px' }} alt="Medal" />
                                    </div>
                                </div> */}

                                <div style={{ backgroundColor: '#1E2024', padding: '20px', borderRadius: '15px', marginTop: '20px' }}>
                                    <h4 style={{ marginBottom: '15px' }}>Statistics</h4>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }}>
                                        <StatBox>
                                            <div className="stat-label">Total Wins</div>
                                            <div className="stat-value">{data.wined}</div>
                                        </StatBox>
                                        <StatBox>
                                            <div className="stat-label">Total Bets</div>
                                            <div className="stat-value">{data?.played}</div>
                                        </StatBox>
                                        <StatBox>
                                            <div className="stat-label">Total Wagered</div>
                                            <div className="stat-value">{data?.profit}</div>
                                        </StatBox>
                                    </div>
                                </div>


                            </div>
                        )
                    }

                </ModalBody>
            </StyledModal>

            <ModalOverlay show={openDeposit}>
                    <ModalContent>
                        <ModalHeader1>
                            <ModalTitle>Deposit </ModalTitle>
                            <CloseButton onClick={()=>setOpenDeposit(!openDeposit)}>
                                <X size={24} />
                            </CloseButton>
                        </ModalHeader1>
                        <ModalBody1>
                         
                            <SkylaDepositModal/>
                        </ModalBody1>
                    </ModalContent>
                </ModalOverlay>

                <LogoutModal show={showLogoutModal} onHide={() => setShowLogoutModal(false)} centered>
                    <ModalHeaderLogout>
                        <div className="header-title">Sign Out</div>
                        <button className="close-button" onClick={() => setShowLogoutModal(false)}>
                            <X size={20} color="white" />
                        </button>
                    </ModalHeaderLogout>
                    <LogoutModalBody>
                        <div style={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            height: '100%',
                            padding: '0 1rem'
                        }}>
                            <p style={{ 
                                fontSize: '14px', 
                                marginBottom: '1rem', 
                                color: 'white',
                                textAlign: 'center'
                            }}>
                                Are you sure you want to log out? Check our great promotions and bonuses before you leave!
                            </p>
                            <LogoutButtonContainer>
                                <LogoutButton1 
                                    className="cancel"
                                    onClick={() => setShowLogoutModal(false)}
                                >
                                    Cancel
                                </LogoutButton1>
                                <LogoutButton1 
                                    className="signout"
                                    onClick={handleLogout}
                                >
                                    Sign Out
                                </LogoutButton1>
                            </LogoutButtonContainer>
                        </div>
                    </LogoutModalBody>
                </LogoutModal>
        </Container>
    );
};

export default SkylaMobileUserProfile;