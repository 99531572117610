import React, { useState } from 'react';
import { Crown, Trophy, Star, Users, Award, Gift, Shield, Clock, Globe } from 'lucide-react';
import styled, { keyframes } from 'styled-components';

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
`;

const shine = keyframes`
  0% { background-position: 200% center; }
  100% { background-position: -200% center; }
`;

const PageContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1d1e 0%, #232728 100%);
  padding: 2rem;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  background: linear-gradient(135deg, rgba(50,55,56,0.9) 0%, rgba(74,83,84,0.9) 100%);
  border-radius: 24px;
  padding: 3rem;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, #52EC82, #89E977, #52EC82);
    z-index: -1;
    border-radius: 24px;
    opacity: 0.3;
    animation: ${shine} 3s linear infinite;
  }
`;

const IconContainer = styled.div`
  width: 120px;
  height: 120px;
  margin: 0 auto 2rem;
  position: relative;
`;

const MainIcon = styled.div`
  width: 100%;
  height: 100%;
  background: #292D2E;
  border: 5px solid rgba(82,236,130,0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 110px;
    object-fit: contain;
  }
`;

const FloatingIcon = styled.div`
  position: absolute;
  animation: ${float} 3s infinite ease-in-out;
  color: #52EC82;
  
  &:nth-child(2) { top: 0; left: 0; }
  &:nth-child(3) { top: 0; right: 0; }
  &:nth-child(4) { bottom: 0; left: 0; }
`;

const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #ffffff, #52EC82, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

const Description = styled.p`
  color: rgba(255,255,255,0.8);
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.6;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid rgba(82,236,130,0.3);
  background: rgba(41,45,46,0.8);
  color: white;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #52EC82;
  }
`;

const Button = styled.button`
  background: linear-gradient(to right, #9FE871, #24EE89);
  color: black;
  border: none;
  border-radius: 12px;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
`;

const ClubMembershipPage = () => {
  const [formData, setFormData] = useState({
    clubName: '',
    description: '',
    maxMembers: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <PageContainer>
      <ContentWrapper>
        <IconContainer>
          <MainIcon>
            <img src="/assets/images/lg.png" alt="Club logo" />
          </MainIcon>
          <FloatingIcon><Trophy size={24} /></FloatingIcon>
          <FloatingIcon><Star size={24} /></FloatingIcon>
          <FloatingIcon><Award size={24} /></FloatingIcon>
        </IconContainer>

        <Title>Create Your Club</Title>
        <Description>
          Start your own club and become the Club Owner! Build your community and earn rewards together.
        </Description>

        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Club Name"
            value={formData.clubName}
            onChange={(e) => setFormData({...formData, clubName: e.target.value})}
          />
          <Input
            as="textarea"
            placeholder="Club Description"
            value={formData.description}
            onChange={(e) => setFormData({...formData, description: e.target.value})}
            style={{minHeight: '100px'}}
          />
          <Input
            type="number"
            placeholder="Maximum Members"
            value={formData.maxMembers}
            onChange={(e) => setFormData({...formData, maxMembers: e.target.value})}
          />
          <Button type="submit">Create Club</Button>
        </Form>
      </ContentWrapper>
    </PageContainer>
  );
};

export default ClubMembershipPage;