import React from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import InPlayBetMobile from './components/InplayMobile';
import BookmakerMarketMobile from './components/BookMarketMobile';
import FancyBetMobile from './components/FancyBetMobile';

// Styled Components
const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
`;

const Header = styled.div`
  background: #2c3e50;
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const BannerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.1)
  );
  pointer-events: none;
`;

const MatchTitle = styled.div`
  padding: 15px;
  background: white;
  color: #2c3e50;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid #eee;
`;

const BackButton = styled.button`
  position: absolute;
  top: 2px;
  left: 6px;  // Changed from left to right
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;

  svg {
    color: white;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.7);
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const MobileCricketBettingUI = () => {
  const location = useLocation();
  const history = useHistory();
  const matchData = location.state?.matchData;

  return (
    <>
      <Container>
        <Header>
          <BackButton onClick={() => history.goBack()}>
            <ArrowLeft />
          </BackButton>
          <BannerImage 
            src="/assets/images/sports-b.png" 
            alt="Sports Banner"
            loading="lazy"
          />
          <BannerOverlay />
        </Header>
        {matchData && (
          <MatchTitle>
            {matchData.team1} v {matchData.team2}
          </MatchTitle>
        )}
      </Container>
      <InPlayBetMobile data={matchData}/>
      <BookmakerMarketMobile data={matchData}/>
      <FancyBetMobile/>
    </>
  );
};

export default MobileCricketBettingUI;