import React from 'react';
import styled from 'styled-components';

const SVGWrapper = styled.svg`
  width: 32px;
  height: 32px;
`;

const SecondaryPath = styled.path`
  fill: white;
`;

const BrandPath = styled.path`
  fill: #00ff00;
`;

const RacingIcon = () => {
  return (
    <SVGWrapper 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 32 32"
      aria-hidden="true"
    >
      <SecondaryPath d="M26.464 25.386c-0.038-0.292-0.133-0.565-0.436-0.766-0.095-0.273-0.341-0.656-0.455-0.93-0.455-1.312-1.175-2.242-1.725-2.698-0.209 0.365-1.27 0.565-1.706 0.51 0.455 0.346 0.701 0.729 1.175 1.057 0.758 0.729 1.156 1.458 1.137 2.096-0.076 0.401 0.152 1.477 0.72 1.659 0.265 0.492 0.55 0.857 0.891 1.13 0.701 0.219 1.232 0.2 1.555-0.036-0.076-0.328-0.265-0.62-0.55-1.021 0.057-0.31-0.133-0.656-0.626-0.984l0.019-0.018z" />

      <SecondaryPath d="M7.885 19.644c-0.493-0.31-0.683-0.966-0.815-1.44-0.815 0.729-0.815 2.424-1.99 3.026-0.398 0.292-0.55 0.456-0.512 0.802-0.417 0.82-0.853 1.586-1.365 2.133-0.834 0.2-1.327 0.784-1.422 1.805 0.55 0.2 1.024 0.328 1.232 0.31 0.303 0 0.607-0.109 0.758-0.401 0.417-0.547 0.322-1.477 1.346-2.26 0.265-0.109 0.588-0.438 0.967-1.003 0.284-0.273 0.645-0.583 0.891-0.838 0.645-0.729 1.1-0.93 1.46-1.148-0.171-0.492-0.284-0.747-0.55-0.984z" />

      <SecondaryPath d="M19.82 7.815c0.165 0 0.354 0.045 0.519 0.068l0.448 0.159c0.306 0.363 0.495 0.521 0.589 0.499 0.094 0 0.165-0.113 0.189-0.272 0.094-0.023 0.141-0.091 0.094-0.227 0.118 0.045 0.212 0.068 0.283-0.227 0.071 0.023 0.141 0.045 0.236 0.068 0.023 0 0.071-0.204 0.094-0.635l0.472-0.363-0.802-0.567-1.509-0.68c-0.377-0.023-0.566-0.023-0.613 0-0.094 0-0.094 0.136-0.047 0.476 0.236 0.862 0.236 1.428 0 1.723l0.047-0.023z" />

      <BrandPath d="M22.872 6.95c0.022 0.006 0.037 0.005 0.037 0.005l0.019-0.042c-0.015 0.016-0.033 0.029-0.056 0.038zM21.041 7.29l0.017 0.033c-0.077 0.149-0.112 0.296-0.104 0.44-0.075 0.19-0.020 0.394 0.117 0.532-0.129 0.007-0.476-0.037-0.47-0.679-0.303-0.025-0.456-0.058-0.694-1.577-0.021-0.005-0.037-0.011-0.052-0.016-0.032-0.011-0.057-0.020-0.101-0.017-0.109-0.015-0.155-0.074-0.159-0.136s0.014-0.125 0.057-0.127c0.129-0.379 0.285-0.657 0.465-0.894 0.435-0.589 1.337-0.568 1.696-0.443 1.227 0.485 1.028 1.776 0.954 2.255-0.015 0.097-0.025 0.161-0.017 0.178 0.031 0.076 0.085 0.102 0.12 0.111-0.069 0.027-0.182 0.022-0.395-0.014-0.195-0.031-0.351-0.106-0.486-0.222-0.201-0.134-0.466-0.244-0.774-0.352 0.211 0.299 0.418 0.536 0.526 0.531-0.389 0.121-0.636 0.254-0.702 0.396z" />

      <SecondaryPath d="M29.686 13.12c0.209 0.201 0.322 0.474 0.417 0.747v-0.018c0.247 0.45 0.048 0.574-0.138 0.69-0.085 0.053-0.168 0.104-0.203 0.185v0.164c-0.057 0.274-0.246 0.365-0.474 0.365-0.076 0-0.167-0.029-0.257-0.058-0.114-0.036-0.227-0.072-0.311-0.052-0.398 0.128-0.474-0.164-0.455-0.365 0.060-0.291-0.125-0.396-0.376-0.537-0.22-0.125-0.491-0.278-0.686-0.611-0.104-0.218-0.214-0.341-0.328-0.437 0.009-0.010 0.018-0.022 0.026-0.037 0.512-0.911 1.232-1.713 1.99-2.096 0.112-0.054 0.118-0.248 0.061-0.394 0.072 0.135 0.042 0.278-0.023 0.43 0.152 0.547 0.322 1.094 0.626 1.622 0.095 0.128 0.133 0.219 0.133 0.273v0.128z" />
    </SVGWrapper>
  );
};

export default RacingIcon;