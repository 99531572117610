import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ChevronRight, ChevronDown, Search, Info, FileQuestion, X } from "lucide-react";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import coins from "../../../coins";
import socket from "../../../../Socket";
import { Col, Dropdown } from "react-bootstrap";
import {
  __, wait, decode, encode, forceSatoshiFormat, Event,
  isMobile,
  sendNotfication,
  getUID,
} from '../../../../Helper';
import axios from 'axios';
import { useHistory } from 'react-router-dom';


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

const ModalContent = styled.div`
  background-color: #232626;
  border-radius: 12px;
  width: 100%;
  max-width: 470px;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  min-height: 400px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    border-radius: 0;
  }

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalHeader = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #2A2D35;
  background-color: #323738;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const ModalTitle = styled.h4`
  color: white;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  grid-column: 2;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #6F767E;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 3;
  margin-left: auto;
  
  &:hover {
    color: white;
  }
`;

const ModalBody = styled.div`
  padding: 24px;
  color: white;
  overflow-y: auto;
  flex-grow: 1;
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #2A2D35;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #454951;
    border-radius: 3px;
  }
`;

const Container = styled.div`
  padding: 8px;
  color: #ffffff;
`;

const DropdownContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  flex: 1;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 10px;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  margin-top: 4px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  padding: 10px 12px;
`;

const SearchContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px solid rgb(58 65 66);
  margin-top: 12px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 6px 12px 6px 40px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  
  &::placeholder {
    color: #fff;
    opacity: 0.6;
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 14px;
  top: 49%;
  transform: translateY(-50%);
  color: #fff;
`;

const Input = styled.input`
  width: 100%;
  padding: 6px 10px;
  background: #292D2E;
  margin-bottom: 12px;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  
  &::placeholder {
    color: #fff;
    opacity: 0.4;
  }
`;

const CoinOption = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  cursor: pointer;
  border-radius: 12px;
  
  &:hover {
    background: #323738;
  }
  
  ${props => props.isSelected && `
    background: #323738;
  `}
`;

const CoinImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 12px;
`;

const CoinName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const WithdrawContainer = styled.div`
  margin: 0 auto;
  padding: 24px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  ${props => props.isGreen && `
    color: #20EE89;
    font-size: 16px;
    font-weight: 500;
  `}
  
  ${props => props.isGray && `
    color: #9ba1a6;
    font-size: 14px;
  `}
`;

const Amount = styled.div`
  ${props => props.isGreen && `
    color: #20EE89;
  `}
  font-size: ${props => props.large ? '16px' : '14px'};
  font-weight: ${props => props.bold ? '600' : '400'};
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  color: #9ba1a6;
  
  &:hover {
    opacity: 1;
  }
`;

const DepositButton = styled.button`
  margin-top: auto;
  width: 100%;
  padding: 7px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #9FE871, #24EE89);
  color: black;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  
  &:hover {
    opacity: 0.9;
  }
`;

const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 16px auto;
`;

const WarningText = styled.span`
  color: #fff;
  font-size: 13px;
  font-weight: 500;
`;

const cryptoOptions = [
  { id: 'dot', name: 'DOT', iconClass: 'dot' },
  { id: 'link', name: 'LINK', iconClass: 'link' },
  { id: 'dai', name: 'DAI', iconClass: 'dai' },
  { id: 'usdc', name: 'USDC', iconClass: 'usdc' },
  { id: 'xmr', name: 'XMR', iconClass: 'xmr' },
];

const tokens = [
  { id: 'eth', name: 'ETH', icon: '/assets/images/ETH.png' },
  { id: 'btc', name: 'BTC', icon: '/assets/images/BTC.png' },
  { id: 'usdt', name: 'USDT', icon: '/assets/images/USDT.png' },
  { id: 'usdc', name: 'USDC', icon: '/assets/images/USDC.png' },
  { id: 'doge', name: 'DOGE', icon: '/assets/images/DOGE.png' },
];

const SkylaMobileWithdraw = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNetwork, setIsOpenNetwork] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNetwork, setSelctedNetwork] = useState("ETherum")

  // Coin and credit state
  const [credits, setCredits] = useState({});
  const [selectedCoin, setSelectedCoin] = useState(storage.getKey('coin') || "INR");
  const [activeCoins, setActiveCoins] = useState(
    storage.getKey('active_coins')?.split(',').map(String) || ['1']
  );

  // Main component state
  const [state, setState] = useState({
    currentCoin: storage.getKey('coin') || "INR",
    credits: {},
    list: [],
    content: [],
    wallet: "btc",
    deposit: true,
    slide: false,
    height: 573,
    margin: "mt-1",
    amount: "",
    coinId: "",
    networks: [],
    selectedNetwork: "",
    qrString: "",
    coinName: "",
    logoUrl: "",
    isLoading: false,
    isCopied: false,
    depositAddress: "",
    depositAmount: "",
    showDepositDetails: false
  });
  const [copied, setCopied] = React.useState(false);
  const address = "0x5e6E82fc1117AE06c8b1230164284Ec5d0141Df";

  const handleCopy = () => {
    navigator.clipboard.writeText(address);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Utility Functions
  const getCoinNameByNetwork = useCallback((network, coinInfo, defaultCoin) => {
    if (networkCoinMap[network]) {
      return networkCoinMap[network];
    }
    if (coinInfo && coinInfo.id) {
      return coinInfo.id;
    }
    return defaultCoin.toLowerCase();
  }, []);

  // Socket and Credit Management
  const getCreditAndCoins = useCallback((data) => {
    const { credit } = data;
    setState(prev => ({ ...prev, credits: credit }));
    setUpCoins();
  }, []);

  const setUpCoins = useCallback(() => {
    if (isMobile()) {
      setState(prev => ({ ...prev, height: 685, margin: "mt-1" }));
    }

    const newList = coins.reduce((acc, item, i) => {
      if (item.preffix === "NC") return acc;

      const credit = forceSatoshiFormat(
        state.credits[__.lowerCase(item.preffix)]
      );

      const listItem = (
        <Dropdown.Item
          key={__.toString(i)}
          as="button"
          className="animated fadeIn"
          onClick={() => loadCoinId(item.preffix)}
        >
          <span>
            <img
              src={`/assets/images/${item.image}`}
              className="img-fluid mini-coin mr-1"
              alt="Coin"
            />
            {item.preffix}
          </span>
        </Dropdown.Item>
      );

      return [...acc, listItem];
    }, []);

    setState(prev => ({ ...prev, list: newList }));
  }, [state.credits, isMobile]);

  // Archive Management
  const showArchive = useCallback(() => {
    if (state.slide) Event.emit("deposit_archive_back");

    setState(prev => ({ ...prev, slide: !prev.slide }));

    wait(300).then(() => {
      setState(prev => ({ ...prev, deposit: !prev.deposit }));
    });

    wait(300).then(() => {
      loadCoinId("BTC");
    });
  }, [state.slide]);

  // Coin Loading and Management
  const loadCoinId = useCallback(async (coin) => {
    if (coin.toUpperCase() === 'INR') {
      console.log("Coin is INR, skipping loadCoinId process.");
      return;
    }

    setState(prev => ({
      ...prev,
      currentCoin: coin,
      coinId: "",
      networks: [],
      selectedNetwork: "",
      isLoading: true,
      coinName: "",
      logoUrl: ""
    }));

    console.log("coin", coin)

    try {
      const response = await axios.post('https://api.skyla.game/getCoinDetails', {
        symbol: coin.toUpperCase()
      });


      console.log("infor", response)

      const { coinId, networks, coinFullName, logoUrl } = response.data;
      let networksList = Object.keys(networks);

      const coinInfo = coinsList.find(item => item.symbol === coin.toUpperCase());
      let selectedNetwork = networksList[0] || "";
      let coinName = coinInfo ? coinInfo.id : coin.toLowerCase();

      if (coin.toUpperCase() === 'USDT') {
        networksList = ['ETH', 'TRX', 'BSC'];
      }

      coinName = getCoinNameByNetwork(selectedNetwork, coinInfo, coin);

      setState(prev => ({
        ...prev,
        coinId,
        networks: networksList,
        selectedNetwork,
        isLoading: false,
        coinName,
        logoUrl
      }));
    } catch (error) {
      console.error("Error fetching coin details:", error);
      sendNotfication("Error fetching coin information", "error", "top-right");
      setState(prev => ({ ...prev, isLoading: false }));
    }
  }, [getCoinNameByNetwork]);

  // Payment Processing
  const initiateDeposit = async () => {
    if (!state.amount || state.amount <= 0) {
      sendNotfication("Please enter a correct amount.", "error", "top-right");
      return;
    }

    setState(prev => ({ ...prev, isLoading: true, qrString: "" }));
    const payload = {
      coinId: 1161,//hardcode value
      price: state.amount,
      orderId: `order${Date.now()}`,
      chain: selectedNetwork,
      generateCheckoutURL: true,
      returnUrl: "https://Skyla.games/",
      userid: getUID.toString()
    }

    console.log("paylod", payload)


    try {
      const response = await axios.post('https://api.skyla.game/createDeposit', payload

      );

      const { data } = response.data;

      if (data.address) {
        const coinName = getCoinNameByNetwork(state.selectedNetwork, null, state.coinName);

        setState(prev => ({
          ...prev,
          depositAddress: data.address,
          depositAmount: state.amount,
          showDepositDetails: true,
          isLoading: false,
          qrString: `${coinName}:${data.address}?amount=${state.amount}`
        }));
      } else {
        sendNotfication("Deposit initiation failed", "error", "top-right");
        setState(prev => ({ ...prev, isLoading: false }));
      }
    } catch (error) {
      console.error("Error initiating deposit:", error);
      sendNotfication("Error initiating deposit", "error", "top-right");
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const initiateUPIPayment = async () => {
    if (state.currentCoin !== 'INR') {
      console.log("UPI payment can only be initiated when the current coin is INR.");
      return;
    }

    if (!state.amount || state.amount <= 0) {
      sendNotfication("Please enter a correct amount.", "error", "top-right");
      return;
    }

    setState(prev => ({ ...prev, isLoading: true }));

    try {
      const response = await axios.post('https://api.skyla.game/createorderupi', {
        uid: getUID.toString(),
        amount: state.amount
      });

      const { data } = response.data;

      if (data && response.data.status) {
        const { payment_url } = data;
        const phonepay = data.upi_intent.phonepe_link;

        setState(prev => ({
          ...prev,
          depositAmount: state.amount,
          isLoading: false,
          qrString: phonepay
        }));

        window.location.href = payment_url;
      } else {
        sendNotfication(response.data.msg || "Error creating UPI order", "error", "top-right");
      }
    } catch (error) {
      console.error("Error initiating UPI payment:", error);
      sendNotfication("Error initiating UPI payment", "error", "top-right");
    } finally {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  };

  // Utility Functions
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setState(prev => ({ ...prev, isCopied: true }));
      sendNotfication("Address copied to clipboard", "success", "top-right");
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const handleAmountChange = (e) => {
    setState(prev => ({ ...prev, amount: e.target.value }));
  };

  const handleNetworkChange = (e) => {
    setState(prev => ({ ...prev, selectedNetwork: e.target.value }));
  };

  // Socket Connection Effect
  useEffect(() => {
    const token = storage.getKey('token');

    if (token) {
      socket.emit(C.CREDIT, encode({ token, coin: selectedCoin }));
    }

    const handleCreditData = (data) => {
      const decodedData = decode(data);
      const { credit } = decodedData;
      setCredits(credit);

      const currentCoin = __.lowerCase(selectedCoin);
      const currentCredit = forceSatoshiFormat(credit[currentCoin]);
      storage.setKey('credit', currentCredit);
    };

    const handleCreditUpdate = (data) => {
      const decodedData = decode(data);
      const { value, coin: updatedCoin } = decodedData;
      const formattedValue = forceSatoshiFormat(value);

      setCredits(prev => ({
        ...prev,
        [__.lowerCase(updatedCoin)]: value
      }));

      if (__.lowerCase(updatedCoin) === __.lowerCase(selectedCoin)) {
        storage.setKey('credit', formattedValue);
      }
    };

    socket.on(C.CREDIT, handleCreditData);
    socket.on(C.UPDATE_CREDIT, handleCreditUpdate);

    return () => {
      socket.off(C.CREDIT, handleCreditData);
      socket.off(C.UPDATE_CREDIT, handleCreditUpdate);
    };
  }, [selectedCoin]);

  // Initial Setup Effect
  useEffect(() => {
    socket.emit(C.CREDIT, encode({ coin: storage.getKey('coin') || "INR" }));
    socket.on(C.CREDIT, (data) => getCreditAndCoins(decode(data)));

    Event.on("deposit_archive", showArchive);

    wait(400).then(() => {
      loadCoinId(storage.getKey('coin') || "INR");
    });

    return () => {
      socket.off(C.CREDIT);
    };
  }, [storage.getKey('coin') || "INR", getCreditAndCoins, loadCoinId, showArchive]);

  // Active Coins Initialization Effect
  useEffect(() => {
    if (!storage.getKey('active_coins')) {
      const defaultActiveCoins = ['1', '2', '3', '4', '5', '6', '7'];
      storage.setKey('active_coins', defaultActiveCoins.join(','));
      setActiveCoins(defaultActiveCoins);
    }
  }, []);

  // Click Outside Handler Effect
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.dropdown-container')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  // Coin Selection Handler
  const handleCoinSelect = async (coinName) => {
    if (storage.getKey('coin') === coinName) {
      setIsOpen(false);
      return;
    }

    storage.setKey('coin', coinName);
    const credit = forceSatoshiFormat(credits[__.lowerCase(coinName)] || 0);
    storage.setKey('credit', credit);

    setSelectedCoin(coinName);
    setIsOpen(false);

    try {
      await wait(200);
      Event.emit('coin_changed', coinName);
    } catch (error) {
      console.error('Error during coin selection:', error);
    }
  };

  // Filtered Coins Logic
  const filteredCoins = coins
    .filter(coin => {
      if (!activeCoins.includes(coin.id.toString())) return false;
      return coin.preffix.toLowerCase().includes(searchTerm.toLowerCase());
    })
    .sort((a, b) => {
      if (a.preffix === 'BTC') return -1;
      if (b.preffix === 'BTC') return 1;
      return 0;
    });

  const selectedCoinData = coins.find(coin => coin.preffix === selectedCoin);
  //statically netwrosk added later will remove it
  const networkDetails = [
    "Etherum", "BEP20"
  ]

  const history1= useHistory()
  return (

   <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>Withdraw</ModalTitle>
          <CloseButton onClick={()=>history1.goBack()}>
            <X size={24} />
          </CloseButton>
        </ModalHeader>

        <ModalBody>
          <Container>
            <div style={{ display: "flex", gap: "5px", width: "100%" }}>
              <div style={{ flex: '1' }}>
                <div style={{ color: "#B3BEC1", margin: "4px 0" }}>Deposit Currency</div>
                <DropdownContainer>
                  <DropdownButton onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(!isOpen);
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <CoinImage src={`/assets/images/${filteredCoins.find(c => c.preffix === selectedCoin)?.image}`} alt={selectedCoin} />
                      <CoinName>{selectedCoin}</CoinName>
                    </div>
                    <ChevronDown size={20} />
                  </DropdownButton>

                  {isOpen && (
                    <DropdownPanel onClick={e => e.stopPropagation()}>
                      <SearchContainer>
                        <SearchIcon>
                          <Search size={20} />
                        </SearchIcon>
                        <SearchInput
                          placeholder="Search coins..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          autoFocus
                        />
                      </SearchContainer>

                      {filteredCoins.map((coin) => (
                        <CoinOption
                          key={coin.id}
                          onClick={() => handleCoinSelect(coin.preffix)}
                          isSelected={selectedCoin === coin.preffix}
                        >
                          <CoinImage src={`/assets/images/${coin.image}`} alt={coin.preffix} />
                          <CoinName>{coin.preffix}</CoinName>
                        </CoinOption>
                      ))}
                    </DropdownPanel>
                  )}
                </DropdownContainer>
              </div>

              <div style={{ flex: '1' }}>
                <div style={{ color: "#B3BEC1", margin: "4px 0" }}>Choose Network</div>
                <DropdownContainer>
                  <DropdownButton onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenNetwork(!isOpenNetwork);
                  }}>
                    <CoinName>{selectedNetwork}</CoinName>
                    <ChevronDown size={20} />
                  </DropdownButton>

                  {isOpenNetwork && (
                    <DropdownPanel onClick={e => e.stopPropagation()}>
                      {networkDetails.map((network) => (
                        <CoinOption
                          key={network}
                          onClick={() => {
                            setSelectedNetwork(network);
                            setIsOpenNetwork(false);
                          }}
                          isSelected={selectedNetwork === network}
                        >
                          <CoinName>{network}</CoinName>
                        </CoinOption>
                      ))}
                    </DropdownPanel>
                  )}
                </DropdownContainer>
              </div>
            </div>

            <div style={{ flex: "1", width: "100%" }}>
              <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ color: "#B3BEC1", margin: "4px 0" }}>Withdrawal Amount</div>
                <div style={{ color: "#B3BEC1", margin: "4px 0" }}>00.002</div>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <Input
                value={state.amount}
                onChange={(e) => setState((prev) => ({ ...prev, amount: e.target.value }))}
                placeholder="Enter Withdraw Amount"
              />
            </div>

            <WithdrawContainer>
              <Row>
                <Label isGreen>Withdraw amount</Label>
                <Amount isGreen large bold>0.00 {selectedCoin}</Amount>
              </Row>

              <Row>
                <Label isGray>
                  Fee:
                  <IconButton>
                    <FileQuestion size={16} />
                  </IconButton>
                </Label>
                <Amount>0.289249 {selectedCoin}</Amount>
              </Row>

              <Row>
                <Label isGray>Total Withdraw amount</Label>
                <Amount>0.00 {selectedCoin}</Amount>
              </Row>
            </WithdrawContainer>

            <DepositButton>Preview</DepositButton>

            <WarningContainer>
              <WarningText>
                For security purposes, large or suspicious withdrawal may take 1-6 hours for audit process. We appreciate your patience!
              </WarningText>
            </WarningContainer>
          </Container>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>

  );
};

export default SkylaMobileWithdraw;