import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const sliderData = [
  {
    id: 1,
    title: "BC.GAME PACHINKO",
    subtitle: "ONLINE NOW",
    image: "/assets/images/bcgames/slider/s1.avif"
  },
  {
    id: 2,
    title: "MIKE TYSON VS JAKE PAUL",
    subtitle: "BET NOW",
      image: "/assets/images/bcgames/slider/s2.avif"
  },
  {
    id: 3,
    title: "LCFC",
    subtitle: "BET GUARANTEED",
      image: "/assets/images/bcgames/slider/s3.avif"
  },
  {
    id: 4,
    title: "SPORTS BETTING",
    subtitle: "PLAY NOW",
     image: "/assets/images/bcgames/slider/s4.avif"
  },
  {
    id: 5,
    title: "LIVE CASINO",
    subtitle: "JOIN NOW",
       image: "/assets/images/bcgames/slider/s1.avif"
  },
  {
    id: 6,
    title: "SLOTS",
    subtitle: "SPIN NOW",
      image: "/assets/images/bcgames/slider/s5.avif"
  },
  {
    id: 7,
    title: "POKER",
    subtitle: "PLAY NOW",
       image: "/assets/images/bcgames/slider/s2.avif"
  },
  {
    id: 8,
    title: "LOTTERY",
    subtitle: "TRY YOUR LUCK",
      image: "/assets/images/bcgames/slider/s4.avif"
  },
  {
    id: 9,
    title: "VIRTUAL SPORTS",
    subtitle: "BET NOW",
       image: "/assets/images/bcgames/slider/s3.avif"
  }
];

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding: 15px 0;
`;

const SlideTrack = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(-${props => props.activeIndex * (props.isMobile ? 100 : 33.33)}%);
`;

const Slide = styled.div`
  flex: 0 0 ${props => props.isMobile ? '100%' : '33.33%'};
  padding: 0 5px;
  box-sizing: border-box;
`;

const SlideContent = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background: linear-gradient(45deg, #2a1f62, #4b367c);
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.8;
`;

const PaginationDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
`;

const Dot = styled.button`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: none;
  background-color: ${props => props.active ? '#4CAF50' : '#666'};
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: ${props => props.active ? '#4CAF50' : '#888'};
  }
`;

const SkylaSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle responsive design for mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calculate total slides based on mobile or desktop view
  const totalSlides = isMobile 
    ? Math.ceil(sliderData.length) 
    : Math.ceil(sliderData.length / 3);

  // Auto-scroll effect
  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((current) => (current + 1) % totalSlides);
    }, 5000);

    return () => clearInterval(timer);
  }, [totalSlides]);

  // Handle dot click navigation
  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <CarouselContainer>
      <SlideTrack activeIndex={activeIndex} isMobile={isMobile}>
        {sliderData.map((slide) => (
          <Slide key={slide.id} isMobile={isMobile}>
            <SlideContent>
              <SlideImage src={slide.image} alt={slide.title} />
            </SlideContent>
          </Slide>
        ))}
      </SlideTrack>
      
      <PaginationDots>
        {[...Array(totalSlides)].map((_, index) => (
          <Dot
            key={index}
            active={index === activeIndex}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </PaginationDots>
    </CarouselContainer>
  );
};

export default SkylaSlider;