import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TabSwitcher from '../components/TabSwitcher';
import CricketBettingUI from '../components/CricketUi';
import BetSlip from '../components/BetSlip';
import CricketSchedule from '../components/Today';
import SportsScheduleTomorrow from '../components/Tomorrow';

// Styled components for the layout
const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
`;

const MainContent = styled.div`
  flex: 0 0 75%;
  overflow-y: auto;
  background-color: #1C1F23;
`;

const Sidebar = styled.div`
  flex: 0 0 25%;
  background-color: white;
  color: #9AA6AC;
  overflow-y: auto;

  h2 {
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    padding: 10px 0;
    cursor: pointer;
    
    &:hover {
      color: #1B8271;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const SampleContent = styled.div`
  background-color: #2A3438;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  color: #9AA6AC;
`;


const ContentContainer = styled.div`
  // padding: 24px;
  width:100%;
  border-radius: 0 0 8px 8px;
  color: #9AA6AC;

`;


const TabContent = styled.div`
  animation: fadeIn 0.3s ease;
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;


const SportsWeb = () => {
  const location = useLocation();
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab || 'in-play');

  // Update activeTab when route changes
  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  const renderContent = () => {
    switch (activeTab) {
      case 'in-play':
        return (
          <TabContent>
            <CricketBettingUI />
          </TabContent>
        );
      case 'today':
        return <CricketSchedule />;
      case 'tomorrow':
        return <SportsScheduleTomorrow />;
      default:
        return (
          <TabContent>
            <CricketBettingUI />
          </TabContent>
        );
    }
  };
const history = useHistory();


const { matchData } = location.state || {};

useEffect(() => {
  if (!matchData) {
    // Handle case when no match data is available
    // Maybe redirect back or show error
  }
}, [matchData]);

console.log(matchData, "match data is ");
 
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    history.push(`/sports/markets/${tab}`);
  };

  return (
    <LayoutContainer>
      <MainContent>
      <TabSwitcher
            activeTab={activeTab}
            onTabClick={handleTabClick}
          />
        <Content>
          <ContentContainer>
            {renderContent()}
          </ContentContainer>
        </Content>
      </MainContent>
      <Sidebar>
        <BetSlip />
      </Sidebar>
    </LayoutContainer>
  );
};

export default SportsWeb;