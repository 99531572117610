import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";
import SkylaMenu from "../../../../Icons/Menu";
import MenuPlayIcon from "../../../../Icons/Menu";
import SearchStarIcon from "../../../../Icons/Search";
import MessageIcon from "../../../../Icons/Chat";
import GlobeIcon from "../../../../Icons/Glob";
import SkylaSidebar from "../../../Components/SkylaSiebar";
import { useSidebar } from "../../../../context/SidebarContext";
import BottomNavigation from "../../../Components/SkylaBottomTab";

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: #292D2E;
  border-bottom: 1px solid #2C2F36;
  height: 63px;
  padding: 12px 16px;
`;

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: ${props => props.showFull ? '128px' : '96px'};
  height: auto;
  transition: width 0.3s ease;
`;

const SearchBarContainer = styled.div`
  display: none;
  flex: 1;
  max-width: 576px;
  margin: 0 16px;
  
  @media (min-width: 768px) {
    display: flex;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const IconButton = styled.button`
  padding: 8px;
  background: transparent;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #2C2F36;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const IconBg = styled.div`
  padding: 6px 8px;
  background-color: #394142;
  border-radius: 10px;
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuIconBg = styled(IconBg)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SignInButton = styled.button`
  padding: 6px 16px;
  background: transparent;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #2C2F36;
  }
`;

const SignUpButton = styled.button`
  padding: 6px 16px;
  background-color: #10B981;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #059669;
  }
`;

const ResponsiveSidebarWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Header = () => {
  const [showLogo, setShowLogo] = useState(window.innerWidth > 768);
  const { isCollapsed, setIsCollapsed } = useSidebar();

  useEffect(() => {
    const handleResize = () => {
      setShowLogo(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCollapse = useCallback(() => {
    setIsCollapsed(prev => !prev);
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <ResponsiveSidebarWrapper>
        <SkylaSidebar isCollapsed={isCollapsed} />
      </ResponsiveSidebarWrapper>

      <HeaderWrapper>
        <HeaderContainer>
          <LeftSection>
            <MenuIconBg onClick={handleCollapse}>
              <MenuPlayIcon />
            </MenuIconBg>
            <LogoContainer to="/">
              <Logo
                src="/assets/images/lg.png"
                alt="Logo"
                showFull={showLogo}
              />
            </LogoContainer>
          </LeftSection>
          <RightSection>
            {/* <IconBg>
              <SearchStarIcon />
            </IconBg> */}
            <AuthButtons>
              <Login />
              {/* <Register /> */}
            </AuthButtons>
            <IconBg>
              <MessageIcon />
            </IconBg>
            <IconBg>
              <GlobeIcon />
            </IconBg>
          </RightSection>
        </HeaderContainer>
      </HeaderWrapper>
      <BottomNavigation />
    </div>
  );
};

export default Header;