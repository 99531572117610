import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { isMobile } from '../../../Helper';

const Container = styled.div`
  width: 100%;
  padding: 0.5rem 0rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const Title = styled.h2`
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const GreenDot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: #24EE89;
  border-radius: 50%;
  margin-right: 0.5rem;
  box-shadow: 0 0 0 0 rgba(36, 238, 137, 0.4);
  animation: pulse 0.9s infinite;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(36, 238, 137, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(36, 238, 137, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(36, 238, 137, 0);
    }
  }
`;
const TabContainer = styled.div`
  margin-left: 0.5rem;
  display: flex;

`;

const TabButton = styled.div`
  padding: 0.1rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  transition: all 0.2s;
  border-bottom: ${props => props.active ? '2px solid #24EE89' : 'none'};
  color: ${props => props.active ? 'white' : '#9ca3af'};
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;

const SliderContainerOuter = styled.div`
  background-color: #292D2E;
  padding: 0 12px;
  border-radius: 12px;
`;

const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #292D2E;
  padding: 12px;
  border-radius: 12px;
`;

const SliderTrack = styled.div`
  display: flex;
  gap: 0.5rem;
  animation: scroll 12s linear infinite;
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }


  @media (max-width: 768px) {
     display: flex;
  gap: 0.5rem;
  animation: scroll 3s linear infinite;
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  }

  
`;

const GameCard = styled.div`
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 2px;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 5rem;
  width: 3.5rem;
  border-radius: 10px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const UserContainer = styled.div`
  text-align: center;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
`;

const UserBadge = styled.img`
  height: 15px;
  width: 15px;
  object-fit: contain;
`;

const Username = styled.div`
  font-size: 9px;
`;

const ProfitAmount = styled.div`
  color: #24EE89;
  font-size: 9px;
`;

const cryptoConfig = {
  'ETH': {
    icon: '/assets/images/ETH.png',
    minBet: 1.00,
    maxBet: 15.00,
    decimals: 2
  },
  'BTC': {
    icon: '/assets/images/BTC.png',
    minBet: 0.1,
    maxBet: 2.3,
    decimals: 1
  },
  'USDT': {
    icon: '/assets/images/USDT.png',
    minBet: 1000,
    maxBet: 110000,
    decimals: 0
  },
  'TRON': {
    icon: '/assets/images/TRON.png',
    minBet: 5000,
    maxBet: 500000,
    decimals: 0
  },
};

const formatProfit = (amount, currency) => {
  if ((currency === 'USDT' || currency === 'TRON') && amount >= 1000) {
    return `${(amount / 1000).toFixed(1)}k ${currency}`;
  }
  return `${amount.toFixed(2)} ${currency}`;
};

const generateRandomProfit = (min, max, decimals) => {
  const factor = 10 ** decimals;
  return ((Math.random() * (max - min) + min) * factor).toFixed(decimals) / factor;
};

const LatestWins = ({ all, bcOriginals, slots, liveCasino }) => {
  const [activeTab, setActiveTab] = useState('all');

  const tabs = [
    { id: 'all', label: 'All', data: all },
    { id: 'bcOriginals', label: 'BC Originals', data: bcOriginals },
    { id: 'slots', label: 'Slots', data: slots },
    { id: 'liveCasino', label: 'Live Casino', data: liveCasino }
  ];

  const activeGames = tabs.find(tab => tab.id === activeTab)?.data || [];
  
  const processedGames = activeGames.map((game) => {
    const currencies = Object.keys(cryptoConfig);
    const currency = currencies[Math.floor(Math.random() * currencies.length)];
    const { minBet, maxBet, decimals } = cryptoConfig[currency];
    const profitAmount = generateRandomProfit(minBet, maxBet, decimals);
    const profit = formatProfit(profitAmount, currency);
    return {
      ...game,
      profit,
      username: 'Hidden'
    };
  });

  const doubledGames = [...processedGames, ...processedGames];

  return (
    <Container>
      <Header>
        <Title>
          <GreenDot />
          Recent Big Wins
        </Title>
     {
      !isMobile() &&(   <TabContainer>
        {tabs.map(tab => (
          <TabButton
            key={tab.id}
            active={activeTab === tab.id}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </TabButton>
        ))}
      </TabContainer>)
     }
      </Header>
      <SliderContainerOuter>
        <SliderContainer>
          <SliderTrack>
            {doubledGames.map((game, index) => (
              <GameCard key={index}>
                <ImageContainer>
                  <img src={game.banner} alt={game.game_name} />
                </ImageContainer>
                <UserContainer>
                  <UserInfo>
                    <UserBadge
                      src='https://bc.game/assets/vip/badge-platinum.png'
                      alt="VIP badge"
                    />
                    <Username>{game.username}</Username>
                  </UserInfo>
                  <ProfitAmount>{game.profit}</ProfitAmount>
                </UserContainer>
              </GameCard>
            ))}
          </SliderTrack>
        </SliderContainer>
      </SliderContainerOuter>
    </Container>
  );
};

export default LatestWins;