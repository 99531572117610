import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradient} 15s ease infinite;

  text-align: center;
  color: white;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
  animation: ${fadeIn} 1s ease-out, ${float} 6s ease-in-out infinite;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 2rem;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out 0.5s forwards;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const Text = styled.p`
  font-size: 1.2rem;
  max-width: 600px;
  line-height: 1.6;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out 1s forwards;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0 20px;
  }
`;

const CountdownContainer = styled.div`
  display: flex;
  gap: 20px;
  margin: 2rem 0;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out 1.5s forwards;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const CountdownItem = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 10px;
  min-width: 80px;
  backdrop-filter: blur(10px);

  @media (max-width: 768px) {
    min-width: 60px;
    padding: 10px;
  }
`;

const Number = styled.div`
  font-size: 2rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Label = styled.div`
  font-size: 0.9rem;
  opacity: 0.8;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const GlobalSearch = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 30,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prev => ({
        ...prev,
        seconds: prev.seconds > 0 ? prev.seconds - 1 : 59,
        minutes: prev.seconds === 0 ? (prev.minutes > 0 ? prev.minutes - 1 : 59) : prev.minutes,
        hours: prev.minutes === 0 && prev.seconds === 0 ? (prev.hours > 0 ? prev.hours - 1 : 23) : prev.hours,
        days: prev.hours === 0 && prev.minutes === 0 && prev.seconds === 0 ? prev.days - 1 : prev.days
      }));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container>
      <Title>Coming Soon</Title>
      <Subtitle>Something Amazing is Coming</Subtitle>
      {/* <CountdownContainer>
        <CountdownItem>
          <Number>{timeLeft.days}</Number>
          <Label>Days</Label>
        </CountdownItem>
        <CountdownItem>
          <Number>{timeLeft.hours}</Number>
          <Label>Hours</Label>
        </CountdownItem>
        <CountdownItem>
          <Number>{timeLeft.minutes}</Number>
          <Label>Minutes</Label>
        </CountdownItem>
        <CountdownItem>
          <Number>{timeLeft.seconds}</Number>
          <Label>Seconds</Label>
        </CountdownItem>
      </CountdownContainer> */}
      <Text>
        We're working hard to bring you something extraordinary. Stay tuned for the big reveal!
      </Text>
    </Container>
  );
};

export default GlobalSearch;