import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { getUID, sendNotfication } from "../../../../Helper";
import axios from "axios";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
  background-color: #323738;
  border-radius: 10px;
`;

const MainContainer = styled.div`
  max-width: 800px;
  width: 100%;
`;

// Stepper styles
const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 1rem;
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const StepLabel = styled.span`
  @media (max-width: 768px) {
    &::before {
      content: "";
    }
  }

  @media (min-width: 769px) {
    &::before {
      content: "Step ";
    }
  }
`;

const StepText = styled.span`
  font-size: 14px;
  transition: all 0.3s ease;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const StepCircle = styled.div`
  min-width: 100px;
  height: 36px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: all 0.3s ease;
  background-color: ${(props) =>
    props.active || props.completed ? "#39ED85" : "transparent"};
  border: 1px solid
    ${(props) => (props.active || props.completed ? "#39ED85" : "#39ED85")};
  color: ${(props) => (props.active || props.completed ? "black" : "#39ED85")};
  padding: 0 10px;

  @media (max-width: 480px) {
    min-width: 40px; /* Reduced from 80px */
    width: auto; /* Allow content to determine width */
    font-size: 11px; /* Reduced font size */
    height: 28px; /* Reduced height */
    border-radius: 25px; /* Increased border radius */
    padding: 0 8px; /* Reduced padding */
  }
`;
const StepLine = styled.div`
  width: 60px;
  height: 1px;
  background-color: ${(props) =>
    props.active || props.completed ? "#39ED85" : "gray"};
  margin: 0 0.5rem;
  transition: all 0.3s ease;

  @media (max-width: 480px) {
    width: 30px;
  }
`;
const Step = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:last-child ${StepLine} {
    display: none;
  }
`;
const UploadDescription = styled.p`
  color: #9ca3af;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: center;
`;

// Content styles
const ContentContainer = styled.div`
  border-radius: 12px;
  padding: 1rem;
  //   background-color: #1e1f23;
  margin-top: 1rem;

  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`;

const Title = styled.h2`
  color: #e5e7eb;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Description = styled.p`
  color: #9ca3af;
  margin-bottom: 2rem;
  line-height: 1.6;
  text-align: center;
  font-size: 0.95rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
`;

// Form styles
const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const FormGroup = styled.div`
  grid-column: ${(props) => (props.fullWidth ? "span 2" : "span 1")};

  @media (max-width: 768px) {
    grid-column: span 1;
  }
`;

const Label = styled.label`
  display: block;
  color: #9ca3af;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  background-color: #2a2d31;
  border: 1px solid ${(props) => (props.error ? "#ff4d4f" : "#374151")};
  border-radius: 19px;
  color: #e5e7eb;
  outline: none;
  transition: all 0.3s ease;
  font-size: 0.95rem;

  &:focus {
    border-color: #da23ff50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }

  &::placeholder {
    color: #6b7280;
  }

  @media (max-width: 768px) {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  background-color: #2a2d31;
  border: 1px solid ${(props) => (props.error ? "#ff4d4f" : "#374151")};
  border-radius: 8px;
  color: #e5e7eb;
  outline: none;
  transition: all 0.3s ease;
  cursor: pointer;

  &:focus {
    border-color: #da23ff50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }

  @media (max-width: 768px) {
    padding: 0.6rem;
  }
`;

const PreviewGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const PreviewContainer = styled.div`
  margin-bottom: 1rem;
`;

const PreviewTitle = styled.p`
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const DocumentName = styled.span`
  font-weight: normal;
  color: #666;
  font-size: 14px;

  @media (max-width: 480px) {
    font-size: 12px;
    display: block;
    margin-top: 0.25rem;
  }
`;

const PreviewImage = styled.img`
  width: 200px;
  margin-top: 0.5rem;
  border-radius: 4px;
  object-fit: cover;

  @media (max-width: 480px) {
    width: 100%;
    height: auto;
    max-height: 200px;
  }
`;

// Checkbox styles
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 1.5rem 0;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #da23ff;
`;

const CheckboxLabel = styled.label`
  color: #e5e7eb;
  font-size: 0.9rem;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const DocPreviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  width: 100%;
  justify-content: center;
  padding: 0 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 0 0.5rem;
  }
`;

// Updated Review section styles for Step 3
const ReviewSection = styled.div`
  color: #e5e7eb;
  padding: 1rem;

  h3 {
    color: #e5e7eb;
    margin-bottom: 1rem;
    font-size: 1.1rem;

    @media (max-width: 480px) {
      font-size: 1rem;
      margin-bottom: 0.75rem;
    }
  }

  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`;

const ReviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const ReviewItem = styled.div`
  p {
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
    word-break: break-word;

    strong {
      color: #9ca3af;
      margin-right: 0.5rem;
      display: inline-block;
      min-width: 100px;
    }

    @media (max-width: 480px) {
      font-size: 0.85rem;
      margin-bottom: 0.5rem;

      strong {
        min-width: 80px;
        margin-right: 0.25rem;
      }
    }
  }
`;

const DocPreview = styled.div`
  margin-top: 1rem;
  width: 100%;

  img {
    max-width: 200px;
    border-radius: 8px;
    margin-top: 0.5rem;

    @media (max-width: 768px) {
      max-width: 100%;
      height: auto;
    }

    @media (max-width: 480px) {
      margin-top: 0.25rem;
    }
  }
`;

// Updated Step 3 document preview container
const DocumentPreviewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  @media (max-width: 480px) {
    margin-top: 1rem;
  }
`;
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 0.75rem;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #2d3036;
  padding: 0.25rem;
  border-radius: 9999px;
  margin: 0 auto 2rem;
  width: 100%;
  max-width: 400px;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
`;

export const ToggleButton = styled.button`
  flex: 1;
  padding: 0.625rem 1.25rem;
  border-radius: 9999px;
  border: none;
  background-color: ${(props) => (props.active ? "#DA23FF10" : "transparent")};
  color: ${(props) => (props.active ? "#ffffff" : "#9ca3af")};
  transition: all 0.2s ease;
  font-size: 0.875rem;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.8125rem;
  }
`;

export const UploadGrid = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
`;

const UploadContainer = styled.div`
  width: 100%;
  max-width: 280px;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const UploadPreview = styled.div`
  width: 100%;
  //   height: 200px;
  //   background-color: #2D3036;
  border-radius: 0.75rem;
  border: 1px solid ${(props) => (props.error ? "#ef4444" : "#374151")};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 1rem;
  transition: all 0.2s ease;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    padding: 0.5rem;
  }

  @media (max-width: 768px) {
    height: 180px;
  }

  @media (max-width: 480px) {
    height: 160px;
  }
`;

const UploadLabel = styled.p`
  color: #9ca3af;
  font-size: 0.8125rem;
  margin-bottom: 0.375rem;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const ChooseFileButton = styled.button`
  background-color: #da23ff10;
  color: white;
  padding: 0.625rem 1.25rem;
  border-radius: 24px;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  margin-top: 0.75rem;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.8125rem;
  }
`;

const ErrorText = styled.span`
  color: #ef4444;
  font-size: 0.75rem;
  display: block;
  margin-top: 0.375rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }
`;

const Button = styled.button`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  width: max-content;
  min-width: 100px;
  margin-top: 15px;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    color: #fff !important;
    border: 1px solid #da22ff;
  }
`;
const CardIcon = ({ type }) => {
  const icons = {
    idcardFront: "https://static.nanogames.io/assets/id.a9d1262d.svg",
    idcardback: "https://static.nanogames.io/assets/id-back.75c38598.svg",
    passport: "https://static.nanogames.io/assets/id-back.75c38598.svg",
  };

  return (
    <img style={{ width: "100%" }} src={icons[type]} alt={`${type} icon`} />
  );
};

const VerificationForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [documentType, setDocumentType] = useState("idCard");
  const [errors, setErrors] = useState({});
  const [kycStatus, setKycStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    address: "",
    city: "",
    country: "",
    idCardFront: null,
    idCardBack: null,
    passport: null,
  });

  const [previewImages, setPreviewImages] = useState({
    idCardFront: null,
    idCardBack: null,
    passport: null,
  });

  const fileInputRefs = {
    idCardFront: useRef(null),
    idCardBack: useRef(null),
    passport: useRef(null),
  };

  // Fetch KYC status on component initialization
  useEffect(() => {
    const fetchKycStatus = async () => {
      try {
        const response = await fetch(
          `https://api.bidwinx.com/kyc/status/${getUID}`
        );
        const data = await response.json();
        setKycStatus(data.status);
      } catch (error) {
        console.error("Error fetching KYC status:", error);
      }
    };

    fetchKycStatus();

    if (kycStatus === "Pending") {
      const interval = setInterval(fetchKycStatus, 30000);
      return () => clearInterval(interval);
    }
  }, [kycStatus]);

  const renderStatusMessage = () => {
    switch (kycStatus) {
      case "Pending":
        return (
          <p>
            Your KYC submission is currently pending. Please wait for
            verification.
          </p>
        );
      case "Verified":
        return <p>Your KYC has been verified. Thank you!</p>;
      case "Rejected":
        return <p>Your KYC was rejected. Please re-submit your information.</p>;
      default:
        return null;
    }
  };

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
        return !value || value.trim() === ""
          ? "This field is required"
          : !/^[a-zA-Z\s]+$/.test(value)
          ? "Only letters are allowed"
          : "";
      case "gender":
      case "country":
        return !value ? "Please select an option" : "";
      case "dateOfBirth":
        if (!value) return "Date of birth is required";
        const date = new Date(value);
        const age = new Date().getFullYear() - date.getFullYear();
        return age < 18 ? "Must be at least 18 years old" : "";
      case "address":
      case "city":
        return !value || value.trim() === "" ? "This field is required" : "";
      default:
        return !value ? "This field is required" : "";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleDocumentTypeChange = (type) => {
    setDocumentType(type);
    setErrors({}); // Clear errors when switching document type
    if (type === "idCard") {
      setFormData((prev) => ({
        ...prev,
        passport: null,
      }));
      setPreviewImages((prev) => ({
        ...prev,
        passport: null,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        idCardFront: null,
        idCardBack: null,
      }));
      setPreviewImages((prev) => ({
        ...prev,
        idCardFront: null,
        idCardBack: null,
      }));
    }
  };

  const handleFileSelect = (type) => {
    fileInputRefs[type].current.click();
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        setErrors((prev) => ({
          ...prev,
          [type]: "File size should not exceed 1MB",
        }));
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImages((prev) => ({
          ...prev,
          [type]: e.target.result,
        }));
        setFormData((prev) => ({
          ...prev,
          [type]: file,
        }));
        setErrors((prev) => ({
          ...prev,
          [type]: "",
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const validateStep1 = () => {
    const newErrors = {};
    const fields = [
      "firstName",
      "lastName",
      "gender",
      "dateOfBirth",
      "address",
      "city",
      "country",
    ];

    fields.forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) {
        newErrors[field] = error;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStep2 = () => {
    const newErrors = {};

    if (documentType === "idCard") {
      if (!formData.idCardFront) {
        newErrors.idCardFront = "ID Card Front is required";
      }
      if (!formData.idCardBack) {
        newErrors.idCardBack = "ID Card Back is required";
      }
    } else if (!formData.passport) {
      newErrors.passport = "Passport is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = (e) => {
    e.preventDefault();
    let isValid = false;

    if (currentStep === 1) {
      isValid = validateStep1();
    } else if (currentStep === 2) {
      isValid = validateStep2();
    }

    if (isValid) {
      setCurrentStep((prev) => Math.min(prev + 1, 3));
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!consentChecked) {
      setErrors((prev) => ({
        ...prev,
        consent: "Please accept the terms to continue",
      }));
      return;
    }

    setIsSubmitting(true);
    setErrors({});

    try {
      // Format date to YYYY-MM-DD
      const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        return d.toISOString().split("T")[0];
      };

      // Create and validate FormData
      const formDataToSend = new FormData();

      // Format and append all fields
      const fieldsToSend = {
        userId: getUID?.toString(),
        firstName: formData.firstName?.trim(),
        lastName: formData.lastName?.trim(),
        gender: formData.gender?.toLowerCase(),
        dob: formatDate(formData.dateOfBirth),
        address: formData.address?.trim(),
        city: formData.city?.trim(),
        country: formData.country?.trim(),
        documentType: documentType?.toLowerCase(),
      };

      // Validate all required fields
      const missingFields = Object.entries(fieldsToSend)
        .filter(([_, value]) => !value)
        .map(([key]) => key);

      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(", ")}`);
      }

      // Append validated fields to FormData
      Object.entries(fieldsToSend).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      // Debug: Log all form data being sent
      console.log("Form Data Being Sent:");
      for (let [key, value] of formDataToSend.entries()) {
        console.log(`${key}: ${value}`);
      }

      // Handle file uploads
      if (documentType === "idCard") {
        if (formData.idCardFront) {
          formDataToSend.append("documentFront", formData.idCardFront);
        }
        if (formData.idCardBack) {
          formDataToSend.append("documentBack", formData.idCardBack);
        }
      } else if (documentType === "passport" && formData.passport) {
        formDataToSend.append("documentFront", formData.passport);
      }

      const response = await axios.post(
        "https://api.bidwinx.com/kyc/submit",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log("Upload Progress:", percentCompleted + "%");
          },
        }
      );

      console.log("Server Response:", response.data);

      if (response.data.message) {
        setKycStatus("Pending");
        sendNotfication("KYC submitted successfully");
      }
    } catch (error) {
      console.error("Submission Error:", {
        message: error.message,
        response: error.response?.data,
      });

      const errorMessage = error.response?.data?.error || error.message;
      setErrors((prev) => ({
        ...prev,
        submit: errorMessage,
      }));

      sendNotfication(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStep1 = () => (
    <>
      <Description>
        For a more considerate service, please fill in the following information
        to let us know about you.
      </Description>
      <Form onSubmit={handleNext}>
        <FormGroup>
          <Label>First Name</Label>
          <Input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="Enter first name"
            error={errors.firstName}
          />
          {errors.firstName && <ErrorText>{errors.firstName}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Last Name</Label>
          <Input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Enter last name"
            error={errors.lastName}
          />
          {errors.lastName && <ErrorText>{errors.lastName}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Gender</Label>
          <Select
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            error={errors.gender}
          >
            <option value="">Select gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </Select>
          {errors.gender && <ErrorText>{errors.gender}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Date of Birth</Label>
          <Input
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleInputChange}
            error={errors.dateOfBirth}
          />
          {errors.dateOfBirth && <ErrorText>{errors.dateOfBirth}</ErrorText>}
        </FormGroup>

        <FormGroup fullWidth>
          <Label>Address</Label>
          <Input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            placeholder="Enter your address"
            error={errors.address}
          />
          {errors.address && <ErrorText>{errors.address}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>City</Label>
          <Input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            placeholder="Enter city"
            error={errors.city}
          />
          {errors.city && <ErrorText>{errors.city}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Country</Label>
          <Select
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            error={errors.country}
          >
            <option value="">Select country</option>
            <option value="us">United States</option>
            <option value="uk">United Kingdom</option>
            <option value="ca">Canada</option>
            <option value="au">Australia</option>
            <option value="inr">India</option>
            {/* Add more countries as needed */}
          </Select>
          {errors.country && <ErrorText>{errors.country}</ErrorText>}
        </FormGroup>

        <FormGroup fullWidth>
          <Button type="submit">Next</Button>
        </FormGroup>
      </Form>
    </>
  );

  const renderStep2 = () => (
    <Container>
      <Title>Choose Document Type</Title>

      <ToggleContainer>
        <ToggleButton
          type="button"
          active={documentType === "idCard"}
          onClick={() => handleDocumentTypeChange("idCard")}
        >
          ID Card
        </ToggleButton>
        <ToggleButton
          type="button"
          active={documentType === "passport"}
          onClick={() => handleDocumentTypeChange("passport")}
        >
          Passport
        </ToggleButton>
      </ToggleContainer>

      {documentType === "idCard" ? (
        <>
          <UploadDescription>
            Please upload a valid government-issued ID document or Driver's
            license. It must contain your full name, date of birth, your photo
            and the document's expiration date.
          </UploadDescription>
          <UploadGrid>
            <UploadContainer>
              <UploadPreview error={errors.idCardFront}>
                {previewImages.idCardFront ? (
                  <img src={previewImages.idCardFront} alt="ID Front Preview" />
                ) : (
                  <CardIcon type="idcardFront" />
                )}
              </UploadPreview>
              <UploadLabel>
                ID or Driver's license Front (.jpg, .png)
              </UploadLabel>
              <UploadLabel>File size limit 1MB</UploadLabel>
              <FileInput
                type="file"
                ref={fileInputRefs.idCardFront}
                accept="image/jpeg,image/png"
                onChange={(e) => handleFileChange(e, "idCardFront")}
              />
              <ChooseFileButton
                type="button"
                onClick={() => handleFileSelect("idCardFront")}
              >
                Choose File
              </ChooseFileButton>
              {errors.idCardFront && (
                <ErrorText>{errors.idCardFront}</ErrorText>
              )}
            </UploadContainer>

            <UploadContainer>
              <UploadPreview error={errors.idCardBack}>
                {previewImages.idCardBack ? (
                  <img src={previewImages.idCardBack} alt="ID Back Preview" />
                ) : (
                  <CardIcon type="idcardback" />
                )}
              </UploadPreview>
              <UploadLabel>
                ID or Driver's license Back (.jpg, .png)
              </UploadLabel>
              <UploadLabel>File size limit 1MB</UploadLabel>
              <FileInput
                type="file"
                ref={fileInputRefs.idCardBack}
                accept="image/jpeg,image/png"
                onChange={(e) => handleFileChange(e, "idCardBack")}
              />
              <ChooseFileButton
                type="button"
                onClick={() => handleFileSelect("idCardBack")}
              >
                Choose File
              </ChooseFileButton>
              {errors.idCardBack && <ErrorText>{errors.idCardBack}</ErrorText>}
            </UploadContainer>
          </UploadGrid>
        </>
      ) : (
        <>
          <UploadDescription>
            Please upload the personal page of your passport, which includes
            your photo, name, date of birth, passport number and other personal
            information. Please make sure your passport is within the validity
            period.
          </UploadDescription>
          <UploadGrid>
            <UploadContainer>
              <UploadPreview error={errors.passport}>
                {previewImages.passport ? (
                  <img src={previewImages.passport} alt="Passport Preview" />
                ) : (
                  <CardIcon type="passport" />
                )}
              </UploadPreview>
              <UploadLabel>Passport (.jpg, .png)</UploadLabel>
              <UploadLabel>File size limit 1MB</UploadLabel>
              <FileInput
                type="file"
                ref={fileInputRefs.passport}
                accept="image/jpeg,image/png"
                onChange={(e) => handleFileChange(e, "passport")}
              />
              <ChooseFileButton
                type="button"
                onClick={() => handleFileSelect("passport")}
              >
                Choose File
              </ChooseFileButton>
              {errors.passport && <ErrorText>{errors.passport}</ErrorText>}
            </UploadContainer>
          </UploadGrid>
        </>
      )}

      <ButtonContainer>
        <Button type="button" secondary onClick={handleBack}>
          Back
        </Button>
        <Button type="button" onClick={handleNext}>
          Next
        </Button>
      </ButtonContainer>
    </Container>
  );

  const renderStep3 = () => (
    <>
      <Title>Confirm Your Information</Title>
      <Description>
        Please review your information before submitting
      </Description>
      <div style={{ color: "#e5e7eb", padding: "1rem" }}>
        <h3 style={{ marginBottom: "1rem" }}>Personal Information</h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
        >
          <p>
            <strong>Name:</strong> {formData.firstName} {formData.lastName}
          </p>
          <p>
            <strong>Gender:</strong> {formData.gender}
          </p>
          <p>
            <strong>Date of Birth:</strong> {formData.dateOfBirth}
          </p>
          <p>
            <strong>Country:</strong> {formData.country}
          </p>
          <p>
            <strong>City:</strong> {formData.city}
          </p>
          <p>
            <strong>Address:</strong> {formData.address}
          </p>
        </div>

        <h3 style={{ margin: "1rem 0" }}>Uploaded Documents</h3>
        <PreviewGrid>
          {documentType === "idCard" ? (
            <>
              <PreviewContainer>
                <PreviewTitle>
                  ID Card Front:{" "}
                  <DocumentName>{formData.idCardFront?.name}</DocumentName>
                </PreviewTitle>
                {previewImages.idCardFront && (
                  <PreviewImage
                    src={previewImages.idCardFront}
                    alt="ID Front"
                  />
                )}
              </PreviewContainer>
              <PreviewContainer>
                <PreviewTitle>
                  ID Card Back:{" "}
                  <DocumentName>{formData.idCardBack?.name}</DocumentName>
                </PreviewTitle>
                {previewImages.idCardBack && (
                  <PreviewImage src={previewImages.idCardBack} alt="ID Back" />
                )}
              </PreviewContainer>
            </>
          ) : (
            <PreviewContainer>
              <PreviewTitle>
                Passport: <DocumentName>{formData.passport?.name}</DocumentName>
              </PreviewTitle>
              {previewImages.passport && (
                <PreviewImage src={previewImages.passport} alt="Passport" />
              )}
            </PreviewContainer>
          )}
        </PreviewGrid>

        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={consentChecked}
            onChange={(e) => {
              setConsentChecked(e.target.checked);
              if (e.target.checked) {
                setErrors((prev) => ({ ...prev, consent: "" }));
              }
            }}
          />
          <CheckboxLabel>
            I hereby declare that all the information provided is correct and
            accurate.
          </CheckboxLabel>
        </CheckboxContainer>
        {errors.consent && <ErrorText>{errors.consent}</ErrorText>}

        <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          <Button type="button" onClick={handleBack}>
            Back
          </Button>
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={!consentChecked}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <AppContainer>
      <MainContainer>
        <StepperContainer>
          {[1, 2, 3].map((step, index) => (
            <Step key={step}>
              <StepCircle
                active={step === currentStep}
                completed={step < currentStep}
              >
                <StepText>
                  <StepLabel>{step}</StepLabel>
                </StepText>
              </StepCircle>
              <StepLine
                active={step === currentStep}
                completed={step < currentStep}
              />
            </Step>
          ))}
        </StepperContainer>

        <ContentContainer>
          {(kycStatus === "Not submitted" || kycStatus === null) && (
            <>
              {/* Render steps for new KYC submissions */}
              {currentStep === 1 && renderStep1()}
              {currentStep === 2 && renderStep2()}
              {currentStep === 3 && renderStep3()}
            </>
          )}
          {kycStatus === "Rejected" && (
            <>
              {/* Show form with a "Rejected" message for resubmission */}
              <p style={{ color: "red" }}>
                Your KYC was rejected. Please re-submit your information.
              </p>
              {currentStep === 1 && renderStep1()}
              {currentStep === 2 && renderStep2()}
              {currentStep === 3 && renderStep3()}
            </>
          )}

          {kycStatus === "Pending" && (
            <>
              {/* Show message if KYC is pending */}
              <p>
                Your KYC submission is currently pending. Please wait for
                verification.
              </p>
            </>
          )}

          {kycStatus === "Verified" && (
            <>
              {/* Show message if KYC is verified */}
              <p>Your KYC has been verified. Thank you!</p>
            </>
          )}
        </ContentContainer>
      </MainContainer>
    </AppContainer>
  );
};

export default VerificationForm;
