import React, { useState } from 'react';
import styled from 'styled-components';
import { useBetting } from '../../../../../../../../context/BettingContext';


const Container = styled.div`
  width: 100%;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
`;

const MatchOddsSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Title = styled.div`
  background: #37474f;
  color: white;
  padding: 8px 15px;
  border-radius: 4px;
  font-weight: bold;
`;

const InPlay = styled.div`
  color: #4CAF50;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
`;

const MaxValue = styled.div`
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  border-radius: 3px;
  color: #000;
  font-weight: 500;
`;

const Matched = styled.div`
  text-align: right;
  color: #000;
  span {
    font-weight: bold;
    color: #000;
  }
`;

const TableContainer = styled.div`
  margin-top: 10px;
`;

const SelectionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #f8f9fa;
  color: #000;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 30px;
`;

const ActionButton = styled.div`
  padding: 5px 15px;
  background: ${props => props.back ? '#72BBEF' : '#FFB6C1'};
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(6, 1fr);
  border-bottom: 1px solid #ddd;
  color: #000;
  background-color: #fff;
`;

const TeamCell = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  font-weight: 500;
  border-right: 1px solid #ddd;

  svg {
    color: #666;
    min-width: 16px;
    &:hover {
      color: #333;
    }
  }
`;

const OddsCell = styled.div`
   display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 5px;
  background: ${props => {
    if (props.type === 'back') {
      // Keep the blue gradients as they are
      switch(props.index) {
        case 0:
          return 'linear-gradient(90deg, #E3F2FD, #BBDEFB)';
        case 1:
          return 'linear-gradient(90deg, #BBDEFB, #90CAF9)';
        case 2:
          return 'linear-gradient(90deg, #90CAF9, #64B5F6)';
        default:
          return 'linear-gradient(90deg, #E3F2FD, #BBDEFB)';
      }
    } else {
      // Updated pink gradients for lay cells
      switch(props.index) {
        case 2:
          return 'linear-gradient(90deg, #FFE6EA, #FFD4DA)';
        case 1:
          return 'linear-gradient(90deg, #FFD4DA, #FFC1C9)';
        case 0:
          return 'linear-gradient(90deg, #FFC1C9, #FFAEB8)';
        default:
          return 'linear-gradient(90deg, #FFE6EA, #FFD4DA)';
      }
    }
  }};
  border: 1px solid #ddd;
  cursor: pointer;
  color: #000;

  span:first-child {
    font-weight: bold;
    color: #000;
  }
  span:last-child {
    font-size: 0.9em;
    color: #333;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const GraphIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2"
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
  </svg>
);

const matchData = [
  {
    team: "West Indies",
    odds: [
      { value: 1.75, volume: 1797 },
      { value: 1.76, volume: 2218 },
      { value: 1.77, volume: 434 },
      { value: 1.78, volume: 1240 },
      { value: 1.79, volume: 6563 },
      { value: 1.80, volume: 2642 }
    ]
  },
  {
    team: "Bangladesh",
    odds: [
      { value: 2.36, volume: 2016 },
      { value: 2.38, volume: 6663 },
      { value: 2.40, volume: 920 },
      { value: 2.42, volume: 3 },
      { value: 2.44, volume: 3201 },
      { value: 2.46, volume: 1959 }
    ]
  },
  {
    team: "The Draw",
    odds: [
      { value: 36, volume: 77 },
      { value: 38, volume: 319 },
      { value: 40, volume: 76 },
      { value: 42, volume: 21 },
      { value: 44, volume: 69 },
      { value: 46, volume: 437 }
    ]
  }
];

const InPlayBet = () => {
  const { addBet } = useBetting();
  const [selectedBack, setSelectedBack] = useState(null);
  const [selectedLay, setSelectedLay] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOddsClick = (team, type, odds, volume, rowIndex) => {
    if (odds === '--') return;

    const betDetails = {
      matchId: 1, // You might want to pass this as a prop
      matchTitle: "West Indies v Bangladesh", // You might want to pass this as a prop
      team1: "West Indies",
      team2: "Bangladesh",
      selectionName: team,
      category: team === "The Draw" ? 'x' : team === "West Indies" ? 'matched' : 'two',
      type,
      odds: odds.value,
      isBlue: type === 'back',
      stake: '',
      liability: '0.00',
      volume: volume
    };

    if (type === 'back') {
      setSelectedBack(odds.value);
      setSelectedLay(null);
    } else {
      setSelectedLay(odds.value);
      setSelectedBack(null);
    }
    setSelectedRow(rowIndex);

    console.log('Selected Bet:', betDetails);
    addBet(betDetails);
  };

  return (
    <Container>
      <Header>
        <MatchOddsSection>
          <Title>Match Odds</Title>
          <InPlay>
            <span>⏱</span>
            In-Play
          </InPlay>
          <MaxValue>Max 400</MaxValue>
        </MatchOddsSection>
        <Matched>
          Matched <span>PTE 14,751,821</span>
        </Matched>
      </Header>
      <TableContainer>
        <SelectionsRow>
          <div>3 selections</div>
          <div>99.9%</div>
          <ButtonGroup>
            <ActionButton back>Back all</ActionButton>
            <ActionButton>Lay all</ActionButton>
          </ButtonGroup>
          <div>99.9%</div>
        </SelectionsRow>
        {matchData.map((row, index) => (
          <TableRow key={index}>
            <TeamCell>
              <GraphIcon />
              {row.team}
            </TeamCell>
            {row.odds.slice(0, 3).map((odd, i) => (
              <OddsCell 
                key={i} 
                type="back" 
                index={i}
                onClick={() => handleOddsClick(row.team, 'back', odd, odd.volume, index)}
              >
                <span>{odd.value}</span>
                <span>{odd.volume.toLocaleString()}</span>
              </OddsCell>
            ))}
            {row.odds.slice(3).map((odd, i) => (
              <OddsCell 
                key={i + 3} 
                type="lay" 
                index={i}
                onClick={() => handleOddsClick(row.team, 'lay', odd, odd.volume, index)}
              >
                <span>{odd.value}</span>
                <span>{odd.volume.toLocaleString()}</span>
              </OddsCell>
            ))}
          </TableRow>
        ))}
      </TableContainer>
    </Container>
  );
};

export default InPlayBet;