import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, Card, Dropdown } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import { Event, isValidNumber, forceSatoshiFormat, sendNotfication, wait } from "../../../Helper";
import { setPlay } from "../../../actions/gamePlay";
import { setRisk } from "../../../actions/gameOption";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";
import styled from 'styled-components';
import { FileQuestion, Info } from 'lucide-react';



const Container2 = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  width: 100%;  /* Full width on mobile */
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  /* Desktop size (768px and above) */
  @media (min-width: 768px) {
    max-width: 380px;
  }
`;



const TabContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #2a2a2a;
`;

const Tab = styled.button`
  padding: 8px 16px;
  color: ${props => props.active ? '#34d399' : '#808080'};
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#34d399' : 'transparent'};
  }
`;
const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 16px;
    margin-top: 8px;
`;

const DemoText = styled.div`
  color: #808080;
  font-size: 14px;
  text-align: left;
`;



const TabContainer1 = styled.div`
  background-color: #272B2C;
  border-radius: 8px;
  display: flex;
  margin-bottom: 24px;
  padding: 6px;
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding: 4px;
  }
`;

const Tab1 = styled.button`
  background: ${props => props.active ? '#3A4142' : 'transparent'};
  border: none;
  border-radius: 6px;
  color: ${props => props.active ? 'white' : '#6b7280'};
  cursor: pointer;
  flex: 1;
  padding: 4px 12px;
  transition: all 0.3s ease;
  font-weight: ${props => props.active ? '600' : '400'};
  font-size: 16px;
  
  @media (max-width: 768px) {
   padding: 4px 12px;
    font-size: 14px;
  }
  
  &:hover {
    color: ${props => props.active ? 'white' : '#a1a1aa'};
  }
`;

const BetButton = styled.div`
  width: 100%;
  padding: 12px;
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  color:black !important;
  display:flex;
  justify-content:center;
  align-items:center;
`;


const NewTag = styled.span`
  color: #fbbf24;
  font-size: 12px;
  margin-left: 4px;
`;

const AmountSection = styled.div`
  margin-bottom: 16px;
`;

const MultiplierGroup = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`;

const MultiplierButton = styled.button`
  background-color: #3A4142;
  padding: 4px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
`;


const Label = styled.div`
  display: flex;
  align-items: center;
  color: #808080;
  margin-bottom: 4px;
  
  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
`;

const BetDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #292D2E;
  padding: 4px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  position:relative;
`;
const SwitcherContainer = styled.div`
display:flex;
  background-color: #292D2E;
  border-radius: 8px;
  padding: 2px;
  gap: 2px;
  margin-top:-14px;
  
`;


const SwitchButton = styled.div`
  border: none;
  padding: 13px 16px;
  border-radius: 8px;
  width:100px;
  font-size: 12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-warap:no-wrap;
  cursor: pointer;
  transition: all 0.2s ease;
  
  ${props => props.active ? `
    background-color: #3A4142;
    color: white;
  ` : `
    background: none;
    color: #6B7280;
  `}

  &:hover {
    color: ${props => props.active ? 'white' : '#9CA3AF'};
  }
`;
const BetInput = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const CurrencyDot = styled.div`
  width: 20px;
  height: 20px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;



class Bet extends React.Component {
    _isMounted = false;
    _autoPlay = null;

    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            bet: 'manual',
            started: false,
            risk: '8',
            riskName: 'Low',
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            isLogged: storage.getKey("logged") !== null ? true : false,
            credit: storage.getKey('credit'),
            inputDisabled: false,
            buttonText: 'BET',
            added: false,
            activeTab: 'Manual',
            activeTabM: 'Low'
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        let { engine } = this.state;
        this.props.gameCoin();
        engine.on(C.PLAY_PLINKO, () => this.play());
        engine.on(C.BUSTED_PLINKO, (data) => this.busted(data));
        engine.on(C.ERROR_PLINKO, (data) => this.error(data));
        engine.on("stop_playing", () => this.stopManual());
    }

    stopManual() {
        this.setState({ started: false, buttonText: 'BET', inputDisabled: false });
    }

    componentWillMount() {
        this._isMounted = true;
        window.cancelAnimationFrame(this._autoPlay);
    }

    componentWillUnmount() {
        let { engine, started } = this.state;
        this._isMounted = false;
        engine.init = false;

        try {
            if (started)
                document.getElementById('bet').click()
        }
        catch (e) { }
    }


    error(data) {
        if (this._isMounted) {
            sendNotfication(data.message, 'info', 'top-center');

            if (data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false });

            if (this.state.started) {
                this.setState({ started: false, buttonText: 'Start Auto Mode' });
                window.cancelAnimationFrame(this._autoPlay);
            }
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;

        if (target.name === 'amount') {
            this.setState({ amount: value })
            if (!isValidNumber(value)) return;
        }

        if (target.name === 'amount') {
            storage.setKey('lam', value);
        }

        this.setState({ [target.name]: value });
    }

    handleBet() {
      
        let { amount, isLogged, bet, started } = this.state;

        // Check User
        if (!isLogged) {
            return Event.emit('showAuthModal', true);
        }

        if (parseFloat(amount) <= 0) {
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        this.setState({ inputDisabled: true });

        if (this.state.activeTab === 'Auto') {
            if (started) {
                this.setState({ started: false, buttonText: 'Start Auto Mode', inputDisabled: false });
                window.cancelAnimationFrame(this._autoPlay);
                return false;
            }
            else {
                this.setState({ started: true, buttonText: 'Stop Auto Mode' });

                let self = this;

                function auto() {
                    if (self._isMounted) {
                        let { engine, amount } = self.state;

                        if (!self.state.added) {
                            self.setState({ added: true });

                            engine.init = true;
                            engine.coin = self.props.coin;
                            engine.amount = amount;
                            engine.autoStart();

                            wait(2000).then(() => {
                                self.setState({ added: false });
                            })

                        }
                        self._autoPlay = window.requestAnimationFrame(auto)
                    }
                }

                this._autoPlay = window.requestAnimationFrame(auto);
            }
        }
        else {
            this.placeBet();
        }
    }

    placeBet() {
        if (this._isMounted) {
            let { engine, amount } = this.state;

            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.amount = amount;
                    engine.coin = this.props.coin;
                    engine.start();
                })
        }
    }

    busted(data) {
        if (this._isMounted) {
            let { engine, bet, started } = this.state;

            wait(100)
                .then(() => {
                    this.setState({ lock: false, playing: false, inputDisabled: false });

                    if (bet !== 'auto') {
                        engine.init = false;
                    }
                })
        }
    }
    setActiveTabM = (tabName) => {
        this.setState({ activeTabM: tabName });
    }


    play() {
        let { bet, engine, started } = this.state;
        if (this._isMounted) {
            wait(500).then(() => {
                this.setState({ inputDisabled: false });
            })

            //Set For Redux
            this.props.setPlay(true);
        }
    }

    handleChange = value => {
        this.setState({ risk: value });
        this.props.setRisk(value);
        this.setState({ riskName: value });
    }

    betMode = (type) => {
        let text;

        if (type === 'auto') {
            text = 'Start Auto Mode';
        }
        else {
            text = 'BET';
        }
        this.setState({ bet: type, buttonText: text });
    };
    handleTabChange = (tab) => {
        this.setState({ activeTab: tab });
    }


    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am);
    }

    render() {
        let { inputDisabled, amount, bet, started, riskName } = this.state;
        const tabs = ['Manual', 'Auto', ''];
        const { activeTab, betAmount, activeTabM } = this.state;

        return (
            <Container2>


                <>
                    <TabContainer>
                        {tabs.map(tab => (
                            <Tab
                                key={tab}
                                active={activeTab === tab}
                                onClick={() => this.handleTabChange(tab)}
                            >
                                {tab}
                                {tab === 'Advanced' && <NewTag>New!</NewTag>}
                            </Tab>
                        ))}
                    </TabContainer>


                    {
                        activeTab == "Manual" && (
                            <>

                                <AmountSection>
                                    <Label>
                                        Amount
                                        <Info />
                                    </Label>

                                    <BetDisplay>
                                        <img
                                            src={"/assets/images/" + this.props.coin + ".png"}
                                            className={"mini-coin-7"}
                                            alt=""
                                        />



                                        <BetInput
                                            disabled={inputDisabled} type="text" className="form-control text-left" id="amount" name="amount"
                                            placeholder="Enter Bet Amount" value={amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />
                                        <MultiplierGroup>
                                            <MultiplierButton>1/2</MultiplierButton>
                                            <MultiplierButton>2x</MultiplierButton>
                                            <div className="input-group-append">
                                                <RangeCredit set={this.setAmount} />
                                            </div>
                                        </MultiplierGroup>
                                    </BetDisplay>
                                </AmountSection>
                                <Label>
                                    Risk

                                </Label>
                                <TabContainer1>

                                    <Tab1
                                        active={activeTabM === "Low"}
                                        onClick={() => {

                                            this.handleChange('Low')
                                            this.setActiveTabM('Low')
                                        }
                                        }
                                    >
                                        Low
                                    </Tab1>
                                    <Tab1
                                        active={activeTabM === 'Medium'}
                                        onClick={() => {

                                            this.handleChange('Medium')
                                            this.setActiveTabM('Medium')
                                        }
                                        }
                                    >
                                        Medium
                                    </Tab1>
                                    <Tab1
                                        active={activeTabM === 'High'}
                                        onClick={() => {

                                            this.handleChange('High')
                                            this.setActiveTabM('High')
                                        }}
                                    >
                                        High
                                    </Tab1>

                                </TabContainer1>

                                <BetButton>Bet</BetButton>
                                <WarningContainer>
                                    <DemoText>Betting with $0 will enter demo mode.</DemoText>
                                </WarningContainer>
                            </>




                        )
                    }

                    {
                        activeTab == "Auto" && (
                            <>

                                <AmountSection>
                                    <Label>
                                        Amount
                                        <Info />
                                    </Label>

                                    <BetDisplay>
                                        <img
                                            src={"/assets/images/" + this.props.coin + ".png"}
                                            className={"mini-coin-7"}
                                            alt=""
                                        />



                                        <BetInput
                                            disabled={inputDisabled} type="text" className="form-control text-left" id="amount" name="amount"
                                            placeholder="Enter Bet Amount" value={amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />
                                        <MultiplierGroup>
                                            <MultiplierButton>1/2</MultiplierButton>
                                            <MultiplierButton>2x</MultiplierButton>
                                            <div className="input-group-append">
                                                <RangeCredit set={this.setAmount} />
                                            </div>
                                        </MultiplierGroup>
                                    </BetDisplay>
                                </AmountSection>
                                <Label>
                                    Risk

                                </Label>
                                <TabContainer1>

                                    <Tab1
                                        active={activeTabM === "Low"}
                                        onClick={() => {

                                            this.handleChange('Low')
                                            this.setActiveTabM('Low')
                                        }
                                        }
                                    >
                                        Low
                                    </Tab1>
                                    <Tab1
                                        active={activeTabM === 'Medium'}
                                        onClick={() => {

                                            this.handleChange('Medium')
                                            this.setActiveTabM('Medium')
                                        }
                                        }
                                    >
                                        Medium
                                    </Tab1>
                                    <Tab1
                                        active={activeTabM === 'High'}
                                        onClick={() => {

                                            this.handleChange('High')
                                            this.setActiveTabM('High')
                                        }}
                                    >
                                        High
                                    </Tab1>

                                </TabContainer1>
                                <div style={{ display: "flex", gap: "4px", margin:'4px 0px'}}>
                                    <FileQuestion color='rgb(128,128,128)'/>
                                    <div>
                                        <DemoText>Use of script is optional and players must</DemoText>
                                        <DemoText>take full responsibility for any attendant</DemoText>
                                        <DemoText>risks. We will not be held liable in this regard.</DemoText>
                                    </div>

                                </div>

                                <BetButton onClick={() => this.handleBet()}>Start Auto Bet</BetButton>
                                <WarningContainer>
                                    <DemoText>Betting with $0 will enter demo mode.</DemoText>
                                </WarningContainer>
                            </>




                        )
                    }
                </>
                {/* <Row>
                    <Col lg={12} md={12} sm={12} className={'m-auto'}>
                        <Card className={'mb-0'}>
                            <Card.Body className={'px-2 pt-2 py-0'}>
                                <Row>
                                    <Col xl={6} md={8} sm={12}>
                                        <div className={"form-group mb-1 bet-input"}>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                                                        BET
                                                    </span>
                                                </div>
                                                <input disabled={inputDisabled} type="text" className="form-control text-left" id="amount" name="amount"
                                                    placeholder="Enter Bet Amount" value={amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />
                                                <div className="input-group-append">
                                                    <RangeCredit set={this.setAmount} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={6} md={4} sm={12}>
                                        <div className={"form-group mb-1"}>
                                            <div className="input-group fw">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">Power</span>
                                                </div>
                                                <Dropdown className={"drop-select btn-block"}>
                                                    <Dropdown.Toggle split variant="" className={'py-0 mt-0'} >
                                                        <span className="text-muted text-center">
                                                            <i className={'mdi mdi-chevron-down mr-1'} />
                                                            {riskName}
                                                        </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu disabled={inputDisabled} className={"dopdown-menu-bottom " + inputDisabled}>
                                                        <Dropdown.Item onClick={e => this.handleChange('Low')} className={'animated fadeIn'}>
                                                            Low
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.handleChange('Medium')} className={'animated fadeIn'}>
                                                            Medium
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.handleChange('High')} className={'animated fadeIn'}>
                                                            High
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={5} className={"m-auto"}>
                                        {bet === 'manual' &&
                                            <Button variant={'btn my-1 btn-block btn-bet btn-sm'}
                                                onClick={(e) => { this.handleBet(e) }}
                                                disabled={inputDisabled}
                                                type="submit">
                                                {this.state.buttonText}
                                            </Button>
                                        }
                                        {bet === 'auto' &&
                                            <Button id={'bet'} variant={'btn my-1 btn-block btn-bet btn-sm'}
                                                onClick={(e) => { this.handleBet(e) }}
                                                type="submit">
                                                {this.state.buttonText}
                                                {started &&
                                                    <><div className={'ml-2 spinner-border spinner-border-sm'} /></>
                                                }
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                                <div className="btn-group btn-group-toggle mb-1 ml-1" data-toggle="buttons">
                                    <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                                        <input disabled={started} onClick={e => this.betMode('manual')} type="radio" /> Manual
                                    </label>
                                    <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                                        <input disabled={started} onClick={e => this.betMode('auto')} type="radio" /> Auto
                                    </label>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> */}
            </Container2>
        );
    }
}

Bet.propTypes = {
    setPlay: PropTypes.bool,
    setRisk: PropTypes.number,
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, { setPlay, setRisk, gameCoin, setWallet })(Bet);
