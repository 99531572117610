import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  width: ${props => props.size || '24px'};
  height: ${props => props.size || '24px'};
  
  path {
    fill: ${props => props.variant === 'white' ? '#FFFFFF' : props.color || 'currentColor'};
  }
  
  &:hover {
    opacity: ${props => props.variant === 'white' ? 0.8 : 1};
  }
`;

const CurrencyIcon = ({ size, color, variant }) => {
  return (
    <StyledSvg 
      viewBox="0 0 32 32" 
      size={size}
      color={color}
      variant={variant}
      aria-hidden="true"
    >
      <path d="M16.0004 4.19043C22.5223 4.19043 27.8094 9.47748 27.8094 15.9994C27.8094 22.5214 22.5223 27.8084 16.0004 27.8084C9.47846 27.8084 4.19141 22.5214 4.19141 15.9994C4.19141 9.47748 9.47846 4.19043 16.0004 4.19043ZM16.0004 6.72093C10.8761 6.72093 6.7219 10.8752 6.7219 15.9994C6.7219 21.1237 10.8761 25.2779 16.0004 25.2779C21.1247 25.2779 25.2789 21.1237 25.2789 15.9994C25.2789 10.8752 21.1247 6.72093 16.0004 6.72093ZM16.6684 9.25143V11.1544C17.7616 11.2623 18.8143 11.6402 19.5971 12.1531L18.7738 13.921C17.7751 13.3272 16.5875 12.9493 15.8317 12.9493C15.1164 12.9493 14.6306 13.2057 14.6306 13.7186C14.6306 15.4596 19.6646 14.4879 19.6511 17.8889C19.6511 19.5219 18.3959 20.4936 16.6684 20.696V22.7069H15.0489V20.669C13.8208 20.4936 12.6466 19.9672 11.7829 19.2385L12.6331 17.4975C13.6049 18.3612 14.9814 18.9146 15.9802 18.9146C16.8574 18.9146 17.3837 18.5907 17.3837 18.0103C17.3837 16.2289 12.3497 17.268 12.3497 13.921C12.3497 12.477 13.4024 11.4783 15.0489 11.2083V9.25143H16.6684Z" />
    </StyledSvg>
  );
};

// Create a white version of the icon
const WhiteCurrencyIcon = styled(CurrencyIcon)`
  path {
    fill: white;
  }
`;

export { CurrencyIcon, WhiteCurrencyIcon };