import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.size || '24px'};
  height: ${props => props.size || '24px'};
  flex: none;
  transition: transform 0.2s ease;
  
  & path {
    fill: ${props => props.fill || 'currentColor'};
  }
`;

const GiftSkyla = ({ size, fill, className }) => {
  return (
    <StyledSVG 
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      fill={fill}
      className={className}
    >
      <g id="vector">
        <path d="M27.4077 13.2726C20.3295 13.2636 11.47 13.2636 4.59287 13.2726C3.87545 13.2739 3.29358 12.6927 3.29358 11.9753V9.69262C3.29358 8.97646 3.87411 8.39528 4.59029 8.39528C5.51983 8.39528 6.62966 8.4011 7.01236 8.4011C6.98774 8.33237 6.97541 8.28178 6.95269 8.23695C5.92517 6.23386 7.22188 3.83905 9.45782 3.59903C10.4548 3.49203 11.3026 3.80402 12.0356 4.46692C12.7317 5.0968 13.4264 5.72794 14.1217 6.35846C14.1626 6.3954 14.206 6.43046 14.2612 6.47778C14.7587 5.97702 15.3483 5.69479 16.056 5.69553C16.7552 5.69553 17.3429 5.96472 17.8463 6.47325C17.9021 6.42851 17.9578 6.38761 18.0097 6.34089C18.7116 5.70585 19.4108 5.06691 20.1146 4.43445C21.99 2.75054 24.8999 3.67163 25.448 6.12426C25.6063 6.83391 25.5284 7.52214 25.1905 8.17407C25.1587 8.23572 25.1296 8.29926 25.0822 8.39789C25.4169 8.39789 26.2674 8.39594 27.4096 8.39662C28.1258 8.39662 28.7063 8.97781 28.7063 9.69396V11.9746C28.7063 12.6921 28.1251 13.2732 27.4077 13.2726Z" />
        <path d="M6.68743 28.4213C6.60503 28.3954 6.52329 28.3675 6.44025 28.3435C5.69882 28.1301 5.24091 27.5047 5.24025 26.6874C5.2383 24.6403 5.23962 22.5931 5.23962 20.5466C5.23962 18.7667 5.23962 16.9867 5.23962 15.2068C5.23962 15.1108 5.23962 15.0141 5.23962 14.8974H14.448C14.448 14.9817 14.448 15.0673 14.448 15.153C14.448 19.4815 14.448 23.8107 14.4461 28.1393C14.4461 28.2334 14.4215 28.3281 14.4078 28.4221L6.68743 28.4213Z" />
        <path d="M17.7095 28.4213C17.6965 28.335 17.6712 28.2487 17.6712 28.1632C17.6692 23.8184 17.6692 19.4737 17.6692 15.1282C17.6692 15.0569 17.6692 14.9849 17.6692 14.8954H26.8777C26.8777 15.0018 26.8777 15.1042 26.8777 15.2067C26.8777 18.9936 26.8777 22.7799 26.8777 26.5668C26.8777 27.5884 26.456 28.132 25.4642 28.3902C25.4507 28.3934 25.4409 28.4109 25.4298 28.4219L17.7095 28.4213Z" />
      </g>
    </StyledSVG>
  );
};

export default GiftSkyla;