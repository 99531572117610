import React from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 0;
  background: #1a1a1a;
`;

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 16px;
  padding: 0 20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const GameCard = styled.div`
  min-width: 200px;
  height: 280px;
  border-radius: 16px;
  padding: 20px;
  position: relative;
  cursor: pointer;
  background: ${props => props.bgColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const GameTitle = styled.h3`
  color: white;
  font-size: 24px;
  margin: 0;
  font-weight: bold;
`;

const PlayerCount = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Multiplier = styled.div`
  font-size: 20px;
  color: white;
  font-weight: bold;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => props.right ? 'right: 10px' : 'left: 10px'};
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const SkylaGameCards = () => {
  // Sample game data
  const games = Array(20).fill(null).map((_, index) => ({
    id: index + 1,
    title: ['TWIST', 'CRASH', 'PLINKO', 'MINES', 'WHEEL', 'DICE', 'SLOTS', 'POKER'][index % 8],
    players: Math.floor(Math.random() * 1000) + 100,
    multiplier: (Math.random() * 500).toFixed(1) + 'x',
    bgColor: [
      '#8B5CF6', // Purple
      '#F59E0B', // Orange
      '#3B82F6', // Blue
      '#10B981', // Green
      '#EC4899', // Pink
      '#6366F1', // Indigo
      '#EF4444', // Red
      '#06B6D4'  // Cyan
    ][index % 8]
  }));

  const scrollLeft = () => {
    const container = document.querySelector('#scroll-container');
    container.scrollBy({ left: -400, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const container = document.querySelector('#scroll-container');
    container.scrollBy({ left: 400, behavior: 'smooth' });
  };

  return (
    <CarouselContainer>
      <NavButton onClick={scrollLeft}>
        <ChevronLeft size={24} />
      </NavButton>
      
      <ScrollContainer id="scroll-container">
        {games.map(game => (
          <GameCard key={game.id} bgColor={game.bgColor}>
            <PlayerCount>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="white">
                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
              </svg>
              {game.players}
            </PlayerCount>
            
            <div>
              <Multiplier>{game.multiplier}</Multiplier>
              <GameTitle>{game.title}</GameTitle>
            </div>
          </GameCard>
        ))}
      </ScrollContainer>

      <NavButton right onClick={scrollRight}>
        <ChevronRight size={24} />
      </NavButton>
    </CarouselContainer>
  );
};

export default SkylaGameCards;