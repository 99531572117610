import { ChevronRight } from 'lucide-react';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
 width: 100%;
 max-width: 600px;
 margin: 0 auto;
 box-sizing: border-box;
 border-radius: 8px;
`;

const MemberInfo = styled.div`
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: center;
 align-items: center;
   border: 1px solid rgba(82, 236, 130, 0.2);
 gap: 15px;
 margin-bottom: 20px;
 background-color: #323738; // Added background color
 padding: 20px; // Optional: add some padding to give space around content
 border-radius: 8px; // Optional: match the container's border-radius
`;


const MemberAvatar = styled.img`
 width: 60px;
 height: 60px;
 border-radius: 50%;
 border: 2px solid #5CEB7F;
`;

const MemberDetails1 = styled.div`
 text-align: center;
`;

const MemberName = styled.div`
 font-size: 18px;
 font-weight: bold;
 color: #fff;
`;

const MemberRole = styled.div`
 font-size: 14px;
 color: #8b8b8b;
`;

const MemberID = styled.div`
 font-size: 14px;
 color: #8b8b8b;
`;

const MemberActivity = styled.div`
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 gap: 9px;
`;

const ActivityItem = styled.div`
 background-color: #4A5354;
 border-radius: 6px;
  border: 1px solid rgba(82, 236, 130, 0.2);
 padding: 12px;
 display: flex;
 flex-direction: column;
 align-items: center;
`;

const ActivityLabel = styled.div`
 font-size: 14px;
 color: #fff;
   @media (max-width: 768px) {
   font-size: 11px;
 white-space: nowrap;
  }
`;

const ActivityValue = styled.div`
 font-size: 16px;
 font-weight: bold;
 color: ${props => props.success ? '#5CEB7F' : '#ff6b6b'};
`;

const Container1 = styled.div`
 width: 100%;
 max-width: 600px;
 margin: 0 auto;
 box-sizing: border-box;
 border-radius: 8px;
`;

const OptionList = styled.div`
 display: flex;
 flex-direction: column;
  margin-top:12px;
  gap:4px;
`;

const OptionItem = styled.div`
 display: flex;
 align-items: center;
 gap: 12px;
 justify-content:space-between;
 padding: 12px;
  border: 1px solid rgba(82, 236, 130, 0.2);
 background-color: #323738;
 border-radius: 8px;
 cursor: pointer;

`;

const RadioButton = styled.div`
 width: 18px;
 height: 18px;
 border: 2px solid #8b8b8b;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;

 &:before {
   content: '';
   width: 10px;
   height: 10px;
   background-color: ${props => props.selected ? '#5CEB7F' : 'transparent'};
   border-radius: 50%;
 }
`;

const OptionLabel = styled.div`
 font-size: 16px;
 color: #fff;
`;

const MemberDetails = ({ setIsCreateModalOpen, isOpen, member, role, onBack }) => {
    const [selectedOption, setSelectedOption] = React.useState({ label: 'Member', value: 'member' });

    const handleAgentRedirect = () => {
        if (onBack) onBack(); // Close Members modal
        if (typeof setIsCreateModalOpen === 'function') {
            // Just pass the new role - simplified interface
            setIsCreateModalOpen('agent');
        }
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        if (option.value === 'agent') {
            handleAgentRedirect();
        }
    };

    const options = role === 'Club' 
      ? [
          { label: 'Agent', value: 'agent' },
          { label: 'Member', value: 'member' },
        ]
      : [
          { label: 'Member', value: 'member' },
        ];
   
    return (
        <Container>
            <MemberInfo>
                <MemberAvatar src={member.avatar} alt={member.name} />
                <MemberDetails1>
                    <MemberName>{member.name}</MemberName>
                    <MemberID>ID: {member.id} Nickname: {member.name}</MemberID>
                    <MemberID>No remark</MemberID>
                </MemberDetails1>
            </MemberInfo>

            <MemberActivity>
                <ActivityItem>
                    <ActivityLabel>Hands</ActivityLabel>
                    <ActivityValue success>{member.hands}</ActivityValue>
                </ActivityItem>
                <ActivityItem>
                    <ActivityLabel>Buy-in</ActivityLabel>
                    <ActivityValue>{member.buyin}</ActivityValue>
                </ActivityItem>
                <ActivityItem>
                    <ActivityLabel>Winnings</ActivityLabel>
                    <ActivityValue success>{member.winnings}</ActivityValue>
                </ActivityItem>
                <ActivityItem>
                    <ActivityLabel>Rakeback</ActivityLabel>
                    <ActivityValue>{member.rakeback}</ActivityValue>
                </ActivityItem>
                <ActivityItem>
                    <ActivityLabel>System Rakeback</ActivityLabel>
                    <ActivityValue>{member.systemRakeback}%</ActivityValue>
                </ActivityItem>
                <ActivityItem>
                    <ActivityLabel>Fee</ActivityLabel>
                    <ActivityValue>{member.fee}</ActivityValue>
                </ActivityItem>
            </MemberActivity>
            <OptionList>
                {options.map((option) => (
                    <OptionItem 
                        key={option.value} 
                        onClick={() => handleOptionClick(option)}
                    >
                        <div style={{display:"flex", gap:'9px', alignItems:"center"}}>
                            <RadioButton selected={selectedOption?.value === option.value} />
                            <OptionLabel>{option.label}</OptionLabel>
                        </div>
                        {role === 'Club' && option.value === 'agent' && (
                            <ChevronRight 
                                color="#5EEB7F"
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleAgentRedirect();
                                }}
                            />
                        )}
                    </OptionItem>
                ))}
            </OptionList>
        </Container>
    );
};

export default MemberDetails;