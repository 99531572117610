import React, { useState } from 'react';
import styled from 'styled-components';
import { useBetting } from '../../../../../context/BettingContext';
import { Play, RefreshCw, Flag, Info, BadgeDollarSign } from 'lucide-react';
import { useHistory } from 'react-router-dom';

// Styled Components
const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  background: #2c3e50;
  color: white;
  padding: 10px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  background: white;
`;

const Match = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
`;

const MatchInfo = styled.div`
  flex: 1;
`;

const MatchTitle = styled.div`
  color: #3498db;
  font-weight: bold;
  margin-bottom: 0px;
  cursor: pointer;
  display: inline-block;
  
  &:hover {
    text-decoration: underline;
    color: #2980b9;
  }
`;

const StatusDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: ${props => props.isLive ? '#2ecc71' : '#95a5a6'};
  border-radius: 50%;
  margin-right: 8px;
  animation: ${props => props.isLive ? 'blink 1s ease-in-out infinite' : 'none'};

  @keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

const Status = styled.span`
  color: ${props => {
    switch(props.status) {
      case 'In-Play':
        return '#27ae60';
      case 'Not Started':
        return '#95a5a6';
      case 'Suspended':
        return '#e74c3c';
      default:
        return '#95a5a6';
    }
  }};
  font-size: 14px;
`;

const IconGroup = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 5px;
`;

const Icon = styled.span`
  background: #2ecc71;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
`;

const TabContainer = styled.div`
  display: flex;
  gap: 2px;
  background: #2A3438;
`;

const TabButton = styled.button`
  padding: 12px 24px;
  background: ${props => props.active ? 'linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113))' : 'transparent'};
  color: ${props => props.active ? '#FFFFFF' : '#9AA6AC'};
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background-color: ${props => props.active ? '#1B8271' : '#374248'};
    color: #FFFFFF;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#22C55E' : 'transparent'};
    transition: all 0.2s ease;
  }
`;

const ContentContainer = styled.div`
  padding: 2px;
  width: 100%;
  border-radius: 0 0 8px 8px;
  color: #9AA6AC;
  min-height: 100vh;
`;

const TabContent = styled.div`
  animation: fadeIn 0.3s ease;
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const MobileSportsUI = () => {
  const { addBet } = useBetting();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('in-play');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Create dates for testing
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const matches = [
    // In-Play Matches (1-10)
    { 
      id: 1,
      team1: 'Bangladesh Women',
      team2: 'Ireland Women',
      status: 'In-Play',
      isLive: true,
      time: '14:30',
      date: new Date(),
      isSuspended: false,
      competition: 'International'
    },
    {
      id: 2,
      team1: 'India',
      team2: 'England',
      status: 'In-Play',
      isLive: true,
      time: '15:00',
      date: new Date(),
      isSuspended: false,
      competition: 'International Test'
    },
    {
      id: 3,
      team1: 'Australia',
      team2: 'South Africa',
      status: 'In-Play',
      isLive: true,
      time: '13:45',
      date: new Date(),
      isSuspended: false,
      competition: 'T20 World Cup'
    },
    {
      id: 4,
      team1: 'Mumbai Indians',
      team2: 'Chennai Super Kings',
      status: 'In-Play',
      isLive: true,
      time: '16:20',
      date: new Date(),
      isSuspended: false,
      competition: 'IPL'
    },
    {
      id: 5,
      team1: 'Royal Challengers',
      team2: 'Delhi Capitals',
      status: 'In-Play',
      isLive: true,
      time: '18:00',
      date: new Date(),
      isSuspended: false,
      competition: 'IPL'
    },
    {
      id: 6,
      team1: 'Pakistan',
      team2: 'New Zealand',
      status: 'In-Play',
      isLive: true,
      time: '12:30',
      date: new Date(),
      isSuspended: false,
      competition: 'International ODI'
    },
    {
      id: 7,
      team1: 'West Indies',
      team2: 'Sri Lanka',
      status: 'In-Play',
      isLive: true,
      time: '11:15',
      date: new Date(),
      isSuspended: false,
      competition: 'International T20'
    },
    {
      id: 8,
      team1: 'Rajasthan Royals',
      team2: 'Punjab Kings',
      status: 'In-Play',
      isLive: true,
      time: '19:30',
      date: new Date(),
      isSuspended: false,
      competition: 'IPL'
    },
    {
      id: 9,
      team1: 'Kolkata Knight Riders',
      team2: 'Sunrisers Hyderabad',
      status: 'In-Play',
      isLive: true,
      time: '15:45',
      date: new Date(),
      isSuspended: false,
      competition: 'IPL'
    },
    {
      id: 10,
      team1: 'Bangladesh',
      team2: 'Afghanistan',
      status: 'In-Play',
      isLive: true,
      time: '14:00',
      date: new Date(),
      isSuspended: false,
      competition: 'International Test'
    },
  
    // Today's Matches (11-20)
    {
      id: 11,
      team1: 'Zimbabwe',
      team2: 'Ireland',
      status: 'Not Started',
      isLive: false,
      time: '20:00',
      date: new Date(),
      isSuspended: false,
      competition: 'International T20'
    },
    {
      id: 12,
      team1: 'Netherlands',
      team2: 'Scotland',
      status: 'Not Started',
      isLive: false,
      time: '21:15',
      date: new Date(),
      isSuspended: false,
      competition: 'European Championship'
    },
    {
      id: 13,
      team1: 'UAE',
      team2: 'Oman',
      status: 'Not Started',
      isLive: false,
      time: '22:30',
      date: new Date(),
      isSuspended: false,
      competition: 'Gulf Cup'
    },
    {
      id: 14,
      team1: 'Nepal',
      team2: 'Malaysia',
      status: 'Not Started',
      isLive: false,
      time: '23:45',
      date: new Date(),
      isSuspended: false,
      competition: 'Asian Cricket Council Cup'
    },
    {
      id: 15,
      team1: 'Kenya',
      team2: 'Uganda',
      status: 'Not Started',
      isLive: false,
      time: '19:00',
      date: new Date(),
      isSuspended: false,
      competition: 'African T20 Cup'
    },
    {
      id: 16,
      team1: 'Canada',
      team2: 'USA',
      status: 'Not Started',
      isLive: false,
      time: '20:30',
      date: new Date(),
      isSuspended: false,
      competition: 'Americas Cup'
    },
    {
      id: 17,
      team1: 'Hong Kong',
      team2: 'Singapore',
      status: 'Not Started',
      isLive: false,
      time: '21:45',
      date: new Date(),
      isSuspended: false,
      competition: 'Asian Cricket League'
    },
    {
      id: 18,
      team1: 'Papua New Guinea',
      team2: 'Namibia',
      status: 'Not Started',
      isLive: false,
      time: '22:00',
      date: new Date(),
      isSuspended: false,
      competition: 'ICC Challenge League'
    },
    {
      id: 19,
      team1: 'Jersey',
      team2: 'Guernsey',
      status: 'Not Started',
      isLive: false,
      time: '23:15',
      date: new Date(),
      isSuspended: false,
      competition: 'Channel Islands Championship'
    },
    {
      id: 20,
      team1: 'Bermuda',
      team2: 'Cayman Islands',
      status: 'Not Started',
      isLive: false,
      time: '23:30',
      date: new Date(),
      isSuspended: false,
      competition: 'Caribbean Cup'
    },
  
    // Tomorrow's Matches (21-30)
    {
      id: 21,
      team1: 'Australia Women',
      team2: 'England Women',
      status: 'Not Started',
      isLive: false,
      time: '09:00',
      date: new Date(new Date().setDate(new Date().getDate() + 1)),
      isSuspended: false,
      competition: 'Women\'s Ashes'
    },
    {
      id: 22,
      team1: 'India Women',
      team2: 'South Africa Women',
      status: 'Not Started',
      isLive: false,
      time: '10:15',
      date: new Date(new Date().setDate(new Date().getDate() + 1)),
      isSuspended: false,
      competition: 'Women\'s International Series'
    },
    {
      id: 23,
      team1: 'New Zealand Women',
      team2: 'West Indies Women',
      status: 'Not Started',
      isLive: false,
      time: '11:30',
      date: new Date(new Date().setDate(new Date().getDate() + 1)),
      isSuspended: false,
      competition: 'Women\'s ODI Series'
    },
    {
      id: 24,
      team1: 'Pakistan Women',
      team2: 'Sri Lanka Women',
      status: 'Not Started',
      isLive: false,
      time: '12:45',
      date: new Date(new Date().setDate(new Date().getDate() + 1)),
      isSuspended: false,
      competition: 'Women\'s Asia Cup'
    },
    {
      id: 25,
      team1: 'Bangladesh Women',
      team2: 'Thailand Women',
      status: 'Not Started',
      isLive: false,
      time: '14:00',
      date: new Date(new Date().setDate(new Date().getDate() + 1)),
      isSuspended: false,
      competition: 'Women\'s T20 Series'
    },
    {
      id: 26,
      team1: 'Ireland Women',
      team2: 'Scotland Women',
      status: 'Not Started',
      isLive: false,
      time: '15:15',
      date: new Date(new Date().setDate(new Date().getDate() + 1)),
      isSuspended: false,
      competition: 'Women\'s European Championship'
    },
    {
      id: 27,
      team1: 'Netherlands Women',
      team2: 'Denmark Women',
      status: 'Not Started',
      isLive: false,
      time: '16:30',
      date: new Date(new Date().setDate(new Date().getDate() + 1)),
      isSuspended: false,
      competition: 'Women\'s T20I Series'
    },
    {
      id: 28,
      team1: 'UAE Women',
      team2: 'Qatar Women',
      status: 'Not Started',
      isLive: false,
      time: '17:45',
      date: new Date(new Date().setDate(new Date().getDate() + 1)),
      isSuspended: false,
      competition: 'Women\'s Gulf Cup'
    },
    {
      id: 29,
      team1: 'Nepal Women',
      team2: 'Malaysia Women',
      status: 'Not Started',
      isLive: false,
      time: '19:00',
      date: new Date(new Date().setDate(new Date().getDate() + 1)),
      isSuspended: false,
      competition: 'Women\'s Asian Games'
    },
    {
      id: 30,
      team1: 'Kenya Women',
      team2: 'Nigeria Women',
      status: 'Not Started',
      isLive: false,
      time: '20:15',
      date: new Date(new Date().setDate(new Date().getDate() + 1)),
      isSuspended: false,
      competition: 'Women\'s African Championship'
    }
  ];
  const getMatchStatus = (match) => {
    if (!match.date) return null;

    if (activeTab === 'in-play' && match.isLive) {
      return (
        <>
          {/* <StatusDot isLive={true} /> */}
          <Status status="In-Play">In-Play</Status>
        </>
      );
    } else if (activeTab === 'today') {
      return (
        <Status status="Not Started">{match.time}</Status>
      );
    } else if (activeTab === 'tomorrow') {
      return (
        <Status status="Not Started">Tomorrow {match.time}</Status>
      );
    }
    return null;
  };

  const filteredMatches = matches.filter(match => {
    if (!match.date) return false;

    const matchDate = new Date(match.date);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // Reset time part for date comparison
    matchDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    tomorrow.setHours(0, 0, 0, 0);

    if (activeTab === 'in-play') {
      return match.isLive;
    } else if (activeTab === 'today') {
      return !match.isLive && 
             match.date.getDate() === today.getDate() &&
             match.date.getMonth() === today.getMonth() &&
             match.date.getYear() === today.getYear();
    } else if (activeTab === 'tomorrow') {
      return match.date.getDate() === tomorrow.getDate() &&
             match.date.getMonth() === tomorrow.getMonth() &&
             match.date.getYear() === tomorrow.getYear();
    }
    return false;
  });

  const handleMatchClick = (matchId) => {
    // Find the match data from matches array
    const selectedMatch = matches.find(match => match.id === matchId);
    
    // Push to history with match data in state
    history.push({
      pathname: `/match/fullmarket/event/${matchId}`,
      state: { 
        matchData: {
          ...selectedMatch,
          matchId: matchId,
          matchTitle: `${selectedMatch.team1} v ${selectedMatch.team2}`,
          competition: selectedMatch.competition,
          status: selectedMatch.status,
          isLive: selectedMatch.isLive,
          isSuspended: selectedMatch.isSuspended
        }
      }
    });
  };

  return (
    <Container>
      <TabContainer>
        <TabButton
          active={activeTab === 'in-play'}
          onClick={() => handleTabClick('in-play')}
        >
          In-Play
        </TabButton>
        <TabButton
          active={activeTab === 'today'}
          onClick={() => handleTabClick('today')}
        >
          Today
        </TabButton>
        <TabButton
          active={activeTab === 'tomorrow'}
          onClick={() => handleTabClick('tomorrow')}
        >
          Tomorrow
        </TabButton>
      </TabContainer>
      <ContentContainer>
        <TabContent>
          {filteredMatches.map((match, index) => (
            <Match key={index}>
              <MatchInfo>
                <div style={{display:'flex',gap:"10px",alignItems:'center'}} >
                  <IconGroup>
                    <Icon><Play size={16} /></Icon>
                    <Icon><RefreshCw size={16}/></Icon>
                    <Icon><Flag size={16}/></Icon>
                    <Icon><Info size={16}/></Icon>
                  </IconGroup>
                  {getMatchStatus(match)}
                </div>
                <div style={{display:'flex',gap:"0px",alignItems:'center'}} >
                  <StatusDot isLive={match.isLive} />
                  <MatchTitle 
                    onClick={() => handleMatchClick(match.id)}
                  >
                    {`${match.team1} v ${match.team2}`}
                  </MatchTitle>
                </div>
              </MatchInfo>
            </Match>
          ))}
        </TabContent>
      </ContentContainer>
    </Container>
  );
};

export default MobileSportsUI;