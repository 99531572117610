import React, { useState, useMemo, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BetSlip from '../../BetSlip';
import CricketSchedule from '../../Today';
import SportsScheduleTomorrow from '../../Tomorrow';
import CricketBettingUI from '..';
import TabSwitcher from '../../TabSwitcher';
import MiddleDetailBettingUi from './Middle';

// Constants
const MENU_ITEMS = [
  { id: 'all-sports', label: 'All Sports' },
  { id: 'cricket', label: 'Cricket' },
  { id: 'u19-asia-cup', label: 'U19 Asia Cup' },
  { id: 'pakistan-vs-uae', label: 'Pakistan vs UAE' }
];

// Add these SVG icons at the top of your file
const SportIcons = {
  'all-sports': (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
      <path d="M12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/>
    </svg>
  ),
  'cricket': (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M14.27 6c-.55.95-.22 2.18.73 2.73.95.55 2.18.22 2.73-.73.55-.95.22-2.18-.73-2.73-.95-.55-2.18-.22-2.73.73z"/>
      <path d="M15.84 10.41l-2.83-2.83-5.66 5.66 2.83 2.83 5.66-5.66z"/>
      <path d="M13.41 15.84l2.83 2.83 5.66-5.66-2.83-2.83-5.66 5.66z"/>
    </svg>
  ),
  'u19-asia-cup': (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
      <path d="M11 7h2v7h-2zm0 8h2v2h-2z"/>
    </svg>
  ),
  'pakistan-vs-uae': (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
      <path d="M12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/>
    </svg>
  )
};

// Styled Components
const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const MainContent = styled.div`
  flex: 0 0 60%;
  background-color: #1C1F23;
  overflow-y: auto;
  height: 100vh;
`;

const Header = styled.div`
  background: #2c3e50;
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const BannerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.1)
  );
  pointer-events: none;
`;

const Section = styled.div`
  flex: ${props => props.width};
  background-color: white;
  min-height: 100vh;
  overflow-y: auto;
  border-right: 1px solid #edf2f7;
  
  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #CBD5E0;
    border-radius: 2px;
  }
`;

const Header1 = styled.div`
  background: #2c3e50;
  color: #24EE89;
  padding: 12px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight:bold;
`;

const MenuList = styled.ul`
  list-style-type: none;
  padding: 8px;
  margin: 0;
`;

const MenuItem = styled.li`
  padding: 10px 12px;
  margin: -6px 0;
  color: ${props => props.active ? '#2C5282' : '#4A5568'};
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 8px;
  background-color: ${props => props.active ? '#EBF8FF' : 'transparent'};
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: ${props => props.active ? '600' : '500'};
  
  &:hover {
    color: #2C5282;
    background-color: ${props => props.active ? '#EBF8FF' : '#F7FAFC'};
  }

  svg {
    color: ${props => props.active ? '#2C5282' : '#718096'};
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TabContent = styled.div`
  animation: fadeIn 0.3s ease;
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;


const MatchTitle = styled.div`
  color: black;
  font-size: 14px;
  padding: 20px;
  text-align: center;
  background:white;
  margin-bottom: 16px;
`;




// Memoized Menu Component
const SideMenu = React.memo(({ activeTab, onTabChange }) => (
  <Section width="0 0 15%">
    <Header1>Sports</Header1>
    <MenuList>
      {MENU_ITEMS.map(item => (
        <MenuItem
          key={item.id}
          active={activeTab === item.id}
          onClick={() => onTabChange(item.id)}
        >
          <IconWrapper>
            {SportIcons[item.id]}
          </IconWrapper>
          {item.label}
        </MenuItem>
      ))}
    </MenuList>
  </Section>
));

const CricketBetUiComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab || 'in-play');

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // history.push(`/sports/${tab}`);
  };

  const renderMiddleContent = () => {
    switch (activeTab) {
      case 'in-play':
        return (
          <TabContent>
            <CricketBettingUI />
          </TabContent>
        );
      case 'today':
        return <CricketSchedule />;
      case 'tomorrow':
        return <SportsScheduleTomorrow />;
      default:
        return (
          <TabContent>
            <MiddleDetailBettingUi/>
          </TabContent>
        );
    }
  };

  // Memoize sections to prevent unnecessary re-renders
  const middleSection = useMemo(() => (
    <Section width="0 0 60%" bg="#1C1F23">
      {renderMiddleContent()}
    </Section>
  ), [activeTab]);

  const rightSection = useMemo(() => (
    <Section width="0 0 25%">
      <BetSlip />
    </Section>
  ), []);


  const { matchData } = location.state || {};
  console.log(matchData, "match data is ");

  useEffect(() => {
    if (!matchData) {
      // Handle case when no match data is available
      // Maybe redirect back or show error
    }
  }, [matchData]);




  const handleTabClick = (tab) => {
    setActiveTab(tab);
    history.push(`/sports/markets/${tab}`);
  };

  return (
    <LayoutContainer>
      <SideMenu
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />

      <MainContent>
        <TabSwitcher
          activeTab={activeTab}
          onTabClick={handleTabClick}
        />
        <Header>
          <BannerImage
            src="/assets/images/sports-b.png"
            alt="Sports Banner"
            loading="lazy"
          />
          <BannerOverlay />
        </Header>

        {matchData && (
        <MatchTitle>
          {matchData.team1} vs {matchData.team2}
        </MatchTitle>
      )}
        {renderMiddleContent()}
      </MainContent>

      {rightSection}
    </LayoutContainer>
  );
};

export default CricketBetUiComponent;