import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: inline-block;
  width: ${props => props.size || '32px'};
  height: ${props => props.size || '32px'};
`;

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${props => props.color || '#666666'};
  transition: fill 0.3s ease;

  &:hover {
    fill: ${props => props.hoverColor || '#444444'};
  }
`;

const LockIcon = ({ size, color, hoverColor, className }) => {
  return (
    <IconWrapper size={size} className={className}>
      <StyledSVG
        viewBox="0 0 32 32"
        color={color}
        hoverColor={hoverColor}
      >
        <path d="M16 23.133c1.2 0 2.173-0.973 2.173-2.173s-0.973-2.173-2.173-2.173c-1.2 0-2.173 0.973-2.173 2.173s0.973 2.173 2.173 2.173z" />
        <path d="M24.373 12.707v-1.667c0-3.6-0.867-8.373-8.373-8.373s-8.373 4.773-8.373 8.373v1.667c-3.733 0.467-4.96 2.36-4.96 7.013v2.48c0 5.467 1.667 7.133 7.133 7.133h12.4c5.467 0 7.133-1.667 7.133-7.133v-2.48c0-4.653-1.227-6.547-4.96-7.013zM16 24.987c-2.227 0-4.027-1.813-4.027-4.027 0-2.227 1.813-4.027 4.027-4.027s4.027 1.813 4.027 4.027c0 2.227-1.8 4.027-4.027 4.027zM9.8 12.587c-0.107 0-0.2 0-0.307 0v-1.547c0-3.907 1.107-6.507 6.507-6.507s6.507 2.6 6.507 6.507v1.56c-0.107 0-0.2 0-0.307 0h-12.4v-0.013z" />
      </StyledSVG>
    </IconWrapper>
  );
};

export default LockIcon;