import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.size || '25px'};
  height: ${props => props.size || '25px'};
  flex-none: 1;
`;

const Path1 = styled.path`
  fill: white;
  d: path("M26.4724 13.2852C19.9993 13.2769 11.8971 13.2769 5.6079 13.2852C4.95181 13.2863 4.41968 12.7548 4.41968 12.0987V10.0112C4.41968 9.35626 4.95058 8.82476 5.60554 8.82476C6.45562 8.82476 7.47058 8.83008 7.82056 8.83008C7.79805 8.76722 7.78678 8.72096 7.76599 8.67997C6.82632 6.84811 8.01218 4.65801 10.057 4.43851C10.9688 4.34066 11.7441 4.62598 12.4144 5.23221C13.051 5.80825 13.6863 6.38544 14.3222 6.96205C14.3595 6.99584 14.3993 7.0279 14.4497 7.07117C14.9047 6.61322 15.444 6.35511 16.0911 6.35579C16.7306 6.35579 17.268 6.60197 17.7284 7.06703C17.7794 7.02611 17.8304 6.98871 17.8778 6.94598C18.5197 6.36523 19.1591 5.78091 19.8028 5.20251C21.5178 3.66255 24.179 4.50491 24.6803 6.74787C24.825 7.39686 24.7538 8.02626 24.4448 8.62246C24.4157 8.67884 24.3891 8.73695 24.3457 8.82714C24.6518 8.82714 25.4296 8.82536 26.4742 8.82599C27.1291 8.82599 27.66 9.35749 27.66 10.0124V12.0981C27.66 12.7542 27.1285 13.2857 26.4724 13.2852Z");
`;

const Path2 = styled.path`
  fill: white;
  d: path("M13.9099 8.81469C13.8613 8.68421 13.8529 8.55902 13.7865 8.49732C12.9542 7.73208 12.1226 6.96504 11.2707 6.22116C10.7967 5.80709 10.1151 5.80119 9.58 6.15411C9.06035 6.49642 8.8171 7.127 8.97432 7.72259C9.15523 8.40833 9.70634 8.82127 10.4722 8.82539C11.5441 8.83134 12.6161 8.82717 13.6874 8.82654C13.7384 8.82654 13.7895 8.82122 13.9099 8.81469Z");
`;

const Path3 = styled.path`
  fill: white;
  d: path("M18.2736 8.75897C18.2973 8.78148 18.3216 8.80468 18.3453 8.82719C18.4996 8.82719 18.6532 8.82835 18.8075 8.82719C19.8569 8.81949 20.9074 8.83252 21.9557 8.79401C22.5418 8.77265 23.0508 8.304 23.2032 7.75174C23.361 7.17869 23.1659 6.58129 22.7031 6.2236C22.2131 5.84512 21.5979 5.80067 21.0664 6.12334C20.9039 6.22179 20.7573 6.35054 20.615 6.47869C19.8954 7.1271 19.177 7.77664 18.4657 8.43391C18.3774 8.51579 18.3358 8.64982 18.2736 8.75897Z");
`;

const Path4 = styled.path`
  fill: white;
  d: path("M7.52341 27.1389C7.44806 27.1152 7.3733 27.0897 7.29736 27.0677C6.61931 26.8726 6.20054 26.3007 6.19994 25.5532C6.19816 23.6811 6.19936 21.8089 6.19936 19.9373C6.19936 18.3096 6.19936 16.6817 6.19936 15.054C6.19936 14.9662 6.19936 14.8778 6.19936 14.7711H14.6206C14.6206 14.8482 14.6206 14.9265 14.6206 15.0048C14.6206 18.9633 14.6206 22.9224 14.6188 26.881C14.6188 26.967 14.5963 27.0536 14.5838 27.1396L7.52341 27.1389Z");
`;

const Path5 = styled.path`
  fill: white;
  d: path("M17.6032 27.1389C17.5914 27.06 17.5682 26.9811 17.5682 26.9028C17.5664 22.9295 17.5664 18.9561 17.5664 14.9822C17.5664 14.9169 17.5664 14.8511 17.5664 14.7692H25.9877C25.9877 14.8665 25.9877 14.9602 25.9877 15.054C25.9877 18.5171 25.9877 21.9797 25.9877 25.4429C25.9877 26.3772 25.6021 26.8743 24.6951 27.1104C24.6827 27.1134 24.6738 27.1294 24.6637 27.1395L17.6032 27.1389Z");
`;

const BonusIcon = ({ size }) => {
  return (
    <StyledSVG viewBox="0 0 32 32">
      <Path1 />
      <Path2 />
      <Path3 />
      <Path4 />
      <Path5 />
    </StyledSVG>
  );
};

export default BonusIcon;