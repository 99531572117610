import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 12px;
  max-width: 600px;
  margin: 0 auto;
`;

const IconRow = styled.div`
  display: flex;
  gap: 2px;
  justify-content:center;
  margin-bottom: 20px;
  overflow-x: auto;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #2a2a32;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

const PaymentMethodRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  justify-content:center;
  flex-wrap: wrap;
`;

const PaymentMethod = styled.div`
  height: 22px;
  display: flex;
  align-items: center;
  justify-content:center;
  
  img {
    height: 100%;
    object-fit: contain;
  }
`;

const OfferSection = styled.div`
  position: relative;
  background-color: rgb(23 26 26/1);
  padding: 16px 24px;
  border-radius: 8px;
  
`;

const BlurredBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(23 26 26/1);
  filter: blur(8px);
  border-radius: 8px;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content:space-between;

`;

const OfferText = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
   font-size: 16px !important;
    font-weight: bold;
  
  span:first-child {
    color: #4ade80;
   font-size: 16px !important;
    font-weight: bold;
  }
  
  span:last-child {
    color: white;
  font-size: 16px !important;
    font-weight: bold;
  }
`;

const ActionButton = styled.div`
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  color: black;
  border: none;
  padding: 8px 18px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.2s;
  
  &:hover {
    opacity: 0.9;
  }
`;

const MobilePaymentBR = () => {
  const digitalAssets = [
    { id: 1, src: 'https://bc.game/coin/BTC.black.png', alt: 'Digital Asset 1' },
    { id: 2, src: 'https://bc.game/coin/ETH.black.png', alt: 'Digital Asset 2' },
    { id: 3, src: 'https://bc.game/coin/BNB.black.png', alt: 'Digital Asset 3' },
    { id: 4, src: 'https://bc.game/coin/XRP.black.png', alt: 'Digital Asset 4' },
    { id: 5, src: 'https://bc.game/coin/USDT.black.png', alt: 'Digital Asset 5' },
    { id: 6, src: 'https://bc.game/coin/USDC.black.png', alt: 'Digital Asset 6' },
    { id: 7, src: 'https://bc.game/coin/SOL.black.png', alt: 'Digital Asset 7' },
    { id: 8, src:' https://bc.game/coin/ADA.black.png', alt: 'Digital Asset 8' },
    { id: 9, src: 'https://bc.game/coin/POL.black.png', alt: 'Digital Asset 7' },
    { id: 20, src:'https://bc.game/coin/TRX.black.png', alt: 'Digital Asset 3' },
  ];

  const paymentMethods = [
    { id: 1, src: 'https://bc.game/assets/apple_pay-DhGEreIw.png', alt: 'Payment Method 1' },
    { id: 2, src: 'https://bc.game/assets/mastercard-CVVg_XRh.png', alt: 'Payment Method 2' },
    { id: 3, src: 'https://bc.game/assets/visa-CHvdFeKw.png', alt: 'Payment Method 3' },
    { id: 4, src: 'https://bc.game/assets/google_pay-FVJ2d1pF.png', alt: 'Payment Method 4' },
    { id: 4, src: 'https://bc.game/assets/pic_pay-fE-XPIEr.png', alt: 'Payment Method 5' },
  ];

  return (
    <Container>
      <IconRow>
        {digitalAssets.map(asset => (
          <Icon key={asset.id}>
            <img src={asset.src} alt={asset.alt} />
          </Icon>
        ))}
      </IconRow>
      
      <PaymentMethodRow>
        {paymentMethods.map(method => (
          <PaymentMethod key={method.id}>
            <img src={method.src} alt={method.alt} />
          </PaymentMethod>
        ))}
      </PaymentMethodRow>
      
      <OfferSection>
        <BlurredBackground />
        <ContentWrapper>
          <OfferText>
            <span>300%</span>
            <span>Welcome Bonus</span>
          </OfferText>
          <ActionButton>Deposit Now</ActionButton>
        </ContentWrapper>
      </OfferSection>
    </Container>
  );
};

export default MobilePaymentBR;