import React, { useState } from 'react';
import styled from 'styled-components';
import CricketBettingUI from '../components/CricketUi';
import MobileSportsUI from './MobileHomeUi';

// Football SVG Icon Component
const FootballIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 15L9 12M9 12L12 9M9 12H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

// Cricket Icon
const CricketIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.3335 16.6667L17.3335 6.66675" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M12.3335 19.6667L15.3335 16.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M4.3335 13.6667L7.3335 16.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    <circle cx="17.3335" cy="6.66675" r="2" stroke="currentColor" strokeWidth="1.5"/>
  </svg>
);

// Soccer/Football Icon
const SoccerIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M12 2V6M12 6L7 10M12 6L17 10M7 10L5 16M7 10L12 12M17 10L19 16M17 10L12 12M5 16L12 12M19 16L12 12M5 16L9 20M19 16L15 20" stroke="currentColor" strokeWidth="1.5"/>
  </svg>
);

// Tennis Icon
const TennisIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M12 2C14.5 4.5 15.5 8 15.5 12C15.5 16 14.5 19.5 12 22" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M12 2C9.5 4.5 8.5 8 8.5 12C8.5 16 9.5 19.5 12 22" stroke="currentColor" strokeWidth="1.5"/>
  </svg>
);

// Multi Markets Icon
const MultiMarketsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="6" height="6" rx="1" stroke="currentColor" strokeWidth="1.5"/>
    <rect x="4" y="14" width="6" height="6" rx="1" stroke="currentColor" strokeWidth="1.5"/>
    <rect x="14" y="4" width="6" height="6" rx="1" stroke="currentColor" strokeWidth="1.5"/>
    <rect x="14" y="14" width="6" height="6" rx="1" stroke="currentColor" strokeWidth="1.5"/>
  </svg>
);

const LayoutContainer = styled.div`
  width: 100%;
  background-color: #1C1F23;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FixedHeader = styled.div`
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #1C1F23;
`;

const TabContainer = styled.div`
  display: flex;
  background-color: #292D2E;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollableContent = styled.div`
  margin-top: 70px; // Adjust this value based on your header height
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 60px);
  -webkit-overflow-scrolling: touch;
`;

const TabContent = styled.div`
  height: 100%;
`;

const TabButton = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: ${props => props.active ? '#CFB773' : 'transparent'};
  color: ${props => props.active ? '#000' : '#9AA6AC'};
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  flex-shrink: 0;
  
  svg {
    color: ${props => props.active ? '#000' : '#9AA6AC'};
    stroke: currentColor;
  }
  
  &:hover {
    background-color: ${props => props.active ? '#CFB773' : '#3A4142'};
    svg {
      color: ${props => props.active ? '#000' : '#fff'};
    }
  }

  & + & {
    margin-left: 8px;
  }
`;

const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  color: #9AA6AC;
  text-align: center;
`;

const ComingSoonText = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #3A4142, #BAC6C9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ComingSoonSubtext = styled.p`
  font-size: 1rem;
  color: #9AA6AC;
  max-width: 400px;
  line-height: 1.5;
`;

const NewsTickerContainer = styled.div`
   background-color: #292D2E;
  color: white;
  padding: 8px 0;
  overflow: hidden;
  position: relative;
`;

const NewsContent = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: ticker 20s linear infinite;
  
  @keyframes ticker {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

const NewsItem = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 24px;
  font-size: 14px;
  color: #fff;
  
  &::before {
    content: "•";
    color: #4CAF50;
    margin-right: 8px;
  }
`;

const LiveIndicator = styled.span`
  color: #4CAF50;
  font-weight: 600;
  margin-right: 8px;
`;

const SportsMobileView = () => {
  const [activeTab, setActiveTab] = useState('cricket');

  const tabs = [
    { 
      id: 'multimarkets', 
      label: 'Multi Markets', 
      icon: MultiMarketsIcon 
    },
    { 
      id: 'cricket', 
      label: 'Cricket', 
      icon: CricketIcon 
    },
    { 
      id: 'soccer', 
      label: 'Soccer', 
      icon: SoccerIcon 
    },
    { 
      id: 'tennis', 
      label: 'Tennis', 
      icon: TennisIcon 
    },
   
  ];

  const newsItems = [
    "IND vs AUS: Virat Kohli scores century in the first innings",
    "ENG vs NZ: England wins the toss and chooses to bat",
    "IPL 2024: Mumbai Indians vs Chennai Super Kings - Match starts in 2 hours",
    "PAK vs SA: Pakistan needs 50 runs in 30 balls with 5 wickets remaining",
  ];

  return (
    <LayoutContainer>
      <FixedHeader>
        <TabContainer>
          {tabs.map(tab => (
            <TabButton
              key={tab.id}
              active={activeTab === tab.id}
              onClick={() => setActiveTab(tab.id)}
            >
              <tab.icon />
              {tab.label}
            </TabButton>
          ))}
        </TabContainer>
        <NewsTickerContainer>
          <NewsContent>
            {newsItems.map((news, index) => (
              <NewsItem key={index}>
                <LiveIndicator>LIVE</LiveIndicator>
                {news}
              </NewsItem>
            ))}
          </NewsContent>
        </NewsTickerContainer>
      </FixedHeader>

      <ScrollableContent>
        <TabContent>
          {activeTab === 'cricket' && <MobileSportsUI />}
          {activeTab !== 'cricket' && (
            <ComingSoonContainer>
              <ComingSoonText>Coming Soon!</ComingSoonText>
              <ComingSoonSubtext>
                We're working hard to bring you exciting {activeTab} matches and betting options. 
                Stay tuned for updates!
              </ComingSoonSubtext>
            </ComingSoonContainer>
          )}
        </TabContent>
      </ScrollableContent>
    </LayoutContainer>
  );
};

export default SportsMobileView;