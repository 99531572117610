import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Row, Col, Button} from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import {gameCoin} from "../../../actions/gameCoin";
import {setWallet} from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";
import {Event, __, isValidNumber, wait, forceSatoshiFormat, sendNotfication, isMobile} from "../../../Helper";



import styled from "styled-components";
import { ChevronDown, ChevronUp, Info } from "lucide-react";

const Container = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  max-width: 380px;
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const Container2 = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  width: 100%;  /* Full width on mobile */
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  /* Desktop size (768px and above) */
  @media (min-width: 768px) {
    max-width: 380px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #2a2a2a;
`;

const Tab = styled.button`
  padding: 8px 16px;
  color: ${props => props.active ? '#34d399' : '#808080'};
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#34d399' : 'transparent'};
  }
`;

const NewTag = styled.span`
  color: #fbbf24;
  font-size: 12px;
  margin-left: 4px;
`;

const AmountSection = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  color: #808080;
  margin-bottom: 4px;
  
  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
`;

const BetDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #292D2E;
  padding: 8px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  position:relative;
`;
const SwitcherContainer = styled.div`
display:flex;
  background-color: #292D2E;
  border-radius: 8px;
  padding: 2px;
  gap: 2px;
  margin-top:-14px;
  
`;


const SwitchButton = styled.div`
  border: none;
  padding: 13px 16px;
  border-radius: 8px;
  width:100px;
  font-size: 12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-warap:no-wrap;
  cursor: pointer;
  transition: all 0.2s ease;
  
  ${props => props.active ? `
    background-color: #3A4142;
    color: white;
  ` : `
    background: none;
    color: #6B7280;
  `}

  &:hover {
    color: ${props => props.active ? 'white' : '#9CA3AF'};
  }
`;
const BetInput = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  padding:5px;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const CurrencyDot = styled.div`
  width: 20px;
  height: 20px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const MultiplierGroup = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`;

const MultiplierButton = styled.button`
  background-color: #3A4142;
  padding: 4px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
`;

const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 24px;
`;

const ValueButton = styled.button`
  background-color: #2a2a2a;
  padding: 4px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  
  &:hover {
    background-color: #404040;
  }
`;

const WinAmountInput = styled.div`
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const RollButton = styled.div`
  width: 100%;
  padding: 12px;
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  color:black !important;
  display:flex;
  justify-content:center;
  align-items:center;
`;
const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 16px;
    margin-top: 8px;
`;

const DemoText = styled.div`
  color: #808080;
  font-size: 14px;
  text-align: center;
`;



const Container1 = styled.div`
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  max-width: 380px;
`;

const Section = styled.div`
  margin-bottom: 10px;
`;

const Label1 = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #808080;
  margin-bottom: 10px;
  font-size: 14px;
`;

const AmountDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const CurrencyIcon = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
`;

const ActionButton = styled.button`
  background-color: #404040;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #505050;
  }
`;

const ChevronGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #404040;
  border-radius: 6px;
  overflow: hidden;

  button {
    border: none;
    background: none;
    color: white;
    padding: 2px;
    cursor: pointer;
    
    &:hover {
      background-color: #505050;
    }
  }
`;

const ValueGrid1 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 16px;
`;

const ValueButton1 = styled.button`
  background-color: #2a2a2a;
  padding: 8px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const BetControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const ResetButton = styled.button`
  background-color: #2a2a2a;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 6px;
  padding: 6px 12px;
  gap: 8px;
  flex: 1;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  background: none;
  color: white;
  width: 60px;
  padding: 4px;
  font-size: 14px;
`;

const PercentageText = styled.span`
  color: #808080;
  font-size: 14px;
`;

const TabSwitcher = styled.div`
  display: flex;
  background: #323738;
  padding: 4px;
  border-radius: 12px;
  gap: 4px;
  width: 100%;
  margin: 0 auto 1rem auto;
`;

const TabOption = styled.button`
  background: ${props => props.active ? 'linear-gradient(90deg, #24ee89, #9fe871)' : 'transparent'};
  color: ${props => props.active ? '#1C1D21' : '#80A94'};
  border: none;
  padding: 8px 24px;
  border-radius: 8px;
  font-weight: ${props => props.active ? '600' : '500'};
  cursor: pointer;
  flex:1;
  transition: all 0.2s ease;
  font-size: 14px;
  
  &:hover {
    color: ${props => props.active ? '#1C1D21' : '#ffffff'};
    background: ${props => props.active ? 'linear-gradient(90deg, #24ee89, #9fe871)' : 'rgba(255, 255, 255, 0.05)'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ButtonGrid = styled.div`
  display: grid;
  gap: 8px;
  margin-bottom: 16px;
  
  /* Desktop layout (3 columns) */
  grid-template-columns: repeat(3, 1fr);
  
  /* Mobile layout (2 columns) */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    
    /* First three buttons take full width of their grid cell */
    & > button:nth-child(-n+3) {
      grid-column: auto;
    }
    
    /* Last two buttons take one column each */
    & > button:nth-last-child(-n+2) {
      grid-column: span 1;
    }
    
    /* Make Double Down button span full width */
    & > button:nth-child(3) {
      grid-column: 1 / -1;
    }
  }
`;

const GameButton = styled.button`
  background-color: #292D2E;
  color: ${props => props.disabled ? '#6B7280' : 'white'};
  border: none;
  padding: 10px;
  border-radius: 8px;
  font-size: 12px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;
  white-space: nowrap;
  
  &:hover {
    background-color: ${props => props.disabled ? '#292D2E' : '#3A4142'};
  }
`;

class Bet extends React.Component {
    _isMounted = false;
    constructor (props){
        super(props);
        this.state = {
            engine: this.props.engine,
            mobile: false,
            isLogged: storage.getKey('logged')!== null ? true : false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            inputDisabled: false,
            InsuranceDisabled: true,
            splitDisabled: true,
            hitDisabled: true,
            standDisabled: true,
            doubleDisabled: true,
            buttonText: 'Deal',
            activeTab: 'Manual',
            betAmount: 0,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
    }

    componentWillUnmount() {
        let { engine} = this.state;
        engine.init = false;
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;

        this.props.gameCoin();
        engine.on(C.PLAY_BLACKJACK, (data) => this.play(data));
        engine.on(C.BUSTED_BLACKJACK, () => this.busted());
        engine.on(C.ERROR_BLACKJACK, (data) => this.error(data));
        engine.on("stop_playing", () => this.stopManual());

        this.winAmount(this.state.amount);

        if(isMobile()){
            this.setState({ mobile: true });
        }
    }
    
    stopManual() {
        this.setState({ buttonText: 'Deal', inputDisabled: false });
    }

    play(data) {
        if(this._isMounted){
            this.setState({ inputDisabled: true });
        }
    }
    handleTabChange = (tab) => {
        this.setState({ activeTab: tab });
      }

    busted(){
        if(this._isMounted){
            wait(2000).then(() => {
                this.setState({ inputDisabled: false });
            })
        }
    }

    placeBet(){
        if(this._isMounted){
            let { amount, engine } = this.state;

            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.amount = amount;
                    engine.coin   = this.props.coin;
                    engine.start();
                })
        }
    }

    error(data) {
        if(this._isMounted){
            sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false });
        }
    }

    handleBet(){
        // e.preventDefault();
        let { isLogged, amount } = this.state;

       // Check User
        if(!isLogged){
           return Event.emit('showAuthModal', true);
        }

        if(parseFloat(amount) <= 0){
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        this.placeBet();
    }

    handleInputChange(event){
        let target = event.target;
        let value = target.value;
        if(target.name === 'amount'){
            this.setState({amount:value})
            if(!isValidNumber(value)) return;
        }
        if(target.name === 'amount'){
            storage.setKey('lam', value);
        }
        else{
            this.setState({ [target.name]: value });
        }

        this.winAmount(value);
    }

    winAmount = (value) => {
        this.setState({ winAmount: forceSatoshiFormat(value) });
    }
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
        this.setState({ winAmount: forceSatoshiFormat(am) });
    }

    render() {
        let { bet, mobile, inputDisabled, InsuranceDisabled, splitDisabled, hitDisabled, standDisabled, doubleDisabled } = this.state;
        const { activeTab, betAmount } = this.state;
        const tabs = ['Manual', ''];
        return (
            <>



<Container2>


<div className="w-100 mt-1"
>
    <>
        <TabContainer>
            {tabs.map(tab => (
                <Tab
                    key={tab}
                    active={activeTab === tab}
                    onClick={() => this.handleTabChange(tab)}
                >
                    {tab}
                    {tab === 'Advanced' && <NewTag>New!</NewTag>}
                </Tab>
            ))}
        </TabContainer>
        {
            activeTab == "Manual" &&
            <>

                <AmountSection>
                    <Label>
                       Bet Amount Amount
                        <Info />
                    </Label>

                    <BetDisplay>
                        <img
                            src={"/assets/images/" + this.props.coin + ".png"}
                            className={"mini-coin-7"}
                            alt=""
                        />



                        <BetInput type="text"
                            //  disabled={inputDisabled2}
                           
                            disabled={true}
                            placeholder=".." value={this.state.amount} autoComplete={"off"} />
                        <MultiplierGroup>
                            {/* <MultiplierButton>1/2</MultiplierButton>
                            <MultiplierButton>2x</MultiplierButton> */}
                            <div className="input-group-append" >
                                <RangeCredit set={this.setAmount} />
                            </div>
                        </MultiplierGroup>
                    </BetDisplay>
                </AmountSection>
                <AmountSection>
                    <Label>
                        Win Amount
                        <Info />
                    </Label>

                    <BetDisplay>
                        <img
                            src={"/assets/images/" + this.props.coin + ".png"}
                            className={"mini-coin-7"}
                            alt=""
                        />



                        <BetInput type="text"
                            //  disabled={inputDisabled2}
                           
                           
                            disabled={true}  className="form-control text-left"
                                        placeholder=".." value={this.state.amount} autoComplete={"off"} />
                     
                    </BetDisplay>
                </AmountSection>
                <ButtonGrid>
                  <GameButton
                    disabled={hitDisabled}
                    id="hit"
                    type="button"
                  >
                    Hit
                  </GameButton>
                  
                  <GameButton
                    disabled={standDisabled}
                    id="stand"
                    type="button"
                  >
                    Stand
                  </GameButton>
                  
                  <GameButton
                    disabled={doubleDisabled}
                    id="double"
                    type="button"
                  >
                    Double Down
                  </GameButton>
                  
                  <GameButton
                    disabled={splitDisabled}
                    id="split"
                    type="button"
                  >
                    Split
                  </GameButton>
                  
                  <GameButton
                    disabled={InsuranceDisabled}
                    id="insurance"
                    type="button"
                    fullWidth={mobile}
                  >
                    Insurance
                  </GameButton>
                </ButtonGrid>
                <RollButton onClick={() => {

                    if (this.props.coin === 'INR') {
                        if (this.state.amount < 50) {
                            sendNotfication('Enter valid amount')

                        } else {
                            this.handleBet();
                        }
                    }
                    else if (this.props.coin === 'USDT') {
                        if (this.state.amount < 1) {
                            sendNotfication('Enter valid amount')

                        } else {
                            this.handleBet();

                        }
                    } else {
                        this.handleBet();

                    }
                }}
                >Deal</RollButton>
                <WarningContainer>
                    <DemoText>Betting with $0 will enter demo mode.</DemoText>
                </WarningContainer>
            </>
        }



    </>


</div>




</Container2>
                {/* <form className="w-100 bj" onSubmit={(e) => {
                e.preventDefault();
                if(this.props.coin==='INR'){
                  if(this.state.amount < 20){
                    sendNotfication('Enter valid amount')
                   
                  }else{
                    this.handleBet(e);
                  }
                }
                else if (this.props.coin ==='USDT'){
                   if(this.state.amount < 0.5){
                  sendNotfication('Enter valid amount')

                }else{
                  this.handleBet(e);

                }
              }else{
                this.handleBet(e);

              }
              }}>
                    <Row>
                        <Col md={2} xl={2} className={'col-4'}>
                            <Button variant={'btn btn-md btn-block my-1 btn-bet'}
                                    disabled={inputDisabled}
                                    id="deal"
                                    type="submit">
                                {this.state.buttonText}
                            </Button>
                        </Col>
                        <Col md={2} xl={2} className={'col-4'}>
                            <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                    disabled={hitDisabled}
                                    id="hit"
                                    type="button">
                               Hit
                            </Button>
                        </Col>
                        <Col md={2} xl={2} className={'col-4'}>
                            <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                    disabled={standDisabled}
                                    id="stand"
                                    type="button">
                               Stand
                            </Button>
                        </Col>
                        <Col md={2} xl={2} className={mobile ? 'col-6' : 'col-4'}>
                            <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                    disabled={doubleDisabled}
                                    id="double"
                                    type="button">
                               Double Down
                            </Button>
                        </Col>
                        <Col md={2} xl={2} className={mobile ? 'col-6' : 'col-4'}>
                            <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                    disabled={splitDisabled}
                                    id="split"
                                    type="button">
                                Split
                            </Button>
                        </Col>
                        <Col md={2} xl={2} className={mobile ? 'col-12' : 'col-4'}>
                            <Button variant={'btn btn-md btn-block mt-2 btn-hilo'}
                                    disabled={InsuranceDisabled}
                                    id="insurance"
                                    type="button">
                               Insurance
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xl={6} md={8} sm={12}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt=""/>
                                            BET
                                        </span>
                                    </div>
                                    <input disabled={inputDisabled} type="text" className="form-control text-left" id="amount" name="amount"
                                            placeholder="Enter Bet Amount" value={this.state.amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />
                                    <div className="input-group-append">
                                        <RangeCredit set={this.setAmount} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={4} sm={12}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt=""/>
                                            WIN AMOUNT
                                        </span>
                                    </div>
                                    <input
                                        disabled={true} type="text" className="form-control text-left"
                                        placeholder=".." value={this.state.amount} autoComplete={"off"} />
                                    <div className="input-group-append">
                                        <button className="btn bg-cs2 after-text no-hover npt nh" type="button"><i className="mdi mdi-close" /></button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div>
              <span style={{fontSize:11,marginLeft:5}}>
                {this.props.coin ==='INR' ?
              "(Minimum Bet - 20 INR)"
              :
              "(Minimum Bet - 0.0001  USDT)"
              }
              </span>
              </div>
                </form> */}
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
