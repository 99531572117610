import React, { useState } from "react";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {
  decode,
  encode,
  wait,
  isEmail,
  sendNotfication,
  getUID,
  __,
} from "../../../../Helper";
import ReactTooltip from "react-tooltip";
import C from "../../../../Constant";

import styled from "styled-components";

const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;
`;

const ProfileInfoTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #ffffff15;
`;

const ProfileInfoDetails = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const UserDetails = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const UserAvatar = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
`;

const UserDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const UserName = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-transform: capitalize;
`;

const UserID = styled.div`
  font-size: 12px;
  color: #b8b9bf;
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;
  margin-top: 15px;
`;

const ContactInfoTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  width: 100%;
  border-bottom: 1px solid #ffffff15;
  padding-bottom: 10px;
`;

const ContactInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #b8b9bf;
  width: 100%;
  justify-content: space-between;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  justify-content: space-between;
`;

const ContactInfoItemTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
`;

const ContactInfoDescription = styled.div`
  font-size: 14px;
  color: #b8b9bf;
`;

const ContactInfoDetails = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: absolute;
  background: #fff;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: zoomIn 0.3s ease;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
`;

const ModalCloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
`;

const ModalContent = styled.div`
  margin-top: 15px;
`;

const ModalFooter = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

const SaveButton = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const CancelButton = styled.button`
  padding: 10px 15px;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 10px;

  &:hover {
    background-color: #565e64;
  }
`;

const UpdateModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #323738;
  backdrop-filter: blur(2.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const UpdateModalPop = styled.div`
  width: 500px;
  padding: 15px;
  border-radius: 10px;
  background-color: #17181b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const UpdateModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UpdateModalTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
`;

const UpdateModalClose = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
`;

const UpdateModalBody = styled.div`
  max-height: max-content;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const UpdateModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const UpdateModalInput = styled.input`
  padding: 15px 10px;
  border: 1px solid #ffffff15;
  border-radius: 10px;
  font-size: 14px;
`;

const UpdateModalBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
`;

const EditButton = styled.button`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 10px !important;
  padding: 8px 25px !important;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  min-width: 100px !important;
  max-width: max-content;
  transition: all 0.3s ease;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    color: #fff !important;
    border: 1px solid #da22ff;
  }
`;

class AccountInfoSettings extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      firstUserName: storage.getKey("name"),
      firstEmail: storage.getKey("email"),
      email: storage.getKey("email"),
      username: storage.getKey("name"),
      checkbox2: storage.getKey("show_usd") === null ? false : true,
      uid: getUID,
      loadingEmail: false,
      loadingUsername: false,
      loadingPrivacy: false,
      loading2Fa: false,
      loading: true,
      loadingCheck: false,
      emailValidated: storage.getKey("email") !== null,
      emailUpdateModal: false,
      usernameUpdateModal: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    socket.on(C.EDIT_ACCOUNT, (data) => this.updateProfile(decode(data)));
  }

  componentWillMount() {
    wait(2000).then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateProfile(data) {
    if (this._isMounted) {
      this.setState({ loadingUsername: false, loadingEmail: false });
      if (data.status) {
        storage.setKey("name", data.username);
        storage.setKey("email", data.email);
        sendNotfication(
          "Your Account Setting was Updated !",
          "success",
          "top-center"
        );
        window.location.replace("../");
      } else {
        return sendNotfication(data.error, "success", "top-center");
      }
    }
  }

  save(input) {
    if (this._isMounted) {
      let { email, username, firstUserName, firstEmail } = this.state;

      if (input === "email") {
        if (email === firstEmail) {
          return sendNotfication(
            "Please Enter New Email Address !",
            "info",
            "top-center"
          );
        }

        if (!email) {
          document.getElementById("email").classList.add("border-danger");
          return;
        }

        if (email.trim() === "") {
          document.getElementById("email").classList.add("border-danger");
          return;
        }

        if (!isEmail(email)) {
          document.getElementById("email").classList.add("border-danger");
          return;
        }

        this.setState({ loadingEmail: true });

        wait(100).then(() => {
          socket.emit(
            C.EDIT_ACCOUNT,
            encode({
              email: email,
            })
          );
        });
      }

      if (input === "username") {
        if (username === firstUserName) {
          return sendNotfication(
            "Please Enter New Username !",
            "info",
            "top-center"
          );
        }

        if (!username) {
          return;
        }

        if (username.trim() === "") {
          return;
        }

        this.setState({ loadingUsername: true });

        wait(500).then(() => {
          socket.emit(
            C.EDIT_ACCOUNT,
            encode({
              username: username,
            })
          );
        });
      }
    }
  }

  handleCheckBox = () => {
    this.setState({ loadingCheck: true, checkbox: true });
    wait(1200).then(() => {
      this.setState({ checkbox: false, loadingCheck: false });
      return sendNotfication(
        "This feature will soon be added",
        "info",
        "top-center"
      );
    });
  };

  handleCheckBox2 = () => {
    this.setState({ loadingCheck2: true, checkbox2: true });
    wait(1200).then(() => {
      this.setState({ loadingCheck2: false, checkbox2: false });
      return sendNotfication(
        "This feature will soon be added",
        "info",
        "top-center"
      );
    });
  };

  toggleModal = (type, isOpen) => {
    this.setState({
      [`${type}UpdateModal`]: isOpen,
    });
  };

  render() {
    const {
      uid,
      email,
      username,
      emailValidated,
      emailUpdateModal,
      usernameUpdateModal,
      loadingEmail,
      loadingUsername,
    } = this.state;

    const inputStyle = {
      backgroundColor: "#25282c",
      borderRadius: "10px",
      color: "#fff",
      padding: "10px 12px",
      fontSize: "14px",
      width: "100%",
    };

    return (
      <>
        <ProfileInfoContainer>
          <ProfileInfoTitle>Profile Info</ProfileInfoTitle>
          <ProfileInfoDetails>
            <UserDetails>
              <UserAvatar
                src="//img2.distributedresourcestorage.com/avatar/59381535/s?t=0"
                alt="User Icon"
                width={40}
                height={40}
                style={{
                  borderRadius: "50%",
                  border: "2px solid #ffffff20",
                }}
              />
              <UserDetailsWrapper>
                <UserName>{username}</UserName>
                <UserID>User ID: {uid}</UserID>
              </UserDetailsWrapper>
            </UserDetails>
            <EditButton onClick={() => this.toggleModal("username", true)}>
              Edit
            </EditButton>
          </ProfileInfoDetails>
        </ProfileInfoContainer>

        <ContactInfoContainer>
          <ContactInfoTitle>Contact Info</ContactInfoTitle>
          <ContactInfoItem>
            <InfoWrapper>
              <ContactInfoItemTitle>E-mail Verification</ContactInfoItemTitle>
              <ContactInfoDescription>
                {emailValidated
                  ? `${email}`
                  : "Verify your email address is valid and accessible by you."}
              </ContactInfoDescription>
            </InfoWrapper>
            <EditButton
              onClick={() => this.toggleModal("email", true)}
              type="button"
            >
              {emailValidated ? "Change" : "Add"}
            </EditButton>
          </ContactInfoItem>
        </ContactInfoContainer>

        {/* Username Update Modal */}
        {usernameUpdateModal && (
          <UpdateModalWrapper>
            <UpdateModalPop>
              <UpdateModalHeader>
                <UpdateModalTitle>Update Username</UpdateModalTitle>
                <UpdateModalClose
                  type="button"
                  onClick={() => this.toggleModal("username", false)}
                >
                  <i className="fas fa-times" style={{ fontSize: "18px" }}></i>
                </UpdateModalClose>
              </UpdateModalHeader>
              <UpdateModalBody>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "110px",
                      height: "110px",
                      borderRadius: "50%",
                    }}
                    src="//img2.distributedresourcestorage.com/avatar/59381535/s?t=0"
                    alt="BidwinX User Icon"
                  />
                </div>
                <UpdateModalInput
                  type="username"
                  id="username"
                  autoComplete="off"
                  style={inputStyle}
                  value={this.state.username}
                  required={true}
                  onChange={(e) => this.setState({ username: e.target.value })}
                />
                <p style={{ margin: "0px", padding: "0px" }}>
                  Do not use special punctuation, otherwise your account may not
                  be supported.
                </p>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <EditButton
                    type="button"
                    onClick={(e) => this.save("username")}
                  >
                    {this.state.loadingUsername ? "Updating..." : "Update"}
                  </EditButton>
                </div>
              </UpdateModalBody>
            </UpdateModalPop>
          </UpdateModalWrapper>
        )}

        {/* Email Update Modal */}
        {emailUpdateModal && (
          <UpdateModalWrapper>
            <UpdateModalPop>
              <UpdateModalHeader>
                <UpdateModalTitle>Update Email</UpdateModalTitle>
                <UpdateModalClose
                  onClick={() => this.toggleModal("email", false)}
                >
                  <i className="fas fa-times" style={{ fontSize: "18px" }}></i>
                </UpdateModalClose>
              </UpdateModalHeader>
              <UpdateModalBody>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "110px",
                      height: "110px",
                      borderRadius: "50%",
                    }}
                    src="//img2.distributedresourcestorage.com/avatar/59381535/s?t=0"
                    alt="BidwinX User Icon"
                  />
                </div>
                <UpdateModalInput
                  type="email"
                  id="email"
                  autoComplete="off"
                  style={inputStyle}
                  placeholder="Enter New Email"
                  value={this.state.email}
                  required={true}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <p>
                  Do not use special punctuation, otherwise your account may not
                  be supported.
                </p>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <EditButton onClick={(e) => this.save("email")} type="button">
                    {this.state.loadingEmail ? "Updating..." : "Update"}
                  </EditButton>
                </div>
              </UpdateModalBody>
            </UpdateModalPop>
          </UpdateModalWrapper>
        )}
      </>
    );
  }
}

export default AccountInfoSettings;
