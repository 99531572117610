import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, X } from 'lucide-react';
import HelpCenter from './HelpCenter';
import UserAgreement from './UserAgreement';
import CoinAccuracyLimit from './CoinsAndCurrencyLimit';
import SupportHelp from './SupportHelp';
import FeeComponent from './FeeHelp';
import GoogleAuthenticatorComponent from './GoogleAuthenticator';
import FAQComponent from './FAQ';
import RegistrationAndLogin from './RegisterAndLogin';
import CryptocurrencyInfo from './CryptCurrencyInfo';
import PrivacyPolicyHelp from './PrivacyPolicyHelp';
import HGSwap from './HGSwapPolicy';
import TermsOfService from './TermsofServiceHelp';
import ProvidersHelp from './ProvidersHelp';

// Styled components
const HelpCenterContainer = styled.div`
  display: flex;
  color: #ffffff;

  gap: 10px;
  align-items: flex-start; // Aligns children to the top

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
  }
`;

const SideContent = styled.div`
  width: 200px;
  padding: 20px;
  background-color: #323738;
  border-radius: 20px;
  align-self: flex-start; // Prevents this from stretching vertically

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0;
    padding: 0;
    display: ${props => props.isOpen ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #1E2024;
    overflow-y: auto;
  }
`;

const SideContentHeader = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #31343C;
    position: sticky;
    top: 0;
  }
`;

const SideContentItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 13px;
  text-wrap: nowrap;
  color: ${props => props.active ? '#23EE89' : '#72767d'};
  font-weight: bold;
  &:hover {
    color: #ffffff;
  }

  @media (max-width: 768px) {
    padding: 15px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const MainContent = styled.div`
  flex: 1;
  min-height: 100%; // Ensures it takes at least full height of the container

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 10px;
  }
`;

// Main HelpCenter component
const ProvablyFair = () => {
    const [activeSection, setActiveSection] = useState('Provably fair');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const sideContentItems = [
        { name: 'Provably fair', component: HelpCenter },
        { name: 'User Agreement', component: UserAgreement },
        { name: 'Coin Accuracy Limit', component: CoinAccuracyLimit },
        { name: 'Support', component: SupportHelp },
        { name: 'Fee', component: FeeComponent },
        { name: 'Google Authenticator', component: GoogleAuthenticatorComponent },
        { name: 'FAQ', component: FAQComponent },
        { name: 'Cryptocurrency', component: CryptocurrencyInfo },
        { name: 'Privacy Policy', component: PrivacyPolicyHelp },
        { name: 'Registration and Lo...', component: RegistrationAndLogin },
        { name: 'Swap Policy', component: HGSwap },
        { name: 'Terms of Service', component: TermsOfService },
        { name: 'Providers', component: ProvidersHelp }
    ];

    const handleSectionChange = (section) => {
        setActiveSection(section);
        setIsMobileMenuOpen(false); // Close mobile menu after selection
    };

    const ActiveComponent = sideContentItems.find(item => item.name === activeSection)?.component || (() => null);

    return (
        <div style={{ marginTop: '18px' }}>
            <MobileMenuButton onClick={() => setIsMobileMenuOpen(true)}>
                <Menu size={20} />
                {activeSection}
            </MobileMenuButton>

            <HelpCenterContainer>
                <SideContent isOpen={isMobileMenuOpen}>
                    <SideContentHeader>
                        <span>Help Center</span>
                        <button 
                            onClick={() => setIsMobileMenuOpen(false)}
                            style={{ background: 'none', border: 'none', color: 'white', cursor: 'pointer' }}
                        >
                            <X size={24} />
                        </button>
                    </SideContentHeader>
                    {sideContentItems.map(item => (
                        <SideContentItem
                            key={item.name}
                            active={activeSection === item.name}
                            onClick={() => handleSectionChange(item.name)}
                        >
                            {item.name}
                        </SideContentItem>
                    ))}
                </SideContent>
                <MainContent>
                    <ActiveComponent />
                </MainContent>
            </HelpCenterContainer>
        </div>
    );
};

export default ProvablyFair;