import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { X } from 'lucide-react';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(50, 55, 56, 0.8);
  backdrop-filter: blur(4px);
  z-index: 1000;
  padding: 1rem;
  animation: ${fadeIn} 0.3s ease-out;
`;

const ModalCard = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background: linear-gradient(
    135deg,
    #323738 0%,
    #4A5354 100%
  );
  border-radius: 24px;
  padding: 1.5rem;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(82, 236, 130, 0.1);
  animation: ${slideIn} 0.3s ease-out;

  @media (min-width: 640px) {
    padding: 2.5rem;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, #52EC82, #89E977);
    z-index: -1;
    border-radius: 24px;
    opacity: 0.2;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: rgba(137, 233, 119, 0.6);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  @media (min-width: 640px) {
    top: 1.5rem;
    right: 1.5rem;
  }

  &:hover {
    color: #52EC82;
    transform: scale(1.1);
  }
`;

const ModalTitle = styled.h2`
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 2rem;
  background: linear-gradient(to right, #52EC82, #89E977);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(82, 236, 130, 0.3);

  @media (min-width: 640px) {
    font-size: 2.5rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  flex-direction: column;

  @media (min-width: 640px) {
    flex-direction: row;
  }
`;

const Input = styled.input`
  flex: 1;
  background: rgba(82, 236, 130, 0.1);
  border: 1px solid rgba(82, 236, 130, 0.2);
  padding: 1rem 1.5rem;
  color: white;
  font-size: 1rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: none;

  @media (min-width: 640px) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 12px;
    border-right: none;
    border-bottom: 1px solid rgba(82, 236, 130, 0.2);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  &:focus {
    outline: none;
    background: rgba(82, 236, 130, 0.15);
    border-color: rgba(82, 236, 130, 0.4);
  }
`;

const JoinButton = styled.div`
  background: linear-gradient(to right, #9FE871, #24EE89);
  border: none;
  padding: 1rem 2.5rem;
  color: black !important;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  @media (min-width: 640px) {
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0;
    min-width: 100px;
  }

  &:hover {
    background: #52EC82;
    box-shadow: 0 0 20px rgba(82, 236, 130, 0.3);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const InfoText = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
  line-height: 1.6;
  margin-top: 0.5rem;
  text-align: center;
  
  strong {
    color: #52EC82;
    font-weight: 500;
  }
`;

const JoinClubModal = ({ isOpen, onClose, onJoin }) => {
  const [clubCode, setClubCode] = useState('');
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 14);
  
  const formattedDate = expiryDate.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (clubCode.trim()) {
      onJoin(clubCode);
      setClubCode('');
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalCard>
        <CloseButton onClick={onClose}>
          <X size={24} />
        </CloseButton>

        <ModalTitle>Join a club</ModalTitle>

        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              type="text"
              value={clubCode}
              onChange={(e) => setClubCode(e.target.value)}
              placeholder="Enter a Club ID/Invite Code"
              autoFocus
            />
            <JoinButton >
              Join
            </JoinButton>
          </InputWrapper>

          <InfoText>
            Please join a club of your choice by <strong>{formattedDate}</strong>. 
            Once you join, you cannot switch clubs.
          </InfoText>
        </Form>
      </ModalCard>
    </ModalOverlay>
  );
};

export default JoinClubModal;