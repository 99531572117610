import React, { createContext, useContext, useState } from 'react';

const BettingContext = createContext();

export const BettingProvider = ({ children }) => {
    const [selectedBets, setSelectedBets] = useState([]);

    const addBet = (bet) => {
        setSelectedBets(prevBets => {
            // Check if bet already exists
            const existingBetIndex = prevBets.findIndex(
                existing => existing.matchId === bet.matchId && 
                           existing.category === bet.category && 
                           existing.type === bet.type
            );

            if (existingBetIndex !== -1) {
                // Remove bet if already selected
                return prevBets.filter((_, index) => index !== existingBetIndex);
            }

            // Add new bet
            return [...prevBets, { ...bet, id: Date.now() }];
        });
    };

    const removeBet = (betId) => {
        setSelectedBets(prevBets => prevBets.filter(bet => bet.id !== betId));
    };

    return (
        <BettingContext.Provider value={{ selectedBets, addBet, removeBet }}>
            {children}
        </BettingContext.Provider>
    );
};

export const useBetting = () => useContext(BettingContext); 