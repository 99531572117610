import React from 'react';
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons";
import storage from "../../../Storage";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import { isMobile, Event, wait, isValidNumber, forceSatoshiFormat, __, sendNotfication, playAudio } from "../../../Helper";
import C from "../../../Constant";

import styled from "styled-components";
import { ChevronDown, ChevronUp, Info } from "lucide-react";

const Container = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  max-width: 380px;
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const Container2 = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  width: 100%;  /* Full width on mobile */
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  /* Desktop size (768px and above) */
  @media (min-width: 768px) {
    max-width: 380px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #2a2a2a;
`;

const Tab = styled.button`
  padding: 8px 16px;
  color: ${props => props.active ? '#34d399' : '#808080'};
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#34d399' : 'transparent'};
  }
`;

const NewTag = styled.span`
  color: #fbbf24;
  font-size: 12px;
  margin-left: 4px;
`;

const AmountSection = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  color: #808080;
  margin-bottom: 4px;
  
  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
`;

const BetDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #292D2E;
  padding: 4px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  position:relative;
`;
const SwitcherContainer = styled.div`
display:flex;
  background-color: #292D2E;
  border-radius: 8px;
  padding: 2px;
  gap: 2px;
  margin-top:-14px;
  
`;


const SwitchButton = styled.div`
  border: none;
  padding: 13px 16px;
  border-radius: 8px;
  width:100px;
  font-size: 12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-warap:no-wrap;
  cursor: pointer;
  transition: all 0.2s ease;
  
  ${props => props.active ? `
    background-color: #3A4142;
    color: white;
  ` : `
    background: none;
    color: #6B7280;
  `}

  &:hover {
    color: ${props => props.active ? 'white' : '#9CA3AF'};
  }
`;
const BetInput = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const CurrencyDot = styled.div`
  width: 20px;
  height: 20px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const MultiplierGroup = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`;

const MultiplierButton = styled.button`
  background-color: #3A4142;
  padding: 4px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
`;

const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 24px;
`;

const ValueButton = styled.button`
  background-color: #2a2a2a;
  padding: 4px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  
  &:hover {
    background-color: #404040;
  }
`;

const WinAmountInput = styled.div`
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const RollButton = styled.div`
  width: 100%;
  padding: 12px;
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  color:black !important;
  display:flex;
  justify-content:center;
  align-items:center;
`;
const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 16px;
    margin-top: 8px;
`;

const DemoText = styled.div`
  color: #808080;
  font-size: 14px;
  text-align: center;
`;



const Container1 = styled.div`
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  max-width: 380px;
`;

const Section = styled.div`
  margin-bottom: 10px;
`;

const Label1 = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #808080;
  margin-bottom: 10px;
  font-size: 14px;
`;

const AmountDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const CurrencyIcon = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
`;

const ActionButton = styled.button`
  background-color: #404040;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #505050;
  }
`;

const ChevronGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #404040;
  border-radius: 6px;
  overflow: hidden;

  button {
    border: none;
    background: none;
    color: white;
    padding: 2px;
    cursor: pointer;
    
    &:hover {
      background-color: #505050;
    }
  }
`;

const ValueGrid1 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 16px;
`;

const ValueButton1 = styled.button`
  background-color: #2a2a2a;
  padding: 8px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const BetControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const ResetButton = styled.button`
  background-color: #2a2a2a;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 6px;
  padding: 6px 12px;
  gap: 8px;
  flex: 1;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  background: none;
  color: white;
  width: 60px;
  padding: 4px;
  font-size: 14px;
`;

const PercentageText = styled.span`
  color: #808080;
  font-size: 14px;
`;

const TabSwitcher = styled.div`
  display: flex;
  background: #323738;
  padding: 4px;
  border-radius: 12px;
  gap: 4px;
  width: 100%;
  margin: 0 auto 1rem auto;
`;

const TabOption = styled.button`
  background: ${props => props.active ? 'linear-gradient(90deg, #24ee89, #9fe871)' : 'transparent'};
  color: ${props => props.active ? '#1C1D21' : '#80A94'};
  border: none;
  padding: 8px 24px;
  border-radius: 8px;
  font-weight: ${props => props.active ? '600' : '500'};
  cursor: pointer;
  flex:1;
  transition: all 0.2s ease;
  font-size: 14px;
  
  &:hover {
    color: ${props => props.active ? '#1C1D21' : '#ffffff'};
    background: ${props => props.active ? 'linear-gradient(90deg, #24ee89, #9fe871)' : 'rgba(255, 255, 255, 0.05)'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;


class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      bet: 'manual',
      buttonText: 'Roll',
      started: false,
      isLogged: storage.getKey("logged") !== null ? true : false,
      amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
      disabled: false,
      payout: '1.0102',
      type: 'low',
      betNumber: 100000,
      firstBetNumber: 100000,
      onWin: 0,
      onLost: 0,
      stopOnWin: 0,
      isWin: false,
      activeTab: 'Manual',
      betAmount: 0,
      multiplier: '1/2',
      amount: 0,
      betsNumber: '∞',
      onWinIncrease: 0,
      onLossIncrease: 0,
      stopOnWin: 0,
      activeButton: 'reset', // or 'increase'
      activeWinButton: 'reset',
      activeLossButton: 'reset',
      onWinMode: 'reset',
      onLossMode: 'reset'
    };
    this.handleInputChangePayout = this.handleInputChangePayout.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  handleSwitch = (button) => {
    this.setState({ activeButton: button });
    if (this.props.onSwitch) {
      this.props.onSwitch(button);
    }
  };

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  }

  componentWillUnmount() {
    let { engine, started } = this.state;
    engine.init = false;
    this._isMounted = false;

    document.removeEventListener('mousedown', this.handleClickOutside);

    try {
      if (started) {
        document.getElementById('bet').click()
      }
    }
    catch (e) { }
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;

    document.addEventListener('mousedown', this.handleClickOutside);

    this.props.gameCoin();
    engine.on(C.BUSTED_HASH_DICE, (data) => this.busted(data));
    engine.on(C.ERROR_HASH_DICE, (data) => this.error(data));
    engine.on("stop_hash_dice", () => this.stopAuto());
    engine.on("stop_playing", () => this.stopManual());

    if (isMobile()) {
      this.setState({ mt: "mt-2" })
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      let value = parseFloat(this.state.payout);

      if (value < 1.0102)
        value = 1.0102;

      if (value > 9000)
        value = 9000;

      if (!isValidNumber(value))
        value = 1.0102;

      this.setState({ payout: parseFloat(value).toFixed(4) });
    }
  }

  stopManual() {
    this.setState({ started: false, buttonText: 'Roll', disabled: false });
  }

  stopAuto() {
    if (this._isMounted) {
      this.setState({ started: false, buttonText: 'Start Auto Mode', disabled: false });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, 'info', 'top-center');

      if (data.code === 'credit')
        this.props.setWallet(true, data.uid);

      this.setState({ disabled: false });

      if (this.state.started) {
        this.setState({ started: false, buttonText: 'Start Auto Mode' });
      }
    }
  }

  busted(data) {
    if (this._isMounted) {
      let { engine, bet, started } = this.state;
      let isWin = false;
      if (parseFloat(data.profit) >= 0) {
        isWin = true;
        this.setState({ isWin: true });
      }
      else {
        isWin = false;
        this.setState({ isWin: false });
      }
      if (bet === 'auto') {
        if (started) {
          wait(1100)
            .then(() => {
              engine.isWin = isWin;
              engine.roundProfit = __.toNumber(data.profit) + __.toNumber(engine.roundProfit);
              this.placeAutoBet();
            })
        }
      }
      wait(1000).then(() => {
        this.setState({ disabled: false });
      })

      wait(500).then(() => {
        if (isWin) {
          playAudio('win.mp3');
        }
        else {
          // playAudio('lost.mp3');
        }
      })
    }
  }

  setBet(e) {
    if (this._isMounted) {

      e.preventDefault();

      let self = this;

      let { isLogged, amount, bet, started } = this.state;

      // Check User
      if (!isLogged) {
        return Event.emit('showAuthModal', true);
      }

      this.setState({ disabled: true });

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.00000010) });
      }
      else {
        amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
        this.setState({ amount: forceSatoshiFormat(amount) });
      }

      if (bet === 'auto') {
        if (started) {
          this.setState({ started: false, buttonText: 'Start Auto Mode', disabled: false });
          return false;
        }
        else {
          this.setState({ started: true, buttonText: 'Stop Auto Mode' });
          this.placeAutoBet();
        }
      }
      else {
        this.placeBet();
      }
    }
  }

  // placeAutoBet() {
  //     if (this._isMounted) {
  //         let { engine, amount, payout, type, betNumber, firstBetNumber, onWin, onLost, stopOnWin } = this.state;

  //         wait(500).then(() => {

  //             engine.init = true;
  //             engine.amount = amount;
  //             engine.type = type;
  //             engine.payout = payout;
  //             engine.coin = this.props.coin;
  //             engine.betNumber = firstBetNumber;
  //             engine.stopOnWin = parseInt(stopOnWin);
  //             engine.onWin = parseInt(onWin);
  //             engine.onLost = parseInt(onLost);
  //             engine.autoStart();
  //             this.setState({ betNumber: betNumber - 1 });
  //         })
  //     }
  // }
  placeAutoBet() {
    if (this._isMounted) {
      let {
        engine,
        amount,            // Current bet amount
        payout,            // Current payout value
        type,              // Bet type (e.g., low/high)
        betNumber,         // Total number of remaining bets
        firstBetNumber,    // Initial number of bets
        onWin,             // Percentage increase on win
        onLost,            // Percentage increase on loss
        stopOnWin,         // Stop betting after a win
        isWin,             // Whether the last bet was a win
      } = this.state;
      const initialAmount = amount;
      // Delay for each bet
      wait(500).then(() => {
        console.log("Current bet number:", betNumber);
        console.log("IsWin:", isWin);

        // Adjust amount based on win or loss
        if (isWin) {
          // If onWin is set, increase the initialAmount by the percentage
          if (onWin !== 0) {
            const newAmount = initialAmount * (1 + parseFloat(onWin) / 100);
            this.setState({ amount: newAmount });
            console.log(`Win: Reset to initial amount and apply onWin percentage. New amount: ${newAmount}`);
          } else {
            // Otherwise, reset to initial bet amount
            this.setState({ amount: initialAmount });
            console.log(`Win: Reset to initial amount. New amount: ${initialAmount}`);
          }

          // If stopOnWin is true, end the auto-bet after a win
          if (stopOnWin) {
            console.log("Auto-betting stopped due to win.");
            engine.trigger.emit("stop_classic_dice");
            return;  // Stop further betting
          }

        } else {
          // On loss, increase the bet amount by the onLost percentage
          if (onLost !== 0) {
            const newAmount = amount * (1 + parseFloat(onLost) / 100);
            this.setState({ amount: newAmount });
            console.log(`Loss: Increase bet by onLost percentage. New amount: ${newAmount}`);
          }
        }

        // Decrease the betNumber after each bet
        this.setState(prevState => ({ betNumber: prevState.betNumber - 1 }));

        // Update engine with the new bet amount and other parameters
        const updatedAmount = this.state.amount;
        engine.init = true;
        engine.amount = parseFloat(updatedAmount);
        engine.payout = payout;
        engine.type = type;
        engine.coin = this.props.coin;
        engine.betNumber = firstBetNumber;
        engine.stopOnWin = parseInt(stopOnWin);
        engine.onWin = parseInt(onWin);
        engine.onLost = parseInt(onLost);

        // Continue auto-betting if there are remaining bets
        if (this.state.betNumber > 0) {
          engine.autoStart(); // Continue betting
        } else {
          console.log("Auto-betting has finished.");
          engine.trigger.emit("stop_classic_dice");
        }
      });
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { amount, type, payout, engine } = this.state;

      wait(100)
        .then(() => {
          engine.game = 'hash_dice';
          engine.init = true;
          engine.amount = amount;
          engine.payout = payout;
          engine.type = type;
          engine.coin = this.props.coin;
          engine.start();
        })
    }
  }

  handleChange(value) {
    if (this._isMounted) {
      this.setState({ type: value });
      Event.emit('change_type', value);
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;

    if (target.name === 'amount' || target.name === 'stopOnWin') {
      this.setState({ amount: value })
      if (!isValidNumber(value)) return;
    }

    if (target.name === 'amount') {
      storage.setKey('lam', value);
    }
    else {
      this.setState({ [target.name]: value });
    }
  };

  handleInputChangePayout(event) {
    let value = event.target.value;

    if (value.length > 5) return;

    this.setState({ payout: value });
  };

  betMode = (type) => {
    let text;

    if (type === 'auto') {
      text = 'Start Auto Mode';
    }
    else {
      text = 'Roll';
    }
    this.setState({ bet: type, buttonText: text });
  };

  setAmount = (val) => {
    var am = forceSatoshiFormat(val);
    this.setState({ amount: am });
    storage.setKey('lam', am);
  }

  setNumber = (e, num) => {
    e.preventDefault();
    this.setState({ betNumber: num });
    this.setState({ firstBetNumber: num });
  };

  handleWinSwitch = (mode) => {
    this.setState({ 
      activeWinButton: mode,
      onWinMode: mode,
      onWin: mode === 'reset' ? 0 : this.state.onWin
    });
  };

  handleLossSwitch = (mode) => {
    this.setState({ 
      activeLossButton: mode,
      onLossMode: mode,
      onLost: mode === 'reset' ? 0 : this.state.onLost
    });
  };

  render() {
    let { disabled, amount, bet, type, started, mt, inputDisabled } = this.state;
    const betValues = [0.001, 0.01, 0.1, 1];
    const betNumbers = ['∞', '∞', '10', '100'];
    const { activeButton } = this.state;
    const { activeTab, betAmount } = this.state;
    const tabs = ['Manual', 'Auto', ''];
    return (
      <>

        <Container2>

          <>
            <TabContainer>
              {tabs.map(tab => (
                <Tab
                  key={tab}
                  active={activeTab === tab}
                  onClick={() => this.handleTabChange(tab)}
                >
                  {tab}
                  {tab === 'Advanced' && <NewTag>New!</NewTag>}
                </Tab>
              ))}
            </TabContainer>
            {
              activeTab == "Manual" &&
              <>
                <TabSwitcher>
                  <TabOption
                    active={type === 'low'}
                    disabled={disabled}
                    onClick={e => this.handleChange('low')}
                  >
                    Low
                  </TabOption>
                  <TabOption
                    active={type === 'high'}
                    disabled={disabled}
                    onClick={e => this.handleChange('high')}
                  >
                    High
                  </TabOption>
                </TabSwitcher>

                <AmountSection>
                  <Label>
                    Amount
                    <Info />
                  </Label>

                  <BetDisplay>
                    <img
                      src={"/assets/images/" + this.props.coin + ".png"}
                      className={"mini-coin-7"}
                      alt=""
                    />



                    <BetInput type="text"
                      disabled={inputDisabled}
                      className="form-control"
                      id="amount"
                      name="amount"
                      value={this.state.amount}
                      autoComplete={"off"}
                      onKeyUp={this.handleInputChange}
                      onChange={this.handleInputChange} />
                    <MultiplierGroup>
                      <MultiplierButton>1/2</MultiplierButton>
                      <MultiplierButton>2x</MultiplierButton>
                      <div className="input-group-append">
                        <RangeCredit set={this.setAmount} />
                      </div>
                    </MultiplierGroup>
                  </BetDisplay>
                </AmountSection>

                <Label>Payout</Label>
                <BetDisplay>
                  <BetInput ref={this.wrapperRef}
                    disabled={disabled} type="text" className="form-control text-left" id="payout"
                    name="payout" min="2" max="9900.0" value={this.state.payout} autoComplete={"off"}
                    onChange={this.handleInputChangePayout} required={true} />
                </BetDisplay>

                <RollButton onClick={() => {
                  {

                    if (this.props.coin === 'INR') {
                      if (this.state.amount < 50) {
                        sendNotfication('Enter valid amount')

                      } else {
                        this.handleBet();
                      }
                    }
                    else if (this.props.coin === 'USDT') {
                      if (this.state.amount < 1) {
                        sendNotfication('Enter valid amount')

                      } else {
                        this.handleBet();

                      }
                    } else {
                      this.handleBet();

                    }
                  }

                }}>Roll Now</RollButton>
                <WarningContainer>
                  <DemoText>Betting with $0 will enter demo mode.</DemoText>
                </WarningContainer>
              </>
            }

            {

              activeTab == "Auto" && (
                <>
                  <TabSwitcher>
                    <TabOption
                      active={type === 'low'}
                      disabled={disabled}
                      onClick={e => this.handleChange('low')}
                    >
                      Low
                    </TabOption>
                    <TabOption
                      active={type === 'high'}
                      disabled={disabled}
                      onClick={e => this.handleChange('high')}
                    >
                      High
                    </TabOption>
                  </TabSwitcher>

                  <Section>
                    <Label>
                      Amount <Info size={16} />
                    </Label>

                    <BetDisplay>
                      <img
                        src={"/assets/images/" + this.props.coin + ".png"}
                        className={"mini-coin-7"}
                        alt=""
                      />



                      <BetInput type="text"
                        disabled={inputDisabled}
                        className="form-control"
                        id="amount"
                        name="amount"
                        value={this.state.amount}
                        autoComplete={"off"}
                        onKeyUp={this.handleInputChange}
                        onChange={this.handleInputChange} />
                      <MultiplierGroup>
                        <MultiplierButton>1/2</MultiplierButton>
                        <MultiplierButton>2x</MultiplierButton>
                        <div className="input-group-append">
                          <RangeCredit set={this.setAmount} />
                        </div>
                      </MultiplierGroup>
                    </BetDisplay>
                  </Section>

                  <Label>Number of Bets</Label>
                  <BetDisplay>




                    <BetInput
                      type="number"
                      disabled={inputDisabled}
                      className="form-control"
                      required={true}
                      value={this.state.betNumber}
                      onChange={(e) => this.setNumber(e, e.target.value)} />
                    <MultiplierGroup>
                      <MultiplierButton onClick={(e) => this.setNumber(e, 10)}>10</MultiplierButton>
                      <MultiplierButton onClick={(e) => this.setNumber(e, 50)}>50</MultiplierButton>
                      <MultiplierButton onClick={(e) => this.setNumber(e, 100)}>100</MultiplierButton>

                    </MultiplierGroup>
                  </BetDisplay>



                  <Section>
                    <Label>On win</Label>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <SwitcherContainer>
                        <SwitchButton
                          active={this.state.activeWinButton === 'reset'}
                          onClick={() => this.handleWinSwitch('reset')}
                        >
                          Reset
                        </SwitchButton>
                        <SwitchButton
                          active={this.state.activeWinButton === 'increase'}
                          onClick={() => this.handleWinSwitch('increase')}
                        >
                          Increase by
                        </SwitchButton>
                      </SwitcherContainer>

                      <BetDisplay>



                        <BetInput 
                          disabled={this.state.onWinMode === 'reset' || inputDisabled}
                          type="number"
                          className="form-control"
                          name="onWin"
                          min="0"
                          autoComplete={"off"}
                          value={this.state.onWin}
                          onChange={(e) => this.setState({ onWin: e.target.value })}
                        />
                        <PercentageText>%</PercentageText>

                      </BetDisplay>
                    </div>
                  </Section>

                  <Section>
                    <Label>On Loss</Label>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <SwitcherContainer>
                        <SwitchButton
                          active={this.state.activeLossButton === 'reset'}
                          onClick={() => this.handleLossSwitch('reset')}
                        >
                          Reset
                        </SwitchButton>
                        <SwitchButton
                          active={this.state.activeLossButton === 'increase'}
                          onClick={() => this.handleLossSwitch('increase')}
                        >
                          Increase by
                        </SwitchButton>
                      </SwitcherContainer>

                      <BetDisplay>



                        <BetInput
                          disabled={this.state.onLossMode === 'reset' || inputDisabled}
                          type="number"
                          className="form-control"
                          name="onLost"
                          min="0"
                          autoComplete={"off"}
                          value={this.state.onLost}
                          onChange={(e) => this.setState({ onLost: e.target.value })}
                        />
                        <PercentageText>%</PercentageText>

                      </BetDisplay>
                    </div>
                  </Section>

                  <Section>
                    <Label>Stop On Win</Label>
                    <BetDisplay>
                      <img
                        src={"/assets/images/" + this.props.coin + ".png"}
                        className={"mini-coin-7"}
                        alt=""
                      />



                      <BetInput type="text"
                        disabled={inputDisabled}

                       
                        name="stopOnWin"
                        placeholder=".."
                        value={this.state.stopOnWin}
                        autoComplete={"off"}
                        onChange={this.handleInputChange} />

                    </BetDisplay>
                  </Section>
                  <RollButton onClick={() => {
                    {

                      if (this.props.coin === 'INR') {
                        if (this.state.amount < 50) {
                          sendNotfication('Enter valid amount')

                        } else {
                          this.handleBet();
                        }
                      }
                      else if (this.props.coin === 'USDT') {
                        if (this.state.amount < 1) {
                          sendNotfication('Enter valid amount')

                        } else {
                          this.handleBet();

                        }
                      } else {
                        this.handleBet();

                      }
                    }

                  }}>Start Auto Bet</RollButton>

                  <WarningContainer>
                    <DemoText>Betting with $0 will enter demo mode.</DemoText>
                  </WarningContainer>
                </>
              )
            }

          </>


        </Container2>

        {/* <>
          <Form onSubmit={(e) => this.setBet(e)}>
            <div className={" text-center btn-group-toggle mb-3 " + mt} data-toggle="buttons">
              <label className={'' + type === 'low' ? 'btn bg-cs2 btn-xs hslb  active' : 'btn bg-cs2 btn-xs hslb '}>
                <input disabled={disabled} onClick={e => this.handleChange('low')} type="radio" /> Low
              </label>
              <label className={'' + type === 'high' ? 'btn bg-cs2 btn-xs hsrb  active' : 'btn bg-cs2 btn-xs hsrb '}>
                <input disabled={disabled} onClick={e => this.handleChange('high')} type="radio" /> High
              </label>
            </div>
            <Row className={'mt-1'}>
              <Col xl={6} md={6} sm={12}>
                <div className="form-group mb-1">
                  <div className="input-group">
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                        BET
                      </span>
                    </div>
                    <input
                      disabled={disabled}
                      type="text"
                      id="amount"
                      name="amount"
                      placeholder="Enter Bet Amount"
                      value={amount}
                      autoComplete={"off"}
                      onKeyUp={this.handleInputChange}
                      onChange={this.handleInputChange}
                      className="form-control text-left"
                      required={true}
                    />
                    <div className="input-group-append">
                      <RangeCredit set={this.setAmount} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={6} md={6} sm={12}>
                <div className={"form-group mb-1 bet-input"}>
                  <div className="input-group">
                    <div className="input-group-append">
                      <span className="input-group-text">PAYOUT</span>
                    </div>
                    <input
                      ref={this.wrapperRef}
                      disabled={disabled} type="text" className="form-control text-left" id="payout"
                      name="payout" min="2" max="9900.0" value={this.state.payout} autoComplete={"off"}
                      onChange={this.handleInputChangePayout} required={true} />
                    <div className="input-group-append">
                      <button className="btn bg-cs2 after-text no-hover npt nh" type="button">x</button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div>
              <span style={{ fontSize: 11, marginLeft: 5 }}>
                {this.props.coin === 'INR' ?
                  "(Minimum Bet - 200 INR)"
                  :
                  "(Minimum Bet - 0.0001  USDT)"
                }
              </span>
            </div>
            <Row>
              <Col md={5} sm={12} className="m-auto">
                {bet === 'manual' &&
                  <div className={"form-group rev mt-2 mb-0"}>
                    <Button variant={'btn btn-block btn-bet btn-xl mb-2'}
                      id={'bet'}
                      disabled={disabled}
                      type="submit">
                      {this.state.buttonText}
                    </Button>
                  </div>
                }
              </Col>
            </Row>
            {bet === 'auto' &&
              <>
                <Row>
                  <Col xl={6} md={6} sm={12}>
                    <div className={"form-group mb-1 bet-input"}>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            Bets Num
                          </span>
                        </div>
                        <input
                          type="number"
                          disabled={disabled}
                          className="form-control"
                          required={true}
                          value={this.state.betNumber}
                          onChange={e => this.setNumber(e, e.target.value)} />
                        <div className="input-group-append">
                          <button onClick={e => this.setNumber(e, 10)} className={'btn btn-s-4 btn-xs'}>10</button>
                          <button onClick={e => this.setNumber(e, 50)} className={'btn btn-s-4 btn-xs'}>50</button>
                          <button onClick={e => this.setNumber(e, 100)} className={'btn btn-s-4 btn-xs'}>100</button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} md={6} sm={12}>
                    <div className={"form-group mb-1 bet-input"}>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            Increase Amount On Win
                          </span>
                        </div>
                        <input
                          disabled={disabled}
                          type="number"
                          className="form-control"
                          name="onWin"
                          min="0"
                          max="100"
                          autoComplete={"off"}
                          value={this.state.onWin}
                          onChange={e => this.setState({ onWin: e.target.value })} />
                        <div className="input-group-append">
                          <button className="btn bg-cs2 after-text no-hover npt nh" type="button">%</button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} md={6} sm={12}>
                    <div className={"form-group mb-1 bet-input"}>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            Increase Amount On Lost
                          </span>
                        </div>
                        <input
                          disabled={disabled}
                          type="number"
                          className="form-control"
                          name="onLost"
                          min="0"
                          max="100"
                          autoComplete={"off"}
                          value={this.state.onLost}
                          onChange={e => this.setState({ onLost: e.target.value })} />
                        <div className="input-group-append">
                          <button className="btn bg-cs2 after-text no-hover npt nh" type="button">%</button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} md={6} sm={12}>
                    <div className={"form-group mb-1 bet-input"}>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            Stop On Win
                          </span>
                        </div>
                        <input
                          disabled={disabled}
                          type="text"
                          placeholder=".."
                          className="form-control"
                          name="stopOnWin"
                          value={this.state.stopOnWin}
                          autoComplete={"off"}
                          onChange={this.handleInputChange} />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-2 mr-1'} alt="" />
                            {this.props.coin}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className={'m-auto'}>
                    <div className={"form-group mt-0 mb-0 rev"}>
                      <Button variant={'btn btn-md btn-block my-2 btn-bet'}
                        type="submit">
                        {this.state.buttonText}
                        {started &&
                          <><div className={'ml-2 spinner-border spinner-border-sm'} /></>
                        }
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            }
          </Form>
          <div className="btn-group btn-group-toggle mb-1 ml-1" data-toggle="buttons">
            <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
              <input disabled={disabled} onClick={e => this.betMode('manual')} type="radio" /> Manual
            </label>
            <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
              <input disabled={disabled} onClick={e => this.betMode('auto')} type="radio" /> Auto
            </label>
          </div>
        </> */}
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string
};

const mapStateToProps = state => ({
  coin: state.items.coin,
  credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
