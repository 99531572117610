import React from 'react';
import styled from 'styled-components';
import { useBetting } from '../../../../../context/BettingContext';
import { 
    Play, 
    RefreshCw, 
    Flag, 
    Info,
    ChevronRight,
    Star
} from 'lucide-react';
import { useHistory } from 'react-router-dom';

// Styled Components
const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  background: #2c3e50;
  color: white;
  padding: 10px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  background: white;
`;

const Match = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
`;

const MatchInfo = styled.div`
  flex: 1;
`;

const MatchTitle = styled.div`
  color: #3498db;
  font-weight: bold;
  // margin-bottom: 5px;
  font-size: 10px;
`;

const Status = styled.span`
  color: #27ae60;
  font-size: 10px;
  margin-left: 12px;
`;

const OddsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const OddsSection = styled.div`
  display: flex;
  gap: 2px;
`;

const OddsBox = styled.div`
  background: ${props => props.blue ? '#72BBEF' : '#FFB6C1'};
  padding: 10px;
  min-width: 60px;
  text-align: center;
  border-radius: 4px;
  cursor: ${props => props.suspended ? 'not-allowed' : 'pointer'};
  color: ${props => props.suspended ? '#e74c3c' : 'black'} !important;
  opacity: ${props => props.suspended ? 0.7 : 1};
  transition: all 0.2s ease;

  &:hover {
    opacity: ${props => props.suspended ? 0.7 : 0.8};
  }
`;

const IconGroup = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 5px;
`;

const Icon = styled.span`
  background: #2ecc71;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
`;

const PTE = styled.div`
  color: #95a5a6;
  margin-right: 10px;
  font-size: 14px;
`;

const LiveDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #2ecc71;
  border-radius: 50%;
  margin-right: 8px;
  animation: blink 1s ease-in-out infinite;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;


const CricketBettingUI = () => {
  const { addBet } = useBetting();

  const matches = [
    {
      id: 1,
      team1: 'Bangladesh Women',
      team2: 'Ireland Women',
      status: 'In-Play',
      pte: 'PTE10.05M',
      isSuspended: false,
      odds: {
        matched: { back: '1.01', lay: '1.02' },
        x: { back: '--', lay: '--' },
        two: { back: '1000', lay: '--' }
      }
    },
    {
      id: 2,
      team1: 'Biratnagar Kings',
      team2: 'Janakpur Bolts',
      status: 'In-Play',
      pte: 'PTE0',
      isSuspended: false,
      odds: {
        matched: { back: '1.5', lay: '1.6' },
        x: { back: '--', lay: '--' },
        two: { back: '2.5', lay: '2.6' }
      }
    },
    {
      id: 3,
      team1: 'India Under-19s',
      team2: 'Pakistan Under-19s',
      status: 'In-Play',
      pte: 'PTE0',
      isSuspended: false,
      odds: {
        matched: { back: '1.8', lay: '1.9' },
        x: { back: '--', lay: '--' },
        two: { back: '2.1', lay: '2.2' }
      }
    },
    {
      id: 4,
      team1: 'Lions',
      team2: 'Warriors',
      status: 'In-Play',
      pte: 'PTE0',
      isSuspended: false,
      odds: {
        matched: { back: '2.1', lay: '2.2' },
        x: { back: '--', lay: '--' },
        two: { back: '1.8', lay: '1.9' }
      }
    },
    {
      id: 5,
      team1: 'South Africa',
      team2: 'Sri Lanka',
      status: 'In-Play',
      pte: 'PTE20.34M',
      isSuspended: false,
      odds: {
        matched: { back: '1.01', lay: '1.02' },
        x: { back: '1000', lay: '--' },
        two: { back: '1000', lay: '--' }
      }
    },
    {
      id: 6,
      team1: 'Sydney Thunder SRL T20',
      team2: 'Melbourne Renegades SRL T20',
      status: 'In-Play',
      pte: 'PTE0',
      isSuspended: false,
      odds: {
        matched: { back: '1.9', lay: '2.0' },
        x: { back: '--', lay: '--' },
        two: { back: '1.9', lay: '2.0' }
      }
    },
    {
      id: 7,
      team1: 'Western Province',
      team2: 'Titans',
      status: 'In-Play',
      pte: 'PTE0',
      isSuspended: false,
      odds: {
        matched: { back: '2.5', lay: '2.6' },
        x: { back: '--', lay: '--' },
        two: { back: '1.6', lay: '1.7' }
      }
    },
    {
      id: 8,
      team1: 'Bangla Tigers',
      team2: 'Up Nawabs',
      status: 'In-Play',
      pte: 'PTE95.019',
      isSuspended: true,
      odds: {
        matched: { back: 'Suspend', lay: 'Suspend' },
        x: { back: 'Suspend', lay: 'Suspend' },
        two: { back: 'Suspend', lay: 'Suspend' }
      }
    },
    {
      id: 9,
      team1: 'Northern Warriors',
      team2: 'Abu Dhabi',
      status: 'In-Play',
      pte: 'PTE32.52M',
      isSuspended: false,
      odds: {
        matched: { back: '120', lay: '190' },
        x: { back: '--', lay: '--' },
        two: { back: '--', lay: '1.01' }
      }
    },
    {
      id: 10,
      team1: 'Mi Cape Town SRL T20',
      team2: 'Joburg Super Kings SRL T20',
      status: 'In-Play',
      pte: 'PTE0',
      isSuspended: false,
      odds: {
        matched: { back: '1.8', lay: '1.9' },
        x: { back: '--', lay: '--' },
        two: { back: '2.1', lay: '2.2' }
      }
    }
  ];

  const handleOddsClick = (match, category, type, odds) => {
    if (match.isSuspended || odds === '--' || odds === 'Suspend') return;

    // Determine the selection name based on category
    let selectionName = '';
    if (category === 'matched') {
        selectionName = match.team1;  // First team for matched category
    } else if (category === 'x') {
        selectionName = 'The Draw';   // Draw for x category
    } else if (category === 'two') {
        selectionName = match.team2;  // Second team for two category
    }

    const betDetails = {
        matchId: match.id,
        matchTitle: `${match.team1} v ${match.team2}`,
        team1: match.team1,
        team2: match.team2,
        selectionName: selectionName, // Add selection name
        category,
        type,
        odds,
        isBlue: type === 'back',
        stake: '',
        liability: '0.00'
    };

    console.log('Selected Bet:', betDetails);
    addBet(betDetails);
  };
const history=useHistory();
const handleMatchClick = (match) => {
  history.push({
    pathname: `/sports/markets/${match.id}`,
    state: {
      matchData: {
        id: match.id,
        team1: match.team1,
        team2: match.team2,
        status: match.status,
        pte: match.pte,
        odds: match.odds,
        isSuspended: match.isSuspended
      }
    }
  });
};


  return (
    <Container>
      {/* <Header>
        <div>Cricket</div>
      </Header> */}
      <Content>
        <Match style={{ backgroundColor: '#f8f9fa' }}>
          <MatchInfo style={{ flex: 1 }} />
          <OddsContainer>
            <OddsSection>
              <div style={{ textAlign: 'center', minWidth: '120px' }}>Matched</div>
            </OddsSection>
            <OddsSection>
              <div style={{ textAlign: 'center', minWidth: '120px' }}>1</div>
            </OddsSection>
            <OddsSection>
              <div style={{ textAlign: 'center', minWidth: '120px' }}>X</div>
            </OddsSection>
            <OddsSection>
              <div style={{ textAlign: 'center', minWidth: '120px' }}>2</div>
            </OddsSection>
          </OddsContainer>
        </Match>
        {matches.map((match, index) => (
           <Match key={index}>
           <MatchInfo onClick={() => handleMatchClick(match)}>
             <TitleContainer>
               <LiveDot />
               <MatchTitle>{`${match.team1} v ${match.team2}`}</MatchTitle>
             </TitleContainer>

             <div style={{display:'flex',alignItems:'center',gap:'4px'}}>
             <Status>{match.status}</Status>
             <IconGroup>
               <Icon><Play size={12} /></Icon>
               <Icon><RefreshCw size={12}/></Icon>
               <Icon><Flag size={12}/></Icon>
               <Icon><Info size={12}/></Icon>
             </IconGroup>
             </div>
            
           </MatchInfo>
            <PTE>{match.pte}</PTE>
            <OddsContainer>
              <OddsSection>
                <OddsBox 
                  blue 
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'matched', 'back', match.odds.matched.back)}
                >
                  {match.odds.matched.back}
                </OddsBox>
                <OddsBox 
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'matched', 'lay', match.odds.matched.lay)}
                >
                  {match.odds.matched.lay}
                </OddsBox>
              </OddsSection>
              <OddsSection>
                <OddsBox 
                  blue 
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'x', 'back', match.odds.x.back)}
                >
                  {match.odds.x.back}
                </OddsBox>
                <OddsBox 
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'x', 'lay', match.odds.x.lay)}
                >
                  {match.odds.x.lay}
                </OddsBox>
              </OddsSection>
              <OddsSection>
                <OddsBox 
                  blue 
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'two', 'back', match.odds.two.back)}
                >
                  {match.odds.two.back}
                </OddsBox>
                <OddsBox 
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'two', 'lay', match.odds.two.lay)}
                >
                  {match.odds.two.lay}
                </OddsBox>
              </OddsSection>
            </OddsContainer>
          </Match>
        ))}
      </Content>
    </Container>
  );
};

export default CricketBettingUI;