import React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin:12px 0px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-image: ${props => props.background || '#2A2A2A'};
  border-radius: 12px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 160px;
  background-color: rgba(50, 55, 56, 1); /* Correctly applied RGBA background color */
`;

const Card1 = styled.div`
  background-image: ${props => `url(${props.bgUrl})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 160px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }
`;


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const BonusText = styled.h2`
  color: #00FF9D;
  font-size: 24px;
  margin: 0;
  font-weight: 600;
`;

const SubText = styled.p`
  color: white;
  font-size: 18px;
  margin: 0;
`;

const Status = styled.div`
  background: #1A1A1A;
  padding: 8px 16px;
  border-radius: 20px;
  color: #00FF9D;
  font-size: 14px;
  display: inline-block;
  margin-top: 8px;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

const BonusCards = () => {
    const cards = [
        {
            bonusText: "100% Bonus +",
            subText: "100 Free Bet In Casino",
            status: "In Progress",
            image: "https://bc.game/modules/bonus2/assets/banner-casino-87e5d3e8.png",
            background: "linear-gradient(240deg, rgba(255, 168, 37, 0.4) -16.41%, rgba(255, 220, 37, 0) 71.96%);"
        },
        {
            bonusText: "100% Bonus +",
            subText: "5 Free Bet In Sports",
            status: "In Progress",
            image: "https://bc.game/modules/bonus2/assets/banner-sport-383774b2.png",
            background: "linear-gradient(240deg, rgba(64, 163, 106, 0.8) -16.41%, rgba(36, 220, 114, 0) 71.96%);"
        },
        // {
        //   bonusText: "200% Bonus",
        //   subText: "10 Free Spins",
        //   status: "Available",
        //   image: "/path-to-spins-image.png",
        //   background: "#2A2A2A"
        // }
    ];

    return (
        <Grid>
            {cards.map((card, index) => (
                <>


                    <Card key={index} background={card.background}>
                        <ContentWrapper>
                            <BonusText>{card.bonusText}</BonusText>
                            <SubText>{card.subText}</SubText>
                            {/* <Status>{card.status}</Status> */}
                        </ContentWrapper>
                        <Image src={card.image} alt={`${card.subText} icon`} />
                    </Card>

                </>
            ))}
            <Card1 bgUrl="https://bc.imgix.net/bc_event/e2ee5c41ab.png?_v=4&auto=format&dpr=1&w=1200" >

                <ContentWrapper>
                    {/* <BonusText>20%</BonusText>
            <SubText>Depsoit</SubText> */}
                    {/* <Status>{card.status}</Status> */}
                </ContentWrapper>



            </Card1>
            <Card1 bgUrl="https://bc.imgix.net/bc_event/8a0a66f9f3.png?_v=4&auto=format&dpr=1&w=1200" >

                <ContentWrapper>
                    {/* <BonusText>20%</BonusText>
      <SubText>Depsoit</SubText> */}
                    {/* <Status>{card.status}</Status> */}
                </ContentWrapper>



            </Card1>
            <Card1 bgUrl="https://bc.imgix.net/bc_event/9951acd516.png?_v=4&auto=format&dpr=1&w=1200" >

                <ContentWrapper>
                    {/* <BonusText>20%</BonusText>
      <SubText>Depsoit</SubText> */}
                    {/* <Status>{card.status}</Status> */}
                </ContentWrapper>



            </Card1>
            <Card1 bgUrl="https://bc.imgix.net/bc_event/cc6ab29bd8.png?_v=4&auto=format&dpr=1&w=1200" >

                <ContentWrapper>
                    {/* <BonusText>20%</BonusText>
      <SubText>Depsoit</SubText> */}
                    {/* <Status>{card.status}</Status> */}
                </ContentWrapper>



            </Card1>
        </Grid>
    );
};

export default BonusCards;