import React from "react";
import styled from "styled-components";

const AffiliateBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;
`;

const SectionHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #2c3137;
  margin-bottom: 20px;
`;

const StepTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: max-content;
  font-size: 20px;

  @media (max-width: 911px) {
    font-size: 16px;
  }
`;

const Title = styled.h3`
  font-size: 20px !important;
  font-weight: bold;
  margin: 0 !important;

  @media (max-width: 911px) {
    font-size: 16px !important;
  }
`;

const Button = styled.button`
  position: absolute;
  right: 10px;
  top: 2px;
  background-color: #2b2f33;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;

  @media (max-width: 911px) {
    right: 0px;
  }
`;

const CommissionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;

  @media (max-width: 911px) {
    flex-direction: column;
    align-items: start;
  }
`;

const CommissionBox = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const CommissionDetails = styled.div`
  padding: 10px 25px;
  background-color: #2b2f33;
  border-radius: 10px;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: 911px) {
    flex-direction: column;
    align-items: start;
  }
`;

const StepBox = styled.div`
  display: flex;
  align-items: start;
  gap: 15px;
  border-radius: 10px;
  padding: 10px;
  background-color: #2b2f33;
  width: 100%;
  min-height: 11vh;

  @media (max-width: 911px) {
    min-height: unset;
  }
`;

const StepNumber = styled.div`
  color: #24EE89;
  font-size: 48px;
  font-weight: bold;
`;

const StepText = styled.div`
  color: #24EE89;
  font-weight: bold;
`;
const RewardsTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;

  /* Hide scrollbar but keep functionality */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const RewardsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Montserrat, sans-serif;
  border-radius: 10px;
  min-width: 300px; // Ensures minimum width for content

  th {
    padding: 10px 8px;
    font-weight: bold;
    border-bottom: 1px solid #2c3137;
    color: #ffffff90;
    font-size: 14px;

    @media (max-width: 768px) {
      padding: 8px 6px;
      font-size: 12px;
    }
  }

  td {
    padding: 8px;
    text-align: left;

    @media (max-width: 768px) {
      padding: 6px;
      font-size: 12px;
    }
  }

  tbody {
    tr:hover {
      background-color: #2c3137;
    }

    td {
      font-weight: bold;
    }
  }
`;

const LevelCell = styled.td`
  display: flex;
  gap: 8px;
  align-items: center;
  width: max-content;
  justify-content: start;
  margin-right: auto;

  img {
    width: 25px;
    height: 25px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
`;


class AffiliateRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rulesModal: false,
    };
  }

  handleRulesModal = (bool) => {
    this.setState({ rulesModal: bool });
  };

  render() {
    const VIPLevelData = [
      {
        id: 1,
        level: "VIP 04",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAcCAMAAABS8b9vAAAAwFBMVEUAAADnmTLNdgrdmyvnmTDomTHnmTHnmTHnlzDQehDnmjHMdQvnmTLnmzTKdQvMdQrMdgrMdArnmjLomTLLdArLdAnNdQvPeAj6qT3/uVzMdQr2zjf/4GnnmTHrnTXuoj3/vl7yojjpmzP+uFr9tVb5sTz3pjv/22f/12b2rUv5rDz2yTjunzX/wl782ln5sVH9tE3zqUb300T7rEPjlSzhkSnVghfRfBH+3WP/yGH6zU38r0n4tT33ujrdiyPdiyK4SHOlAAAAGHRSTlMAgN8QYO/ffyDfv79wQDDvz6Cfj4BwYCACrNBxAAAA8klEQVQoz5XRx5LCMAwGYFIJbXtxYuQQp7CBwMLStgHv/1bIwcQxOfFfpPk0o4PUUrE81StsO9BuqO2wPGeureGgy5ex7/tLcAcVel0IEUWSEJ68y9LwU1A1eLaQDZA4Gp1rzvuCQ6njcenJihk1/hgO0eM/QhTPEEV+/0mdf0qcU7pXLFfMvyidEp1n34iUpjrHC7QGLwjBBVNKJ4pZssI+RZmkpOI+RERLBHZ5U5dHNeSOLT9gAM/OmDEwrOq0nQMUGWIBx05LxQx2Gygi2OwCU+Mg2K7XWyw6yzS59/rWu+b7lztR3x/NhxpbqsXxzTkB6pAmmuZQUCUAAAAASUVORK5CYII=",
        wagerValue: "1000",
        rewardAmount: "$0.50",
      },
      {
        id: 2,
        level: "VIP 08",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAcCAMAAABS8b9vAAAAwFBMVEUAAADnmTLNdgrdmyvnmTDomTHnmTHnmTHnlzDQehDnmjHMdQvnmTLnmzTKdQvMdQrMdgrMdArnmjLomTLLdArLdAnNdQvPeAj6qT3/uVzMdQr2zjf/4GnnmTHrnTXuoj3/vl7yojjpmzP+uFr9tVb5sTz3pjv/22f/12b2rUv5rDz2yTjunzX/wl782ln5sVH9tE3zqUb300T7rEPjlSzhkSnVghfRfBH+3WP/yGH6zU38r0n4tT33ujrdiyPdiyK4SHOlAAAAGHRSTlMAgN8QYO/ffyDfv79wQDDvz6Cfj4BwYCACrNBxAAAA8klEQVQoz5XRx5LCMAwGYFIJbXtxYuQQp7CBwMLStgHv/1bIwcQxOfFfpPk0o4PUUrE81StsO9BuqO2wPGeureGgy5ex7/tLcAcVel0IEUWSEJ68y9LwU1A1eLaQDZA4Gp1rzvuCQ6njcenJihk1/hgO0eM/QhTPEEV+/0mdf0qcU7pXLFfMvyidEp1n34iUpjrHC7QGLwjBBVNKJ4pZssI+RZmkpOI+RERLBHZ5U5dHNeSOLT9gAM/OmDEwrOq0nQMUGWIBx05LxQx2Gygi2OwCU+Mg2K7XWyw6yzS59/rWu+b7lztR3x/NhxpbqsXxzTkB6pAmmuZQUCUAAAAASUVORK5CYII=",
        wagerValue: "5000",
        rewardAmount: "$2.50",
      },
      {
        id: 3,
        level: "VIP 14",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAcCAMAAABS8b9vAAAAwFBMVEUAAADnmTLNdgrdmyvnmTDomTHnmTHnmTHnlzDQehDnmjHMdQvnmTLnmzTKdQvMdQrMdgrMdArnmjLomTLLdArLdAnNdQvPeAj6qT3/uVzMdQr2zjf/4GnnmTHrnTXuoj3/vl7yojjpmzP+uFr9tVb5sTz3pjv/22f/12b2rUv5rDz2yTjunzX/wl782ln5sVH9tE3zqUb300T7rEPjlSzhkSnVghfRfBH+3WP/yGH6zU38r0n4tT33ujrdiyPdiyK4SHOlAAAAGHRSTlMAgN8QYO/ffyDfv79wQDDvz6Cfj4BwYCACrNBxAAAA8klEQVQoz5XRx5LCMAwGYFIJbXtxYuQQp7CBwMLStgHv/1bIwcQxOfFfpPk0o4PUUrE81StsO9BuqO2wPGeureGgy5ex7/tLcAcVel0IEUWSEJ68y9LwU1A1eLaQDZA4Gp1rzvuCQ6njcenJihk1/hgO0eM/QhTPEEV+/0mdf0qcU7pXLFfMvyidEp1n34iUpjrHC7QGLwjBBVNKJ4pZssI+RZmkpOI+RERLBHZ5U5dHNeSOLT9gAM/OmDEwrOq0nQMUGWIBx05LxQx2Gygi2OwCU+Mg2K7XWyw6yzS59/rWu+b7lztR3x/NhxpbqsXxzTkB6pAmmuZQUCUAAAAASUVORK5CYII=",
        wagerValue: "17000",
        rewardAmount: "$5.00",
      },
      {
        id: 4,
        level: "VIP 22",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAcCAMAAABS8b9vAAAAwFBMVEUAAADnmTLNdgrdmyvnmTDomTHnmTHnmTHnlzDQehDnmjHMdQvnmTLnmzTKdQvMdQrMdgrMdArnmjLomTLLdArLdAnNdQvPeAj6qT3/uVzMdQr2zjf/4GnnmTHrnTXuoj3/vl7yojjpmzP+uFr9tVb5sTz3pjv/22f/12b2rUv5rDz2yTjunzX/wl782ln5sVH9tE3zqUb300T7rEPjlSzhkSnVghfRfBH+3WP/yGH6zU38r0n4tT33ujrdiyPdiyK4SHOlAAAAGHRSTlMAgN8QYO/ffyDfv79wQDDvz6Cfj4BwYCACrNBxAAAA8klEQVQoz5XRx5LCMAwGYFIJbXtxYuQQp7CBwMLStgHv/1bIwcQxOfFfpPk0o4PUUrE81StsO9BuqO2wPGeureGgy5ex7/tLcAcVel0IEUWSEJ68y9LwU1A1eLaQDZA4Gp1rzvuCQ6njcenJihk1/hgO0eM/QhTPEEV+/0mdf0qcU7pXLFfMvyidEp1n34iUpjrHC7QGLwjBBVNKJ4pZssI+RZmkpOI+RERLBHZ5U5dHNeSOLT9gAM/OmDEwrOq0nQMUGWIBx05LxQx2Gygi2OwCU+Mg2K7XWyw6yzS59/rWu+b7lztR3x/NhxpbqsXxzTkB6pAmmuZQUCUAAAAASUVORK5CYII=",
        wagerValue: "49000",
        rewardAmount: "$12.00",
      },
      {
        id: 5,
        level: "VIP 30",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAcCAMAAABS8b9vAAAAwFBMVEUAAADnmTLNdgrdmyvnmTDomTHnmTHnmTHnlzDQehDnmjHMdQvnmTLnmzTKdQvMdQrMdgrMdArnmjLomTLLdArLdAnNdQvPeAj6qT3/uVzMdQr2zjf/4GnnmTHrnTXuoj3/vl7yojjpmzP+uFr9tVb5sTz3pjv/22f/12b2rUv5rDz2yTjunzX/wl782ln5sVH9tE3zqUb300T7rEPjlSzhkSnVghfRfBH+3WP/yGH6zU38r0n4tT33ujrdiyPdiyK4SHOlAAAAGHRSTlMAgN8QYO/ffyDfv79wQDDvz6Cfj4BwYCACrNBxAAAA8klEQVQoz5XRx5LCMAwGYFIJbXtxYuQQp7CBwMLStgHv/1bIwcQxOfFfpPk0o4PUUrE81StsO9BuqO2wPGeureGgy5ex7/tLcAcVel0IEUWSEJ68y9LwU1A1eLaQDZA4Gp1rzvuCQ6njcenJihk1/hgO0eM/QhTPEEV+/0mdf0qcU7pXLFfMvyidEp1n34iUpjrHC7QGLwjBBVNKJ4pZssI+RZmkpOI+RERLBHZ5U5dHNeSOLT9gAM/OmDEwrOq0nQMUGWIBx05LxQx2Gygi2OwCU+Mg2K7XWyw6yzS59/rWu+b7lztR3x/NhxpbqsXxzTkB6pAmmuZQUCUAAAAASUVORK5CYII=",
        wagerValue: "129000",
        rewardAmount: "$25.00",
      },
      {
        id: 6,
        level: "VIP 38",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAcCAMAAABS8b9vAAAAmVBMVEUAAACLUvpoI9GLSfmNUfmNUfmNUfmPUPduKdeMUfpoJNKLUviLVPtnJc9nI9FnItFoI9GNUvqNUvpoJM9mJM9oI89oIM+MUfl/M/FoI9FxWv+ygP2JSveFQPWANvOsev2RV/mKTviHRvZ8O/WDY/+pdf1zVf2COvNzMt6UW/p6QveHTPSER/B/QOqZcv6hd/2aY/uISPZtKdZNYj5JAAAAF3RSTlMAgN8QYO/fIN+/v3BAMO/PoJ+PgHBgIO9EidIAAADVSURBVCjPldHXEoMgEAVQscT0noDEhjGWqGn//3FZyCCgT7kPspydWRmwVBxf1QqRh9FIXQ9DJq6ByxlH0Vj26AOq7Px+qJm9A4zkLk1ltdA4TRLhYRkijW+XC/eIEMU1ICTJ70Tnl9AHpYrliGdOaUFMrpOcQq4DzsBGnMUEBhSUxhqHfHMl4tPzAo6qJ46wK+50wrRGxDxXvgBm5Q/LkCHHkpl2/K8kfuNuaqnYwafFWcTaKrANDoKqaSpYTJYZ8fx4mg95fVjx9by1Nxo7qoT23/kCzuoiAL1lpEQAAAAASUVORK5CYII=",
        wagerValue: "321000",
        rewardAmount: "$50.00",
      },
      {
        id: 7,
        level: "VIP 46",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAcCAMAAABS8b9vAAAAmVBMVEUAAACLUvpoI9GLSfmNUfmNUfmNUfmPUPduKdeMUfpoJNKLUviLVPtnJc9nI9FnItFoI9GNUvqNUvpoJM9mJM9oI89oIM+MUfl/M/FoI9FxWv+ygP2JSveFQPWANvOsev2RV/mKTviHRvZ8O/WDY/+pdf1zVf2COvNzMt6UW/p6QveHTPSER/B/QOqZcv6hd/2aY/uISPZtKdZNYj5JAAAAF3RSTlMAgN8QYO/fIN+/v3BAMO/PoJ+PgHBgIO9EidIAAADVSURBVCjPldHXEoMgEAVQscT0noDEhjGWqGn//3FZyCCgT7kPspydWRmwVBxf1QqRh9FIXQ9DJq6ByxlH0Vj26AOq7Px+qJm9A4zkLk1ltdA4TRLhYRkijW+XC/eIEMU1ICTJ70Tnl9AHpYrliGdOaUFMrpOcQq4DzsBGnMUEBhSUxhqHfHMl4tPzAo6qJ46wK+50wrRGxDxXvgBm5Q/LkCHHkpl2/K8kfuNuaqnYwafFWcTaKrANDoKqaSpYTJYZ8fx4mg95fVjx9by1Nxo7qoT23/kCzuoiAL1lpEQAAAAASUVORK5CYII=",
        wagerValue: "769000",
        rewardAmount: "$80.00",
      },
      {
        id: 8,
        level: "VIP 54",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAcCAMAAABS8b9vAAAAmVBMVEUAAACLUvpoI9GLSfmNUfmNUfmNUfmPUPduKdeMUfpoJNKLUviLVPtnJc9nI9FnItFoI9GNUvqNUvpoJM9mJM9oI89oIM+MUfl/M/FoI9FxWv+ygP2JSveFQPWANvOsev2RV/mKTviHRvZ8O/WDY/+pdf1zVf2COvNzMt6UW/p6QveHTPSER/B/QOqZcv6hd/2aY/uISPZtKdZNYj5JAAAAF3RSTlMAgN8QYO/fIN+/v3BAMO/PoJ+PgHBgIO9EidIAAADVSURBVCjPldHXEoMgEAVQscT0noDEhjGWqGn//3FZyCCgT7kPspydWRmwVBxf1QqRh9FIXQ9DJq6ByxlH0Vj26AOq7Px+qJm9A4zkLk1ltdA4TRLhYRkijW+XC/eIEMU1ICTJ70Tnl9AHpYrliGdOaUFMrpOcQq4DzsBGnMUEBhSUxhqHfHMl4tPzAo6qJ46wK+50wrRGxDxXvgBm5Q/LkCHHkpl2/K8kfuNuaqnYwafFWcTaKrANDoKqaSpYTJYZ8fx4mg95fVjx9by1Nxo7qoT23/kCzuoiAL1lpEQAAAAASUVORK5CYII=",
        wagerValue: "1793000",
        rewardAmount: "$120.00",
      },
      {
        id: 9,
        level: "VIP 62",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAcCAMAAABS8b9vAAAAmVBMVEUAAACLUvpoI9GLSfmNUfmNUfmNUfmPUPduKdeMUfpoJNKLUviLVPtnJc9nI9FnItFoI9GNUvqNUvpoJM9mJM9oI89oIM+MUfl/M/FoI9FxWv+ygP2JSveFQPWANvOsev2RV/mKTviHRvZ8O/WDY/+pdf1zVf2COvNzMt6UW/p6QveHTPSER/B/QOqZcv6hd/2aY/uISPZtKdZNYj5JAAAAF3RSTlMAgN8QYO/fIN+/v3BAMO/PoJ+PgHBgIO9EidIAAADVSURBVCjPldHXEoMgEAVQscT0noDEhjGWqGn//3FZyCCgT7kPspydWRmwVBxf1QqRh9FIXQ9DJq6ByxlH0Vj26AOq7Px+qJm9A4zkLk1ltdA4TRLhYRkijW+XC/eIEMU1ICTJ70Tnl9AHpYrliGdOaUFMrpOcQq4DzsBGnMUEBhSUxhqHfHMl4tPzAo6qJ46wK+50wrRGxDxXvgBm5Q/LkCHHkpl2/K8kfuNuaqnYwafFWcTaKrANDoKqaSpYTJYZ8fx4mg95fVjx9by1Nxo7qoT23/kCzuoiAL1lpEQAAAAASUVORK5CYII=",
        wagerValue: "4097000",
        rewardAmount: "$205.00",
      },
      {
        id: 10,
        level: "VIP 70",
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAcCAMAAABS8b9vAAAAnFBMVEUAAAD2Uvq5I9HwSfn0Ufn1Ufn3UPf1Ufn2Ufq6JNL3VPu6Jc+6I9H2UfnBKtfBKda6ItG5I9H1Uvr2Uvq3JM/2Uvj0Uvi4JM+3I8+3IM/1UfnWM/G5I9H/Wu79gOLtSvf2V/beO/H9eeXbN/LxTfjmQvbzT/jqRvbiPvTnQ/D5Ve/8c+jqSPL6Yu//ZOvfQOrKMNz3XfPPNeDBKdY/QCCmAAAAGnRSTlMAgN8QYO8g37+/QDDv39/fz6Cfj4BwcHBgIHcu2AoAAADSSURBVCjPldHJFoIgFIBhcapsngtEnGfT6v3frSsdRHLVvxD44LAQTWbYci4RWRhN1LQwNDMVdOZgvJkzoA0oO9jDpWpHAxiJle+L2XnEfhhyZwFDA4N6HniaEyLZrzxeU5AxNxwrSlXGcDp8UFoSlZ+AUKByWoNNOCEELigpjUbM+kVA+GfgC5xVSrDJ/+k6ziXmsWWKF8Bx9MWAxcjQRNsWZ7ARZbjdaDLdfdc4S9Kic3WFXffFWAeDyqIJL663xS+vTst+vO/13YgNOYXtv/sAyBklnX1oiAMAAAAASUVORK5CYII=",
        wagerValue: "9217000",
        rewardAmount: "$500.00",
      },
    ];

    return (
      <AffiliateBody>
        <SectionWrapper>
          <SectionHeader>
            <Title>Commission Reward Rate</Title>
            <Button
              onClick={() => {
                this.handleRulesModal(true);
              }}
            >
              View Rules
            </Button>
          </SectionHeader>

          <CommissionContainer>
            <CommissionBox>
              <div style={{ color: "#ffffff90", fontSize: "14px" }}>
                The Original Games
              </div>
              <CommissionDetails>
                Wager x 1% x Commission Rate x 25%
              </CommissionDetails>
            </CommissionBox>
            <CommissionBox>
              <div style={{ color: "#ffffff90", fontSize: "14px" }}>
                3rd Party Slots and Casino Games
              </div>
              <CommissionDetails>
                Wager × 1% × Commission Rate × 60%
              </CommissionDetails>
            </CommissionBox>
          </CommissionContainer>
        </SectionWrapper>

        <SectionWrapper>
          <SectionHeader>
            <Title>How to Get the Referral Rewards</Title>
          </SectionHeader>
          <StepContainer>
            <StepBox>
              <StepNumber>1</StepNumber>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <StepTextWrapper>
                  <StepText>Share</StepText> to Friends
                </StepTextWrapper>
                <div style={{ color: "#ffffff90", fontSize: "14px" }}>
                  Share your referral link or code to your friends
                </div>
              </div>
            </StepBox>

            <StepBox>
              <StepNumber>2</StepNumber>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <StepTextWrapper>
                  Get <StepText>$1000</StepText>
                </StepTextWrapper>
                <div style={{ color: "#ffffff90", fontSize: "14px" }}>
                  Your awards will be locked for now
                </div>
              </div>
            </StepBox>

            <StepBox>
              <StepNumber>3</StepNumber>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <StepTextWrapper>
                  Level Up &<StepText>Receive</StepText>
                </StepTextWrapper>
                <div style={{ color: "#ffffff90", fontSize: "14px" }}>
                  Your friend’s VIP level will unlock your awards (see rules
                  below)
                </div>
              </div>
            </StepBox>
          </StepContainer>

          <RewardsTableWrapper>
    <RewardsTable>
      <thead>
        <tr>
          <th>Friend's Level</th>
          <th style={{ textAlign: "center" }}>Total Wager</th>
          <th style={{ textAlign: "right" }}>Unlock Amount</th>
        </tr>
      </thead>
      <tbody>
        {VIPLevelData.map((item) => (
          <tr key={item.id}>
            <LevelCell>
              <img
                src={item.icon}
                alt="BidwinX VIP Level"
              />
              {item.level}
            </LevelCell>
            <td style={{ textAlign: "center" }}>{item.wagerValue}</td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {item.rewardAmount}
            </td>
          </tr>
        ))}
      </tbody>
    </RewardsTable>
  </RewardsTableWrapper>
        </SectionWrapper>
      </AffiliateBody>
    );
  }
}

export default AffiliateRules;
