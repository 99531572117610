import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.size || '28px'};
  height: ${props => props.size || '28px'};
  
  rect, path {
    fill: white;
  }
`;

const MenuPlayIcon = ({ size }) => {
  return (
    <StyledSVG 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 32 32" 
      size={size}
    >
      {/* Menu Lines */}
      <rect 
        width="19.386" 
        height="3.497" 
        x="5.282" 
        y="5.58" 
        rx="0.6" 
      />
      <rect 
        width="13.879" 
        height="3.497" 
        x="5.925" 
        y="13.83" 
        rx="0.6" 
      />
      <rect 
        width="19.386" 
        height="3.497" 
        x="5.282" 
        y="22.08" 
        rx="0.6" 
      />
      {/* Play Button Triangle */}
      <path 
        d="M27.797 14.714a1 1 0 0 1 0 1.728l-4.178 2.432a1 1 0 0 1-1.503-.864v-4.863a1 1 0 0 1 1.503-.865l4.178 2.432Z"
      />
    </StyledSVG>
  );
};

export default MenuPlayIcon;