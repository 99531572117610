import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import PerfectScrollbar from 'perfect-scrollbar';
import coins from "../../../coins";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import { setCoin, gameCoin, setCredit } from "../../../../actions/gameCoin";
import { __, wait, decode, encode, forceSatoshiFormat, Event, getUID, sendNotfication } from "../../../../Helper";
import C from "../../../../Constant";
import { Search, Wallet, ArrowDownUp, Vault, ChevronRight, WalletCards, X } from 'lucide-react';
import axios from "axios";

class SkylawWalletManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showManage: false,
            isTransferIn: true,
            token: storage.getKey('token'),
            coin: storage.getKey('coin') ? storage.getKey('coin') : "INR",
            activeClientCoins: storage.getKey('active_coins') ? storage.getKey('active_coins') : null,
            selectedCoin: storage.getKey('coin'),
            credits: {},
            coins: [],
            uid: getUID,
            manage_coins: [],
            different: [],
            credit: false,
            usd: storage.getKey('usd') === "true" ? true : false,
            searchTerm: "",
            showWalletModal: false,
            showCurrencyModal: false,
            activeSection: 'deposit',
            activeTab: 'deposit',
            selectedCoinSwapSend: 'INR',
            creditSWapSend: 0,
            isModalOpenSwapSend: false,
            selectedCoinSwapApprox: 'INR',
            creditSWapApprox: 0,
            isModalOpenSwapApprox: false,
            selectedCoinTransferIn: 'INR',
            creditTransferIn: 0,
            isModalOpen: false,
            isModalOpenTransferIn: false,
            selectedCoinTransferOut: 'INR',
            creditTransferOut: 0,
            isModalOpenTransferOut: false,
            amountTransferOut: '',
            amountTransferIn: '',
            vaultAmount: null,
            errorSend: '',
            openTransacationModal: false,
        };

        // Bind all methods in constructor
        this.selectCoin = this.selectCoin.bind(this);
        this.getCredit = this.getCredit.bind(this);
        this.updateCredit = this.updateCredit.bind(this);
        this.closeManage = this.closeManage.bind(this);
        this.activeCoinChange = this.activeCoinChange.bind(this);
        this.setUpManageCoins = this.setUpManageCoins.bind(this);
        this.updateParentCoin = this.updateParentCoin.bind(this);
        this.updateAllCoins = this.updateAllCoins.bind(this);
        
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        this.props.gameCoin();

        Event.on('other_coins', () => {
            this.setState({ showManage: true });
        });

        if (this.state.token !== null)
            socket.emit(C.CREDIT, encode({ token: this.state.token, coin: this.state.coin }));

        socket.on(C.CREDIT, data => this.getCredit(decode(data)));
        socket.on(C.UPDATE_CREDIT, data => this.updateCredit(decode(data)));

        if (this.state.activeClientCoins === null) {
            var arr = [];
            for (var i = 1; i < 8; i++) {
                arr.push(i)
            }
            storage.setKey('active_coins', arr);
        }

        this.setUpManageCoins();

        if (this.state.showManage) {
            wait(1000).then(() => {
                const ps = new PerfectScrollbar('.ccoins', {
                    wheelSpeed: 1,
                    suppressScrollX: true,
                    wheelPropagation: true,
                    minScrollbarLength: 2
                });
                ps.update();
            })
        }

        window.addEventListener('wheel', this.handleScroll, { passive: false });
    }

    componentWillUnmount() {
        window.removeEventListener('wheel', this.handleScroll);
        socket.off(C.CREDIT);
        socket.off(C.UPDATE_CREDIT);
    }

    // Add missing methods that were bound in constructor
    selectCoin(coin) {
        this.setState({ selectedCoin: coin });
        this.props.setCoin(coin);
        storage.setKey('coin', coin);
    }

    updateParentCoin(coin, credit) {
        // Implementation for updating parent coin
        if (this.props.onCoinUpdate) {
            this.props.onCoinUpdate(coin, credit);
        }
    }

    updateAllCoins(coin, credit) {
        // Implementation for updating all coins
        const credits = { ...this.state.credits };
        if (coin) {
            credits[coin] = credit;
            this.setState({ credits });
        }
    }

    getCredit(data) {
        let { credit } = data;
        this.setState({ credits: credit });

        let currentCoin = __.lowerCase(this.props.coin);
        let currentCredit = forceSatoshiFormat(this.state.credits[currentCoin]);
        this.setState({ credit: currentCredit });
        storage.setKey('credit', currentCredit);

        //Set for Redux
        this.props.setCredit(currentCredit);
        this.updateAllCoins();
    }

    updateCredit(data) {
        let credit = data.value;
        let coin = data.coin;

        let different;
        let arr;

        if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
            different = __.toNumber(credit) - __.toNumber(this.state.credit);
            arr = {
                amount: different,
                color: 'text-success'
            }
        } else {
            different = __.toNumber(this.state.credit) - __.toNumber(credit);
            arr = {
                amount: different,
                color: 'text-danger'
            }
        }

        let check = forceSatoshiFormat(different);

        if (check.toString() !== '0.00000000') {
            this.setState(state => ({ different: [arr, ...state.different] }));
        }

        var realCredit = credit
        credit = forceSatoshiFormat(credit);

        this.setState({ credit: credit });
        storage.setKey('credit', credit);

        this.updateParentCoin(__.lowerCase(coin), credit);
        this.updateAllCoins(__.lowerCase(coin), realCredit);

        //Set for Redux
        this.props.setCredit(credit);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({ showManage: this.props.show });
        }
    }

    closeManage() {
        this.setState({ showManage: false });
        if (this.props.onHide) {
            this.props.onHide();
        }
        wait(200).then(() => {
            this.updateAllCoins();
        });
    }

    activeCoinChange(id, active) {
        if (id === 1) return;
        let activeClientCoins = storage.getKey('active_coins');
        let update;

        if (!active) {
            update = activeClientCoins + ',' + id;
        } else {
            update = __.replace(activeClientCoins, "," + id, "");
        }

        update = __.replace(update, null, "");
        const arr = update.match(/\d+/g).map(Number).sort((a, b) => a - b);
        const uniqueArr = __.xor(arr);

        storage.setKey('active_coins', uniqueArr);
        this.setUpManageCoins();
    }

    setUpManageCoins() {
        const activeCoins = storage.getKey('active_coins');
        const ac = activeCoins ? activeCoins.split(",") : [];

        const manage_coins = coins.map((item, key) => {
            const active = ac.some(element => parseFloat(element) === parseFloat(item.id));
            const disabled = item.preffix === 'BTC';

            return (
                <div key={key} className="num-style p-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <img src={'/assets/images/' + item.image} alt="coin" className="mini-coin-12 mr-2" />
                        <span className="text-white">{item.preffix}</span>
                    </div>
                    <div className="custom-control custom-switch switch-success">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`switch_${key}`}
                            checked={active}
                            disabled={disabled}
                            onChange={() => this.activeCoinChange(parseFloat(item.id), active)}
                        />
                        <label className="custom-control-label" htmlFor={`switch_${key}`}>
                            {/* {item.name} */}
                        </label>
                    </div>
                </div>
            );
        });

        this.setState({ manage_coins });
    }

    render() {
        let { credit, different, coin, coins, show, showWallet, searchTerm, isModalOpen } = this.state;
        const { selectedCoinSwapSend, creditSWapSend } = this.state;
        credit = forceSatoshiFormat(credit, coin, this.state.usd);
        
        return (
            <Modal
                size="sm"
                centered={true}
                backdrop={'static'}
                show={this.state.showManage}
                onHide={this.closeManage}
                aria-labelledby="coins-modal"
                className={"animated zoomIn"}
            >
                <Modal.Header className={'font-light'}>
                    Manage Coins
                    <button type="button" className="close p-2" onClick={this.closeManage}>
                        <i className={'mdi mdi-close'} />
                    </button>
                </Modal.Header>
                <Modal.Body className="ccoins p-1">
                    {this.state.manage_coins}
                </Modal.Body>
            </Modal>
        );
    }
}

SkylawWalletManage.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    setCoin: PropTypes.func.isRequired,
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, { setCoin, gameCoin, setCredit })(SkylawWalletManage);